import { SEC_INTO_MSEC } from '@services/time';

/**
 * const latitude  = position.coords.latitude;
 * const longitude = position.coords.longitude;
 * @return {Promise<GeolocationPosition, GeolocationPositionError>}
 */
export const attemptToUseGeoLocation = () =>
  new Promise(function (rs, rj) {
    if (!('geolocation' in navigator)) {
      rj('Geolocation is not supported by your browser');
      return;
    }
    navigator.geolocation.getCurrentPosition(rs, rj, {
      enableHighAccuracy: true,
      timeout: 60 * SEC_INTO_MSEC,
    });
  });
