import { isError } from '@/api/helpers/redux';
import { apiGetSurveySessionId, apiSurveyQuestions, apiUseDynamicDeleteUrlWithAuth } from '@model/api/apiCommunication';
import { handleImperativeActionThunk } from '@model/middleware/imperativeAction';
import { createSlice } from '@reduxjs/toolkit';
import {
  createAsyncThunkWithTokenRefresh,
  createAutoUnwrappingAsyncThunkWithTokenRefresh,
  serializeErrorOption,
} from '@services/reduxToolkit';
import { isSuccessResponse } from '@ui/support/utils/guards';
import { accessor } from '../../accessors';
import { signout } from '../auth';
import { apiPutDemographicsAsyncThunk } from '../demographics';
import { updateDialogStateOnFinish } from '../dialogs';
import { DIALOG_NAMES } from '../dialogs/dialogNames';
import { getSurveysAsync } from './action-creators';

const name = 'survey';

const CONTINUE = 'continue_response';
const REDIRECT = 'redirect';
const SHOW = 'show';

/**
 * @type {import('./types').SurveyState}
 */
const initialState = {
  swidgetResult: null,
  swidgetError: null,
  availableSurveys: null,
  sessionIdError: null,
  sessionEnded: false,
  isMobile: window.innerWidth < 600,
  exitLink: {},
  errorContext: null,
  isLoading: false,
  pollingTmId: null,
  requireUpdate: false,
};

export const getSessionIdAsyncThunk = createAutoUnwrappingAsyncThunkWithTokenRefresh(
  `api/${name}/getSessionId`,
  apiGetSurveySessionId
);

export const getQuestionsAsyncThunk = createAutoUnwrappingAsyncThunkWithTokenRefresh(
  `api/${name}/getQuestions`,
  apiSurveyQuestions
);

export const deleteSurveySessionAsyncThunk = createAsyncThunkWithTokenRefresh(
  `api/${name}/deleteSession`,
  apiUseDynamicDeleteUrlWithAuth,
  serializeErrorOption
);

const surveySlice = createSlice({
  name,
  initialState,
  reducers: {
    clearSurveyId(state, _action) {
      state.surveySessionId = undefined;
    },
    setSurveyResult(state, action) {
      state.swidgetResult = action.payload;
    },
    setSurveyError(state, action) {
      state.swidgetError = action.payload.error;
    },
    clearSurveyResult(state) {
      state.swidgetResult = null;
    },
    clearSurveyData(state) {
      state.swidgetCommand = null;
      state.surveySessionId = undefined;
      state.sessionId = undefined;
      state.sessionIdError = undefined;
      state.swidgetError = undefined;
    },
    clearEnded(state) {
      state.sessionEnded = false;
    },
    setSurveyPollingTimeout(state, { payload }) {
      state.pollingTmId = payload;
    },
    clearErrorContext(state) {
      state.errorContext = null;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getSessionIdAsyncThunk.fulfilled, (state, { payload }) => {
        const { data, meta, errors } = payload.response;
        if (errors) {
          if (errors[0]) {
            state.sessionIdError = errors[0].message;
            state.errorContext = errors[0].context;
            if ('Forbidden' === errors[0].error) {
              state.errorContext.entry_status = 'forbidden';
            }
          } else {
            state.sessionIdError = `Error ${payload.code}`;
          }
          return;
        }

        state.sessionEnded = false;

        if (data) {
          if (data.action == SHOW) {
            const formattedData = data;
            if (!formattedData.text) formattedData.text = 'remove me into features::survey::index.js';
            /*data.blocks.forEach((block, _idx) => {
              if ('title' === block.type) formattedData.title = block.text
              if ('text' === block.type) formattedData.text += block.text + '<br/>'
            })*/
            state.swidgetResult = formattedData;
          } else {
            state.sessionId = data.id;
            if (meta?.links?.destroy) {
              state.exitLink = meta.links.destroy;
            }
          }
        } else if (meta && meta.action) {
          switch (meta.action.name) {
            case CONTINUE: {
              state.sessionId = meta.action.session_id;
              break;
            }
            case REDIRECT: {
              window.location.replace(meta.action.url);
              break;
            }
            default: {
              break;
            }
          }
        }
      })
      .addCase(getQuestionsAsyncThunk.fulfilled, (state, { payload }) => {
        const { response } = payload;
        if (!response) {
          return;
        }
        state.swidgetCommand = response.data;
      })
      .addCase(getSurveysAsync.pending, state => {
        state.isLoading = true;
      })
      .addCase(getSurveysAsync.rejected, (state, { payload }) => {
        if (isError(payload) && payload.meta?.imperative_action && 403 === payload.error.code) {
          state.requireUpdate = true;
          state.availableSurveys = null;
          // TODO Have to be moved outside redux store
          handleImperativeActionThunk(payload.meta?.imperative_action);
        }
        state.isLoading = false;
      })
      .addCase(getSurveysAsync.fulfilled, (state, { payload }) => {
        state.availableSurveys = payload.data;
        state.isLoading = false;
      })
      .addCase(apiPutDemographicsAsyncThunk.fulfilled, (state, { payload }) => {
        if (isSuccessResponse(payload.response)) {
          state.requireUpdate = false;
        }
      })
      .addCase(deleteSurveySessionAsyncThunk.fulfilled, (state, { payload }) => {
        const { response } = payload;
        if (!response) {
          return;
        }
        state.sessionEnded = true;
        state.errorContext = null;
      })
      // Clear result when dialog is closed
      .addCase(updateDialogStateOnFinish, (state, { payload }) => {
        if (payload.name === DIALOG_NAMES.SURVEY_RESULT) {
          state.swidgetResult = null;
        }
      })
      .addCase(signout, state => {
        // Clean state on signout
        return { ...initialState, isMobile: state.isMobile };
      });
  },
});

const defaultExport = { [name]: surveySlice.reducer };

export default defaultExport;

export const accessSurveyInfo = accessor(name);

export const accessSurveySessionId = () => accessSurveyInfo('sessionId', null);
export const accessExitLink = () => accessSurveyInfo('exitLink', null);
export const accessSurveySessionIdError = () => accessSurveyInfo('sessionIdError', null);
export const accessSurveySwidgetError = () => accessSurveyInfo('swidgetError', null);
export const accessSurveysessionEnded = () => accessSurveyInfo('sessionEnded', null);
export const accessSurveyWidgetCommand = () => accessSurveyInfo('swidgetCommand', null);
export const accessSurveyWidgetResult = () => accessSurveyInfo('swidgetResult', null);
export const accessAvailableSurveys = fallbackValue => accessSurveyInfo('availableSurveys', fallbackValue || null);
export const accessIsMobile = () => accessSurveyInfo('isMobile', null);

export const {
  setSurveyResult,
  clearSurveyResult,
  clearSurveyData,
  clearEnded,
  setSurveyError,
  setSurveyPollingTimeout,
  clearErrorContext,
} = surveySlice.actions;

/**
 * @param {import('@/store').RootState} state
 */
export const selectIsLoadingForWidget = state => state[name].isLoading && null == state[name].availableSurveys;
/**
 * @param {import('@/store').RootState} state
 * @returns {import('@/store').RootState['survey']['pollingTmId']}
 */
export const selectPollingTimeout = state => state.survey.pollingTmId;

export const selectUpdateRequired = state => state.survey.requireUpdate;
