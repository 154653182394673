import { Stringifiable } from '@model/api/endpoint';
import { provideOneHandlerForCodes } from '@model/api/endpointCallWrapper';
import * as BACKEND from '@model/api/publishedEndpoints';
import { URLTemplateParams } from '@model/api/schemas';
import {
  AuthMagicCodePostRequest,
  AuthMagicCodePostResponse,
  AuthMagicSendPostRequest,
  AuthMagicSendPostResponse,
  AuthSetUsersPasswordPostRequest,
  AuthSetUsersPasswordPostResponse,
  RefreshTokenResponse,
} from '@model/features/auth/types';
import { LocalesAllResponse, LocalesItemParamsRequest, LocalesItemResponse } from '@model/features/locale/types';
import {
  ProfilingGetStepResponse,
  ProfilingPutStepResponse,
  ProfilingStepPutRequestForm,
} from '@model/features/registration/types';
import {
  SurveySessionGetResponse,
  SurveySessionIdRequestForm,
  SurveySessionPostRequestForm,
  SurveySessionPostResponse,
  SurveysResponse,
} from '@model/features/survey/types';
import {
  DeleteUsersCurrentResponse,
  PhoneVerificationResponse,
  RestoreUserResponse,
  UserSignUpRequestForm,
  UsersActivationsPostRequest,
  UsersActivationsPostResponse,
  UsersEmailActivateRequest,
  UsersEmailsSendActivationPostRequest,
  UsersEmailsSendActivationPostResponse,
  UsersPhonePostRequestForm,
  UsersPhonePostResponse,
  UsersPhonesActivatePostRequestForm,
  UsersPhonesActivatePostResponse,
  UsersPushSubscriptionsPostRequest,
  UsersSignUpResponse,
} from '@model/features/user/types';
import { formatURLPath } from '@services/format';

import { DemographicsGetResponse } from '@model/features/demographics/types';
import {
  CashoutResponse,
  EarningsHistoryRequest,
  EarningsHistoryResponse,
  EarningsResponse,
} from '@model/features/earnings/types';
import {
  FriendsGetResponse,
  FriendsInvitationDeleteRequest,
  FriendsInvitationsGetResponse,
  FriendsSettingsGetResponse,
} from '@model/features/friends/types';
import {
  GeoCountriesGetResponse,
  GeoCountriesZipValidationGetResponse,
  GeoValidateGetResponse,
} from '@model/features/geo/types';
import { SettingsGetResponse } from '@model/features/settings/types';
import { UserDataResponse } from '@ui/pages/Settings/components/AccountActions/types';
import { CommentsResponse, NewCommentFormData, SupportTicketsResponse, TicketResponse } from '@ui/support';

export const apiBackendHealth = provideOneHandlerForCodes<{ status: boolean }, void>(BACKEND.BACKEND_HEALTH, null, 200);

export const apiDeleteUserAccount = provideOneHandlerForCodes<DeleteUsersCurrentResponse>(
  BACKEND.USERS_DELETE_CURRENT,
  null,
  200
);

export const apiPostAuthSetUsersPassword = provideOneHandlerForCodes<
  AuthSetUsersPasswordPostResponse,
  { password: string; code: string },
  AuthSetUsersPasswordPostRequest
>(
  BACKEND.USERS_POST_PASSWORD,
  ({ password, code }) =>
    Object({
      body: {
        password: {
          password,
          code,
        },
      },
    }),
  201,
  403
);

export const apiUserRestore = provideOneHandlerForCodes<RestoreUserResponse, string>(
  BACKEND.USERS_RESTORE,
  code =>
    Object({
      body: { subscription_code: code },
    }),
  200,
  404
);

export const apiUserLogin = provideOneHandlerForCodes(
  BACKEND.TOKENS_POST,
  body =>
    Object({
      body,
    }),
  201,
  400,
  401
);

export const apiTokenRefresh = provideOneHandlerForCodes<RefreshTokenResponse, string>(
  BACKEND.TOKENS_REFRESH,
  refresh_token => Object({ body: { refresh_token } }),
  201,
  400,
  401
);

export const apiUserRegistration = provideOneHandlerForCodes(
  BACKEND.USERS_REGISTRATION_POST,
  body =>
    Object({
      body: { user: body },
    }),
  201,
  422
);

export const apiSignUpUserByEmail = provideOneHandlerForCodes<UsersSignUpResponse, UserSignUpRequestForm>(
  BACKEND.USERS_SIGNUP_EMAIL,
  user => Object({ body: { user } }),
  201,
  422,
  403
);

export const apiSignUpUserActivateEmail = provideOneHandlerForCodes<unknown, UsersEmailActivateRequest>(
  BACKEND.USERS_SIGNUP_EMAIL_ACTIVATE,
  ({ code, email }) => Object({ body: { code, email } }),
  200,
  422
);

export const apiAcceptPrivacyTerms = provideOneHandlerForCodes<unknown, void>(
  BACKEND.USERS_POST_ACCEPTANCE,
  () =>
    Object({
      body: {
        token: 'privacy',
        status: 'accept',
      },
    }),
  200,
  422
);

export const apiUserActivation = provideOneHandlerForCodes<UsersActivationsPostResponse, UsersActivationsPostRequest>(
  BACKEND.USERS_ACTIVATIONS_POST,
  body =>
    Object({
      body,
    }),
  200,
  422
);

export const apiUsersPostNewPhone = provideOneHandlerForCodes<UsersPhonePostResponse, UsersPhonePostRequestForm>(
  BACKEND.USERS_POST_PHONES,
  ({ phoneNumber, channel }) =>
    Object({
      body: {
        national_number: phoneNumber,
        channel,
        send_code: true,
      },
    }),
  200,
  422
);

export const apiUsersPostActivatePhone = provideOneHandlerForCodes<
  UsersPhonesActivatePostResponse,
  UsersPhonesActivatePostRequestForm
>(
  BACKEND.USERS_POST_PHONES_ACTIVATE,
  ({ id, phoneNumber, internationalPhoneNumber, code }) =>
    Object({
      params: {
        id: id ?? phoneNumber ?? internationalPhoneNumber,
      },
      body: { code: String(code) },
    }),

  200,
  404,
  422
);

export const apiRetrieveAvailableLocales = provideOneHandlerForCodes<LocalesAllResponse>(
  BACKEND.LOCALES_ALL_GET,
  null,
  200
);

export const apiRetrieveLocaleStrings = provideOneHandlerForCodes<LocalesItemResponse, LocalesItemParamsRequest>(
  BACKEND.LOCALES_GET,
  params =>
    Object({
      params,
    }),
  200
);

// profiling
export const apiProfilingGetCurrentStep = provideOneHandlerForCodes<ProfilingGetStepResponse>(
  BACKEND.PROFILING_GET_CURRENT_STEP,
  null,
  200,
  403,
  451
);

export const apiProfilingSaveStep = provideOneHandlerForCodes<ProfilingPutStepResponse, ProfilingStepPutRequestForm>(
  BACKEND.PROFILING_PUT_STEP,
  ({ stepId, data }) =>
    Object({
      params: { stepId },
      body: {
        step: data || {},
      },
    }),
  201,
  404,
  409,
  422,
  451
);

export const apiRequestMagicLinkForEmail = provideOneHandlerForCodes<
  AuthMagicSendPostResponse,
  AuthMagicSendPostRequest
>(
  BACKEND.AUTHORIZATION_POST_MAGIC_SEND,
  ({ email, did, remember_me }) => Object({ body: { email, did, remember_me } }),
  201,
  403,
  451
);

export const apiExchangeMagicLink = provideOneHandlerForCodes<AuthMagicCodePostResponse, AuthMagicCodePostRequest>(
  BACKEND.AUTHORIZATION_POST_MAGIC,
  ({ code, email, did, remember_me }) =>
    Object({
      body: {
        code,
        email,
        did,
        remember_me,
      },
    }),
  201,
  400,
  404,
  410
);

export const apiGetSurveySessionId = provideOneHandlerForCodes<SurveySessionPostResponse, SurveySessionPostRequestForm>(
  BACKEND.SURVEY_POST_ACQUIRE_SESSION,
  ({ surveyInvitationCode }) =>
    Object({
      params: {
        id: surveyInvitationCode,
      },
    }),
  200,
  201,
  422,
  409,
  403
);

/**
 * Loads the survey question JSON based on the survey session ID
 */
export const apiSurveyQuestions = provideOneHandlerForCodes<SurveySessionGetResponse, SurveySessionIdRequestForm>(
  BACKEND.SURVEY_GET_QUESTIONS,
  ({ sessionId }) =>
    Object({
      params: {
        id: sessionId,
      },
    }),
  200,
  422
);

/**
 * Loads available survey list based on the user ID
 */
export const apiAvailableSurveys = provideOneHandlerForCodes<SurveysResponse>(
  BACKEND.SURVEYS_GET_AVAILABLE,
  null,
  200,
  403,
  422
);

export const apiGetValuesFrom = provideOneHandlerForCodes<unknown, URLTemplateParams>(
  BACKEND.DYNAMIC_URL,
  ({ urlTemplate, data }) =>
    Object({
      params: {
        url: formatURLPath(urlTemplate, data),
      },
    }),
  200
);

/**
 * validating geo form
 * api: /geo/validate
 */
export const apiGetGeoValidate = provideOneHandlerForCodes<
  GeoValidateGetResponse,
  {
    country: string;
    state: string | number;
    zip: string | number;
    county: string | number | undefined;
    street_address: string | undefined;
  }
>(
  BACKEND.GEO_VALIDATION_GET,
  obj => {
    const params = { ...obj };
    Object.keys(params).forEach(key => undefined === params[key] && delete params[key]);
    return Object({ params });
  },
  200,
  404,
  422
);

export const apiUseDynamicUrlWithAuth = provideOneHandlerForCodes<unknown, string>(
  BACKEND.DYNAMIC_GET_URL_AUTH,
  url =>
    Object({
      params: {
        url,
      },
    }),
  200
);

export const apiUseDynamicDeleteUrlWithAuth = provideOneHandlerForCodes<unknown, string>(
  BACKEND.DYNAMIC_DELETE_URL_AUTH,
  url =>
    Object({
      params: {
        url,
      },
    }),
  200
);

export const apiPostDynamicUrlWithAuth = provideOneHandlerForCodes<
  unknown,
  { url: string; data?: Record<string, Stringifiable>; [key: string]: unknown }
>(
  BACKEND.DYNAMIC_POST_URL_AUTH,
  ({ url, body, ...rest }) =>
    Object({
      params: {
        url,
      },
      body,
      ...rest,
    }),
  200
);

/**
 * POST users/current/emails
 */
export const apiUpdateEmailAndSendActivation = provideOneHandlerForCodes<
  UsersEmailsSendActivationPostResponse,
  UsersEmailsSendActivationPostRequest
>(
  BACKEND.USERS_EMAILS_SEND_ACTIVATION,
  payload =>
    Object({
      body: payload,
    }),
  201,
  403,
  422
);

/**
 */
export const apiPostExportGDPRData = provideOneHandlerForCodes<UserDataResponse, { url: string; code?: string }>(
  BACKEND.DYNAMIC_POST_URL_AUTH,
  ({ url, code }) =>
    Object({
      params: {
        url,
      },
      body: {
        'g-recaptcha-response': code,
      },
    }),
  200
);

export const apiResetPasswordForEmail = provideOneHandlerForCodes<unknown, { email: string; rememberMe: boolean }>(
  BACKEND.USERS_RESET_PASSWORD_POST,
  ({ email, rememberMe }) =>
    Object({
      body: { email, remember_me: rememberMe },
    }),
  200,
  404,
  429
);

/**
 * Subscribes the user to push notifications
 */
export const apiPushSubscription = provideOneHandlerForCodes<unknown, UsersPushSubscriptionsPostRequest>(
  BACKEND.USERS_PUSH_SUBSCRIPTIONS,
  ({ did, token }) =>
    Object({
      body: { pvdid: did, token, did, platform: 'web' },
    }),
  201,
  422
);

/**
 * @deprecated use apiPostAuthSetUsersPassword instead
 *
 */
export const apiResetPasswordWithNewPassword = provideOneHandlerForCodes<
  unknown,
  { resetCode: string; newPassword: string }
>(
  BACKEND.SETTINGS_PATCH,
  ({ resetCode, newPassword }) =>
    Object({
      body: {
        password: {
          code: resetCode,
          password: newPassword,
        },
      },
    }),
  200,
  400,
  403,
  422,
  429
);

/**
 * Loads the settings groups
 */
export const apiRequestSettingsGroups = provideOneHandlerForCodes<SettingsGetResponse>(BACKEND.SETTINGS_GET, null, 200);

export const apiSaveAvatarToSettings = provideOneHandlerForCodes<unknown, { formData: FormData }>(
  BACKEND.SETTINGS_AVATAR_PUT,
  ({ formData }) =>
    Object({
      body: formData,
    }),
  200,
  422
);

/**
 */
export const apiSaveSettingsData = provideOneHandlerForCodes<
  SettingsGetResponse,
  FormData | { [name: string]: unknown }
>(
  BACKEND.SETTINGS_PATCH,
  formData =>
    Object({
      body: formData,
    }),
  200,
  400,
  403,
  409,
  422,
  429
);

export const apiZipValidationForCountry = provideOneHandlerForCodes<
  GeoCountriesZipValidationGetResponse,
  { country: string; zip: string }
>(
  BACKEND.ZIP_VALIDATION_GET,
  ({ country, zip }) =>
    Object({
      params: {
        country_code: country,
        zip_code: zip,
      },
    }),
  200,
  404
);

export const apiGetCountriesList = provideOneHandlerForCodes<GeoCountriesGetResponse>(
  BACKEND.COUNTRIES_LIST_GET,
  null,
  200
);

// **** Support endpoints ****
/**
 *
 * @param data - page data
 * @param data.page - current page
 * @param data.perPage - items per page
 */
export const getSupportTicketsList = provideOneHandlerForCodes<
  SupportTicketsResponse,
  { page: number | string; perPage: number | string }
>(
  BACKEND.SUPPORT_GET_TICKETS_LIST,
  ({ page, perPage }) =>
    Object({
      params: {
        page,
        perPage,
      },
    }),
  200
);

/**
 *
 * @param id - ticket id
 */
export const getSupportTicket = provideOneHandlerForCodes<TicketResponse, string | number>(
  BACKEND.SUPPORT_GET_TICKET,
  id =>
    Object({
      params: {
        id,
      },
    }),
  200,
  404
);

/**
 * Resolve support ticket, mark as closed
 * @param id - ticket id
 */
export const resolveSupportTicket = provideOneHandlerForCodes<TicketResponse, number | string>(
  BACKEND.SUPPORT_RESOLVE_TICKET,
  id =>
    Object({
      params: {
        id,
      },
    }),
  200
);

/**
 *
 * @param data - ticket id and comment data
 * @param data.id - ticket id
 * @param data.formData - comment data
 */
export const postCommentToSupportTicket = provideOneHandlerForCodes<
  CommentsResponse,
  { id: string | number; formData: NewCommentFormData }
>(
  BACKEND.SUPPORT_POST_COMMENT,
  ({ id, formData }) => {
    const data = new FormData();
    if (formData.content) {
      data.append('comment[content]', formData.content);
    }
    if (formData.images) {
      for (const image of formData.images) {
        data.append('comment[images][]', image);
      }
    }
    return {
      params: {
        id,
      },
      body: data,
    };
  },
  201,
  422
);

/**
 * Get cash-out information
 */
export const apiGetCashOutInformation = provideOneHandlerForCodes<EarningsResponse>(
  BACKEND.EARNINGS_GET_CASH_OUT,
  null,
  200
);

/**
 * Get cash-out information
 * @param params - page data
 */
export const apiGetEarningsHistory = provideOneHandlerForCodes<EarningsHistoryResponse, EarningsHistoryRequest>(
  BACKEND.EARNINGS_GET_TRANSACTIONS,
  params =>
    Object({
      params,
    }),
  200
);

/**
 * @param  provider
 */
export const apiPostCashOut = provideOneHandlerForCodes<CashoutResponse, string>(
  BACKEND.EARNINGS_POST_CASH_OUT,
  program =>
    Object({
      body: {
        program,
      },
    }),
  201,
  403,
  422
);

export const apiGetFriends = provideOneHandlerForCodes<
  FriendsGetResponse,
  {
    codes: string | string[];
    statuses: string | string[];
    range: string | number;
    sortColumn: string | number;
    page: string | number;
  }
>(
  BACKEND.FRIENDS_GET_LIST,
  ({ codes, statuses, range, sortColumn, page }) =>
    Object({
      params: {
        referral_codes: codes,
        statuses,
        range,
        sort_column: sortColumn,
        page,
      },
    }),
  200,
  422
);

/**
 * delete invitation friend
 */
export const apiDeleteFriendsInvitations = provideOneHandlerForCodes<unknown, FriendsInvitationDeleteRequest>(
  BACKEND.FRIENDS_INVITATION_DELETE,
  ({ id }) =>
    Object({
      params: {
        id,
      },
    }),
  200
);

export const apiGetFriendsInvitations = provideOneHandlerForCodes<
  FriendsInvitationsGetResponse,
  { range: string | number; sortColumn: string | number; page: string | number }
>(
  BACKEND.FRIENDS_GET,
  ({ range, sortColumn, page }) =>
    Object({
      params: {
        range,
        sort_column: sortColumn,
        page,
      },
    }),
  200,
  422
);

export const apiPostFriendsInvitations = provideOneHandlerForCodes<
  { data: string },
  { emails: string[]; captchaValue: string }
>(
  BACKEND.FRIENDS_INVITATION_POST,
  formData => {
    const { emails, captchaValue } = formData;
    const data = { 'g-recaptcha-response': captchaValue, emails };
    return {
      body: data,
    };
  },
  200,
  422,
  498
);

export const apiGetFriendsSettings = provideOneHandlerForCodes<FriendsSettingsGetResponse>(
  BACKEND.FRIENDS_GET_SETTINGS,
  null,
  200
);

export const apiPostFriendsReferralCode = provideOneHandlerForCodes<unknown, string>(
  BACKEND.FRIENDS_REFERRAL_POST,
  formData => {
    const data = { referral_code: formData };
    return {
      body: data,
    };
  },
  200,
  400,
  422
);

export const apiPostUnsubscribe = provideOneHandlerForCodes<
  unknown,
  { code: string; email: string; mailer: string; template: string }
>(
  BACKEND.UNSUBSCRIBE_POST,
  formData => {
    const { code, email, mailer, template } = formData;
    const data = { code, email, mailer, template };
    return {
      body: data,
    };
  },
  200
);

export const apiPostResubscribe = provideOneHandlerForCodes<
  unknown,
  { code: string; email: string; mailer: string; template: string }
>(
  BACKEND.RESUBSCRIBE_POST,
  formData => {
    const { code, email, mailer, template } = formData;
    const data = { code, email, mailer, template };
    return {
      body: data,
    };
  },
  200
);

export const apiGetDemographics = provideOneHandlerForCodes<DemographicsGetResponse>(
  BACKEND.DEMOGRAPHICS_GET,
  null,
  200
);

export const apiPutDemographics = provideOneHandlerForCodes<unknown, FormData | object>(
  BACKEND.DEMOGRAPHICS_PUT,
  formData => {
    const data = { demographic: formData };
    return {
      body: data,
    };
  },
  200,
  422
);

/**
 * Request to send verification code to phone id
 * @param param
 * @param param.id
 * @param param.channel
 */
export const apiSendPhoneVerificationCode = provideOneHandlerForCodes<
  PhoneVerificationResponse,
  { id: string; channel: 'sms' | 'call' }
>(
  BACKEND.USER_PHONES_SEND_VERIFICATION_CODE,
  ({ id, channel }) =>
    Object({
      params: id,
      body: { channel },
    }),
  200,
  404,
  422
);

/**
 * Send received verification code to phone id
 * @param param
 * @param param.id
 * @param param.code
 */
export const apiVerifyPhone = provideOneHandlerForCodes<PhoneVerificationResponse, { id: string; code: string }>(
  BACKEND.USER_PHONES_VERIFY,
  ({ id, code }) =>
    Object({
      params: id,
      body: { code },
    }),
  200,
  404,
  422
);

export const apiGetDebugInfo = provideOneHandlerForCodes<
  unknown,
  { code: number; format: 'html' | 'json' | 'text' | 'xml' | 'js' | 'css' }
>(
  BACKEND.DEBUG_API,
  ({ code, format }) => {
    return {
      params: {
        code,
        format,
      },
    };
  },
  200
);
