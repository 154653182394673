import { FIELD_TYPES } from '@services/fields';
import {
  CheckboxSmartFormComponent,
  DateSmartFormComponent,
  EmailActivationSmartFormComponent,
  MultiselectSmartFormComponent,
  PasswordSmartFormComponent,
  SingleChoiceSmartFormComponent,
  TextConfirmSmartFormComponent,
  TextSmartFormComponent,
} from '@ui/components/SmartFormComponents';
import { ProfilingBirthdayComponent } from '@ui/components/UpdateProfileSteps/profilingBirthdayComponent';
import { ProfilingPassword } from '@ui/components/UpdateProfileSteps/profilingPasswordStep';
import {
  ControlledCheckbox,
  ControlledDropdown,
  ControlledSegmentedDateComponent,
  ControlledTextInput,
  ErrorGroup,
  MultiselectComponent,
  ParentalComponent,
  StaticText,
} from '@ui/elements/forms';
import PhoneVerificationSmartFormComponent from './SmartFormComponents/complexComponents/PhoneVerificationSmartFormComponent';

/**
 *
 * @param {KNOWN_FIELD_TYPES_MAP_TYPE_VALUES} type
 * @param {string} name
 * @return {SmartFormComponent} getComponent returns only known Component type
 */
export function getComponent(type, name, options) {
  const { preferClean, multiple } = options || {};
  // TODO: check on next construction needed
  switch (name) {
    case 'paypal_email': {
      return TextConfirmSmartFormComponent;
    }
    case 'paypal_email_confirmation': {
      return null;
    }
    default: {
      break;
    }
  }

  switch (type) {
    case FIELD_TYPES.ERROR_GROUP: {
      return ErrorGroup;
    }
    case FIELD_TYPES.TEXT:
    case FIELD_TYPES.PLACES_AUTOCOMPLETE: // fallthrough //  return PlacesSmartFormComponent
    case FIELD_TYPES.EMAIL: {
      return preferClean ? ControlledTextInput : TextSmartFormComponent;
    }
    case FIELD_TYPES.TEXT_CLEAN: {
      return ControlledTextInput;
    }
    case FIELD_TYPES.STATIC_TEXT: {
      return StaticText;
    }
    case FIELD_TYPES.PASSWORD: {
      return PasswordSmartFormComponent;
    }
    case FIELD_TYPES.SINGLE_CHOICE: {
      return SingleChoiceSmartFormComponent;
    }

    case FIELD_TYPES.PROFILING_BIRTHDATE: {
      return ProfilingBirthdayComponent;
    }

    case FIELD_TYPES.PROFILING_PASSWORD: {
      return ProfilingPassword;
    }

    case FIELD_TYPES.ADDRESS: {
      return null; // ProfilingHomeAddressComponent
    }

    case FIELD_TYPES.BIRTHDATE:
    case FIELD_TYPES.DATE: {
      return DateSmartFormComponent;
    }

    case FIELD_TYPES.SEGMENTED_DATE: {
      return ControlledSegmentedDateComponent;
    }
    case FIELD_TYPES.DROPDOWN: {
      if (multiple) return MultiselectComponent;
      return ControlledDropdown; //preferClean ? ControlledDropdown : DropdownSmartFormComponent
    }

    case FIELD_TYPES.DROPDOWN_CLEAN: {
      return ControlledDropdown;
    }

    case FIELD_TYPES.CHECKBOX: {
      return preferClean ? ControlledCheckbox : CheckboxSmartFormComponent;
    }

    case FIELD_TYPES.CHECKBOX_CLEAN: {
      return ControlledCheckbox;
    }

    /**
     * @deprecated
     */
    case FIELD_TYPES.MULTISELECT: {
      return MultiselectSmartFormComponent;
    }

    case FIELD_TYPES.TRAIT_PARENTAL: {
      return ParentalComponent;
    }
    case FIELD_TYPES.EMAIL_ACTIVATION: {
      return EmailActivationSmartFormComponent;
    }
    case FIELD_TYPES.PHONE_ACTIVATION: {
      return PhoneVerificationSmartFormComponent;
    }

    // TODO: introduce server-side field type
    case 'paypal_email': {
      return TextConfirmSmartFormComponent;
    }

    default: {
      // eslint-disable-next-line no-console
      console.warn(`getComponent: unknown field type: ${type}`);
      return null;
    }
  }
}
