import { Dialog, DialogProps } from 'primereact/dialog';
import { forwardRef, useEffect, useState } from 'react';

import { concatClsx } from '@/utils/classnames';
import { useOnAnimationEnd } from '@ui/hooks/useOnAnimationEnd';
import './pv-dialog.scss';

type PvDialogProps = Omit<DialogProps, 'children'> & {
  footer?: React.ReactNode;
  title?: string;
  ref?: React.Ref<Dialog>;
  children?: React.ReactNode | (() => React.ReactNode);
};

export const PvDialog = forwardRef<Dialog, PvDialogProps>(
  (
    { footer, children, className, contentClassName, headerClassName, title, maskClassName, visible, ...props },
    ref
  ) => {
    const [innerVisible, setInnerVisible] = useState(visible);

    useEffect(() => {
      if (visible) {
        // mount Dialog instance
        setInnerVisible(true);
      }
      document.body.classList.toggle('dialog-visible', visible);
      return () => {
        document.body.classList.remove('dialog-visible');
      };
    }, [visible]);

    // Listen to animation end event to avoid unmounting the dialog before the animation ends
    useOnAnimationEnd(ref?.['current']?.getMask(), event => {
      // unmount Dialog instance when animation ends and visible is false
      if (!visible && 'p-component-overlay-leave-animation' === event?.animationName) {
        setInnerVisible(false);
        document.body.classList.remove('p-overflow-hidden');
      }
    });

    return innerVisible ? (
      <Dialog
        ref={ref}
        {...props}
        visible={visible}
        className={concatClsx('pv-dialog', className)}
        maskClassName={concatClsx('pv-dialog-mask', maskClassName)}
        headerClassName={headerClassName ? `pv-dialog-header ${headerClassName}` : 'pv-dialog-header'}
        contentClassName={contentClassName ? `pv-dialog-content ${contentClassName}` : 'pv-dialog-content'}
        draggable={false}
      >
        {title != null && (
          <div className="pv-dialog-title font-bold text-dark-i first-letter:uppercase md:mb-12 md:text-title-l dark:text-light-b">
            {title}
          </div>
        )}
        <div className="content-container max-md:px-6">{'function' === typeof children ? children() : children}</div>
        {footer != null && <div className="pv-dialog-footer">{footer}</div>}
      </Dialog>
    ) : null;
  }
);

PvDialog.displayName = 'PvDialog';
