import { UserStatus } from '@/api/user/user-status';
import { RootState } from '@/store';
import { createSelector } from '@reduxjs/toolkit';
import { FALLBACK_AVATAR_URL } from './const';

export const selectUserAvatar = (state: RootState) => state.user?.profile?.avatar?.original ?? FALLBACK_AVATAR_URL;
export const selectUserAvatarThumb = (state: RootState) => state.user.profile?.avatar?.thumb ?? FALLBACK_AVATAR_URL;

export const isEarningsFrozen = (state: RootState) => Boolean(state.user?.earnings?.frozen);

export const cashoutResult = (state: RootState) => state.earnings.result;

export const hasSupportUpdates = (state: RootState) => state.user.support?.updates ?? false;

export const selectDashboardProfileProgress = (state: RootState) => state.user.dashboard?.fill_profile;

export const selectDedupScriptUrl = (state: RootState) => state.user.dd2_script_url;

export const selectUserTrustScore = (state: RootState) => state.user.traitscore?.current ?? 0;
export const selectUserTraitScoreProgress = (state: RootState) => state.user.traitscore?.progress ?? 0;

export const selectUserStatus = (state: RootState) => state.user.status;
export const selectIsUserAlive = (state: RootState) => UserStatus.ALIVE === state.user.status;
export const selectIsUserFetching = (state: RootState) => state.user.isFetching;

export const selectUserName = (state: RootState) => state.user.profile?.username ?? null;
export const selectProfileProgress = (state: RootState) => state.user.profile?.progress ?? 0;

export const selectUsersEarnings = (state: RootState) => state.user.earnings?.current ?? 0;

export const selectCashoutWidgetData = createSelector(
  [(state: RootState) => state.user.earnings, (state: RootState) => state.user.cashout?.threshold],
  (earnings, threshold) => {
    return {
      earnings: earnings?.current ?? 0,
      overall: earnings?.total ?? 0,
      currentYear: earnings?.ytd ?? 0,
      balance: earnings?.balance ?? 0,
      threshold: threshold ?? 0,
      progress: earnings?.progress ?? 0,
      frozen: Boolean(earnings?.frozen),
    };
  }
);

export const selectBanners = createSelector(
  [(state: RootState) => state.user.banners, (state: RootState) => state.user.handledBanners],
  (banners, handledBanners) => {
    const handledBannersSet = new Set(handledBanners || []);
    return (banners || []).filter(banner => !handledBannersSet.has(banner.token));
  }
);
