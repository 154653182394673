type StorageChangeCallback = (newValue: string | null, oldValue: string | null) => void;

const listeners: Record<string, StorageChangeCallback> = {};

window.addEventListener('storage', ({ key, newValue, oldValue }) => {
  if (key != null) {
    listeners[key]?.(newValue, oldValue);
  }
});

export const StorageListener = {
  addListener(key: string, listener: StorageChangeCallback) {
    listeners[key] = listener;
  },
  removeListener(key: string) {
    delete listeners[key];
  },
};
