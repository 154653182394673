import * as yup from 'yup';

export const schemaFriendsInvitationDeleteParams = yup.object({ id: yup.number() }).required();
export const schemaFriendsInvitationsGetParamsRequest = yup
  .object({
    range: yup.string(),
    sort_column: yup.string(),
    page: yup.number(),
  })
  .required();
export const schemaFriendsInvitationsGetResponse = yup
  .object({
    data: yup.object({
      friends: yup.array().of(
        yup.object({
          name: yup.string(),
          status: yup.string(),
          created_at: yup.string(),
        })
      ),
      totals: yup.object({
        friends: yup.number(),
      }),
    }),
    meta: yup.object({
      current_page: yup.number(),
      next_page: yup.number().nullable(),
      prev_page: yup.number().nullable(),
      total_pages: yup.number(),
      total_count: yup.number(),
    }),
    links: yup.object({
      create: yup.object({
        href: yup.string(),
        meta: yup.object({
          validate: yup.object({
            recaptcha: yup.object({
              public_key: yup.string(),
            }),
          }),
        }),
      }),
      referral_code: yup.string(),
    }),
  })
  .required();
export const schemaFriendsInvitationPostRequest = yup
  .object({
    emails: yup.array().of(yup.string().email()),
    'g-recaptcha-response': yup.string().required(),
  })
  .required();
export const schemaFriendsReferralPostRequest = yup
  .object({
    referral_code: yup.string().required(),
  })
  .required();
export const schemaFriendsGetParamsRequest = yup
  .object({
    referral_codes: yup.string(),
    statuses: yup.string(),
    range: yup.string(),
    sort_column: yup.string(),
    page: yup.number(),
  })
  .required();
export const schemaFriendsGetResponse = yup
  .object({
    data: yup.object({
      friends: yup.array().of(
        yup.object({
          avatar: yup.object({
            url: yup.string(),
          }),
          name: yup.string(),
          status: yup.string(),
          pending_amount: yup.number(),
          awarded_amount: yup.number(),
          created_at: yup.string(),
        })
      ),
      totals: yup.object({
        friends: yup.number(),
        pending_amount: yup.number(),
        awarded_amount: yup.number(),
      }),
    }),
    meta: yup.object({
      current_page: yup.number(),
      next_page: yup.number().nullable(),
      prev_page: yup.number().nullable(),
      total_pages: yup.number(),
      total_count: yup.number(),
    }),
  })
  .required();
export const schemaFriendsSettingsGetResponse = yup
  .object({
    data: yup
      .object({
        enabled: yup.boolean(),
        referral_code: yup.string(),
        referral_link: yup.string(),
        earning_type: yup.string(),
        show_vip_banner: yup.boolean(),
        // invited_by: yup.string(),
        refcodes: yup.array(),
        invitations_available: yup.boolean(),
        since: yup.string(),
      })
      .required(),
  })
  .required();
