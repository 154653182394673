import { getIsKeepMeLoggedIn } from '@model/features/auth';
import { accessProfilingCurrentData } from '@model/features/registration';
import { useLocaleServices } from '@ui/contextProviders';
import { LoadingSpinner } from '@ui/elements/spinners';
import { Button } from '@ui/lib/Button';
import { UI_TEST_ASSIST_EXT } from '@ui/testids';
import { Password } from 'primereact/password';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import pvs from './profilingFieldWrapper.module.scss';

ProfilingPassword.propTypes = {
  initialState: PropTypes.object,
  onSave: PropTypes.func,
};

export function ProfilingPassword(props) {
  const { onSave } = props;
  const { t } = useLocaleServices();
  const data = useSelector(accessProfilingCurrentData());
  const { stepId, labels = {}, fields } = data;
  const passwordField = fields?.[0];
  const [moreError, setMoreError] = useState(null);

  const { handleSubmit, control } = useForm();

  const onSaveHandler = async payload => {
    setMoreError(null);
    if (onSave) {
      const result = await onSave({
        profiling_password: {
          password: payload.profiling_password || null,
          remember_me: getIsKeepMeLoggedIn(),
        },
      });
      if (result) {
        setMoreError(result);
      }
    }
  };

  const onSkip = async () => {
    setMoreError(null);
    const result = await onSave({
      profiling_password: {
        password: null,
      },
    });
    if (result) {
      setMoreError(result);
    }
  };

  if (!fields) {
    return <LoadingSpinner className="mt-12" />;
  }

  return (
    <div className="profiling-step" {...UI_TEST_ASSIST_EXT.CMP_PROFILING_STEP(stepId)}>
      <div className="profiling-step__title">{t(labels?.title ?? 'labels.title')}</div>
      <div className="profiling-step__subtitle">{t(labels?.subtitle ?? 'labels.subtitle')}</div>
      <form className="form-vertical p-component" onSubmit={handleSubmit(onSaveHandler)}>
        <input type="hidden" name="email" value={passwordField.value} />
        <div className="app-form-control mt-8">
          <label htmlFor="profiling_password">{t(passwordField?.label)}</label>
          {passwordField ? (
            <Controller
              name={passwordField.name}
              defaultValue={''}
              control={control}
              rules={{
                required: passwordField.options?.required,
                minLength: {
                  value: passwordField.options?.min,
                  message: t('profiling.password.errors.min', { min: passwordField.options?.min }),
                },
                maxLength: {
                  value: passwordField.options?.max,
                  message: t('profiling.password.errors.max', { max: passwordField.options?.max }),
                },
              }}
              render={({
                field: { onChange, onBlur, value: fieldValue, name: fieldName, ref },
                fieldState: { error },
              }) => {
                const handleBlur = () => {
                  onBlur();
                  onChange({
                    target: { name: fieldName, value: fieldValue.trim().length ? fieldValue.trim() : fieldValue },
                  });
                };

                return (
                  <>
                    <Password
                      name={fieldName}
                      value={fieldValue}
                      inputRef={ref}
                      onChange={onChange}
                      onBlur={handleBlur}
                      id={data?.name}
                      className="p-fluid"
                      placeholder={t('profiling.password.placeholder')}
                      feedback={false}
                      data-testid="profiling_password"
                      toggleMask
                    />
                    {error && (
                      <div className={`${pvs.error} max-w-72 pl-0`}>
                        <span className="p-error">{error.message}</span>
                      </div>
                    )}
                    {moreError && (
                      <div className={`${pvs.error} max-w-72 pl-0`}>
                        <span className="p-error pl-0">{t(moreError)}</span>
                      </div>
                    )}
                  </>
                );
              }}
            />
          ) : null}
        </div>
        <Button label={t(labels?.next)} type="submit" big />
        <Button className="mt-4" textButton label={t('profiling.password.skip')} onClick={onSkip} />
      </form>
    </div>
  );
}
