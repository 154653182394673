import {
  apiGetValuesFrom,
  apiProfilingGetCurrentStep,
  apiProfilingSaveStep,
  apiUseDynamicUrlWithAuth,
  apiUsersPostActivatePhone,
  apiUsersPostNewPhone,
} from '@model/api';
import { createAsyncThunkWithTokenRefresh, serializeErrorOption } from '@services/reduxToolkit';
import { name } from '@model/features/registration/name';

export const getDropdownValuesAsyncThunk = createAsyncThunkWithTokenRefresh(
  `api/form/dropdown/loadValues`,
  apiGetValuesFrom,
  serializeErrorOption
);
export const getGeneralProtectedDataAsyncThunk = createAsyncThunkWithTokenRefresh(
  `api/general/data`,
  apiUseDynamicUrlWithAuth,
  serializeErrorOption
);

export const apiProfilingGetCurrentStepAsyncThunk = createAsyncThunkWithTokenRefresh(
  `api/${name}/profiling/step`,
  apiProfilingGetCurrentStep,
  serializeErrorOption
);
export const apiProfilingSaveStepAsyncThunk = createAsyncThunkWithTokenRefresh(
  `api/${name}/profiling/save`,
  apiProfilingSaveStep,
  serializeErrorOption
);

export const apiUsersPostNewPhoneAsyncThunk = createAsyncThunkWithTokenRefresh(
  `api/${name}/phone/new`,
  apiUsersPostNewPhone,
  serializeErrorOption
);
export const apiUsersPostActivatePhoneAsyncThunk = createAsyncThunkWithTokenRefresh(
  `api/${name}/phone/activate`,
  apiUsersPostActivatePhone,
  serializeErrorOption
);
