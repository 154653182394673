import { ApiClient } from '@/api/ApiClient';
import { createAsync } from '@/api/helpers/redux';
import { isUserStatus } from '@/api/user/user-status';
import { AppDispatch } from '@/store';
import { apiPostAuthSetUsersPassword, apiSignUpUserActivateEmail, apiUserLogin, apiUserRegistration } from '@model/api';
import { constant, safelyExecute } from '@services/functional';
import { safelyParseJSON } from '@services/json';
import {
  createAsyncThunkWithTokenRefresh,
  createAutoUnwrappingAsyncThunkWithTokenRefresh,
  serializeErrorOption,
} from '@services/reduxToolkit';
import { popDataFromStorage, restoreDataFromStorage, STORAGE_KEY_LIST } from '@services/storage';
import { isTimeWithin, SEC_INTO_MSEC } from '@services/time';
import { persistTokenPair } from '.';
import { authenticationExchangeMagicLinkAsyncThunk } from '../magic/asyncActions';
import { accessUserInfoTimestamp, updateUserInfo, userInfoAsyncThunk } from '../user';
import { oauthFailureResult, oauthSuccessResult } from './actions';
import { name } from './name';
import { updateToken } from './slice';

export const loginAsyncThunk = createAutoUnwrappingAsyncThunkWithTokenRefresh(`api/${name}/login`, apiUserLogin);

export const registerAsyncThunk = createAutoUnwrappingAsyncThunkWithTokenRefresh(
  `api/${name}/register`,
  apiUserRegistration
);
export const apiSignUpUserActivateEmailAsyncThunk = createAutoUnwrappingAsyncThunkWithTokenRefresh(
  `api/${name}/activate/email`,
  apiSignUpUserActivateEmail
);
export const changePasswordAsyncThunk = createAsyncThunkWithTokenRefresh(
  `api/${name}/change-password`,
  apiPostAuthSetUsersPassword,
  serializeErrorOption
);

export const allAuthPending = action =>
  loginAsyncThunk.pending.match(action) || registerAsyncThunk.pending.match(action);

export const allAuthFulfilled = action =>
  loginAsyncThunk.fulfilled.match(action) ||
  registerAsyncThunk.fulfilled.match(action) ||
  authenticationExchangeMagicLinkAsyncThunk.fulfilled.match(action);

export const allAuthRejected = action =>
  loginAsyncThunk.rejected.match(action) || registerAsyncThunk.rejected.match(action);

const destructureTokenMetadata = safelyExecute(({ status, message }) => [status, message], constant([null, null]));

export const actualizeTokenThunk = token => dispatch => {
  if (token) {
    dispatch(userInfoAsyncThunk());
  }
};

export const actualizeUserInfoThunkAct = async (dispatch, getState) => {
  // Need this check here, since this action is used by AppRoutes to update user data when path is changed.
  // It prevents multiple API calls for situations when path is changed multiple times, say, two times,
  // due to fallback redirects
  const ts = accessUserInfoTimestamp()(getState());
  if (isTimeWithin(ts, 10 * SEC_INTO_MSEC)) {
    return;
  }
  await dispatch(userInfoAsyncThunk({ noAutoRedirects: true }));
};

export const restoreTokenFromStorageThunk = (dispatch: AppDispatch) => {
  const restoredStatus = restoreDataFromStorage(STORAGE_KEY_LIST.USER_STATUS, null);
  const restoredToken = restoreDataFromStorage(STORAGE_KEY_LIST.AUTH_TOKEN, null);

  if (restoredToken) {
    dispatch(
      updateToken({
        token: restoredToken,
      })
    );
    if (restoredStatus) {
      dispatch(
        updateUserInfo({
          status: isUserStatus(restoredStatus) ? restoredStatus : undefined,
          timestamp: 0,
        })
      );
    }
    dispatch(actualizeTokenThunk(restoredToken));
  }
  return restoredToken;
};

export async function checkIfOAuthAuthenticationThunkAction(dispatch) {
  const token = popDataFromStorage(STORAGE_KEY_LIST.SOCIAL_AUTH_RESULT_TOKEN, null);
  const refreshToken = popDataFromStorage(STORAGE_KEY_LIST.SOCIAL_AUTH_REFRESH_TOKEN, null);
  const restoredTokenMetaStr = popDataFromStorage(STORAGE_KEY_LIST.SOCIAL_AUTH_RESULT_METADATA, '');
  if (!(token || restoredTokenMetaStr || refreshToken)) {
    return false;
  }

  const restoredTokenMeta = safelyParseJSON(restoredTokenMetaStr);

  const [status, message] = destructureTokenMetadata(restoredTokenMeta);

  switch (status) {
    case 'success': {
      persistTokenPair({ token, refreshToken });
      dispatch(
        oauthSuccessResult({
          message,
          token,
        })
      );
      await dispatch(actualizeTokenThunk(token));
      // do nothing, routing will kick in based on the user's state
      return true;
    }
    case 'error':
    default:
      {
        dispatch(
          oauthFailureResult({
            message,
          })
        );
      }
      return false;
  }
}

// ============== NEW REWRITTEN API ==============
export const saveUserCountryAsyncThunk = createAsync('api/users/current/country', ApiClient.user.saveCountry);
