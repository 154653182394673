import { createAction } from '@reduxjs/toolkit';
import { getTimestamp } from '@services/time';

export const welcomeAction = createAction('STARTED', ({ tenantName, pathname }) => {
  return {
    payload: {
      tenantName,
      pathname: pathname ?? window.location.pathname,
    },
    meta: {
      ts: performance.now(),
      date: getTimestamp(),
    },
  };
});
