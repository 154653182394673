import * as yup from 'yup';
import { MAGIC_ACTION_NAMES } from '../magic/types';

export const schemaAuthTokensRefreshPostResponse = yup.object({
  data: yup
    .object({
      token: yup.string().required(),
      refresh_token: yup.string().required(),
    })
    .required(),
});
export const schemaAuthMagicSendPostRequest = yup
  .object({
    email: yup.string().email().required(),
    did: yup.string(),
    remember_me: yup.boolean().required(),
    'g-recaptcha-response': yup.string().optional(),
  })
  .required();

export const schemaAuthMagicSendPostResponse = yup
  .object({
    data: yup
      .object({
        code_head: yup.string().required(),
        masked_email: yup.string().required(),
      })
      .required(),
  })
  .required();

export const schemaAuthSetUsersPasswordPostRequest = yup
  .object({
    password: yup
      .object({
        password: yup.string().required(),
        code: yup.string().required(),
      })
      .required(),
  })
  .required();

export const schemaAuthSetUsersPasswordPostResponse = yup
  .object({
    meta: yup
      .object({
        password: yup
          .object({
            tokens: yup.tuple([yup.string(), yup.string()]).required(),
            masked_email: yup.string().required(),
          })
          .required(),
      })
      .required(),
  })
  .required();

export const schemaAuthMagicCodePostRequest = yup
  .object({
    code: yup.string().required(),
    email: yup.string().email().optional(),
    did: yup.string().optional(),
    remember_me: yup.boolean().optional(),
  })
  .required('API.AUTHORIZATION_POST_MAGIC.missing_body');

export const schemaAuthMagicCodePostResponse = yup
  .object({
    data: yup
      .object({
        token: yup.string(),
        refresh_token: yup.string(),
      })
      .required(),
    meta: yup
      .object({
        action: yup
          .string()
          .oneOf([
            MAGIC_ACTION_NAMES.LOGIN,
            MAGIC_ACTION_NAMES.ADMIN_TOOL_LOGIN,

            MAGIC_ACTION_NAMES.RESET_PASSWORD,
            MAGIC_ACTION_NAMES.PASSWORD_CHANGE,

            MAGIC_ACTION_NAMES.REGISTRATION_EMAIL_ACTIVATION,
            MAGIC_ACTION_NAMES.SETTINGS_EMAIL_CONFIRMATION,

            MAGIC_ACTION_NAMES.SETTINGS_ALT_EMAIL_CONFIRMATION,
            MAGIC_ACTION_NAMES.SURVEY_INVITATION,
          ])
          .required(),
        payload: yup.object().when('action', {
          is: 'survey_invitation',
          then: schema =>
            schema.shape({
              method: yup.string(),
              survey_id: yup.string(),
              utm_source: yup.string(),
              utm_campaign: yup.string(),
            }),
        }),
      })
      .required(),
  })
  .required();
export const schemaAuthTokensPostRequest = yup
  .object({
    email: yup.string().email().required(),
    password: yup.string().required(),
    did: yup.string().optional(),
  })
  .required();
export const schemaAuthTokensPostResponse = yup
  .object({
    data: yup
      .object({
        token: yup.string().required(),
        refresh_token: yup.string().required(),
      })
      .required(),
  })
  .required();
export const schemaAuthTokensRefreshPostRequest = yup
  .object({
    refresh_token: yup.string().required(),
  })
  .required();
