import { get } from '@services/objects/get';

const templateItemPattern = /%{\s*([^ }]+)\s*}/g;
const templateUrlPathPattern = /{\s*([^ }]+)\s*}/g;

function internalFormat(pattern, template, replacementLookup, fallback) {
  return (template || '').replace(pattern, (_m, p1) => get(replacementLookup || {}, p1.split('.'), fallback));
}

/**
 * @param {string} template
 * @param {{}} replacementLookup?
 * @param {string} fallback?
 * @return {string}
 */
export function format(template, replacementLookup, fallback = '') {
  return internalFormat(templateItemPattern, template, replacementLookup, fallback);
}

export function formatURLPath(template, replacementLookup, fallback = '') {
  return internalFormat(templateUrlPathPattern, template, replacementLookup, fallback);
}
