import { Icons } from '@ui/lib/Icon';

const modeQuery = window.matchMedia('(prefers-color-scheme: dark)');

export function getOsTheme() {
  return modeQuery.matches ? 'dark' : 'light';
}

export function subscribeToOsThemeChange(
  handler: (event: MediaQueryListEvent) => void,
  modeQueryList: MediaQueryList = modeQuery
) {
  const isModernAPI = 'addEventListener' in modeQueryList && 'function' === typeof modeQueryList.addEventListener;
  if (isModernAPI) {
    modeQuery.addEventListener('change', handler);
  } else {
    modeQuery.addListener(handler);
  }
}

export function unsubscribeToOsThemeChange(
  handler: (event: MediaQueryListEvent) => void,
  modeQueryList: MediaQueryList = modeQuery
) {
  const isModernAPI = 'addEventListener' in modeQueryList && 'function' === typeof modeQueryList.addEventListener;
  if (isModernAPI) {
    modeQuery.removeEventListener('change', handler);
  } else {
    modeQuery.removeListener(handler);
  }
}

export const getIcon = (icon: string) => {
  switch (icon) {
    case 'light':
      return Icons.ThemeLight;
    case 'retro':
      return Icons.ThemeRetro;
    case 'dark':
      return Icons.ThemeDark;
    default:
      return Icons.ThemeAuto;
  }
};
