import { useAuth } from '@ui/contextProviders';
import { Button } from '@ui/lib/Button';
import { FormInputText } from '@ui/lib/Form/FormInputText';
import { getEmailRules, getPasswordRules } from '@ui/lib/Form/rules';
import { isSuccessResponse } from '@ui/support/utils/guards';
import { FC, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

type FormData = {
  email: string;
  password: string;
};

export const CcpaLoginForm: FC = () => {
  const { handleSignIn } = useAuth();
  const { t } = useTranslation();
  const { control, handleSubmit } = useForm<FormData>({
    defaultValues: { email: '', password: '' },
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const onSubmit = async (data: FormData) => {
    setLoading(true);
    const res = await handleSignIn(data);

    if (!isSuccessResponse(res.response)) {
      setError(t(res.response.errors[0].message));
    }

    setLoading(false);
  };

  const rules = useRef({ email: getEmailRules(t), password: getPasswordRules(t) });

  return (
    <div className="mt-8 w-full md:w-[19.125rem]">
      <form className="form-vertical p-component p-fluid" onSubmit={handleSubmit(onSubmit)}>
        <FormInputText
          control={control}
          name="email"
          type="email"
          placeholder={t('labels.email.placeholder')}
          aria-label={t('labels.email.aria')}
          rules={rules.current.email}
        />
        <FormInputText
          control={control}
          name="password"
          type="password"
          placeholder="Password"
          rules={rules.current.password}
        />
        {error && <div className="p-error my-4 pl-6">{error}</div>}
        <Button type="submit" label={t('login.login')} big loading={loading} />
      </form>
    </div>
  );
};
