import { FIELD_TYPES } from '@services/fields';
import { useLocaleServices } from '@ui/contextProviders';
import { IconCakeCandlesDuotone } from '@ui/elements/icons';
import { omitParaRenderer } from '@ui/elements/markdown/renderers/paragraph';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import ReactMarkdown from 'react-markdown';
import './profiling-birthday-wrapper.scss';

const mdComponents = {
  p: omitParaRenderer,
};

ProfilingBirthdayWrapper.propTypes = {
  type: PropTypes.string,
  children: PropTypes.any,
  label: PropTypes.string,
  name: PropTypes.string,
  options: PropTypes.object,
};

export function ProfilingBirthdayWrapper({ type, children, label, name, options }) {
  const { t } = useLocaleServices();
  const { watch, getFieldState } = useFormContext();
  const { error } = getFieldState(name);
  void watch(name);
  if (type === FIELD_TYPES.SEGMENTED_DATE) {
    return (
      <div className="flex flex-col items-center md:flex-row md:items-baseline">
        <IconCakeCandlesDuotone
          alt="birthday cake with candles"
          className="block w-full max-w-[4rem] shrink-0 md:mr-7"
        />
        {children}
      </div>
    );
  } else if (type === FIELD_TYPES.CHECKBOX_CLEAN) {
    return (
      <>
        <div className="flex flex-row items-center">
          <div className="flex flex-row items-center">
            <label className="ml-4 mt-2 flex items-center md:ml-28">
              {children}
              <ReactMarkdown components={mdComponents} className="highlightable-label ml-3">
                {t(label, options)}
              </ReactMarkdown>
            </label>
          </div>
        </div>
        {error && <div className="error">{error.message}</div>}
      </>
    );
  }
  return (
    <>
      <div className="flex flex-row items-center">
        <div className="birthday-cake-spacer ml-28"></div>
        <div className="flex flex-row items-center">
          <div>{children}</div>
          <div>{t(label)}</div>
        </div>
      </div>
      {error && <div className="error">{error.message}</div>}
    </>
  );
}
