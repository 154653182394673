import { metaSchema } from '@model/api/schemas';
import { array, InferType, number, object, string, tuple } from 'yup';
import { schemaDemographicsGetResponse } from '@model/features/demographics/schema';

export type TraitValue = '1' | '0' | 'x';

export type Trait = {
  id: number;
  name: string;
  value: TraitValue | null;
  values: Map<string, TraitValue>;
};

export type DemographicTraits = {
  traits: Trait[];
  rowsPerPage: number;
  currentPage: number;
  totalCount: number;
};

const traitSchema = object({
  id: number().required(),
  name: string().required(),
  value: string<TraitValue>().nullable().default(null),
  values: array()
    .of(tuple([string().required(), string<TraitValue>().required()]).required())
    .required(),
});

export const resSchema = object({
  data: object({
    tag_assignments: array().of(traitSchema).required(),
  }),
});

export const resSchemaWithMeta = resSchema.concat(
  object({
    meta: metaSchema.required(),
  })
);

export const traitUpdateSchema = object({
  tag_assignments: array()
    .of(
      object({
        id: number().required(),
        value: string<TraitValue>().required(),
      })
    )
    .required(),
  'g-recaptcha-response': string().required(),
});

export type TraitsResponse = InferType<typeof resSchema>;
export type TraitsWithMetaResponse = InferType<typeof resSchemaWithMeta>;
export type TraitsUpdateRequest = InferType<typeof traitUpdateSchema>;
export type TraitsToSave = TraitsUpdateRequest['tag_assignments'];

export type DemographicsGetResponse = InferType<typeof schemaDemographicsGetResponse>;
