import { init, reactRouterV6BrowserTracingIntegration } from '@sentry/react';
import React from 'react';
import { createRoutesFromElements, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';

export function initSentry() {
  const cfg = window.context.sentry_config;

  if (!cfg) {
    return;
  }

  init({
    dsn: cfg.dsn,
    environment: cfg.environment,
    release: process.env.RELEASE_VERSION,
    debug: 'development' === process.env.NODE_ENV,
    initialScope: {
      tags: {
        client: 'web_app',
        platform: window.innerWidth < 768 ? 'mobile' : 'desktop',
      },
    },
    integrations: integrations => [
      ...integrations,
      reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren: createRoutesFromElements,
        matchRoutes,
      }),
    ],
    tracePropagationTargets: [/^https:\/\/app.paidviewpoint.com/, /^https:\/\/.+\.review\.vpn\.aytm\.com/],
  });
}
