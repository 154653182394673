import { useAuth } from '@ui/contextProviders/Auth';
import { useShowSuccess } from '@ui/contextProviders/ErrorReporting';
import { useLocaleServices } from '@ui/contextProviders/Locale';
import { useHistorySelector } from '@ui/hooks';
import { Button } from '@ui/lib/Button';
import { isOnPages, paths } from '@ui/navigation';
import { routeNames } from '@ui/routes/routeNames';
import { useMemo } from 'react';
import { useNavigate } from 'react-router';

const isOnDashboardCheck = isOnPages(paths.toDashboard);
/**
 *
 * @returns @deprecated
 */
export const BottomButtons = () => {
  const { handleSignOut } = useAuth();
  const showSuccess = useShowSuccess();
  const { t } = useLocaleServices();
  const navigate = useNavigate();
  const pathname = useHistorySelector(({ pathname }) => pathname);

  const isDashboard = useMemo(() => isOnDashboardCheck(pathname), [pathname]);

  const handleLogout = () => {
    handleSignOut();
    showSuccess(t('sign_out.success_toast_message'));
  };

  return (
    <div className="my-8 flex w-full flex-col">
      {!isDashboard && (
        <Button
          variant="accent-b"
          label={t('labels.back_to_dashboard')}
          icon="pi pi-angle-left"
          iconPos="left"
          onClick={() => navigate(routeNames.dashboard)}
          aria-label="Back to dashboard"
        />
      )}
      <Button variant="secondary" className="mt-4" label="Logout" onClick={handleLogout} />
    </div>
  );
};
