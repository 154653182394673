import { maskEmail } from '@/utils/string/email';
import { getIsKeepMeLoggedIn } from '@model/features/auth';
import {
  apiRequestMagicLinkForEmailAsyncThunk,
  authenticationExchangeMagicLinkAsyncThunk,
} from '@model/features/magic/asyncActions';
import { getErrorMessage } from '@services/errors';
import { safelyAwait } from '@services/promises';
import { SEC_INTO_MSEC, formatTime } from '@services/time';
import { useDidCodePromise, useLocaleServices } from '@ui/contextProviders';
import { SegmentedInput } from '@ui/elements/SegmentedInput';
import { useAutomatedCountdown } from '@ui/hooks/useCountdown';
import { Button } from '@ui/lib/Button';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

MagicLinkCodeActivation.propTypes = {
  email: PropTypes.string,
  onSuccess: PropTypes.func,
  onNoEmail: PropTypes.func,
  onResendRequest: PropTypes.func,
  requestFirstEmail: PropTypes.bool,
  resendLabel: PropTypes.string,
  headerElement: PropTypes.any,
  headerSubtitleElement: PropTypes.any,
  headerLabel: PropTypes.string,
  inputLabel: PropTypes.string,
  submitButtonLabel: PropTypes.string,
  footerElement: PropTypes.any,
  className: PropTypes.string,
  codePrefix: PropTypes.string.isRequired,
};

const codeLength = 6;

export function MagicLinkCodeActivation({
  email,
  onSuccess,
  onNoEmail,
  onResendRequest,
  requestFirstEmail,
  resendLabel,
  headerElement,
  headerSubtitleElement,
  headerLabel,
  inputLabel,
  submitButtonLabel,
  footerElement,
  className,
  codePrefix,
}) {
  const { t } = useLocaleServices();
  const dispatch = useDispatch();
  const [{ isButtonDisabled, isSubmitting, resendDisabled }, setFlags] = useState({
    isButtonDisabled: true,
    isSubmitting: false,
    resendDisabled: true,
  });
  /**
   * @type {string}
   */
  const [codeErrorMessage, setCodeErrorMessage] = useState(null);

  const [code, setCode] = useState(null);

  const didPromise = useDidCodePromise();

  const handleOnSubmit = useCallback(
    async nextCode => {
      const newCode = nextCode ?? code;
      setCodeErrorMessage(null);
      if (!newCode) {
        setFlags(state => Object({ ...state, isButtonDisabled: true, isSubmitting: false }));
        return;
      }
      setFlags(state => Object({ ...state, isButtonDisabled: true, isSubmitting: true }));
      const compoundCode = `${codePrefix}-${newCode}`;

      const [error, result] = await safelyAwait(
        dispatch(
          authenticationExchangeMagicLinkAsyncThunk({
            code: compoundCode,
            email,
            did: await didPromise,
            remember_me: getIsKeepMeLoggedIn(),
          })
        ).unwrap()
      );
      const errorLabel = getErrorMessage(error || result);
      setCodeErrorMessage(errorLabel);
      if (errorLabel) {
        setFlags(state => Object({ ...state, isButtonDisabled: true, isSubmitting: false }));
        return;
      }
      if (200 === result.code || 201 === result.code) {
        // no need to update UI here
        onSuccess && onSuccess();
        return;
      }
      setFlags(state => Object({ ...state, isButtonDisabled: false, isSubmitting: false }));
    },
    [code, codePrefix, didPromise, dispatch, email, onSuccess]
  );

  const handleOnCodePending = useCallback(
    nextCode => {
      if (nextCode.length === codeLength) {
        setCode(nextCode);
        setFlags(state => Object({ ...state, isButtonDisabled: false }));
        handleOnSubmit(nextCode);
      } else {
        setCode(null);
        setFlags(state => Object({ ...state, isButtonDisabled: true }));
      }
      setCodeErrorMessage(null);
    },
    [handleOnSubmit]
  );

  const handleResendLinkRequest = useCallback(async () => {
    setCodeErrorMessage(null);

    const [error, result] = await safelyAwait(
      onResendRequest
        ? onResendRequest()
        : dispatch(
            apiRequestMagicLinkForEmailAsyncThunk({ email, did: await didPromise, remember_me: getIsKeepMeLoggedIn() })
          ).unwrap()
    );
    setCodeErrorMessage(getErrorMessage(error || result));
  }, [dispatch, onResendRequest, email, didPromise]);

  const handleResend = useCallback(
    async event => {
      event?.preventDefault?.();

      setFlags(state => Object({ ...state, resendDisabled: true }));
      void handleResendLinkRequest(email);
    },
    [email, handleResendLinkRequest]
  );

  const countdown = useAutomatedCountdown(
    60 * SEC_INTO_MSEC,
    () => resendDisabled,
    () => setFlags(state => Object({ ...state, resendDisabled: false }))
  );

  useEffect(() => {
    if (!email) {
      onNoEmail();
    } else if (requestFirstEmail) {
      void handleResendLinkRequest();
    }
  }, [email, onNoEmail, requestFirstEmail, handleResendLinkRequest]);

  const effectiveResendLabel = [resendLabel, ' ', ...(resendDisabled ? [formatTime(countdown)] : [])];

  return (
    <div className={className}>
      {headerElement ? headerElement : null}
      {headerLabel && (
        <div className="max-w-[27.5rem] text-center text-title-s text-dark-i dark:text-light-b">
          {headerLabel}&nbsp;
          <span className="font-bold" data-role="masked-email">
            {maskEmail(email)}.
          </span>
        </div>
      )}
      {headerSubtitleElement ? headerSubtitleElement : null}
      <div className="my-0">
        <SegmentedInput
          codeLength={codeLength}
          className="mt-10 flex flex-col items-center text-dimmed-f dark:text-light-d"
          label={inputLabel}
          onChange={handleOnCodePending}
          testId="email-activation-code"
          hasError={Boolean(codeErrorMessage)}
          onSubmit={handleOnSubmit}
        />

        {codeErrorMessage && <div className="p-error">{t(codeErrorMessage)}</div>}
      </div>
      <Button
        className="!mt-10 min-w-[16.25rem]"
        label={submitButtonLabel}
        disabled={isButtonDisabled || isSubmitting}
        loading={isSubmitting}
        onClick={handleOnSubmit}
        data-role="verify"
      />
      <Button
        className="resend-link !mt-4"
        textButton
        role="link"
        disabled={resendDisabled}
        onClick={handleResend}
        label={effectiveResendLabel}
        data-role="resend"
      />
      {footerElement ? footerElement : null}
    </div>
  );
}
