import { FIELD_TYPES } from '@services/fields';
import { emailField, emailFieldWithoutMutation, rememberMeField } from '../Login/login.fields.data';

const emailFieldData = emailField;

const acceptPolicyFieldData = {
  type: FIELD_TYPES.CHECKBOX,
  name: 'read_policy',
  value: false,
  options: {
    checkbox_label: 'register.agree',
    ariaLabel: 'register.read_policy',
    required: true,
  },
  labels: {
    errors: {
      required: 'errors.validation.read_policy.required',
    },
  },
};

export const rememberMeFieldData = rememberMeField;

export const registerByEmailFieldsData = [emailFieldWithoutMutation, acceptPolicyFieldData, rememberMeFieldData];
export const setupEmailFieldsData = [emailFieldData];
export const termsConsentFormFieldsData = [acceptPolicyFieldData];
export const registerFieldsData = [
  emailFieldData,
  {
    type: FIELD_TYPES.PASSWORD_CONFIRM,
    name: 'password',
    label: 'labels.password.label',
    options: {
      placeholder: 'labels.password.simple_placeholder',
      ariaLabel: 'labels.password.simple_placeholder',
      required: true,
      min: 6,
      max: 20,

      equal_to: 'confirm',
      confirm_label: 'labels.confirm_password',
      confirm_placeholder: 'labels.confirm_password',
      confirm_ariaLabel: 'labels.confirm_password',
    },
    labels: {
      errors: {
        required: 'errors.validation.password.required',
      },
    },
  },
  {
    type: FIELD_TYPES.TEXT,
    name: 'bonus_code',
    label: 'register.bonus',
    options: {
      ariaLabel: 'labels.bonus',
    },
  },
  acceptPolicyFieldData,
];
