import { signOutThunk } from '@model/actions/signoutAction';
import { accessAuthInfo, accessAuthToken, accessIsAuthenticating, getIsKeepMeLoggedIn } from '@model/features/auth';
import { loginAsyncThunk, registerAsyncThunk } from '@model/features/auth/authAsyncThunks';
import { apiRequestMagicLinkForEmailAsyncThunk } from '@model/features/magic/asyncActions';
import { unwrapResult } from '@reduxjs/toolkit';
import { STORAGE_KEY_LIST, persistDataToStorage } from '@services/storage';
import { useDidCodePromise } from '@ui/contextProviders';
import { routeNames } from '@ui/routes/routeNames';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

const AuthContext = React.createContext(null);

AuthProvider.propTypes = {
  children: PropTypes.any,
};

/**
 *
 * @param {React.ReactNode} children
 */
export function AuthProvider({ children }) {
  const userInfo = useSelector(accessAuthInfo());
  const token = useSelector(accessAuthToken());
  const isAuthenticating = useSelector(accessIsAuthenticating());
  const isAuthenticated = useMemo(() => Boolean(token), [token]);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const didPromise = useDidCodePromise();

  const handleSignIn = useCallback(
    async ({ email, password }) =>
      dispatch(
        loginAsyncThunk({
          email,
          password,
          did: await didPromise,
          remember_me: getIsKeepMeLoggedIn(),
        })
      ).then(unwrapResult),
    [dispatch, didPromise]
  );

  const handleSignInEmail = useCallback(
    async ({ email }) =>
      dispatch(
        apiRequestMagicLinkForEmailAsyncThunk({
          email,
          did: await didPromise,
          remember_me: getIsKeepMeLoggedIn(),
        })
      ).unwrap(),
    [dispatch, didPromise]
  );

  const handleRegistration = useCallback(
    ({ email, password, terms_accepted, refcode, bonus_code, did }) =>
      dispatch(
        registerAsyncThunk({
          email,
          password,
          terms_accepted,
          refcode,
          bonus_code,
          did,
          remember_me: getIsKeepMeLoggedIn(),
        })
      ).then(unwrapResult),
    [dispatch]
  );

  const handleSignOut = useCallback(() => dispatch(signOutThunk()), [dispatch]);
  const handleSignOutAndNavigate = useCallback(() => {
    dispatch(signOutThunk());
    navigate(routeNames.root);
  }, [dispatch, navigate]);

  const storeRestrictedLocation = useCallback(() => {
    persistDataToStorage(STORAGE_KEY_LIST.REDIRECT_TO, location.pathname);
    persistDataToStorage(STORAGE_KEY_LIST.REDIRECT_TO_TIMESTAMP, Date.now(), window.sessionStorage);
  }, [location.pathname]);

  const value = useMemo(() => {
    return {
      token,
      handleSignIn,
      handleSignInEmail,
      handleSignOut,
      handleSignOutAndNavigate,
      handleRegistration,
      isAuthenticated,
      isAuthenticating,
      userInfo,
      storeRestrictedLocation,
    };
  }, [
    token,
    handleSignIn,
    handleSignInEmail,
    handleSignOut,
    handleSignOutAndNavigate,
    handleRegistration,
    isAuthenticated,
    isAuthenticating,
    userInfo,
    storeRestrictedLocation,
  ]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function getAuthProviderForTests(value) {
  AuthProviderForTests.propTypes = {
    children: PropTypes.any,
  };
  return AuthProviderForTests;

  function AuthProviderForTests({ children }) {
    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
  }
}

/**
 * @typedef {Object} AuthContext
 * @property {string} token
 * @property {function} handleSignIn
 * @property {function} handleSignInEmail
 * @property {function} handleSignOut
 * @property {() => void} handleSignOutAndNavigate
 * @property {function} handleRegistration
 * @property {boolean} isAuthenticated
 * @property {boolean} isAuthenticating
 * @property {Object} userInfo
 * @property {function} storeRestrictedLocation
 */

/**
 * @returns {AuthContext}
 */
export function useAuth() {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error(`Invalid usage. 'useAuth()' needs to be called in elements descending from <AuthProvider />`);
  }
  return context;
}
