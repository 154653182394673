import { Status } from '@/api/support/types';
import { array, boolean, InferType, mixed, number, object, Schema, string } from 'yup';

class FilesScema extends Schema<File[] | undefined> {
  constructor() {
    super({
      type: 'files',
      check: (value: File[] | undefined): value is File[] => value != null && Array.isArray(value),
    });
  }

  maxSize(size: number, message?: string) {
    return this.test('maxSize', message ?? `Up to ${size / 1024}KB are permitted.`, files => {
      if (files && files.length > 0) {
        return files.every(file => file.size < size);
      }
      return true;
    });
  }

  fileType(type: string, message?: string) {
    return this.test('fileType', message ?? `Only file type ${type} permitted.`, files => {
      if (files && files.length > 0) {
        return files.every(file => file.type.startsWith(type));
      }
      return true;
    });
  }

  oneOfType(types: string[], message?: string) {
    return this.test('oneOfType', message ?? `Only file types ${types.join(', ')} permitted.`, files => {
      if (files && files.length > 0) {
        return files.every(file => types.some(type => file.type.startsWith(type)));
      }
      return true;
    });
  }

  maxFiles(count: number, message?: string) {
    return this.test('maxFiles', message ?? `Up to ${count} files are permitted.`, files => {
      if (files && files.length > 0) {
        return files.length <= count;
      }
      return true;
    });
  }
}

/**
 * Validate files
 */
export function files(): FilesScema {
  return new FilesScema();
}

const commentSchema = object({
  id: number().required(),
  content: string().nullable().optional(),
  created_at: string().required(),
  is_admin: boolean().required(),
  user_read_at: string().nullable(),
  images: array()
    .of(
      object({
        original_url: string().required(),
        thumb50_url: string().required(),
      })
    )
    .required(),
});

const ticketSchema = object({
  id: number().required(),
  subject: string().required(),
  status: mixed<Status>().oneOf(['in_progress', 'open', 'resolved', 'new', 'closed']).required(),
  created_at: string().required(),
  is_read: boolean().required(),
  comments: array().of(commentSchema).required(),
  can_be_commented: boolean(),
});

export const ticketsResSchema = object({
  data: array().of(ticketSchema),
  meta: object({
    current_page: number(),
    next_page: number().nullable(),
    prev_page: number().nullable(),
    total_count: number(),
    total_pages: number(),
  }).required(),
});

export const ticketResSchema = object({
  data: ticketSchema,
});

export const ticketCommentResSchema = object({
  data: array().of(commentSchema).required(),
});

export interface NewCommentFormData {
  content?: string;
  images?: File[];
}

export type SupportTicketsResponse = InferType<typeof ticketsResSchema>;
export type SupportTicket = InferType<typeof ticketSchema>;
export type SupportTicketComment = InferType<typeof commentSchema>;
export type TicketResponse = InferType<typeof ticketResSchema>;
export type CommentsResponse = InferType<typeof ticketCommentResSchema>;
