import { Button } from '@ui/lib/Button';
import { Icon, Icons } from '@ui/lib/Icon';
import { classNames } from 'primereact/utils';
import { PropsWithChildren } from 'react';
import './auth-layout.scss';

type AuthDialogProps = {
  className?: string;
  showCloseButton: boolean;
  onCloseModal?: () => void;
  'data-testid'?: string;
};

export function AuthDialog({
  className,
  children,
  showCloseButton,
  onCloseModal,
  'data-testid': dataTestId = 'auth-root',
}: PropsWithChildren<AuthDialogProps>) {
  return (
    <div className="auth-layout flex h-full w-full items-center justify-center">
      <div className="auth-layout__background" />
      <div className={classNames('relative z-20 md:m-6', className || 'w-full max-w-[900px]')} data-testid={dataTestId}>
        <div className="absolute bottom-0 left-0 right-0 top-0 -z-10 bg-white md:rounded-7xl md:bg-white dark:bg-dark-j dark:md:bg-dark-h dark:md:bg-opacity-75" />
        {showCloseButton && (
          <Button
            textButton
            buttonType="rounded"
            // TODO check this
            className="absolute right-4 top-4 text-dimmed-d md:right-6 md:top-6 hover:dark:text-white"
            aria-label="close"
            data-role="close-cross"
            onClick={onCloseModal}
          >
            <Icon icon={Icons.Close} className="text-[30px]" />
          </Button>
        )}
        <div className="p-4">{children}</div>
      </div>
    </div>
  );
}
