import { Root, createRoot } from 'react-dom/client';
import { CfChallenge } from './Challenge';

type TurnstileOptions = {
  key: string;
  widgetId: string;
  containerSelector: string;
};

export class Turnstile {
  private readonly key: string;
  private readonly widgetId: string;
  private readonly containerSelector: string;
  private readonly scriptUrl: string = 'https://challenges.cloudflare.com/turnstile/v0/api.js';
  private container: HTMLElement | null = null;
  root: Root | null = null;
  private loadedPromise: Promise<void> | null = null;

  constructor({ key, widgetId, containerSelector }: TurnstileOptions) {
    this.key = key;
    this.widgetId = widgetId;
    this.containerSelector = containerSelector;
    this.init();
  }

  private loadWidget() {
    this.container = document.querySelector(this.containerSelector);

    if (!this.container) {
      throw new Error('Container not found! ' + this.containerSelector);
    }
    this.root = createRoot(this.container);
  }

  public init() {
    const script = document.createElement('script');
    script.src = this.scriptUrl;
    script.async = true;
    this.loadedPromise = new Promise(resolve => {
      script.onload = () => {
        resolve();
      };
    });
    document.body.appendChild(script);
  }

  public async showChallenge() {
    await this.loadedPromise;
    return new Promise(resolve => {
      if (null == this.root) {
        this.loadWidget();
      }
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      this.root!.render(
        <CfChallenge
          onDone={token => {
            this.hideChallenge();
            resolve(token);
          }}
          siteKey={this.key}
          widgetId={this.widgetId}
        />
      );
    });
  }

  public hideChallenge() {
    window.turnstile.remove();
    this.root?.unmount();
    this.root = null;
  }
}

declare global {
  interface Window {
    cf: Turnstile;
  }
}
