import '@/icons/font/icons.css';
import { concatClsx } from '@/utils/classnames';
import { FC, HTMLProps } from 'react';

export enum Icons {
  Check = 'check',
  Close = 'close',
  Support = 'support',
  Arrow = 'arrow',
  Question = 'question',
  Fb = 'fb',
  InstOutline = 'inst-outline',
  Inst = 'inst',
  TwtXOutline = 'twt-x-outline',
  TwtX = 'twt-x',
  SendDisabled = 'send-disabled',
  SendActive = 'send-active',
  Chat = 'chat',
  Camera = 'camera',
  User = 'user',
  Checkmark = 'checkmark',
  SendNew = 'send-new',
  ChatUpload = 'chat-upload',
  Flame = 'flame',
  DollarCircle = 'dollar-circle',
  Snowflake = 'snowflake',
  LogoApple = 'logo-apple',
  LogoGoogle = 'logo-google',
  Award = 'award',
  Users = 'users',
  CrownSmall = 'crown-small',
  Timer = 'timer',
  ThemeRetro = 'theme-retro',
  ThemeLight = 'theme-light',
  ThemeDark = 'theme-dark',
  ThemeAuto = 'theme-auto',
  FbCircled = 'fb-circled',
  Apple = 'apple',
  Gauth2 = 'gauth2',
  Coins = 'coins',
  Settings = 'settings',
  Exit = 'exit',
}

type Props = {
  icon: Icons;
} & HTMLProps<HTMLElement>;

export const Icon: FC<Props> = ({ icon, className, ...rest }) => (
  <i className={concatClsx(`pv-icon pv-icon-${icon} inline-block leading-0`, className)} {...rest} />
);
