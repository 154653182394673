import { setupActionListeners } from '@model/actions/setupActionListeners';
import { signOutThunk } from '@model/actions/signoutAction';
import { welcomeAction } from '@model/actions/welcomeAction';
import '@model/api';
import { sendToGoogleAnalytics } from '@services/analytics';
import { dataCollector } from '@services/fcm/dataCollector';
import { isNotificationSupported } from '@services/fcm/utils';
import { STORAGE_KEY_LIST, restoreDataFromStorage } from '@services/storage';
import { Turnstile } from '@services/turnstile';
import { enableWebpushNotifications } from '@services/webpush/webpushNotificationSetup';
import { AppRoutes } from '@ui/appRoutes';
import { clearRefCode, saveRefCodeIfPresent } from '@ui/hooks/useRefCode';
import { enableMapSet } from 'immer';
import ReactDOM from 'react-dom/client';
import { listenerMiddleware } from './configureStore';
import reportWebVitals from './reportWebVitals';
import { getRootElementFor } from './root';
import { initSentry } from './sentry';
import { StorageListener } from './storageListener';
import { registerSwWithFcm } from './sw-registration';
import { getOsTheme } from './utils/osColorTheme';
import { getTenant } from './utils/tenant';

import 'primeicons/primeicons.css';

import './bootstrap';
import './nonce';

if ('production' === process.env.NODE_ENV) {
  // Enable Sentry only in production
  initSentry();
}

enableMapSet();

window.switchTheme = (theme, isActiveIcon = false) => {
  document.body.classList.toggle('classic', 'retro' === theme);
  document.body.classList.toggle('lightTheme', 'light' === theme);
  document.body.classList.toggle('dark', 'light' !== theme);
  window.changeFavicon(theme, isActiveIcon);
};

window.getCurrentTheme = () => {
  const restored = restoreDataFromStorage('theme');
  if ('auto' === restored || null == restored) {
    return getOsTheme();
  }
  return restored;
};

const tenantName = getTenant();

const root = ReactDOM.createRoot(document.querySelector('#root'));

// safari auto-zoom disable
const iOS = navigator.userAgent && /iPhone/.test(navigator.userAgent) && !window.MSStream;
if (iOS) {
  document.head.querySelector('meta[name="viewport"]').content = 'width=device-width, initial-scale=1, maximum-scale=1';
}

// TODO add support of more themes here
const theme = window.getCurrentTheme();

window.switchTheme(theme);

const { rootElement, store } = getRootElementFor(AppRoutes /* INITIAL_STATE */);

setupActionListeners(listenerMiddleware);

root.render(rootElement);

store.dispatch(welcomeAction({ tenantName }));

// See more: https://bit.ly/CRA-vitals
reportWebVitals(sendToGoogleAnalytics);

// Comment out this line to develop with service worker
if ('production' === process.env.NODE_ENV && isNotificationSupported()) {
  registerSwWithFcm();
  // Listen for all data collected to subscribe to notifications
  dataCollector.subscribe(enableWebpushNotifications);
}

saveRefCodeIfPresent();
dataCollector.subscribe(data => {
  if (data.has('token')) {
    clearRefCode();
  }
});

StorageListener.addListener(STORAGE_KEY_LIST.AUTH_TOKEN, (newValue, oldValue) => {
  // user logged out
  if (oldValue != null && null == newValue) {
    store.dispatch(signOutThunk());
  }
  // user logged in
  if (newValue != null && newValue != oldValue) {
    window.location.reload();
  }
});

window.cf = new Turnstile({
  key: window.tenant.config.turnstile.site_key,
  containerSelector: '#cf-challenge',
  widgetId: 'turnstile_widget',
});
