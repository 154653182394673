import { useLocaleServices } from '@/ui/contextProviders/Locale';
import { useShowError } from '@ui/contextProviders';
import { LoadingSpinner } from '@ui/elements/spinners';
import { MEDIA_BP, useMediaQuery } from '@ui/hooks';
import { useAppDispatch } from '@ui/hooks/redux';
import useMounted from '@ui/hooks/useMounted';
import { Button } from '@ui/lib/Button';
import { Icon, Icons } from '@ui/lib/Icon';
import { statusMapper } from '@ui/support/utils/statusMapper';
import { getDistanceInWordsToNow } from '@ui/support/utils/time';
import { Dialog } from 'primereact/dialog';
import { FC, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  getSupportTicketAsyncThunk,
  resolveSupportTicketAsyncThunk,
  submitTicketCommentAsyncThunk,
} from '../../api/redux';
import { CommentsResponse, NewCommentFormData, SupportTicket, TicketResponse } from '../../api/schemas';
import { isSuccessResponse } from '../../utils/guards';
import { Chat } from './Chat';
import { ChatTextInput } from './ChatTextInput';
import styles from './index.module.scss';

export type CommentHandlers = {
  onAddComment: (comment: string) => void;
  onResolve: () => void;
};

type TicketProps = {
  dialogRef?: React.RefObject<Dialog>;
  isMobile?: boolean;
};

export const Ticket: FC<TicketProps> = ({ dialogRef, isMobile }) => {
  const { hash } = useLocation();
  const dispatch = useAppDispatch();
  const showError = useShowError();
  const { t } = useLocaleServices();
  const { current: isMounted } = useMounted();
  const isLargeScreen = useMediaQuery(MEDIA_BP.isOrGreaterThan768);
  const navigate = useNavigate();

  const [ticket, setTicket] = useState<SupportTicket | null>(null);

  const id = hash.split('/')[2];

  useEffect(() => {
    if (id) {
      dispatch(getSupportTicketAsyncThunk(id))
        .unwrap()
        .then(res => {
          if (isSuccessResponse(res.response)) {
            setTicket(res.response.data);
          } else {
            navigate(window.location.pathname + '#support', { replace: true });
          }
        });
    }
  }, [dispatch, id, navigate]);

  if (!id) {
    navigate(window.location.pathname + '#support', { replace: true });
    return null;
  }

  const handleResolve = async () => {
    const res: ThunkResponse<TicketResponse> = await dispatch(resolveSupportTicketAsyncThunk(id) as any).unwrap();

    if (isSuccessResponse(res.response)) {
      setTicket(res.response.data);
    } else {
      // TODO: Handle error
    }
  };

  const handleNewComment = async (formData: NewCommentFormData) => {
    // TODO: Show loader
    const res: ThunkResponse<CommentsResponse> = await dispatch(
      submitTicketCommentAsyncThunk({
        id,
        formData,
      }) as any
    ).unwrap();

    if (isSuccessResponse(res.response) && isMounted) {
      const comments = res.response.data;
      // TODO: Hide loader
      setTicket((ticket: SupportTicket) => {
        return {
          ...ticket,
          comments,
        };
      });
    } else {
      if (res?.code != 422) {
        showError(t('support.errors.unacceptable'));
      }
      throw res.response;
    }
  };

  const isOpen = ticket?.status != 'closed' && ticket?.status != 'resolved';

  return ticket != null ? (
    <>
      {!isLargeScreen && (
        <div>
          <h2 className={`${styles.standaloneTitle} text-center font-bitter font-bold text-dark-a dark:text-white`}>
            {ticket.subject}
          </h2>
          <div className="text-center font-proxi text-body text-dark-d dark:text-light-d">
            {statusMapper[ticket.status]}
          </div>
        </div>
      )}
      <div className={styles.title_wrapper}>
        <Button
          className="absolute left-4 top-7 !px-3 !py-1 !text-base md:left-2"
          textButton
          icon="pi pv-arrow-back text-accent-a"
          data-role="go-back"
          type="button"
          onClick={() => navigate(window.location.pathname + '#support', { replace: true })}
        >
          {!isMobile && <span className="uppercase text-dark-a dark:text-white">{t('back')}</span>}
        </Button>
        <div className={styles.title}>
          {isLargeScreen && (
            <h2
              className="mb-2 font-bitter text-body-l font-bold leading-[22px] text-dark-a dark:text-light-b"
              data-testid="ticket-subject"
            >
              {ticket.subject}
            </h2>
          )}
          <div className="text-dimmed-g dark:text-light-d" data-testid="ticket-status">
            <span>#{ticket.id}</span>
            <span>&bull;</span>
            {isLargeScreen && (
              <>
                <span>{statusMapper[ticket.status]}</span>
                <span>&bull;</span>
              </>
            )}
            <span className={styles.commentDate}>
              {getDistanceInWordsToNow(new Date(ticket.created_at), t, t('labels.ago'))}
            </span>
          </div>
        </div>
        {isOpen && (
          <Button
            textButton
            onClick={handleResolve}
            type="button"
            className="mb-2 min-w-[auto] text-accent-a md:mb-0 md:min-w-[158px]"
            data-role="resolve-ticket"
          >
            <Icon icon={Icons.Checkmark} className="mr-2 text-[16px]" />
            <span className="underline !decoration-dashed">{t('support.mark_as_closed')}</span>
          </Button>
        )}
      </div>
      <Chat comments={ticket.comments} />
      {ticket.can_be_commented && (
        <ChatTextInput
          onSubmit={handleNewComment}
          placeholder={t('support.start_new_comment')}
          dialogRef={dialogRef}
          isMobile={isMobile}
        />
      )}
    </>
  ) : (
    <div className="flex min-h-[18rem] items-center justify-center">
      <LoadingSpinner />
    </div>
  );
};
