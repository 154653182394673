import { signOutThunk } from '@model/actions/signoutAction';
import { accessUserCountry } from '@model/features/user';
import { useAuth, useLocaleServices } from '@ui/contextProviders';
import { useAppDispatch, useAppSelector } from '@ui/hooks/redux';
import { routeNames } from '@ui/routes/routeNames';
import { useCallback, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { BalloonButtonActions, BalloonPageName } from './types';

/**
 * It’s better to use the signOutThunk when calling the balloon if necessary, it’s more clear
 * @deprecated
 */
export function useSignOutEffectIfNeeded(pageName?: BalloonPageName) {
  const { handleSignOut } = useAuth();
  useEffect(() => {
    if (pageName !== BalloonPageName.ForbiddenCountry) {
      return;
    }
    handleSignOut();
  }, [handleSignOut, pageName]);
}

export function useBalloonInterpolationData(pageName?: BalloonPageName) {
  const { t } = useLocaleServices();
  const location = useLocation();
  const [searchParams] = useSearchParams(location.search);

  const countryLabel = useAppSelector(accessUserCountry());
  const min_age = searchParams.get('min_age');
  const country = t(`the_countries.${countryLabel}`).includes('the_countries')
    ? t(`countries.${countryLabel || 'fallback'}`)
    : t(`the_countries.${countryLabel}`);

  if (pageName !== BalloonPageName.Minor) return { country };

  // data for balloon page 'minor'
  return min_age
    ? { country, years_old: String(min_age), nth_birthday: String(min_age) }
    : { country, years_old: '13', nth_birthday: '13' };
}

export function useCloseHandler(closeAction) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const dismissBalloon = useCallback(() => {
    dispatch(signOutThunk());
    navigate(routeNames.root, { replace: true });
  }, [dispatch, navigate]);

  return (
    {
      [BalloonButtonActions.Dismiss]: dismissBalloon,
    }[closeAction] ?? null
  );
}
