import { selectDedupScriptUrl } from '@model/features/user/selectors';
import { installResource, uninstallResource } from '@services/resources';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

const DedupeScriptContext = React.createContext(null);

const dedupeScriptId = 'dedupeScriptId';

const scriptInstaller = installResource('script');

DedupeScriptProvider.propTypes = {
  children: PropTypes.any,
};
export function DedupeScriptProvider({ children }) {
  const ddScriptFromRedux = useSelector(selectDedupScriptUrl);
  const scriptInstallationContext = useRef({});

  const installScript = useCallback(url => {
    scriptInstaller(scriptInstallationContext.current, dedupeScriptId, url);
  }, []);

  const uninstallScript = () => {
    uninstallResource(scriptInstallationContext.current, dedupeScriptId);
  };

  useEffect(() => {
    if (scriptInstallationContext.current.placement) {
      return void 0; // undefined
    }
    if (scriptInstallationContext.current.loaded) {
      if (scriptInstallationContext.current.url === ddScriptFromRedux) {
        return void 0; // undefined
      }
      uninstallScript();
    }
    if ('production' === process.env.NODE_ENV) {
      installScript(ddScriptFromRedux);
    }

    return () => {
      uninstallScript();
    };
  }, [ddScriptFromRedux, installScript]);

  return (
    <DedupeScriptContext.Provider value={scriptInstallationContext.current.url}>
      {children}
    </DedupeScriptContext.Provider>
  );
}
