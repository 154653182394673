import { useBannerAcceptanceActionCreator } from './bannerWrapper.hooks';
import { Banner } from './banner';
import PropTypes from 'prop-types';

BannerWrapper.propTypes = {
  token: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.string,
  close: PropTypes.object,
  accept: PropTypes.object,
  deny: PropTypes.object,
};
/**
 *
 * @param {string} bannerToken
 * @param {'info'} type
 * @param {string} label
 * @param {null|{}} closeOption
 * @param {null|{}} acceptOption
 * @param {null|{}} denyOption
 * @return {JSX.Element}
 * @constructor
 */
export function BannerWrapper({
  token: bannerToken,
  type,
  label,
  close: closeOption,
  accept: acceptOption,
  deny: denyOption,
}) {
  const bannerAcceptanceCreator = useBannerAcceptanceActionCreator(bannerToken);

  const { label: acceptLabel } = acceptOption || {};
  const { label: rejectLabel } = denyOption || {};
  const { label: closeLabel } = closeOption || {};

  return (
    <Banner
      type={type}
      message={label}
      acceptLabel={acceptLabel}
      rejectLabel={rejectLabel}
      closeLabel={closeLabel}
      acceptAction={bannerAcceptanceCreator('accept', acceptOption)}
      rejectAction={bannerAcceptanceCreator('deny', denyOption)}
      closeAction={bannerAcceptanceCreator('close', closeOption)}
    />
  );
}
