import { accessDialogShown, dismissDialog } from '@model/features/dialogs';
import { PvDialog } from '@ui/lib/PvDialog';
import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

ApplicationDialog.propTypes = {
  name: PropTypes.string,
  onBeforeClose: PropTypes.func,
  children: PropTypes.any,
  className: PropTypes.string,
  contentClassName: PropTypes.string,
  title: PropTypes.string,
  closable: PropTypes.bool,
  maskClassName: PropTypes.string,
};
export function ApplicationDialog({
  name,
  onBeforeClose,
  children,
  className,
  contentClassName,
  title,
  closable,
  maskClassName,
}) {
  const isDialogShown = useSelector(accessDialogShown(name));
  const dispatch = useDispatch();
  const handleOnDismiss = useCallback(() => {
    onBeforeClose && onBeforeClose(name);
    dispatch(dismissDialog(name, null));
  }, [dispatch, name, onBeforeClose]);

  return (
    <PvDialog
      visible={isDialogShown}
      onHide={handleOnDismiss}
      className={className}
      title={title}
      contentClassName={contentClassName}
      closable={closable}
      resizable={false}
      maskClassName={maskClassName}
    >
      {children}
    </PvDialog>
  );
}
