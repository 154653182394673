function differenceBetween(from: Date, to: Date) {
  return from.getTime() - to.getTime();
}

function getDifferenceCount(diff: number) {
  if (diff < 60000) return Math.round(diff / 1000);
  if (diff < 3600000) return Math.round(diff / 60000);
  if (diff < 86400000) return Math.round(diff / 3600000);
  if (diff < 2592000000) return Math.round(diff / 86400000);
  if (diff < 31536000000) return Math.round(diff / 2592000000);
  return Math.round(diff / 31536000000);
}

function getLocaleUnitPath(diff: number) {
  if (diff < 60000) return 'datetime.distance_in_words.less_than_x_minutes.one';
  if (diff < 3600000) return 'datetime.distance_in_words.x_minutes.other';
  if (diff < 7200000) return 'datetime.distance_in_words.about_x_hours.one';
  if (diff < 86400000) return 'datetime.distance_in_words.about_x_hours.other';
  if (diff < 172800000) return 'datetime.distance_in_words.x_days.one';
  if (diff < 2592000000) return 'datetime.distance_in_words.x_days.other';
  if (diff < 5184000000) return 'datetime.distance_in_words.about_x_months.one';
  if (diff < 31536000000) return 'datetime.distance_in_words.x_months.other';
  if (diff < 63072000000) return 'datetime.distance_in_words.about_x_years.one';
  return 'datetime.distance_in_words.over_x_years.other';
}

export const timeDifference = {
  differenceBetween,
  getLocaleUnitPath,
  getDifferenceCount,
};
