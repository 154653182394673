import { getTimestamp } from '@services/time';
import { FIELD_NAMES, FIELD_TYPES } from '@services/fields';
import { updateFields } from './updateFields';

export function updateCountiesFieldOrDoNothing(state, counties, zip) {
  /**
   * @type {undefined|null|import('@services/fields/types').FieldsArrayType}
   */
  const fields = state.currentStepData?.originalFields;
  if (!fields) {
    return;
  }
  const countyName = FIELD_NAMES.COUNTY;
  const dependsOn = FIELD_NAMES.ZIP_CODE;

  const fieldNamesSet = new Set(fields.map(({ name }) => name));
  if (!fieldNamesSet.has(countyName) && !counties) {
    return;
  }
  if (fieldNamesSet.has(countyName) && !counties) {
    // wipe && update
    const nextFields = fields.filter(({ name }) => name !== countyName);
    updateFields(state, nextFields, getTimestamp());
    return;
  }
  //fips_code: 29019
  //name: "Boone County"
  /**
   * @type {import('@services/fields/types').FieldType}
   */
  const newField = {
    name: countyName,
    type: FIELD_TYPES.DROPDOWN,
    values: counties.map(({ fips_code, name }) => {
      return { id: fips_code, name, label: name, value: fips_code };
    }),
    options: {
      required: true,
      depends_on: dependsOn,
      depends_on_value: zip,
      placeholder: 'Select county',
    },
  };
  const zipCodeFieldPosition = fields.findIndex(({ name }) => name === dependsOn);

  // add new field
  if (!fieldNamesSet[countyName] && counties) {
    const nextFields = [
      ...fields.slice(0, zipCodeFieldPosition + 1),
      newField,
      ...fields.slice(zipCodeFieldPosition + 1),
    ];
    nextFields[zipCodeFieldPosition].value = zip;
    updateFields(state, nextFields, getTimestamp());
    return;
  }
  // fieldNamesSet.has(countyName) && counties
  // check && update if different
  const previousField = fields.find(({ name }) => name === countyName);
  const areCountiesEqual =
    newField.values.length === previousField.values.length &&
    newField.values.reduce((memo, newItem, idx) => {
      const oldItem = previousField.values[idx];
      return memo && newItem.id === oldItem.id;
    }, true) &&
    newField.options.depends_on_value === previousField.options.depends_on_value;

  if (areCountiesEqual) {
    return;
  }
  const countiesPosition = fields.findIndex(({ name }) => name === countyName);
  const nextFields = [...fields.slice(0, countiesPosition), newField, ...fields.slice(countiesPosition + 1)];
  nextFields[zipCodeFieldPosition].value = zip;
  updateFields(state, nextFields, getTimestamp());
}
