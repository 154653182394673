import { apiService } from '../ApiService';
import {
  LoginRequest,
  LoginResponse,
  MagicRequest,
  MagicResponse,
  MagicSendRequest,
  MagicSendResponse,
  RefreshRequest,
} from './types';

export const authApi = {
  magicAuth: (data: MagicRequest) => apiService.post<MagicResponse>('/authorization/magic', data, { withToken: false }),
  magicSend: (data: MagicSendRequest) =>
    apiService.post<MagicSendResponse>('/authorization/magic/send', data, { withToken: false }),
  login: (data: LoginRequest) => apiService.post<LoginResponse>('/authorization/tokens', data, { withToken: false }),
  refreshToken: (data: RefreshRequest) =>
    apiService.post<LoginResponse>('/authorization/tokens/refresh', data, { withToken: false }),
  logout: () => apiService.delete('/authorization/tokens'),
};
