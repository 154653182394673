import { configureStore, createListenerMiddleware } from '@reduxjs/toolkit';
import { createReduxEnhancer } from '@sentry/react';

import { traitsApi } from '@model/features/demographics/traits';
import { unexpectedAPIResponseListener } from '@model/middleware';
import { apiService } from './api/ApiService';
import { createRootReducer } from './model';

const baseAPI_URL = process.env.REACT_APP_API_URL;
void baseAPI_URL;

const isDevelopment = 'development' === process.env.NODE_ENV; // || ((new URLSearchParams(window.location.search)).has('debug'));

export const listenerMiddleware = createListenerMiddleware();

export const getStore = (preloadedState, options = {}) => {
  const { isUnitTesting } = options;

  const sentryReduxEnhancer = createReduxEnhancer({
    stateTransformer: state => {
      const transformedState = {
        ...state,
        user: {
          id: state.user?.id,
        },
        auth: {
          lastErrorMessage: state.auth?.lastErrorMessage,
          error: state.auth?.error,
        },
      };

      return transformedState;
    },
  });

  const store = configureStore({
    reducer: createRootReducer(),
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        serializableCheck: false,
      })
        .prepend(unexpectedAPIResponseListener)
        .prepend(listenerMiddleware.middleware)
        .concat(traitsApi.middleware),
    devTools: isDevelopment,
    enhancers: getDefaultEnhancers => getDefaultEnhancers().concat(sentryReduxEnhancer),
    preloadedState,
  });

  isDevelopment &&
    !isUnitTesting &&
    (() => {
      if (module.hot) {
        module.hot.accept('./model', () => {
          // eslint-disable-next-line @typescript-eslint/no-var-requires
          const getNextRootReducer = require('./model').default;
          store.replaceReducer(getNextRootReducer());
        });
      }
    })();

  apiService.setDispatch(store.dispatch);

  return {
    store,
  };
};
