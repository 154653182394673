import { UserStatus } from '@/api/user/user-status';
import { memoize } from '@services/functional';

// TODO: Get rid of this shit
export const userStatusChecks = memoize(1, 1, status => {
  const isUserLoggedIn = (status ?? '') !== '';
  return {
    isUserNotLoggedIn: !isUserLoggedIn,
    isUserAlive: status === UserStatus.ALIVE,
    isUserInRegistrationPhase: status === UserStatus.REGISTRATION_PROCESS,
    isUserInEmailSetup: status === UserStatus.EMAIL_SETUP,
    isUserInEmailActivation: status === UserStatus.EMAIL_ACTIVATION,
    isUserInUnsupportedCountry: status === UserStatus.UNSUPPORTED_COUNTRY,
    isUserInForbiddenCountry: status === UserStatus.FORBIDDEN_COUNTRY,
    isUserMinor: status === UserStatus.MINOR_PROBLEM,
    isUserInTermsConsentPhase: status === UserStatus.TERMS_CONSENT_PHASE,
    isUserStatusUnidentified:
      isUserLoggedIn &&
      status !== UserStatus.ALIVE &&
      status !== UserStatus.REGISTRATION_PROCESS &&
      status !== UserStatus.EMAIL_SETUP &&
      status !== UserStatus.EMAIL_ACTIVATION &&
      status !== UserStatus.UNSUPPORTED_COUNTRY &&
      status !== UserStatus.FORBIDDEN_COUNTRY &&
      status !== UserStatus.MINOR_PROBLEM &&
      status !== UserStatus.TERMS_CONSENT_PHASE,
  } as const;
});
