import { LocaleStringGetter, useLocaleServices } from '@/ui/contextProviders/Locale';
import { MEDIA_BP, useMediaQuery } from '@ui/hooks';
import { useAppDispatch } from '@ui/hooks/redux';
import useMounted from '@ui/hooks/useMounted';
import { Button } from '@ui/lib/Button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { DataView } from 'primereact/dataview';
import { Paginator } from 'primereact/paginator';
import { classNames } from 'primereact/utils';
import { FC, memo, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getSupportTicketsListAsyncThunk } from '../../api/redux';
import { SupportTicket } from '../../api/schemas';
import { isSuccessResponse } from '../../utils/guards';
import { HashEnum, statusMapper } from '../../utils/statusMapper';
import { getDistanceInWordsToNow } from '../../utils/time';
import styles from './ticket-list.module.scss';

type PageData = {
  page: number;
  first: number;
};

const perPage = 10;

const TicketList: FC = () => {
  const [tickets, setTickets] = useState<SupportTicket[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const isLargeScreen = useMediaQuery(MEDIA_BP.isOrGreaterThan768);
  const { current: isMounted } = useMounted();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useLocaleServices();

  const [page, setPage] = useState<PageData>({
    page: 0,
    first: 0,
  });

  useEffect(() => {
    setLoading(true);
    dispatch(getSupportTicketsListAsyncThunk({ page: page.page + 1, perPage }))
      .unwrap()
      .then(res => {
        if (isSuccessResponse(res.response) && isMounted) {
          setTickets(res.response.data || []);
          setTotalRecords(res.response.meta.total_count || 0);
        } else {
          // TODO: handle error
        }
        setLoading(false);
      });
  }, [dispatch, page, isMounted]);

  const itemTemplate = useCallback(
    (ticket: SupportTicket) => (
      <div
        className="mb-4 rounded-3xl border-light-e bg-light-c p-3 text-sm font-bold text-silver-gray dark:border-dimmed-c dark:bg-dark-h"
        data-role="ticket-list-item"
        onClick={() => {
          navigate(window.location.pathname + `#support/ticket/${ticket.id}`);
        }}
      >
        <div className={`ticket-list-row ${styles.mobileTicketRow}`}>
          <div>{t('support.subject')}</div>
          <div className={`ticket-list-right-col ${styles.rightCol}`}>{renderSubject(ticket)}</div>
        </div>
        <div className={`ticket-list-row ${styles.mobileTicketRow}`}>
          <div>ID</div>
          <div className={`ticket-list-right-col ${styles.rightCol}`}>{ticket.id}</div>
        </div>
        <div className={`ticket-list-row ${styles.mobileTicketRow}`}>
          <div>{t('support.created_at')}</div>
          <div className={`ticket-list-right-col ${styles.rightCol}`}>{renderDate(ticket, t)}</div>
        </div>
        <div className={`ticket-list-row ${styles.mobileTicketRow} border-0`}>
          <div>{t('support.status')}</div>
          <div className={`ticket-list-right-col ${styles.rightCol}`}>{renderStatus(ticket)}</div>
        </div>
      </div>
    ),
    [navigate, t]
  );

  return (
    <>
      <div
        className="text-center font-bitter text-title-s font-bold text-dark-a md:px-20 md:pb-10 md:text-left md:text-3xl dark:text-light-b"
        data-testid="dialog-title"
      >
        {t('support.title')}
      </div>

      {isLargeScreen ? (
        <div className="ticket-list custom-scroll min-h-[20rem] overflow-y-auto px-20 text-dark-a dark:text-dimmed-a">
          <DataTable
            value={tickets}
            scrollable
            loading={loading}
            onRowClick={event => {
              navigate(window.location.pathname + `#support/ticket/${event.data.id}`);
            }}
            emptyMessage={<div className="text-lg dark:text-light-b">{t('support.no_tickets')}</div>}
            lazy
            className={styles.ticketListTable}
          >
            <Column field="subject" header={t('support.subject')} body={renderSubject} className="relative" />
            <Column headerStyle={{ width: '3rem' }} style={{ width: '3rem' }} field="id" body={renderId} header="ID" />
            <Column field="created_at" header={t('support.created_at')} body={row => renderDate(row, t)} />
            <Column field="status" className="ticket-status-column" header={t('support.status')} body={renderStatus} />
          </DataTable>

          <div className="flex items-center justify-between">
            <div className="max-md:text-center">
              <Button
                variant="accent-b"
                onClick={() => navigate(`${window.location.pathname}#support/${HashEnum.NEW}`)}
              >
                {t('support.new_ticket')}
              </Button>
            </div>
            {totalRecords > perPage && (
              <Paginator
                first={page.first}
                rows={perPage}
                totalRecords={totalRecords}
                className="rounded-full bg-light-c dark:bg-dark-b"
                onPageChange={event => {
                  if (event.page != null) {
                    setPage({
                      page: event.page,
                      first: event.first,
                    });
                  }
                }}
              />
            )}
          </div>
        </div>
      ) : (
        <>
          <div className="mt-6 overflow-y-auto">
            {0 < totalRecords ? (
              <DataView
                lazy
                paginator={perPage < totalRecords}
                rows={perPage}
                className="px-4"
                loading={loading}
                totalRecords={totalRecords}
                first={page.first}
                onPage={event => {
                  if (event.page != null) {
                    setPage({
                      page: event.page,
                      first: event.first,
                    });
                  }
                }}
                value={tickets}
                itemTemplate={itemTemplate}
                emptyMessage={t('support.no_result')}
              />
            ) : (
              <div className="text-center text-body text-dimmed-d dark:text-light-b">{t('support.no_result')}</div>
            )}
          </div>
          <div className="flex flex-col items-center justify-end py-6 max-md:text-center md:px-20 md:pb-18 md:pt-10">
            <Button
              data-role="new-ticket"
              label={t('support.new_ticket')}
              onClick={() => navigate(`${window.location.pathname}#support/${HashEnum.NEW}`)}
              big
              // className="self-end"
            />
          </div>
        </>
      )}
    </>
  );
};

export default memo(TicketList);

function renderStatus(ticket: SupportTicket): string | JSX.Element {
  return 'new' === ticket.status ? (
    <span className="text-accent-b">{statusMapper[ticket.status]}</span>
  ) : (
    <span
      className={classNames('text-dimmed-a dark:text-dimmed-d', {
        in_progress: ['in_progress', 'open'].includes(ticket.status),
      })}
    >
      {statusMapper[ticket.status]}
    </span>
  );
}

function renderId(ticket: SupportTicket): string | JSX.Element {
  return ticket.is_read ? (
    <span className={`ticket-id text-dimmed-a dark:text-dimmed-d`}>{ticket.id}</span>
  ) : (
    <span className="ticket-id text-dark-a dark:text-light-b">{ticket.id}</span>
  );
}

function renderDate(ticket: SupportTicket, t: LocaleStringGetter): string | JSX.Element {
  const date = getDistanceInWordsToNow(new Date(ticket.created_at), t, t('labels.ago'));

  return ticket.is_read ? (
    <span className={`text-dimmed-a dark:text-dimmed-d`}>{date}</span>
  ) : (
    <span className={`${styles.unread} text-dark-a dark:text-light-b`}>{date}</span>
  );
}

function renderSubject(ticket: SupportTicket): string | JSX.Element {
  if (ticket.is_read) {
    return (
      <span className={`${styles.subject} line-clamp-1 font-bold text-dimmed-a dark:text-dimmed-d`}>
        {ticket.subject}
      </span>
    );
  }

  return (
    <>
      <span className={styles.unread_marker} />
      <b className={`${styles.unread} text-dark-a dark:text-light-b`}>{ticket.subject}</b>
    </>
  );
}
