export function maskEmail(email: string) {
  if (typeof email !== 'string') {
    throw new TypeError('Email must be a string');
  }

  const atSymbolCount = (email.match(/@/g) || []).length;
  if (atSymbolCount !== 1) {
    throw new Error('Invalid email address');
  }

  const [user, domain] = email.trim().split('@');

  if (!user || !domain) {
    throw new Error('Invalid email address');
  }
  const parts = domain.split('.');
  if (parts.length < 2) {
    throw new Error('Invalid email address');
  }
  if (!parts[0] || !parts.at(-1)) {
    throw new Error('Invalid email address');
  }

  return `${user.slice(0, 2)}•••${user.slice(-1)}@${parts[0].slice(0, 2)}•••${parts.at(-1)}`;
}
