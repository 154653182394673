export enum BalloonPageName {
  UnsupportedCountry = 'unsupported',
  ForbiddenCountry = 'forbidden',
  Minor = 'minor',
}

export enum BalloonButtonActions {
  Dismiss = 'dismiss',
  CloseAccount = 'close-account',
}
