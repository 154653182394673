import { STORAGE_KEY_LIST } from '@services/storage';

export function persistTranslations(data: string) {
  localStorage.setItem(STORAGE_KEY_LIST.TRANSLATIONS, data);
}

export function getTranslations() {
  const data = localStorage.getItem(STORAGE_KEY_LIST.TRANSLATIONS);
  if (!data) {
    return null;
  }

  return JSON.parse(data)?.data;
}
