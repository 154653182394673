import * as yup from 'yup';

export const schemaLocalesAllResponse = yup
  .object({
    data: yup.array().of(
      yup.object({
        code: yup.string(),
        name: yup.string(),
        localized_name: yup.string(),
      })
    ),
  })
  .required();
export const schemaLocalesItemParamsRequest = yup
  .object({
    locale: yup.string().required(),
  })
  .required();
export const schemaLocalesItemResponse = yup
  .object({
    data: yup.object(),
  })
  .required();
