import { splitIntoDateParts } from '@services/time';
import { SimpleFieldWrapper } from '@ui/elements';
import { FieldsFormWrapper } from '@ui/elements/FormWrapper';
import { useFieldsBasedOnMonthAndYear } from '@ui/elements/forms/SegmentedDateComponent/hooks';
import PropTypes from 'prop-types';
import { useCallback, useMemo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

const formBuildingMapperOptions = {
  preferClean: true,
  noInternalWrapper: true,
};

SegmentedDateComponent.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  name: PropTypes.string,
  hasError: PropTypes.bool,
};

export function SegmentedDateComponent({ name, value, onChange, hasError }) {
  const [monthYearRecord, setMonthYearRecord] = useState(splitIntoDateParts(value));
  const fields = useFieldsBasedOnMonthAndYear(monthYearRecord);

  const defaultValues = useMemo(() => splitIntoDateParts(value), [value]);

  const onSubmitHandler = useCallback(
    data => {
      const { date = -1, month = -1, year = -1 } = data;
      setMonthYearRecord(state => (state.month !== month || state.year !== year ? { month, year } : state));

      if (hasNoValue(year) || hasNoValue(month) || hasNoValue(date)) {
        onChange?.({ target: { name, value: null }, value: null });
        return;
      }

      const value = `${year}-${String(1 + month).padStart(2, '0')}-${String(date).padStart(2, '0')}`; // ("2020-04-13")

      onChange?.({ target: { name, value }, value });
    },
    [name, onChange]
  );
  // TODO: Fix flex gap
  return (
    <div className="flex flex-col gap-4 max-md:w-[17.25rem] md:flex-row md:gap-2">
      <FieldsFormWrapper
        bareFields
        submitOnChange
        formClassName={['segmentedDateForm', hasError ? 'segmentedDateForm-error' : ''].join(' ')}
        name={`segmented-date`}
        onSubmit={onSubmitHandler}
        fields={fields}
        formBuildingMapperOptions={formBuildingMapperOptions}
        fieldsWrapperComponent={SimpleFieldWrapper}
        defaultValues={defaultValues}
      />
    </div>
  );
}

function hasNoValue(input) {
  return isNaN(input) || 'undefined' === typeof input || -1 === input;
}

ControlledSegmentedDateComponent.propTypes = {
  name: PropTypes.string,
  value: PropTypes.any,
  options: PropTypes.object,
};

export function ControlledSegmentedDateComponent({ name, value, options }) {
  const { submitOnChange } = options;
  const { control, forceSubmit } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={value}
      render={({ field, fieldState }) => (
        <SegmentedDateComponent
          id={field.name}
          name={name}
          value={field.value}
          onChange={event => {
            field.onChange(event.value);
            submitOnChange && forceSubmit?.();
          }}
          hasError={Boolean(fieldState.error)}
        />
      )}
    />
  );
}
