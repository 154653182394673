export const constant = c => () => c;

export const safelyExecute =
  (fn, fallback) =>
  (...args) => {
    try {
      return fn(...args);
    } catch {
      return fallback && fallback(...args);
    }
  };
export const tryExecute =
  fn =>
  (...args) => {
    try {
      return [null, fn(...args)];
    } catch (error) {
      return [error];
    }
  };

export function memoize(cacheSize, argListSize, fn) {
  if (cacheSize !== 1 || argListSize !== 1) {
    return fn;
  }
  const memo = { arg: null, result: null };
  return arg => {
    if (memo.arg !== arg) {
      memo.arg = arg;
      memo.result = fn(arg);
    }
    return memo.result;
  };
}
