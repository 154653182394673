import { useLocaleServices } from '@ui/contextProviders/Locale';
import { Icon, Icons } from '@ui/lib/Icon';
import { NewCommentFormData, files } from '@ui/support/api/schemas';
import { toBlob } from '@ui/support/utils/screenshoter';
import { BlockUI } from 'primereact/blockui';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { ChangeEvent, FC, RefObject, useRef, useState } from 'react';
import { ObjectSchema, ValidationError, object, string } from 'yup';
import { FilePreview } from '../FormInputs/FilePreview';
import styles from './ChatTextInput.module.scss';

type ChatTextInputProps = {
  onSubmit: (value: NewCommentFormData) => Promise<void>;
  placeholder?: string;
  dialogRef?: RefObject<Dialog>;
  isMobile?: boolean;
};

const commentScema: ObjectSchema<NewCommentFormData> = object({
  content: string().optional(),
  images: files()
    .maxSize(5_000_000)
    .fileType('image/')
    .maxFiles(5)
    .when('content', {
      is: (content: string) => 0 === content?.length,
      then: schema => schema.required(),
      otherwise: schema => schema.optional(),
    }),
});

export const ChatTextInput: FC<ChatTextInputProps> = ({ onSubmit, placeholder, dialogRef, isMobile }) => {
  const [isEmpty, setEmpty] = useState(true);
  const [images, setImages] = useState<File[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const { t } = useLocaleServices();

  const fieldRef = useRef<HTMLDivElement>(null);

  async function handleSubmit() {
    try {
      if (fieldRef.current) {
        const formData: NewCommentFormData = {
          content: fieldRef.current.innerText,
          images,
        };
        commentScema.validateSync(formData);
        setLoading(true);
        try {
          await onSubmit(formData);
          setEmpty(true);
          setImages([]);
          fieldRef.current.innerText = '';
        } catch (error: any) {
          const message = 'errors' in error && (error as ApiErrorResponseType).errors?.[0].message;
          setError(message ? t(message) : 'Something went wrong');
        } finally {
          setLoading(false);
        }
      }
    } catch (error) {
      setError((error as ValidationError).message);
    }
  }

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    const files: File[] = [...images];
    for (const file of event.target.files ?? []) {
      if (!files.some(f => f.name === file.name)) {
        files.push(file);
      }
    }
    if (files.length !== images.length) {
      setImages(files);
    }
  }

  function handleRemove(file: File) {
    setImages(images.filter(f => f.name !== file.name));
  }

  async function handleTakeScreenshot() {
    const el = dialogRef?.current?.getElement()?.parentElement;
    if (el != null) {
      el.style.visibility = 'hidden';
    }
    try {
      const bytes = await toBlob();
      if (bytes != null) {
        const file = new File([bytes], `screenshot_${Math.random().toString(36).slice(4)}.png`, {
          type: 'image/png',
        });
        if (images.length > 0) {
          setImages(prev => [...prev, file]);
        } else {
          setImages([file]);
        }
      }
    } finally {
      if (el != null) {
        el.style.visibility = 'visible';
      }
    }
  }

  return (
    <div className={`support-chat-input ${styles.footer}`}>
      <div className={styles.input_wrapper}>
        <BlockUI blocked={loading}>
          <div className={styles.input_field_wrapper}>
            <div
              className={styles.input_field}
              ref={fieldRef}
              contentEditable
              data-placeholder={placeholder}
              onInput={event => {
                const len = event.currentTarget.innerText.trim().length;
                if (error) {
                  setError(null);
                }
                if (isEmpty && len > 0) {
                  setEmpty(false);
                } else if (!isEmpty && 0 === len) {
                  setEmpty(true);
                }
              }}
              onKeyDown={event => {
                if ('Enter' === event.key && (event.ctrlKey || event.metaKey)) {
                  event.preventDefault();
                  handleSubmit();
                }
              }}
            />
            <div className={styles.controls}>
              <label>
                <input type="file" multiple accept="image/*" className="hidden" onChange={handleChange} />
                <Icon icon={Icons.ChatUpload} />
              </label>
              {!isMobile && <Icon icon={Icons.Camera} onClick={handleTakeScreenshot} />}
            </div>
          </div>
          {error && <div className="p-error block">{error}</div>}
          {images.length > 0 && (
            <div className={styles.file_list}>
              {images.map((file: File) => (
                <FilePreview file={file} key={file.name} showName={false} onRemove={handleRemove} />
              ))}
            </div>
          )}
        </BlockUI>
      </div>
      <Button
        className="p-button-rounded h-[46px] w-[46px] bg-accent-a px-[0.8rem] py-0 disabled:opacity-50"
        aria-label="Filter"
        onClick={handleSubmit}
        disabled={isEmpty && 0 === images.length}
        loading={loading}
      >
        <Icon icon={Icons.SendNew} className="h-[30px] text-[20px] dark:text-white" />
      </Button>
    </div>
  );
};
