import { FIELD_TYPES } from '@services/fields';

const dropdownDefault = (name, value) => {
  if ('country' === name) {
    return 'US';
  }
  return value;
};

/**
 * @deprecated
 */
export function preprocessFormFields(fields) {
  return fields.map(item => {
    return {
      ...item,
      defaultValue: getFieldDefaultValue(item),
      ...(item.values
        ? {
            values: preprocessDropdownValues(item.values),
          }
        : {}),
      ...('zip_code' === item.name
        ? {
            options: Object.assign({}, item.options, {
              depends_on: 'country',
            }),
          }
        : {}),
    };
  });
}

export function preprocessDropdownValues(values) {
  if (!Array.isArray(values)) {
    return [];
  }
  return values.map(item =>
    Object.assign({}, item, {
      ...('undefined' !== typeof item.value ? {} : { value: item.code ?? item.id }),
      ...('undefined' !== typeof item.label ? {} : { label: item.name }),
      ...('undefined' !== typeof item.name ? {} : { name: item.label }),
    })
  );
}

export function getFieldDefaultValue({ type, value, name /*values*/ }) {
  switch (type) {
    case FIELD_TYPES.SINGLE_CHOICE:
    case FIELD_TYPES.TEXT:
    case FIELD_TYPES.STATIC_TEXT:
    case FIELD_TYPES.PASSWORD:
    case FIELD_TYPES.EMAIL: {
      return value ?? '';
    }

    // boolean typed elements
    case FIELD_TYPES.CHECKBOX_CLEAN:
    case FIELD_TYPES.CHECKBOX: {
      return value ?? false;
    }

    // date typed elements
    case FIELD_TYPES.BIRTHDATE:
    case FIELD_TYPES.DATE: {
      return value ? new Date(value).toISOString() : '';
    }

    case FIELD_TYPES.DROPDOWN_CLEAN:
    case FIELD_TYPES.DROPDOWN: {
      return value || dropdownDefault(name, value);
    }
    case FIELD_TYPES.MULTISELECT: {
      return value || [];
    }

    //      return values.reduce((res, item) => {
    //        if (value.includes(item.code)) {
    //          res.push(item)
    //        }
    //        return res
    //      }, [])
    case FIELD_TYPES.PLACES_AUTOCOMPLETE: {
      // TODO: make sure if we need `null`, and not `''`
      return null;
    }

    case FIELD_TYPES.PUSH_NOTIFICATION: {
      return value ?? false;
    }

    // complex types:
    case FIELD_TYPES.PAYPAL_EMAIL_CHANGE:
    case FIELD_TYPES.SOCIAL_AUTHORIZATION:
    case FIELD_TYPES.AVATAR:
    case FIELD_TYPES.ADDRESS: {
      return null;
    }
    // default choice
    default: {
      return null;
    }
  }
}

export function getDefaultValuesForFields(fields, defaultValues, parentName = '') {
  const result = Object.fromEntries(
    (fields || []).map(field =>
      field.fields /* && field.type === FIELD_TYPES.ADDRESS*/
        ? [
            field.name,
            getDefaultValuesForFields(
              field.fields,
              defaultValues?.[field.name],
              parentName ? `${parentName}.${field.name}` : field.name
            ),
          ]
        : [field.name, defaultValues?.[field.name] ?? getFieldDefaultValue(field)]
    )
  );
  return result;
}
