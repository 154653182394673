import * as yup from 'yup';
// import { emailMatchPattern } from '../patterns'
import { validateZipCodeForCountryAsyncThunk } from '@model/features/geo';
import { updateCountiesField } from '@model/features/registration';
import { memoizeAsync, safelyAwait } from '../promises';

export const username_validation = async input =>
  // TODO: implement this
  Boolean(input);

export const emailValidSchema = yup.string().trim().required('errors.no_email').email('errors.no_valid_email');

const confirmEmailValidSchema = emailFieldName =>
  yup
    .string()
    .trim()
    .required('errors.no_email')
    .oneOf([yup.ref(emailFieldName), null], 'errors.no_confirm_email');

export function emailValidation(value) {
  return emailValidSchema.isValidSync(value);
}

export async function state_validation(/*input, ...args*/) {
  // TODO: implement this
  return true;
}

const validateZipForCountryMemoized = memoizeAsync(validateZipForCountryForMemo, { skipArgs: 1 });

export async function zip_code_validation(
  input,
  { path, parent: formData, ...yupAPI },
  { preconditionSchema, extra /*, ...options*/ }
) {
  // has to do with unpredictable order of validation
  if (!preconditionSchema.isValidSync(input)) {
    return false;
  }

  const { dispatch } = extra;
  if (!dispatch) {
    return true;
  }
  if (!input) {
    return true;
  }
  const { country } = formData;
  if (!country) {
    return true;
  }

  // TODO: delete the below clause when server supports validation of postal codes for other countries
  if (!/^us$/i.test(country)) {
    return true;
  }

  const [error, result] = await validateZipForCountryMemoized(dispatch, country, input);

  setTimeout(() => {
    if (error || !result) {
      dispatch(updateCountiesField({ data: null }));
      return;
    }
    dispatch(updateCountiesField({ data: result, zip: input }));
  }, 0);

  if (error || !result) {
    //    dispatch(updateCountiesField({ data: null }))
    return yupAPI.createError({ path, message: 'Code is invalid' });
  }
  //  dispatch(updateCountiesField({ data: result , zip: input }))
  return true;
}

async function validateZipForCountryForMemo(dispatch, country, zip) {
  const [error, result] = await safelyAwait(dispatch(validateZipCodeForCountryAsyncThunk({ country, zip })).unwrap());
  return error ? null : 200 === result.code ? result.response?.data : null;
}

export const knownValidations = {
  zip_code_validation,
  state_validation,
};

export const changeEmailValidationSchema = yup
  .object({
    email: emailValidSchema.test(
      'not-old-email',
      'errors.email.new_required',
      (value, context) => value !== context.options?.context?.old_email
    ),
  })
  .required('changeEmailValidationSchema.required');

// export const loginSchema = yup.object({
//   email: emailValidSchema,
//   password: yup.string().trim().required('errors.no_password'),
//   remember_me: yup.boolean()
// })

export const passwordValidSchema = yup.string().trim().required('errors.no_password').min(6, 'errors.weak_password');

export const confirmValidSchema = yup
  .string()
  .trim()
  .required('errors.no_password')
  .min(6, 'errors.weak_password')
  .oneOf([yup.ref('password'), null], 'errors.no_valid_password');

// export const confirmIsValid = async value => {
//   const schema = yup.object({ confirm: confirmValidSchema })
//   const result = await schema.isValid(value)
//   return result
// }

// export const passwordIsValid = async value => {
//   const schema = yup.object({ password: passwordValidSchema })
//   const result = await schema.isValid(value)
//   return result
// }

// /**
//  * @deprecated
//  */
// export const passwordAndConfirmValidSchema = yup.object({
//   password: passwordValidSchema,
//   confirm: confirmValidSchema
// })

// /**
//  *
//  * @returns @deprecated
//  */
// export const passwordAndConfirmIsValid = async values => {
//   const result = await passwordAndConfirmValidSchema.isValid(values)
//   return result
//   // return await schema.validate(values)
// }

/**
 *
 *
 * @returns @deprecated
 */
export const emailAndConfirmIsValid = async values => {
  const schema = yup.object({
    paypal_email: emailValidSchema,
    paypal_email_confirmation: confirmEmailValidSchema('paypal_email'),
  });
  const result = await schema.isValid(values);
  return result;
  // return await schema.validate(values)
};

export const usernameYupScheme = yup.string().trim().required(true).min(6);

export const settingsGroup = yup.object({
  labels: yup.mixed(),
  fields: yup
    .array(
      yup.object({
        name: yup.string().required(),
        label: yup.string().required(),
        labels: yup.mixed(),
        type: yup.string().required(),
        options: yup.object(),
        value: yup.mixed(),
        values: yup.array(),
      }).required
    )
    .required(),
});
