import { forwardRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { recaptchaV2Key } from '.';

type Props = {
  onCode?: (code: string) => void;
};

export const RecaptchaInline = forwardRef<ReCAPTCHA, Props>(({ onCode }, ref) => {
  if (!recaptchaV2Key) {
    // eslint-disable-next-line no-console
    console.error('Missing GOOGLE_RECAPTCHA_V2_PUBLIC_KEY');
    return null;
  }

  const handleRecaptchaCommit = code => {
    onCode?.(code);
  };

  return <ReCAPTCHA ref={ref} sitekey={recaptchaV2Key} onChange={handleRecaptchaCommit} />;
});

RecaptchaInline.displayName = 'RecaptchaInline';
