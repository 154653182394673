import { accessor } from '@model/accessors';
import { CLIENT_ERRORS } from '@model/api/endpointError';
import { createSlice } from '@reduxjs/toolkit';
import { getTimestamp } from '@services/time';
import { i18next } from '@ui/contextProviders/Locale/i18n';

export const name = 'errors';

const initialState = {
  lastError: null,
  timestamp: 0,
  handled: 0,
};

export const errorsSlice = createSlice({
  name,
  initialState,
  reducers: {
    setLastReportedErrorHandled(state, { payload }) {
      const timestamp =
        Object(payload) === payload && 'timestamp' in payload ? payload.timestamp : payload ?? getTimestamp();
      if (state.timestamp !== timestamp) {
        return;
      }
      state.handled = timestamp;
    },

    reportError(state, action) {
      const timestamp = getTimestamp();
      Object.assign(state, { timestamp });

      if (!action.payload && action.error) {
        state.lastError = String(action.error.message ?? action.error);
        return;
      }
      if (!action.payload) {
        state.lastError = null;
        return;
      }
      const { payload } = action; // (sic!)
      if (payload.cause) {
        switch (payload.cause) {
          case CLIENT_ERRORS.NETWORK_ERROR:
            state.lastError = i18next.t('errors.network_error');
            break;
          case CLIENT_ERRORS.SERVER_UNEXPECTED_STATUS_CODE:
            state.lastError = i18next.t('errors.server_error');
            break;
          default:
            state.lastError = payload.cause;
        }
        return;
      }

      if (!payload.response) {
        state.lastError = 'string' === typeof payload ? payload : payload.message ?? 'General error';
        return;
      }

      const { errors, error: singleError } = payload.response;
      const [error = {}] = errors || [];
      if ('ConditionError' === error.name) {
        state.lastError = `ConditionError: ${String(error)}`;
      } else if ('TypeError' === error.name) {
        state.lastError = 'Please wait and try again';
      } else {
        state.lastError = error.context
          ? i18next.t(error.message ?? singleError.message, error.context)
          : i18next.t(error.message ?? singleError.message ?? String(singleError));
      }
    },
  },
});

const defaultExport = {
  [name]: errorsSlice.reducer,
};

export default defaultExport;

export const { reportError, setLastReportedErrorHandled } = errorsSlice.actions;

const errorsAccessor = accessor(name);
export const accessLastError = () =>
  errorsAccessor(({ lastError, timestamp, handled }) => (timestamp > handled ? lastError : undefined), null);

export const accessLastErrorTimestamp = () => errorsAccessor('timestamp', null);
