import { getPvContext } from '@services/context';
import React from 'react';
import css from './environmentHint.module.scss';

const environmentHint = getPvContext('backend_env');
export const EnvironmentHint = () => {
  if (!environmentHint) {
    return null;
  }
  return (
    <div id="env-notifier" className={css.environmentHint}>
      {environmentHint} environment
    </div>
  );
};

export default React.memo(EnvironmentHint);
