import { Button } from '@ui/lib/Button';
import { FC, useState } from 'react';
import { CloseAccountConfirm } from '../Settings/components/AccountActions/CloseAccountConfirm';
import { CloseAccountConfirmFinal } from '../Settings/components/AccountActions/CloseAccountConfirmFinal';
import { FlameLabelOne, FlameLabelTwo } from '../Settings/components/AccountActions/FlameLabels';

import '../Settings/components/AccountActions/CloseAccount.scss';
import { useDeleteAccountHandler } from '../Settings/hooks/useDeleteAccount';

const labels = {
  cancel: 'settings.close_account.cancel',
  cancel2: 'settings.close_account.cancel2',
  confirm: 'settings.close_account.confirm',
  confirm2: 'settings.close_account.confirm2',
  delete_message: 'settings.close_account.delete_message',
  title: 'settings.close_account.title',
  warning: 'settings.close_account.warning',
};

export const DeleteAccountForm: FC = () => {
  const [step, setStep] = useState(0);

  const handleDeleteAcc = useDeleteAccountHandler();
  // TODO: Fix flex gap
  return (
    <div className="flex items-center justify-center gap-6 max-md:flex-col">
      {1 === step && <FlameLabelOne />}
      {2 === step && <FlameLabelTwo />}
      {0 === step && <Button onClick={() => setStep(1)} className="mt-4" label="Delete Account" />}
      {1 === step && <CloseAccountConfirm labels={labels} onCancel={() => setStep(0)} onSubmit={() => setStep(2)} />}
      {2 === step && (
        <CloseAccountConfirmFinal labels={labels} onCancel={() => setStep(0)} onSubmit={handleDeleteAcc} />
      )}
    </div>
  );
};
