import { getOsTheme } from '@/utils/osColorTheme';
import { selectAvailableSurveysCount } from '@model/features/survey/selectors';
import { persistDataToStorage } from '@services/storage';
import { useLocaleServices } from '@ui/contextProviders/Locale';
import { useAppSelector } from '@ui/hooks/redux';
import { useCallback, useState } from 'react';
import { useUpdateSettingsRef } from './useUpdateSettings';

export function useThemeChange() {
  const { t } = useLocaleServices();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const updateSettingsRef = useUpdateSettingsRef();
  const availableSurveysLength = useAppSelector(selectAvailableSurveysCount);

  const updateTheme = useCallback(
    async (value: AppTheme) => {
      setIsLoading(true);
      setError(null);
      // set favicon
      persistDataToStorage('theme', value);
      if ('auto' === value) {
        window.switchTheme(getOsTheme(), availableSurveysLength > 0);
      } else {
        window.switchTheme(value, availableSurveysLength > 0);
      }
      const [resError] = await updateSettingsRef.current('theme', value);
      if (null != resError) {
        setError(t(resError.message, resError.context));
      }
      setIsLoading(false);
    },
    [t, updateSettingsRef, availableSurveysLength]
  );

  return {
    updateTheme,
    isLoading,
    error,
  };
}
