import { makeUiTestAssistFromTuples } from '@modules/ui-test-assist/testidHelpers';

export const {
  props: UI_TEST_ASSIST,
  extendableProps: UI_TEST_ASSIST_EXT,
  selectors: UI_SELECTORS,
  testids: UI_TESTIDS,
  //  tails: UI_TESTID_TAIL,
  //  ...E2E_HELPERS
} = makeUiTestAssistFromTuples(t => {
  return {
    // cleared:
    // pages
    PAGE_HOME: t('page', 'home'),
    PAGE_DASHBOARD: t('page', 'dashboard'),
    PAGE_MAGIC_LINK: t('page', 'magic link'),
    PAGE_REGISTRATION: t('page', 'registration'),
    PAGE_FORGOT_PASSWORD: t('page', 'forgot password'),
    PAGE_SETUP_EMAIL: t('page', 'setup email'),
    PAGE_ACTIVATE_EMAIL: t('page', 'activate email'),
    PAGE_REMOVE_ACCOUNT: t('page', 'remove account'),
    PAGE_RESTORE_ACCOUNT: t('page', 'restore account'),
    PAGE_TRAITSCORE: t('page', 'traitscore'),

    BTN_SUPPORT_SIDE_BUTTON: t('button', 'support_side_button'),
    BTN_SUPPORT_FOOTER_BUTTON: t('button', 'support_footer_button'),
    PAGE_EARNINGS: t('page', 'earnings'),
    CMP_EARNINGS_HEAD: t('component', 'earnings head'),
    CMP_EARNINGS_STATUS: t('component', 'earnings status'),
    PAGE_EARNINGS_HISTORY: t('page', 'earnings history'),
    EL_EARNINGS_HISTORY_DATATABLE: t('element', 'earnings history datatable'),
    EL_EARNINGS_HISTORY_PAGINATOR: t('element', 'earnings history paginator'),

    CMP_REQUEST_DOWNLOAD_COMPONENT: t('component', 'request download'),
    CMP_DOWNLOAD_COMPONENT: t('component', 'download'),
    BTN_REQUEST_DOWNLOAD_BUTTON: t('button', 'request download button'),
    BTN_DOWNLOAD_BUTTON: t('button', 'download button'),

    BTN_FORGOT_PASSWORD_RESEND_LINK: t('button', 'forget password', 'resend link'),

    FLD_WRAPPER_HOME: t('wrapper field', 'home'),

    FRM_RESTORE_PASSWORD: t('form', 'restore password'),
    BTN_RESTORE_PASSWORD_SUBMIT: t('button', 'restore password', 'submit'),

    CMP_REGISTRATION_COMPONENT: t('component', 'registration'),
    CMP_PASSWORD_CONFIRM_COMPONENT: t('component', 'password confirm'),
    FLD_PASSWORD_CONFIRM: t('field', 'password confirm'),
    INP_PASSWORD_CONFIRM: t('input', 'password confirm'),
    ERR_PASSWORD_CONFIRM: t('form error', 'password confirm'),

    PIC_NAV_BTN_DASHBOARD: t('picture button', 'dashboard navigation'),
    PIC_NAV_BTN_FOOTER: t('picture button', 'footer navigation'),
    PIC_NAV_BTN_TOOLTIP: t('picture button', 'tooltip navigation'),

    BTN_SIGN_UP_SIGN_IN_TOGGLE: t('toggle', 'sign up sign in'),

    CMP_LOGIN: t('component', 'login'),
    BTN_LOGIN_MODE_TOGGLE: t('toggle', 'login mode'),
    BTN_LOGIN_RESEND_LINK: t('button', 'login by email', 'resend link'),
    BTN_LOGIN_BY_EMAIL: t('button', 'login mode', 'email'),
    BTN_LOGIN_BY_EMAIL_VERIFY: t('button', 'login by email', 'verify code'),
    FLD_LOGIN_BY_EMAIL_CODE: t('input', 'login by email', 'code'),
    ERR_LOGIN_BY_EMAIL_CODE_ERROR: t('error', 'login by email', 'code'),
    BTN_LOGIN_BY_PASSWORD: t('button', 'login mode', 'password'),

    FRM_LOGIN: t('form', 'login'),
    FLD_LOGIN: t('field', 'login'),
    BTN_LOGIN_SUBMIT: t('button', 'login', 'submit'),

    CMP_HOME_VIDEO: t('component', 'home page video'),

    CMP_REGISTER_NEW_USER: t('component', 'register new user'),
    FRM_REGISTER_NEW_USER: t('form', 'register new user'),
    FLD_REGISTER_NEW_USER: t('field', 'register new user'),
    BTN_REGISTER_NEW_USER_SUBMIT: t('button', 'register new user', 'submit'),

    FLD_RESTORE_PASSWORD: t('field', 'restore password'),

    CMP_HOME_BUTTONS: t('component', 'home page buttons'),
    BTN_HOME_BUTTONS: t('button', 'home page buttons'),

    CMP_MOBILE_HOME_BACK_BUTTONS: t('component', 'mobile home page back buttons'),
    BTN_MOBILE_HOME_BACK_BUTTONS_BUTTON: t('button', 'mobile home page back buttons', 'back'),

    CMP_CONFIRM: t('component', 'text confirm'),
    FLD_CONFIRM_INPUT: t('field', 'text confirm'),
    ERR_CONFIRM: t('error', 'text confirm'),

    CMP_INFO_BALLOON_CONTAINER: t('component', 'info balloon container'),
    EL_ERROR_BALLOON: t('element', 'balloon', 'error'),
    EL_SUCCESS_BALLOON: t('element', 'balloon', 'success'),
    EL_INFO_BALLOON: t('element', 'balloon', 'info'),
    EL_BUTTON: t('element', 'button'),

    // CMP_PASSWORD_SMART: t('component', 'password smart'),
    FLD_PASSWORD_SMART: t('field', 'password smart'),

    CMP_PROFILING_STEP: t('component', 'profilinf step'),
    FRM_PROFILING: t('form', 'profile'),

    CMP_SETTINGS_WRAPPER_COMPONENT: t('component', 'settings wrapper component'),
    CMP_SETTINGS_CHANGE_COMPONENT: t('component', 'settings change component'),
    BTN_SETTINGS_CHANGE_BUTTON: t('button', 'settings change button'),
    BTN_SETTINGS_SAVE_BUTTON: t('button', 'settings save button'),
    BTN_SETTINGS_CANCEL_BUTTON: t('button', 'settings cancel button'),

    CMP_AVATAR_COMPONENT: t('component', 'avatar component'),
    FLD_AVATAR_INPUT: t('field', 'avatar upload'),

    CMP_ADD_CHANGE_EMAIL_COMPONENT: t('component', 'add-or-change component'),
    FLD_ADD_CHANGE_EMAIL: t('field', 'add-or-change input'),
    FLD_ADD_CHANGE_EMAIL_ERROR: t('field', 'add-or-change input error'),
    FLD_CODE_ADD_CHANGE_EMAIL: t('field', 'add-or-change code'),

    CMP_ANNOTATION_COMPONENT: t('component', 'annotation'),
    FLD_ANNOTATION_METHOD: t('radio', 'annotation method'),

    CMP_PHONE_CHANGE_COMPONENT: t('component', 'change phone'),

    CMP_PASSWORD_COMPONENT: t('component', 'password'),
    FLD_PASSWORD_INPUT: t('field', 'input field'),
    FLD_SETTINGS_PASSWORD_ERROR: t('field', 'settings password error field'),

    CMP_INPUT_COMPONENT: t('component', 'input component'),

    FLD_TEXT_INPUT: t('generic form field', 'text input'),
    FLD_CHECKBOX: t('generic form field', 'checkbox'),
    FLD_RADIO: t('generic form field', 'radiobutton'),

    FLD_ONE_CHOICE_COMPONENT: t('field', 'single choice input'),

    FLD_DROPDOWN_INPUT: t('select', 'dropdown'),

    FLD_CHECKBOX_INPUT: t('checkbox', 'input'),

    CMP_EMAIL_ACTIVATION: t('component', 'email activation'),
    BTN_EMAIL_ACTIVATION_CHANGE: t('button', 'email activation', 'change'),
    FLD_EMAIL_ACTIVATION_EMAIL: t('field', 'email activation', 'email'),
    FLD_EMAIL_ACTIVATION_CODE: t('field', 'email activation', 'code'),
    ERR_EMAIL_ACTIVATION_EMAIL: t('error', 'email activation', 'email'),
    BTN_EMAIL_ACTIVATION_EMAIL_SAVE: t('button', 'email activation', 'save email'),
    BTN_EMAIL_ACTIVATION_EMAIL_CANCEL: t('button', 'email activation', 'cancel edit email'),
    BTN_EMAIL_ACTIVATION_VERIFY_CODE: t('button', 'email activation', 'verify code'),
    BTN_EMAIL_ACTIVATION_RESEND: t('button', 'email activation', 'resend'),

    FORM_SOCIAL_BUTTONS: t('form', 'social buttons'),
    BTN_SOCIAL_BUTTON: t('button', 'social button'),

    BTN_CASHOUT_METHOD_BUTTON: t('button', 'method button'),

    MARK_BUTTON_GROUP_LARGE: t('marker', 'group'),

    EL_SPINNER: t('element', 'spinner'),
    EL_PROGRESS: t('element', 'progressbar'),
  };
});
