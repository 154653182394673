import { accessRegistrationPhoneNumber } from '@model/features/registration';
import { getPvContext } from '@services/context';
import { centsToDollar } from '@services/currency';
import { PortalParent } from '@ui/components/SmartFormComponents/complexComponents/PhoneVerificationSmartFormComponent/portalParent';
import { If, IfNot, PopUp } from '@ui/elements';
import { MEDIA_BP, useMediaQuery } from '@ui/hooks';
import { Button } from 'primereact/button';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { EarningBonus, Tick } from '../../elements';
import { VerifyForDevButton } from './elements';
import { usePhoneVerification } from './usePhoneVerification';

const isProduction = 'production' === getPvContext('backend_env', 'production');

PhoneVerificationSmartFormComponent.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  interceptSubmit: PropTypes.func,
  onSubmit: PropTypes.func,

  masterOptions: PropTypes.object,
  options: PropTypes.object,
};
/**
 * @type {SmartFormComplexComponent} PhoneVerificationSmartFormComponent
 * @return {JSX.Element}
 * @constructor
 */
export function PhoneVerificationSmartFormComponent({
  name,
  value,
  interceptSubmit,
  onSubmit,

  masterOptions,
  options = {},
}) {
  const { formSurroundingElement } = masterOptions || {};
  const {
    incentive_cents,
    status_url, ///api/v1/phones/180602/status
    sms_number,
    dialup_number,
    verification_code,
  } = options;

  const [popupActive, setPopupActive] = useState(false);

  const [showToolTip, setShowToolTip] = useState(false);
  const isLargeScreen = useMediaQuery(MEDIA_BP.isOrGreaterThan768);
  const tooltipRef = useRef(null);

  /**
   * # Flow:
   *               # GET /api/v1/phone/status : { status: 'pending' }
   *               # GET /api/v1/phone/status : { status: 'pending' }
   *               # GET /api/v1/phone/status : { status: 'completed' }
   *               # UI: enabling next button
   *               # GET /api/v1/steps : { id: 'step_3' }
   */
  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useFormContext();

  const storedValue = useSelector(accessRegistrationPhoneNumber());
  register(name, { value: value || storedValue });

  /**
   * We block the form by simulating an intercepted form submission
   * To do that, we issue a controlled promise and have the interceptSubmit return it
   */

  const { verified, stopPolling, phonePollingProcessRef, startPhoneVerificationPolling } = usePhoneVerification({
    value,
    status_url,
  });

  const [pollingEnded, setPollingEnded] = useState(false);

  useEffect(() => {
    if (verified || phonePollingProcessRef.current || pollingEnded) {
      return void 0; // undefined
    }

    interceptSubmit(startPhoneVerificationPolling);
    handleSubmit(onSubmit)().then(() => {
      interceptSubmit(null);
      setPollingEnded(true);
    });
    //      interceptSubmit(null)

    phonePollingProcessRef.current?.then?.(() => {
      setPollingEnded(true);
      interceptSubmit(null);
    });

    return () => {
      stopPolling();
    };
  }, [
    phonePollingProcessRef,
    handleSubmit,
    interceptSubmit,
    startPhoneVerificationPolling,
    onSubmit,
    pollingEnded,
    stopPolling,
    verified,
  ]);

  const tooltipOnMouse = event => {
    setShowToolTip(event);
  };

  useEffect(() => {
    if (!tooltipRef.current) {
      return;
    }
    if (showToolTip) {
      tooltipRef.current.classList.add('show');
    } else {
      tooltipRef.current.classList.remove('show');
    }
  }, [showToolTip]);

  const popupHandler = useCallback(function (event) {
    event.preventDefault();
    setPopupActive(true);
  }, []);

  return (
    <div className="phone_slide">
      <div className="phone">
        <div className="filler img">
          <div className="position">
            <If condition={isLargeScreen}>
              <div className="message phone-msg" role="alert">
                <strong>Important!</strong>
                <br />
                Choose a phone number
                <br />
                that you&apos;ll have access to later.
                <br />
                <strong>You&apos;ll need it again</strong>
                <br />
                <strong>to cash out.</strong>
              </div>
            </If>
          </div>
        </div>
        <div className="phone_capture">
          {/* <strong>One final step.</strong> */}
          <br />
          <p>
            You need to verify your cell phone or a land line number via SMS (text message) or voice message.
            <br />
            Your regular carrier charges may apply. We give {incentive_cents} cents bonus to help defray these charges.
          </p>
          <If condition={isLargeScreen}>
            <div className="popup_desktop">
              <span onMouseEnter={() => tooltipOnMouse(true)} onMouseLeave={() => tooltipOnMouse(false)}>
                Why do we require phone verification?
              </span>
              <div className="tooltip" ref={tooltipRef}>
                <p>
                  As a member of PaidViewpoint.com, you agree to be limited to a single account. Phone validation is
                  part of our system of preventing abuse and fraud. Validation will be performed at the time of each
                  cash out.
                </p>
              </div>
            </div>
          </If>
        </div>
      </div>

      <br />

      <IfNot condition={isLargeScreen}>
        <div className="popup">
          <div className="filler" />
          <Button
            label="Why do we require phone verification?"
            className="button"
            onClick={popupHandler}
            type="button"
          />
        </div>
      </IfNot>

      <IfNot condition={value || storedValue}>
        <div className="call_number">
          <div className="filler" />
          <div className="text">
            Call:
            <a href={`tel:${dialup_number}`}>{dialup_number}</a>
            <br />
            and enter the security code:
            <span className="security-code">{verification_code}</span>
          </div>
          <EarningBonus value={`+ ${centsToDollar(incentive_cents)}`} condition={false} />
        </div>
        <div className="line">
          <div className="filler" />
          <div className="sep">
            <span className="or">OR</span>
            <hr color="61750d" />
            <If condition={isSubmitting}>
              <span className="loader" />
            </If>
          </div>
        </div>
        <div className="sms_number">
          <div className="filler" />
          <div className="text">
            SMS
            <span className="security-code">{verification_code}</span>
            to
            <a href={`sms:${sms_number}`}>{sms_number}</a>
          </div>
        </div>
      </IfNot>

      <If condition={value}>
        <div className="phone_done">
          <div className="filler" />
          <div className="done_wrap">
            <div className="text">Your new phone is now verified:</div>
            <div className="number">{value || storedValue}</div>
          </div>
          <Tick condition={true} />
          <EarningBonus value={`+ ${centsToDollar(incentive_cents)}`} condition={true} />
        </div>
      </If>

      {isProduction ? null : <VerifyForDevButton />}

      <PortalParent element={formSurroundingElement}>
        <PopUp active={popupActive} setActive={setPopupActive}>
          <p>
            As a member of PaidViewpoint.com, you agree to be limited to a single account. Phone validation is part of
            our system of preventing abuse and fraud. Validation will be performed at the time of each cash out.
          </p>
        </PopUp>
      </PortalParent>
    </div>
  );
}

export default PhoneVerificationSmartFormComponent;
