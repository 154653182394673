import { AppDispatch } from '@/store';
import { signOutThunk } from '@model/actions/signoutAction';
import { getAPIUrl } from '@services/context';
import { STORAGE_KEY_LIST } from '@services/storage';
import { recaptchaAPI } from '@ui/contextProviders/CaptchaProvider/recaptchaAPI';
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { LoginResponse } from './auth/types';

interface AppInternalAxiosRequestConfig<D = any> extends InternalAxiosRequestConfig<D> {
  _retry?: boolean;
  withToken?: boolean;
}

interface AppAxiosRequestConfig<D> extends AxiosRequestConfig<D> {
  withToken?: boolean;
}

class ApiService {
  private axiosInstance: AxiosInstance;
  private refreshTokenPromiseMap = new Map<string, Promise<LoginResponse>>();
  private baseUrl = '';
  private dispatch: AppDispatch | null = null;

  constructor(baseURL: string) {
    this.baseUrl = baseURL;
    this.axiosInstance = axios.create({
      baseURL,
    });

    // Add request interceptor
    this.axiosInstance.interceptors.request.use(
      config => this.requestInterceptor(config),
      error => Promise.reject(error)
    );

    // Add response interceptor
    this.axiosInstance.interceptors.response.use(
      response => response,
      error => this.responseInterceptor(error)
    );
  }

  private async requestInterceptor(config: AppInternalAxiosRequestConfig) {
    if (config.withToken) {
      const accessToken = this.getAccessToken();
      if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
      }
    }
    return config;
  }

  private responseInterceptor(error: AxiosError) {
    if (null == error.response || null == error.config) {
      return Promise.reject(error);
    }

    const originalRequest = error.config;

    switch (error.response.status) {
      case 401:
        return this.handle401Error(originalRequest, error);
      case 498:
        return this.handle498Error(originalRequest, error);
      default:
        return Promise.reject(error);
    }
  }
  private handle498Error(originalRequest: AppInternalAxiosRequestConfig, error: AxiosError) {
    if (originalRequest && !originalRequest._retry) {
      const modifiedRequest = { ...originalRequest };
      modifiedRequest._retry = true;

      // Wait for the captcha to be solved
      return recaptchaAPI.invokeRecaptcha?.().then(async code => {
        if (!code || 'NO KEY' === code) {
          throw error;
        }
        modifiedRequest.data['g-recaptcha-response'] = code;
        // Chech if there is a pending refresh token request and wait for it to finish
        const refreshTokenPromise = this.refreshTokenPromiseMap.values().next().value;
        if (refreshTokenPromise) {
          await refreshTokenPromise;
        }
        return this.axiosInstance(modifiedRequest);
      });
    }
    return Promise.reject(error);
  }

  private async handle401Error(originalRequest: AppInternalAxiosRequestConfig, error: AxiosError) {
    const refreshToken = this.getRefreshToken();
    if (refreshToken && originalRequest && !originalRequest._retry) {
      const modifiedRequest = { ...originalRequest };
      modifiedRequest._retry = true;

      // Call your refresh token endpoint and obtain a new access token and retry the original request
      await this.refreshToken(refreshToken);
      return await this.axiosInstance(modifiedRequest);
    }
    return Promise.reject(error);
  }

  private getAccessToken(): string | null {
    return localStorage.getItem(STORAGE_KEY_LIST.AUTH_TOKEN);
  }

  private setAccessToken(token: string): void {
    localStorage.setItem(STORAGE_KEY_LIST.AUTH_TOKEN, token);
  }

  private getRefreshToken(): string | null {
    return localStorage.getItem(STORAGE_KEY_LIST.AUTH_REF_TOKEN);
  }

  private setRefreshToken(token: string): void {
    localStorage.setItem(STORAGE_KEY_LIST.AUTH_REF_TOKEN, token);
  }

  // TODO: Tmp solution to sync old service have to be removed in the future
  public setDispatch(dispatch: AppDispatch) {
    this.dispatch = dispatch;
  }

  public async refreshToken(refreshToken: string): Promise<string | undefined> {
    const key = refreshToken.slice(-10);
    const promise = this.refreshTokenPromiseMap.get(key);

    try {
      if (promise) {
        return (await promise).data.token;
      }
      this.refreshTokenPromiseMap.clear();
      const newPromise = axios
        .post<LoginResponse>(`${this.baseUrl}/authorization/tokens/refresh`, {
          refresh_token: this.getRefreshToken(),
        })
        .then(response => response.data);
      this.refreshTokenPromiseMap.set(key, newPromise);

      const refreshResponse = await newPromise;

      const newAccessToken = refreshResponse.data.token;
      this.setAccessToken(newAccessToken);
      this.setRefreshToken(refreshResponse.data.refresh_token);
      // Retry the original request with the new access token
      return newAccessToken || undefined; // Provide a default value
    } catch (error) {
      // Perform logout action
      this.dispatch?.(signOutThunk());
      // Perform logout
      throw error;
    }
  }

  public async get<T = any, D = any>(url: string, data?: D, config?: AppAxiosRequestConfig<D>): Promise<T> {
    const conf: AppAxiosRequestConfig<D> = {
      withToken: true,
      ...config,
      data,
    };
    const response = await this.axiosInstance.get<T, AxiosResponse<T, D>, D>(url, conf);
    return response.data;
  }

  public async post<T = any, D = any>(url: string, data?: D, config?: AppAxiosRequestConfig<D>): Promise<T> {
    const conf: AppAxiosRequestConfig<D> = {
      withToken: true,
      ...config,
    };
    const response = await this.axiosInstance.post<T, AxiosResponse<T, D>, D>(url, data, conf);
    return response.data;
  }

  public async delete<T = any, D = any>(url: string, config?: AppAxiosRequestConfig<D>): Promise<T> {
    const conf: AppAxiosRequestConfig<D> = {
      withToken: true,
      ...config,
    };
    const response = await this.axiosInstance.delete<T, AxiosResponse<T, D>, D>(url, conf);
    return response.data;
  }
}

export default ApiService;

export const apiService = new ApiService(getAPIUrl('/api/v1'));
