import { useLocaleServices } from '@ui/contextProviders';
import { ControlledCustomCheckbox } from '@ui/elements';
import { Checkbox } from 'primereact/checkbox';
import { Controller, useFormContext } from 'react-hook-form';
import { MEDIA_BP, useMediaQuery } from '@ui/hooks';
import { useCallback, useMemo } from 'react';
import { extraMessages } from '../../elements';
import { UI_TEST_ASSIST_EXT } from '@ui/testids';

import pvs from './checkbox_smart.module.scss';
import PropTypes from 'prop-types';

CheckboxSmartFormComponent.propTypes = {
  name: PropTypes.string,
  options: PropTypes.object,
  testId: PropTypes.string,
  testIdFn: PropTypes.func,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};
/**
 * @type {SmartFormSimpleComponent} CheckboxSmartFormComponent
 * @returns {JSX.Element}
 */
export function CheckboxSmartFormComponent({ name, options = {}, testId, testIdFn, defaultValue }) {
  const { ariaLabel, checkbox_label: checkboxLabel, hideMobileCheckbox } = options || {};

  const isLargeScreen = useMediaQuery(MEDIA_BP.isOrGreaterThan768);
  const { t } = useLocaleServices();

  const {
    getFieldState,
    watch,
    formState: { isSubmitting },
  } = useFormContext();

  const { error: fieldError } = getFieldState(name);

  const checked = watch(name) ?? Boolean(defaultValue);

  const extraLabel = useMemo(
    () =>
      extraMessages({
        message: checkboxLabel,
        t,
      }),
    [checkboxLabel, t]
  );

  const handleKeyDown = useCallback(
    event => {
      if (['Space', 'Enter'].includes(event.code) && !isSubmitting) {
        event.preventDefault();
        event.target.click();
      }
    },
    [isSubmitting]
  );

  const componentProps = {
    name,
    label: extraLabel,
    ariaLabel: t(ariaLabel),
    disabled: isSubmitting,
    hasError: fieldError,
    checked,
  };

  return isLargeScreen || hideMobileCheckbox ? (
    <ControlledCustomCheckbox key="a" {...componentProps} />
  ) : (
    <label
      key="b"
      className={[pvs.checkbox_wrap_mobile, fieldError && pvs.error].join(' ')}
      aria-label={t(ariaLabel)}
      tabIndex="0"
      onKeyDown={handleKeyDown}
    >
      <Controller
        name={name}
        render={({ field: { ref, ...field } }) => (
          <Checkbox
            {...UI_TEST_ASSIST_EXT.FLD_CHECKBOX(name)}
            {...(testIdFn ? testIdFn(name, checked ? 'checked' : 'unchecked') : {})}
            {...(testId ? testId : {})}
            name={name}
            checked={checked}
            data-value={checked ? 'checked' : 'unchecked'}
            inputRef={ref}
            {...field}
            tabIndex="-1"
            disabled={isSubmitting}
          />
        )}
      />

      <span>{extraLabel}</span>
    </label>
  );
}
