interface Data<Type = any> {
  type: string;
  value: Type;
}

export default class DataCollector<Type = any> {
  private readonly data: Map<string, Type>;
  private subscribers: ((data: Map<string, Type>) => void)[];

  constructor() {
    this.data = new Map();
    this.subscribers = [];
  }

  public subscribe(subscriber: (data: Map<string, Type>) => void): void {
    this.subscribers.push(subscriber);
  }

  public unsubscribe(subscriber: (data: Map<string, Type>) => void): void {
    this.subscribers = this.subscribers.filter(s => s !== subscriber);
  }

  public putData(data: Data<Type>): void {
    const oldValue = this.data.get(data.type);

    // Notify subscribers only if the value has changed
    if (oldValue !== data.value) {
      this.data.set(data.type, data.value);
      this.notifySubscribers();
    }
  }

  private notifySubscribers(): void {
    const copy = new Map(this.data);
    this.subscribers.forEach(subscriber => subscriber(copy));
  }
}

export const dataCollector = new DataCollector();
