import { createFibonacciGenerator } from '@/utils/delay';
import { apiBackendHealth } from '@model/api';
import { EndpointError } from '@model/api/endpointError';
import { safelyAwait } from '@services/promises';
import { formatSecondsToTime } from '@services/time/timeFromNumber';
import { InfoBalloon } from '@ui/components/InfoBalloon';
import { BALLOON } from '@ui/components/InfoBalloon/resources';
import { useLocaleServices } from '@ui/contextProviders';
import { useAppSelector } from '@ui/hooks/redux';
import { useCountdown } from '@ui/hooks/useCountdownSeconds';
import { Button } from '@ui/lib/Button';
import { routeNames } from '@ui/routes/routeNames';
import { isFailureResponse } from '@ui/support/utils/guards';
import { useEffect, useRef, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { useNavigate } from 'react-router';

let getNextDelay = createFibonacciGenerator(600);
const init = getNextDelay();

async function tryNetwork() {
  const [error, result] = await safelyAwait(apiBackendHealth());
  return !(error || EndpointError.isEndpointError(result) || isFailureResponse(result.response) || result.code !== 200);
}

export function NetworkErrorPage() {
  const { t } = useLocaleServices();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const delay = useRef<number>(init);

  const navigate = useNavigate();

  const previousPath = useAppSelector(state => state.navigation.previousPath);

  const checkNetwork = async () => {
    setIsLoading(true);
    const isSuccess = await tryNetwork();
    setIsLoading(false);
    return isSuccess;
  };

  const { time, start, reset, stop } = useCountdown(delay.current, async () => {
    const isSuccess = await checkNetwork();
    if (isSuccess) {
      navigate(previousPath || routeNames.root);
    } else {
      delay.current = getNextDelay();
      reset(delay.current);
      start();
    }
  });

  useEffect(() => {
    start();
  }, [start]);

  return (
    <div data-testid="error-page" className="mb-12">
      <div className="mx-auto max-w-4xl">
        <InfoBalloon className="w-full" name={BALLOON[500]} title={t('errors.server_error')} />
        <div className="mx-auto text-center font-bitter text-body-l font-bold text-dark-a dark:text-light-d">
          <ReactMarkdown>{t('errors.500_description')}</ReactMarkdown>
          <Button
            label={t('errors.try_again')}
            big
            className="mx-auto my-8 block"
            onClick={async () => {
              stop();
              const isSuccess = await checkNetwork();
              if (isSuccess) {
                navigate(previousPath || routeNames.root);
              } else {
                getNextDelay = createFibonacciGenerator(600);
                delay.current = getNextDelay();
                reset(delay.current);
                start();
              }
            }}
            disabled={isLoading}
            loading={isLoading}
          />
          {!isLoading && (
            <div className="my-5 text-sm font-normal text-dimmed-a">
              {t('errors.500_retrying', { countdown: formatSecondsToTime(time) })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
