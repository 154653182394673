/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */
// legacy code

/*jslint browser: true, nomen: false, plusplus: false, bitwise: false, maxerr: 50, indent: 2 */
/**
 * @depends swfobject-2.2.min.js
 *
 * pvec 0.4 (10/13/2010) -- extremely persistent cookies
 *
 *  by samy kamkar : code@samy.pl : http://samy.pl
 *
 * this api attempts to produce several types of persistent data
 * to essentially make a cookie virtually irrevocable from a system
 *
 * specifically it uses:
 *  - standard http cookies
 *  - flash cookies (local shared objects)
 *  - png generation w/forced cache and html5 canvas pixel reading
 *  - http etags
 *  - http cache
 *  - window.name
 *  - IE userData
 *  - html5 session cookies
 *  - html5 local storage
 *  - html5 global storage
 *  - html5 database storage via sqlite
 *  - css history scanning
 *
 *  if any cookie is found, it's then reset to all the other locations
 *  for example, if someone deletes all but one type of cookie, once
 *  that cookie is re-discovered, all of the other cookie types get reset
 *
 *  !!! SOME OF THESE ARE CROSS-DOMAIN COOKIES, THIS MEANS
 *  OTHER SITES WILL BE ABLE TO READ SOME OF THESE COOKIES !!!
 *
 * USAGE:
 var ec = new pvec();
 // set a cookie "id" to "12345"
 // usage: ec.set(key, value)
 ec.set("id", "12345");
 // retrieve a cookie called "id" (simply)
 ec.get("id", function(value) { alert("Cookie value is " + value) });
 // or use a more advanced callback function for getting our cookie
 // the cookie value is the first param
 // an object containing the different storage methods
 // and returned cookie values is the second parameter
 function getCookie(best_candidate, all_candidates)
 {
 alert("The retrieved cookie is: " + best_candidate + "\n" +
   "You can see what each storage mechanism returned " +
   "by looping through the all_candidates object.");
 for (var item in all_candidates)
   document.write("Storage mechanism " + item +
     " returned " + all_candidates[item] + " votes<br>");
 }
 ec.get("id", getCookie);
 // we look for "candidates" based off the number of "cookies" that
 // come back matching since it's possible for mismatching cookies.
 // the best candidate is very-very-likely the correct one
 */
const document = window.document
const Image = window.Image
const globalStorage = window.globalStorage
const swfobject = window.swfobject

let localStore = null
try {
  localStore = window.localStorage
} catch {}

let sessionStorage = null
try {
  sessionStorage = window.sessionStorage
} catch {}

function newImage(src) {
  const img = new Image()
  img.style.visibility = 'hidden'
  img.style.position = 'absolute'
  img.src = src
}

function _ec_replace(str, key, value) {
  if (str.includes('&' + key + '=') || 0 === str.indexOf(key + '=')) {
    // find start
    let idx = str.indexOf('&' + key + '=')
    let end
    let newstr
    if (-1 === idx) {
      idx = str.indexOf(key + '=')
    }
    // find end
    end = str.indexOf('&', idx + 1)
    newstr =
      end !== -1
        ? str.slice(0, Math.max(0, idx)) + str.slice(Math.max(0, end + (idx ? 0 : 1))) + '&' + key + '=' + value
        : str.slice(0, Math.max(0, idx)) + '&' + key + '=' + value
    return newstr
  } else {
    return str + '&' + key + '=' + value
  }
}

function idb() {
  if ('indexedDB' in window) {
    return true
  } else {
    //noinspection JSUnresolvedVariable
    return Boolean(
      (window.indexedDB = window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB)
    )
  }
}

// necessary for flash to communicate with js...
// please implement a better way
window._global_lso = null

function _pvec_flash_var(cookie) {
  window._global_lso = cookie

  // remove the flash object now
  const swf = document.querySelector('#myswf')
  if (swf && swf.parentNode) {
    swf.parentNode.removeChild(swf)
  }
}

const defaultOptionMap = {
  history: false, // CSS history knocking or not .. can be network intensive
  tests: 10, // 1000 what is it, actually?
  domain: '.' + window.location.host.replace(/:\d+/, ''), // Get current domain
  baseurl: '', // base url for php, flash  assets
  asseturi: '', // assets = .fla, .jar, etc
  phpuri: '/ec', // php file path or route
  authPath: false, //'/pvec_auth.php', // set to false to disable Basic Authentication cache
  pngCookieName: 'ec_png',
  pngPath: '/png',
  etagCookieName: 'ec_etag',
  etagPath: '/etg',
  cacheCookieName: 'ec_cache',
  cachePath: '/cache'
}

const _baseKeyStr = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/='

/**
 * @class Pvec (PV Evercookie)
 * @param {Object} options
 * @param {Boolean} options.history CSS history knocking or not .. can be network intensive
 * @param {Number} options.tests
 * @param {String} options.domain (eg: www.sitename.com use .sitename.com)
 * @param {String} options.baseurl base url (eg: www.sitename.com/demo use /demo)
 * @param {String} options.asseturi asset path (eg: www.sitename.com/assets use /assets)
 * @param {String} options.phpuri php path/route (eg: www.sitename.com/php use /php)
 * @param {String|Function} options.domain as a string, domain for cookie, as a function, accept window object and return domain string
 * @param {String} options.pngCookieName
 * @param {String} options.pngPath
 * @param {String} options.etagCookieName:
 * @param {String} options.etagPath
 * @param {String} options.cacheCookieName
 * @param {String} options.cachePath
 */
export function Pvec(options = {}) {
  const opts = {}
  for (const key in defaultOptionMap) {
    const optValue = options[key]

    opts[key] = typeof optValue !== 'undefined' ? optValue : defaultOptionMap[key]
  }
  if ('function' === typeof opts.domain) {
    opts.domain = opts.domain(window)
  }

  const _ec_history = opts.history
  const _ec_tests = opts.tests
  const _ec_baseurl = opts.baseurl
  const _ec_asseturi = opts.asseturi
  const _ec_phpuri = opts.phpuri
  const _ec_domain = opts.domain

  void _ec_asseturi

  // private property
  let self = this

  this._ec = {}

  this.get = function (name, cb, dont_reset) {
    self._pvec(name, cb, undefined, undefined, dont_reset)
  }

  this.set = function (name, value) {
    self._pvec(name, function () {}, value)
  }

  this._pvec = function (name, cb, value, i, dont_reset) {
    if (self._pvec === undefined) {
      self = this
    }
    if (i === undefined) {
      i = 0
    }
    // first run
    if (0 === i) {
      self.pvec_database_storage(name, value)
      self.pvec_indexdb_storage(name, value)
      self.ec_png(name, value)
      self.ec_etag(name, value)
      self.pvec_cache(name, value)
      self.pvec_lso(name, value)
      if (opts.authPath) {
        self.pvec_auth(name, value)
      }

      self._ec.userData = self.pvec_userdata(name, value)
      self._ec.cookieData = self.pvec_cookie(name, value)
      self._ec.localData = self.pvec_local_storage(name, value)
      self._ec.globalData = self.pvec_global_storage(name, value)
      self._ec.sessionData = self.pvec_session_storage(name, value)
      self._ec.windowData = self.pvec_window(name, value)

      if (_ec_history) {
        self._ec.historyData = self.pvec_history(name, value)
      }
    }

    // when writing data, we need to make sure lso  is there
    if (value !== undefined) {
      if ('undefined' === typeof _global_lso && i++ < _ec_tests) {
        setTimeout(function () {
          self._pvec(name, cb, value, i, dont_reset)
        }, 300)
      }
    }

    // when reading data, we need to wait for swf, db and png
    else {
      if (
        // we support local db and haven't read data in yet
        ((window.openDatabase && 'undefined' === typeof self._ec.dbData) ||
          (idb() && ('undefined' === typeof self._ec.idbData || '' === self._ec.idbData)) ||
          'undefined' === typeof _global_lso ||
          'undefined' === typeof self._ec.etagData ||
          'undefined' === typeof self._ec.cacheData ||
          (document.createElement('canvas').getContext &&
            ('undefined' === typeof self._ec.pngData || '' === self._ec.pngData))) &&
        i++ < _ec_tests
      ) {
        setTimeout(function () {
          self._pvec(name, cb, value, i, dont_reset)
        }, 300)
      }

      // we hit our max wait time or got all our data
      else {
        // get just the piece of data we need from swf
        self._ec.lsoData = self.getFromStr(name, window._global_lso)
        window._global_lso = undefined

        const tmpec = self._ec
        const candidates = []
        let bestnum = 0
        let candidate
        let item
        self._ec = {}

        // figure out which is the best candidate
        for (item in tmpec) {
          if (tmpec[item] && tmpec[item] !== 'null' && tmpec[item] !== 'undefined') {
            candidates[tmpec[item]] = candidates[tmpec[item]] === undefined ? 1 : candidates[tmpec[item]] + 1
          }
        }

        for (item in candidates) {
          if (candidates[item] > bestnum) {
            bestnum = candidates[item]
            candidate = item
          }
        }

        // reset cookie everywhere
        if (candidate !== undefined && (dont_reset === undefined || dont_reset !== 1)) {
          self.set(name, candidate)
        }
        if ('function' === typeof cb) {
          cb(candidate, tmpec)
        }
      }
    }
  }

  this.pvec_window = function (name, value) {
    try {
      if (value !== undefined) {
        window.name = _ec_replace(window.name, name, value)
      } else {
        return this.getFromStr(name, window.name)
      }
    } catch {}
  }

  this.pvec_userdata = function (name, value) {
    try {
      const elm = this.createElem('div', 'userdata_el', 1)
      if (elm.addBehavior) {
        elm.style.behavior = 'url(#default#userData)'

        if (value !== undefined) {
          elm.setAttribute(name, value)
          elm.save(name)
        } else {
          elm.load(name)
          return elm.getAttribute(name)
        }
      }
    } catch {}
  }

  this.ajax = function (settings) {
    let headers
    let name
    let transports
    let transport
    let i
    let length

    headers = {
      'X-Requested-With': 'XMLHttpRequest',
      Accept: 'text/javascript, text/html, application/xml, text/xml, */*'
    }

    transports = [
      function () {
        return new XMLHttpRequest()
      },
      function () {
        throw new Error(`ActiveXObject('Msxml2.XMLHTTP')`)
        //        return new ActiveXObject('Msxml2.XMLHTTP')
      },
      function () {
        throw new Error(`ActiveXObject('Microsoft.XMLHTTP')`)
        //        return new ActiveXObject('Microsoft.XMLHTTP')
      }
    ]

    for (i = 0, length = transports.length; i < length; i++) {
      transport = transports[i]
      try {
        transport = transport()
        break
      } catch {}
    }

    transport.onreadystatechange = function () {
      if (transport.readyState !== 4) {
        return
      }
      settings.success(transport.responseText)
    }
    transport.open('get', settings.url, true)
    for (name in headers) {
      transport.setRequestHeader(name, headers[name])
    }
    transport.send()
  }

  this.pvec_cache = function (name, value) {
    if (value !== undefined) {
      // make sure we have pvec session defined first
      document.cookie = opts.cacheCookieName + '=' + value + '; path=/; domain=' + _ec_domain
      // {{ajax request to opts.cachePath}} handles caching
      self.ajax({
        url: _ec_baseurl + _ec_phpuri + opts.cachePath + '?name=' + name + '&cookie=' + opts.cacheCookieName,
        success: function (data) {}
      })
    } else {
      // interestingly enough, we want to erase our pvec
      // http cookie so the php will force a cached response
      const origvalue = this.getFromStr(opts.cacheCookieName, document.cookie)
      self._ec.cacheData = undefined
      document.cookie = opts.cacheCookieName + '=; expires=Mon, 20 Sep 2010 00:00:00 UTC; path=/; domain=' + _ec_domain

      self.ajax({
        url: _ec_baseurl + _ec_phpuri + opts.cachePath + '?name=' + name + '&cookie=' + opts.cacheCookieName,
        success: function (data) {
          // put our cookie back
          document.cookie =
            opts.cacheCookieName +
            '=' +
            origvalue +
            '; expires=Tue, 31 Dec 2030 00:00:00 UTC; path=/; domain=' +
            _ec_domain

          self._ec.cacheData = data
        }
      })
    }
  }
  this.pvec_auth = function (name, value) {
    if (value !== undefined) {
      // {{opts.authPath}} handles Basic Access Authentication
      newImage('//' + value + '@' + window.location.host + _ec_baseurl + _ec_phpuri + opts.authPath + '?name=' + name)
    } else {
      self.ajax({
        url: _ec_baseurl + _ec_phpuri + opts.authPath + '?name=' + name,
        success: function (data) {
          self._ec.authData = data
        }
      })
    }
  }

  this.ec_etag = function (name, value) {
    if (value !== undefined) {
      // make sure we have pvec session defined first
      document.cookie = opts.etagCookieName + '=' + value + '; path=/; domain=' + _ec_domain
      // {{ajax request to opts.etagPath}} handles etagging
      self.ajax({
        url: _ec_baseurl + _ec_phpuri + opts.etagPath + '?name=' + name + '&cookie=' + opts.etagCookieName,
        success: function (data) {}
      })
    } else {
      // interestingly enough, we want to erase our pvec
      // http cookie so the php will force a cached response
      const origvalue = this.getFromStr(opts.etagCookieName, document.cookie)
      self._ec.etagData = undefined
      document.cookie = opts.etagCookieName + '=; expires=Mon, 20 Sep 2010 00:00:00 UTC; path=/; domain=' + _ec_domain

      self.ajax({
        url: _ec_baseurl + _ec_phpuri + opts.etagPath + '?name=' + name + '&cookie=' + opts.etagCookieName,
        success: function (data) {
          // put our cookie back
          document.cookie =
            opts.etagCookieName +
            '=' +
            origvalue +
            '; expires=Tue, 31 Dec 2030 00:00:00 UTC; path=/; domain=' +
            _ec_domain

          self._ec.etagData = data
        }
      })
    }
  }

  this.pvec_lso = function (name, value) {
    let div = document.querySelector('#swfdiv')
    const flashvars = {}
    const params = {}
    const attributes = {}
    if (null === div || div === undefined || !div.length) {
      div = document.createElement('div')
      div.setAttribute('id', 'swfdiv')
      document.body.appendChild(div)
    }

    if (value !== undefined) {
      flashvars.everdata = name + '=' + value
    }
    params.swliveconnect = 'true'
    attributes.id = 'myswf'
    attributes.name = 'myswf'
    try {
      swfobject.embedSWF('/swf/ec.swf', 'swfdiv', '1', '1', '9.0.0', false, flashvars, params, attributes)
    } catch {
      // if (logger)
      // 	logger.error("ec:", e)
    }
  }

  this.ec_png = function (name, value) {
    const canvas = document.createElement('canvas')
    let img
    let ctx
    let origvalue
    canvas.style.visibility = 'hidden'
    canvas.style.position = 'absolute'
    canvas.width = 200
    canvas.height = 1
    if (canvas && canvas.getContext) {
      // {{opts.pngPath}} handles the hard part of generating the image
      // based off of the http cookie and returning it cached
      img = new Image()
      img.style.visibility = 'hidden'
      img.style.position = 'absolute'
      if (value !== undefined) {
        // make sure we have pvec session defined first
        document.cookie = opts.pngCookieName + '=' + value + '; path=/; domain=' + _ec_domain
      } else {
        self._ec.pngData = undefined
        ctx = canvas.getContext('2d')

        // interestingly enough, we want to erase our pvec
        // http cookie so the php will force a cached response
        origvalue = this.getFromStr(opts.pngCookieName, document.cookie)
        document.cookie = opts.pngCookieName + '=; expires=Mon, 20 Sep 2010 00:00:00 UTC; path=/; domain=' + _ec_domain

        img.onload = function () {
          // put our cookie back
          document.cookie =
            opts.pngCookieName +
            '=' +
            origvalue +
            '; expires=Tue, 31 Dec 2030 00:00:00 UTC; path=/; domain=' +
            _ec_domain

          self._ec.pngData = ''
          ctx.drawImage(img, 0, 0)

          // get CanvasPixelArray from  given coordinates and dimensions
          const imgd = ctx.getImageData(0, 0, 200, 1)
          const pix = imgd.data
          let i
          let n

          // loop over each pixel to get the "RGB" values (ignore alpha)
          for (i = 0, n = pix.length; i < n; i += 4) {
            if (0 === pix[i]) {
              break
            }
            self._ec.pngData += String.fromCharCode(pix[i])
            if (0 === pix[i + 1]) {
              break
            }
            self._ec.pngData += String.fromCharCode(pix[i + 1])
            if (0 === pix[i + 2]) {
              break
            }
            self._ec.pngData += String.fromCharCode(pix[i + 2])
          }
        }
      }
      img.src = _ec_baseurl + _ec_phpuri + opts.pngPath + '?name=' + name + '&cookie=' + opts.pngCookieName
    }
  }

  this.pvec_local_storage = function (name, value) {
    try {
      if (localStore) {
        if (value !== undefined) {
          localStore.setItem(name, value)
        } else {
          return localStore.getItem(name)
        }
      }
    } catch {}
  }

  this.pvec_database_storage = function (name, value) {
    try {
      if (window.openDatabase) {
        const database = window.openDatabase('pvec', '', 'pvec', 1024 * 1024)

        if (value !== undefined) {
          database.transaction(function (tx) {
            tx.executeSql(
              'CREATE TABLE IF NOT EXISTS cache(' +
                'id INTEGER NOT NULL PRIMARY KEY AUTOINCREMENT, ' +
                'name TEXT NOT NULL, ' +
                'value TEXT NOT NULL, ' +
                'UNIQUE (name)' +
                ')',
              [],
              function (tx, rs) {},
              function (tx, err) {}
            )
            tx.executeSql(
              'INSERT OR REPLACE INTO cache(name, value) ' + 'VALUES(?, ?)',
              [name, value],
              function (tx, rs) {},
              function (tx, err) {}
            )
          })
        } else {
          database.transaction(function (tx) {
            tx.executeSql(
              'SELECT value FROM cache WHERE name=?',
              [name],
              function (tx, result1) {
                self._ec.dbData = result1.rows.length >= 1 ? result1.rows.item(0).value : ''
              },
              function (tx, err) {}
            )
          })
        }
      }
    } catch {}
  }

  this.pvec_indexdb_storage = function (name, value) {
    try {
      if (!('indexedDB' in window)) {
        window.indexedDB = window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB
        window.IDBTransaction = window.IDBTransaction || window.webkitIDBTransaction || window.msIDBTransaction
        window.IDBKeyRange = window.IDBKeyRange || window.webkitIDBKeyRange || window.msIDBKeyRange
      }

      if (indexedDB) {
        const ver = 1
        //FF incognito mode restricts indexedb access
        const request = indexedDB.open('idb_pvec', ver)

        request.onerror = function (e) {
          e.preventDefault()
        }

        request.onupgradeneeded = function (event) {
          const db = event.target.result

          const store = db.createObjectStore('pvec', {
            keyPath: 'name',
            unique: false
          })

          void store
        }

        request.onsuccess =
          value !== undefined
            ? function (event) {
                const idb = event.target.result
                if (idb.objectStoreNames.contains('pvec')) {
                  const tx = idb.transaction(['pvec'], 'readwrite')
                  const objst = tx.objectStore('pvec')
                  const qr = objst.put({
                    name: name,
                    value: value
                  })
                  void qr
                }
                idb.close()
              }
            : function (event) {
                const idb = event.target.result

                if (!idb.objectStoreNames.contains('pvec')) {
                  self._ec.idbData = undefined
                } else {
                  const tx = idb.transaction(['pvec'])
                  const objst = tx.objectStore('pvec')
                  const qr = objst.get(name)

                  qr.onsuccess = function (event) {
                    void event
                    self._ec.idbData = qr.result === undefined ? undefined : qr.result.value
                  }
                }
                idb.close()
              }
      }
    } catch {}
  }

  this.pvec_session_storage = function (name, value) {
    try {
      if (sessionStorage) {
        if (value !== undefined) {
          sessionStorage.setItem(name, value)
        } else {
          return sessionStorage.getItem(name)
        }
      }
    } catch {}
  }

  this.pvec_global_storage = function (name, value) {
    if (globalStorage) {
      const host = this.getHost()
      try {
        if (value !== undefined) {
          globalStorage[host][name] = value
        } else {
          return globalStorage[host][name]
        }
      } catch {}
    }
  }

  // public method for encoding
  this.encode = function (input) {
    let output = ''
    let chr1
    let chr2
    let chr3
    let enc1
    let enc2
    let enc3
    let enc4
    let i = 0

    input = this._utf8_encode(input)

    while (i < input.length) {
      chr1 = input.charCodeAt(i++)
      chr2 = input.charCodeAt(i++)
      chr3 = input.charCodeAt(i++)

      enc1 = chr1 >> 2
      enc2 = ((chr1 & 3) << 4) | (chr2 >> 4)
      enc3 = ((chr2 & 15) << 2) | (chr3 >> 6)
      enc4 = chr3 & 63

      if (isNaN(chr2)) {
        enc3 = enc4 = 64
      } else if (isNaN(chr3)) {
        enc4 = 64
      }

      output =
        output +
        _baseKeyStr.charAt(enc1) +
        _baseKeyStr.charAt(enc2) +
        _baseKeyStr.charAt(enc3) +
        _baseKeyStr.charAt(enc4)
    }

    return output
  }

  // public method for decoding
  this.decode = function (input) {
    let output = ''
    let chr1
    let chr2
    let chr3
    let enc1
    let enc2
    let enc3
    let enc4
    let i = 0

    input = input.replace(/[^\d+/=A-Za-z]/g, '')

    while (i < input.length) {
      enc1 = _baseKeyStr.indexOf(input.charAt(i++))
      enc2 = _baseKeyStr.indexOf(input.charAt(i++))
      enc3 = _baseKeyStr.indexOf(input.charAt(i++))
      enc4 = _baseKeyStr.indexOf(input.charAt(i++))

      chr1 = (enc1 << 2) | (enc2 >> 4)
      chr2 = ((enc2 & 15) << 4) | (enc3 >> 2)
      chr3 = ((enc3 & 3) << 6) | enc4

      output = output + String.fromCharCode(chr1)

      if (enc3 !== 64) {
        output = output + String.fromCharCode(chr2)
      }
      if (enc4 !== 64) {
        output = output + String.fromCharCode(chr3)
      }
    }
    output = this._utf8_decode(output)
    return output
  }

  // private method for UTF-8 encoding
  this._utf8_encode = function (str) {
    str = str.replace(/\r\n/g, '\n')
    let utftext = ''
    let i = 0
    const n = str.length
    let c
    for (; i < n; i++) {
      c = str.charCodeAt(i)
      if (c < 128) {
        utftext += String.fromCharCode(c)
      } else if (c > 127 && c < 2048) {
        utftext += String.fromCharCode((c >> 6) | 192)
        utftext += String.fromCharCode((c & 63) | 128)
      } else {
        utftext += String.fromCharCode((c >> 12) | 224)
        utftext += String.fromCharCode(((c >> 6) & 63) | 128)
        utftext += String.fromCharCode((c & 63) | 128)
      }
    }
    return utftext
  }

  // private method for UTF-8 decoding
  this._utf8_decode = function (utftext) {
    let str = ''
    let i = 0
    const n = utftext.length
    let c = 0
    const c1 = 0
    let c2 = 0
    let c3 = 0
    void c1
    while (i < n) {
      c = utftext.charCodeAt(i)
      if (c < 128) {
        str += String.fromCharCode(c)
        i += 1
      } else if (c > 191 && c < 224) {
        c2 = utftext.charCodeAt(i + 1)
        str += String.fromCharCode(((c & 31) << 6) | (c2 & 63))
        i += 2
      } else {
        c2 = utftext.charCodeAt(i + 1)
        c3 = utftext.charCodeAt(i + 2)
        str += String.fromCharCode(((c & 15) << 12) | ((c2 & 63) << 6) | (c3 & 63))
        i += 3
      }
    }
    return str
  }

  // this is crazy but it's 4am in dublin and i thought this would be hilarious
  // blame the guinness
  this.pvec_history = function (name, value) {
    // - is special
    const baseElems = (_baseKeyStr + '-').split('')
    let // sorry google.
      url = 'http://www.google.com/ec/cache/' + this.getHost() + '/' + name
    let i
    let base
    let letter = ''
    let val = ''
    let found = 1

    if (value !== undefined) {
      // don't reset this if we already have it set once
      // too much data, and you can't clear previous values
      if (this.hasVisited(url)) {
        return
      }

      this.createIframe(url, 'if')
      url = url + '/'

      base = this.encode(value).split('')
      for (i = 0; i < base.length; i++) {
        url = url + base[i]
        this.createIframe(url, 'if' + i)
      }

      // - signifies the end of our data
      url = url + '-'
      this.createIframe(url, 'if_')
    } else {
      // omg you got csspwn3d
      if (this.hasVisited(url)) {
        url = url + '/'

        while (letter !== '-' && 1 === found) {
          found = 0
          for (i = 0; i < baseElems.length; i++) {
            if (this.hasVisited(url + baseElems[i])) {
              letter = baseElems[i]
              if (letter !== '-') {
                val = val + letter
              }
              url = url + letter
              found = 1
              break
            }
          }
        }

        // lolz
        return this.decode(val)
      }
    }
  }

  this.createElem = function (type, name, append) {
    let el
    el =
      name !== undefined && document.getElementById(name) ? document.getElementById(name) : document.createElement(type)
    el.style.visibility = 'hidden'
    el.style.position = 'absolute'

    if (name) {
      el.setAttribute('id', name)
    }

    if (append) {
      document.body.appendChild(el)
    }
    return el
  }

  this.createIframe = function (url, name) {
    const el = this.createElem('iframe', name, 1)
    el.setAttribute('src', url)
    return el
  }

  // wait for our swfobject to appear (swfobject.js to load)
  let waitForSwf
  this.waitForSwf = function (i) {
    if (i === undefined) {
      i = 0
    } else {
      i++
    }

    // wait for ~2 seconds for swfobject to appear
    if (i < _ec_tests && 'undefined' === typeof swfobject) {
      setTimeout(function () {
        waitForSwf(i)
      }, 300)
    }
  }
  waitForSwf = this.waitForSwf

  this.pvec_cookie = function (name, value) {
    if (value !== undefined) {
      // expire the cookie first
      document.cookie = name + '=; expires=Mon, 20 Sep 2010 00:00:00 UTC; path=/; domain=' + _ec_domain
      document.cookie = name + '=' + value + '; expires=Tue, 31 Dec 2030 00:00:00 UTC; path=/; domain=' + _ec_domain
    } else {
      return this.getFromStr(name, document.cookie)
    }
  }

  // get value from param-like string (eg, "x=y&name=VALUE")
  this.getFromStr = function (name, text) {
    if (typeof text !== 'string') {
      return
    }
    const nameEQ = name + '='
    const ca = text.split(/[&;]/)
    let i
    let c
    for (i = 0; i < ca.length; i++) {
      c = ca[i]
      while (' ' === c.charAt(0)) {
        c = c.substring(1, c.length)
      }
      if (0 === c.indexOf(nameEQ)) {
        return c.substring(nameEQ.length, c.length)
      }
    }
  }

  this.getHost = function () {
    return window.location.host.replace(/:\d+/, '')
  }

  //noinspection JSUnusedGlobalSymbols
  this.toHex = function (str) {
    let r = ''
    const e = str.length
    let c = 0
    let h
    while (c < e) {
      h = str.charCodeAt(c++).toString(16)
      while (h.length < 2) {
        h = '0' + h
      }
      r += h
    }
    return r
  }

  //noinspection JSUnusedGlobalSymbols
  this.fromHex = function (str) {
    let r = ''
    let e = str.length
    let s
    while (e >= 0) {
      s = e - 2
      r = String.fromCharCode('0x' + str.substring(s, e)) + r
      e = s
    }
    return r
  }

  /**
   * css history knocker (determine what sites your visitors have been to)
   *
   * originally by Jeremiah Grossman
   * http://jeremiahgrossman.blogspot.com/2006/08/i-know-where-youve-been.html
   *
   * ported to additional browsers by Samy Kamkar
   *
   * compatible with ie6, ie7, ie8, ff1.5, ff2, ff3, opera, safari, chrome, flock
   *
   * - code@samy.pl
   */
  this.hasVisited = function (url) {
    if (-1 === this.no_color) {
      const no_style = this._getRGB('http://samy-was-here-this-should-never-be-visited.com', -1)
      if (-1 === no_style) {
        this.no_color = this._getRGB('http://samy-was-here-' + Math.floor(Math.random() * 9999999) + 'rand.com')
      }
    }

    // didManager we give full url?
    if (0 === url.indexOf('https:') || 0 === url.indexOf('http:')) {
      return this._testURL(url, this.no_color)
    }

    // if not, just test a few diff types  if (exact)
    return (
      this._testURL('http://' + url, this.no_color) ||
      this._testURL('https://' + url, this.no_color) ||
      this._testURL('http://www.' + url, this.no_color) ||
      this._testURL('https://www.' + url, this.no_color)
    )
  }

  /* create our anchor tag */
  const _link = this.createElem('a', '_ec_rgb_link')
  let /* for monitoring */ created_style
  const /* create a custom style tag for the specific link. Set the CSS visited selector to a known value */
    _cssText = '#_ec_rgb_link:visited{display:none;color:#FF0000}'
  let style

  /* Methods for IE6, IE7, FF, Opera, and Safari */
  try {
    created_style = 1
    style = document.createElement('style')
    if (style.styleSheet) {
      style.styleSheet.innerHTML = _cssText
    } else if (style.innerHTML) {
      style.innerHTML = _cssText
    } else {
      style.appendChild(document.createTextNode(_cssText))
    }
  } catch {
    created_style = 0
  }

  /* if test_color, return -1 if we can't set a style */
  this._getRGB = function (u, test_color) {
    if (test_color && 0 === created_style) {
      return -1
    }

    /* create the new anchor tag with the appropriate URL information */
    _link.href = u
    _link.innerHTML = u
    // not sure why, but the next two appendChilds always have to happen vs just once
    document.body.appendChild(style)
    document.body.appendChild(_link)

    /* add the link to the DOM and save the visible computed color */
    let color
    if (document.defaultView) {
      if (null == document.defaultView.getComputedStyle(_link, null)) {
        return -1 // getComputedStyle is unavailable in FF when running in IFRAME
      }
      color = document.defaultView.getComputedStyle(_link, null).getPropertyValue('color')
    } else {
      color = _link.currentStyle.color
    }
    return color
  }

  this._testURL = function (url, no_color) {
    const color = this._getRGB(url)

    /* check to see if the link has been visited if the computed color is red */
    if ('rgb(255, 0, 0)' === color || '#ff0000' === color) {
      return 1
    } else if (no_color && color !== no_color) {
      /* if our style trick didn't work, just compare default style colors */
      return 1
    }
    /* not found */
    return 0
  }
}

window._pvec_flash_var = _pvec_flash_var
/**
 * Because Pvec is a class, it should have first letter in capital
 * Keep first letter in small for legacy purpose
 * @expose Pvec
 */
window.pvec = window.Pvec = Pvec
