import { SEC_INTO_MSEC } from '@services/time';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

/**
 *
 * @param {number} timeToCount
 * @param {number?} interval
 * @returns {[number, { start: () => void, pause: () => void, resume: () => void, reset: () => void, isStarted: boolean, isFinished: boolean }]}
 */
export const useCountdown = (timeToCount = 60 * SEC_INTO_MSEC, interval = SEC_INTO_MSEC) => {
  const [timeLeft, setTimeLeft] = useState(0);
  const timer = useRef({});

  const run = useCallback(
    ts => {
      if (!timer.current.started) {
        timer.current.started = ts;
        timer.current.lastInterval = ts;
      }

      const localInterval = Math.min(interval, timer.current.timeLeft || Infinity);

      if (ts - timer.current.lastInterval >= localInterval) {
        timer.current.lastInterval += localInterval;
        setTimeLeft(timeLeft => {
          timer.current.timeLeft = timeLeft - localInterval;
          return timer.current.timeLeft;
        });
      }

      if (ts - timer.current.started < timer.current.timeToCount) {
        timer.current.requestId = window.requestAnimationFrame(run);
      } else {
        timer.current = {
          isFinished: true,
        };
        setTimeLeft(0);
      }
    },
    [interval]
  );

  const start = useCallback(
    ttc => {
      window.cancelAnimationFrame(timer.current.requestId);

      const newTimeToCount = ttc !== undefined ? ttc : timeToCount;
      timer.current.started = null;
      timer.current.isFinished = false;
      timer.current.lastInterval = null;
      timer.current.timeToCount = newTimeToCount;
      timer.current.requestId = window.requestAnimationFrame(run);

      setTimeLeft(newTimeToCount);
    },
    [run, timeToCount]
  );

  const pause = useCallback(() => {
    window.cancelAnimationFrame(timer.current.requestId);
    timer.current.started = null;
    timer.current.lastInterval = null;
    timer.current.timeToCount = timer.current.timeLeft;
  }, []);

  const resume = useCallback(() => {
    if (!timer.current.started && timer.current.timeLeft > 0) {
      window.cancelAnimationFrame(timer.current.requestId);
      timer.current.requestId = window.requestAnimationFrame(run);
    }
  }, [run]);

  const reset = useCallback(() => {
    if (timer.current.timeLeft) {
      window.cancelAnimationFrame(timer.current.requestId);
    }
    timer.current = {};
    setTimeLeft(0);
  }, []);

  const isStarted = useCallback(() => Boolean(timer.current.started), []);

  const isFinished = useCallback(() => Boolean(timer.current.isFinished), []);

  const actions = useMemo(() => {
    return { start, pause, resume, reset, isStarted, isFinished };
  }, [isStarted, isFinished, pause, reset, resume, start]);

  useEffect(() => () => window.cancelAnimationFrame(timer.current.requestId), []);

  return [timeLeft, actions];
};

export const useAutomatedCountdown = (timeToCount = 60 * SEC_INTO_MSEC, callbackConditionFn, callback) => {
  const [countdown, countdownActions] = useCountdown(timeToCount);

  useEffect(() => {
    if (callbackConditionFn && !callbackConditionFn()) {
      return;
    }

    if (0 === countdown && countdownActions.isFinished()) {
      callback?.();
      countdownActions.reset();
    } else if (!countdownActions.isStarted() && !countdownActions.isFinished()) {
      countdownActions.start(timeToCount);
    }
  }, [timeToCount, countdown, countdownActions, callbackConditionFn, callback]);

  return countdown;
};
