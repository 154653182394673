import { IconSprite } from '@ui/elements';
import { IconName } from '@ui/lib/IconSprite';
import { FC } from 'react';

type Props = {
  links?:
    | {
        href: string;
        ariaLabel: string;
        icon: IconName;
      }[]
    | null;
};

export const SocialIcons: FC<Props> = ({ links }) => {
  if (!links) return null;

  return (
    <div className="social-links ml-4 grid grid-flow-col gap-4" data-testid="footer-social-links">
      {links.map(({ href, ariaLabel, icon }) => (
        <a
          href={href}
          aria-label={ariaLabel}
          key={href}
          // TODO: Need to fix hover color
          className="inline-block leading-[0] text-accent-b transition-colors"
          target="_blank"
          rel="noreferrer"
        >
          <IconSprite
            iconId={icon}
            className="h-5 w-5 fill-accent-b transition-opacity duration-300 hover:opacity-60"
            title={ariaLabel}
          />
        </a>
      ))}
    </div>
  );
};
