import { GTags } from '@/constants/google_tags';
import { gTagSender } from '@services/analytics/gTagSender';
import { useDidCodePromise } from '@ui/contextProviders';
import { processBannerActions, respondAcceptance } from '@ui/elements/Banners/asyncActions';
import { useCallback, useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';

export function useBannerAcceptanceActionCreator(bannerToken) {
  const dispatch = useDispatch();

  const didPromise = useDidCodePromise();

  const bannerFirstResponse = useRef(null);

  const handler = useCallback(
    async (verb, url, actions) => {
      const asyncActions = [];
      if (url) {
        const body = {
          token: bannerToken,
          status: verb,
          did: await didPromise,
        };
        asyncActions.push(
          dispatch(
            respondAcceptance({
              url,
              body,
            })
          )
        );
      }

      if (actions || 'webpush' === bannerToken) {
        asyncActions.push(dispatch(processBannerActions(bannerToken, actions)));
      }
      if ('accept' === verb) {
        if ('webpush' === bannerToken) {
          asyncActions.push(gTagSender(GTags.allowPushNotify));
        } else if ('geolocation' === bannerToken) {
          asyncActions.push(gTagSender(GTags.allowLocation));
        }
      }
      return Promise.all(asyncActions);
    },
    [bannerToken, didPromise, dispatch]
  );

  // the object-based map will be reset on handler's change
  const handlersMap = useMemo(() => {
    if (handler) {
      return {};
    }
    return {};
  }, [handler]);

  // factory function to create and cache the parameters-based handlers
  return (verb, bannerResponse) => {
    if (!bannerResponse) {
      return null;
    }
    const { url, actions } = bannerResponse;
    if (!handlersMap[verb]) {
      handlersMap[verb] = () => {
        if (bannerFirstResponse.current) {
          return;
        }
        bannerFirstResponse.current = verb;
        void handler(verb, url, actions);
      };
    }
    return handlersMap[verb];
  };
}
