import { UserStatus } from '@/api/user/user-status';
import { selectIsUserFetching, selectUserStatus } from '@model/features/user/selectors';
import { STORAGE_KEY_LIST, hasDataInStorage, restoreDataFromStorage } from '@services/storage';
import { LoadingSpinner } from '@ui/elements/spinners';
import { useAppSelector } from '@ui/hooks/redux';
import { ComponentType, FC, ReactNode } from 'react';
import { Navigate, Outlet, useLocation, useSearchParams } from 'react-router-dom';
import { routeNames } from './routeNames';

export const ProtectedRoute: FC<{
  component?: ComponentType<any> | null;
  element?: ReactNode | null;
}> = ({ component: Component, element }) => {
  const userStatus = useAppSelector(selectUserStatus);

  if (null == element && null == Component) {
    return null;
  }

  switch (userStatus) {
    case UserStatus.REGISTRATION_PROCESS:
      return <Navigate to={routeNames.registrationIndex} replace />;
    case UserStatus.EMAIL_SETUP:
      return <Navigate to={routeNames.usersEmailSetup} replace />;
    case UserStatus.EMAIL_ACTIVATION:
      return <Navigate to={routeNames.usersEmailActivate} replace />;
    case UserStatus.FORBIDDEN_COUNTRY:
      return <Navigate to={routeNames.countryForbidden} replace />;
    case UserStatus.UNSUPPORTED_COUNTRY:
      return <Navigate to={routeNames.countryUnsupported} replace />;
    case UserStatus.TERMS_CONSENT_PHASE:
      return <Navigate to={routeNames.usersTermsConsent} replace />;
    default:
      if (Component) {
        return <Component />;
      }
      return <>{element}</>;
  }
};

export const RootRedirect = () => {
  const hasToken = hasDataInStorage(STORAGE_KEY_LIST.AUTH_TOKEN);
  const location = useLocation();
  const [searchParams] = useSearchParams(location.search);

  if (hasToken) {
    return <Navigate to="/dashboard" replace />;
  }

  const loginType = searchParams.get('r')
    ? '/register'
    : localStorage.getItem(STORAGE_KEY_LIST.LOGIN_TYPE) || '/login/password';
  return <Navigate to={loginType} replace />;
};

export const AuthRedirect = () => {
  const hasToken = restoreDataFromStorage(STORAGE_KEY_LIST.AUTH_TOKEN) != null;

  if (hasToken) {
    return <Navigate to="/dashboard" replace />;
  }

  return <Outlet />;
};

Object.assign(window, { routePathHolder: { path: '/login/password', state: null } });

// If we need to redirect to a different page after logout, we can use this fn
export function setRedirectPath({ path, state }: { path?: string; state?: Record<string, any> | null }) {
  if (path) window.routePathHolder.path = path;
  window.routePathHolder.state = state;
}

function resetPath() {
  window.routePathHolder.path = localStorage.getItem(STORAGE_KEY_LIST.LOGIN_TYPE) || '/login/password';
}

export const AuthProtected = () => {
  const hasToken = hasDataInStorage(STORAGE_KEY_LIST.AUTH_TOKEN);
  const isFetching = useAppSelector(selectIsUserFetching);

  if (!hasToken) {
    // We use path and state to redirect to a different page after logout
    const to = window.routePathHolder.path;
    resetPath();
    return <Navigate to={to} replace state={window.routePathHolder.state} />;
  }

  if (isFetching) {
    return (
      <LoadingSpinner
        className="fixed bottom-0 left-0 right-0 top-0 flex items-center justify-center"
        data-protect="true"
      />
    );
  }

  return <Outlet />;
};
