import { Logger } from './utils/Logger';

(function (context) {
  const vh = context.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);

  context.addEventListener('resize', () => {
    const vh = context.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  });

  Logger.log('Bootstrap app', context.changeFavicon);
  if (!context.changeFavicon) {
    // If change this map you need to change the favicons in the public/js/favicon.js file
    const favicons = new Map([
      [
        true,
        {
          light: 'modern-alert.ico',
          retro: 'classic-alert.ico',
          dark: 'modern-alert.ico',
        },
      ],
      [
        false,
        {
          light: 'light-default.ico',
          retro: 'classic-default.ico',
          dark: 'dark-default.ico',
        },
      ],
    ]);

    window.changeFavicon = (theme, isActiveIcon = false) => {
      const el = document.head.querySelector('#pv-favicon');
      if (!el) {
        throw new Error('Favicon link element not found');
      }
      Logger.log('changeFavicon', { theme, isActiveIcon });

      const url = window.context.staticPath.concat('/favicons');
      const icon = favicons?.get(isActiveIcon)?.[theme];
      const icoUrl = icon ? url.concat('/', icon) : url.concat('/dark-default.ico');

      if (el.getAttribute('href') !== icoUrl) {
        el.setAttribute('href', icoUrl);
      }
    };
  }
})(window);
