import { FC } from 'react';

import { IconSprite } from '@ui/elements';
import { routeNames } from '@ui/routes/routeNames';
import { Link } from 'react-router-dom';
import { AnonymousLinks } from './AnonymousLinks';
import { AppMarketLinks } from './AppMarketLinks';
import { FooterLegal } from './FooterLegal';
import { UserLinks } from './UserLinks';
import './footer.scss';

type Props = {
  links: Window['tenant']['links'];
  isAlive: boolean;
};

export const FooterBase: FC<Props> = ({ isAlive, links }) => {
  const linksClassList =
    'footer-link text-dark-e transition-colors duration-300 hover:text-accent-b dark:text-dimmed-d dark:hover:text-accent-b no-underline';
  return (
    <footer className="z-10 bg-light-c dark:bg-dark-a">
      <div className="footer-grid container py-8">
        {isAlive ? (
          <>
            <Link to={routeNames.dashboard} className="mr-4 w-8" aria-label="home">
              <IconSprite iconId="pv-logo-small" className="footer-pv-logo" />
            </Link>
            <UserLinks className={linksClassList} />
          </>
        ) : (
          <>
            <a href={links.landing.url} className="w-[3rem] flex-shrink-0 md:w-[2.5rem] xl:mr-8" aria-label="home">
              <IconSprite iconId="pv-logo-small" className="footer-pv-logo" />
            </a>
            <AnonymousLinks links={links} className={linksClassList} />
          </>
        )}
        <AppMarketLinks />
      </div>
      <div className="bg-light-f py-4 dark:bg-dark-h">
        <FooterLegal links={links} />
      </div>
    </footer>
  );
};
