import { GTags } from '@/constants/google_tags';
import { selectSwidgetResult } from '@model/features/survey/selectors';
import { ISWidgetResultShow, SWidgetResult } from '@model/features/survey/types';
import { gTagSender } from '@services/analytics/gTagSender';
import { restoreDataFromStorage, STORAGE_KEY_LIST } from '@services/storage';
import { useAppDispatch, useAppSelector } from '@ui/hooks/redux';
import { openResultDialog, SurveyResultDialog } from '@ui/pages/Survey/ResultDialog';
import { useEffect } from 'react';

export const SurveyResultPopup = () => {
  const dispatch = useAppDispatch();
  const swidgetResult = useAppSelector(selectSwidgetResult);

  useEffect(() => {
    if (swidgetResult != null) {
      openResultDialog(dispatch);
    }
  }, [swidgetResult, dispatch]);

  return isShowResultAction(swidgetResult) ? <SurveyResultDialog data={swidgetResult.data} /> : null;
};

function isShowResultAction(data: SWidgetResult | null): data is ISWidgetResultShow {
  const code = restoreDataFromStorage(STORAGE_KEY_LIST.SURVEY_INVITATION_CODE, null, window.sessionStorage);

  if (data != null && 'show' === data.action) {
    switch (code) {
      case 'welcome':
        gTagSender(GTags.welcomeCompleted);
        break;
      case 'tag':
        gTagSender(GTags.tagCompleted);
        break;
      default:
        gTagSender(GTags.surveyCompleted);
    }
    return true;
  }
  return false;
}
