import { selectIsLoggedIn } from '@model/features/auth';
import { useAppSelector } from '@ui/hooks/redux';
import { AmountInfoWidget } from './components/AmountInfoWidget';
import { AuthButtons } from './components/AuthButtons';
import { HomePageLogo } from './components/HomePageLogo';
import { MenuElement } from './components/MenuElement';
import { HeaderRightPartOnlySignOut } from './components/SignOutButton';

import { accessUserStatusChecks } from '@model/features/user';
import { MEDIA_BP, useMediaQuery } from '@ui/hooks';
import { Icon, Icons } from '@ui/lib/Icon';
import { SupportButton } from '@ui/support';
import './header.scss';

export const Header = () => {
  const isLoggedIn = useAppSelector(selectIsLoggedIn);
  const isMobile = useMediaQuery(MEDIA_BP.isMobile);
  const { isUserInUnsupportedCountry, isUserInRegistrationPhase, isUserInTermsConsentPhase } =
    useAppSelector(accessUserStatusChecks());

  return (
    <div className="app-header w-full max-w-screen-full-hd px-4 md:px-6 lg:px-8 xl:px-12">
      {isMobile ? (
        <SupportButton data-testid="support-btn" className="support-btn h-8 w-8">
          <Icon icon={Icons.Support} className="text-[2rem] text-dimmed-a" />
        </SupportButton>
      ) : null}
      <HomePageLogo />
      {isLoggedIn ? (
        <div className="app-header__menu flex">
          {isUserInUnsupportedCountry || isUserInRegistrationPhase || isUserInTermsConsentPhase ? (
            <HeaderRightPartOnlySignOut />
          ) : (
            <>
              <AmountInfoWidget />
              <div className="app-header__dropdown flex-shrink-0 pl-5 2xl:pl-10">
                <MenuElement />
              </div>
            </>
          )}
        </div>
      ) : (
        <AuthButtons />
      )}
    </div>
  );
};
