import { apiExchangeMagicLink, apiRequestMagicLinkForEmail, apiSignUpUserByEmail } from '@model/api/apiCommunication';
import { name } from '@model/features/magic/name';
import {
  createAsyncThunkWithTokenRefresh,
  createAutoUnwrappingAsyncThunkWithTokenRefresh,
  serializeErrorOption,
} from '@services/reduxToolkit';

export const authenticationExchangeMagicLinkAsyncThunk = createAutoUnwrappingAsyncThunkWithTokenRefresh(
  `api/${name}/magic`,
  apiExchangeMagicLink,
  serializeErrorOption
);
export const apiRequestMagicLinkForEmailAsyncThunk = createAsyncThunkWithTokenRefresh(
  `api/${name}/requestLink`,
  apiRequestMagicLinkForEmail,
  serializeErrorOption
);

/**
 * POST signups/emails
 * @param {{email:string; terms_accepted:boolean; refcode?:string; did?:string, remember_me: boolean}}} arg
 */
export const apiSignUpUserByEmailAsyncThunk = createAsyncThunkWithTokenRefresh(
  `api/${name}/signup`,
  apiSignUpUserByEmail,
  serializeErrorOption
);
