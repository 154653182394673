import {
  saveSettingsDataAsyncThunk,
  saveSettingsDataWithActionsAsyncThunk,
} from '@model/features/settings/asyncActions';
import { SettingsGetResponse } from '@model/features/settings/types';
import { safelyAwait } from '@services/promises';
import { useAppDispatch } from '@ui/hooks/redux';
import { useRef } from 'react';

export const useUpdateSettingsRef = () => {
  const dispatch = useAppDispatch();

  const cbRef = useRef(<T>(name: string, value: T, refreshSettings = true) => {
    const saveSettingsDataAsyncThunkMethod = refreshSettings
      ? saveSettingsDataAsyncThunk
      : saveSettingsDataWithActionsAsyncThunk;

    return safelyAwait<SettingsGetResponse['data']['groups'], ApiError>(
      dispatch(saveSettingsDataAsyncThunkMethod({ [name]: value })).unwrap()
    );
  });

  return cbRef;
};
