import { useLocaleServices } from '@ui/contextProviders';
import { SortableDropdown } from '@ui/elements/sortableDropdown';
import { classNames } from 'primereact/utils';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { PVImage } from '../images';
import './fflags.scss';
import pvs from './languageSelector.module.scss';

const baseurl = process.env.REACT_APP_PV_STATIC_BASE_URL;

export function LanguageSelector() {
  const { currentLocale, availableLocales, setCurrentLocale } = useLocaleServices();

  const localeOptions = useMemo(
    () =>
      Array.from(Object.entries(availableLocales), ([code, { name, localized_name }]) => {
        return {
          value: code,
          title: name,
          label: localized_name,
        };
      }),
    [availableLocales]
  );

  const handleOnChange = event => {
    if (event.value === currentLocale) {
      return;
    }
    setCurrentLocale(event.value);
  };

  // the function is not a React component, props are passed from within another component
  function selectedLanguageTemplate(_, { placeholder } = {}) {
    if (!placeholder) {
      return null;
    }
    return (
      <div className={pvs.country_item}>
        {currentLocale && (
          <PVImage url={`${baseurl}/images/flags/${currentLocale}.png`} alt={placeholder} className={pvs.flag} />
        )}
        <div className={pvs.name}> {placeholder}</div>
      </div>
    );
  }

  const languageOptionTemplate = option => {
    if (!option) {
      return null;
    }
    return (
      <div className={pvs.country_item}>
        {option.value && (
          <PVImage
            url={`${baseurl}/images/flags/${String(option.value).toLowerCase()}.png`}
            alt={option.label}
            className={pvs.flag}
          />
        )}
        <div className={pvs.name}>{option.label}</div>
      </div>
    );
  };

  return (
    <div className={pvs.selector}>
      {localeOptions.length ? (
        <CountriesDropdown
          options={localeOptions}
          aria-label="choose language"
          className={pvs.dropdown}
          value={currentLocale}
          onChange={handleOnChange}
          placeholder={`${availableLocales[currentLocale] ? availableLocales[currentLocale].localized_name : ''}`}
          valueTemplate={selectedLanguageTemplate}
          itemTemplate={languageOptionTemplate}
        />
      ) : null}
    </div>
  );
}

CountriesDropdown.propTypes = {
  options: PropTypes.array,
  onChange: PropTypes.func,
  defaultCountry: PropTypes.string,
};

export function CountriesDropdown({ options, onChange, defaultCountry, ...primeReactDDProps }) {
  const { t, currentLocale } = useLocaleServices();
  const [selectedItem, setSelectedItem] = useState(defaultCountry || null);

  useEffect(() => {
    if (primeReactDDProps.value != null) {
      setSelectedItem(options.find(({ value }) => value === primeReactDDProps.value) || null);
    }
  }, [primeReactDDProps.value, options]);

  const localizedOptions = useMemo(
    () =>
      options.map(option =>
        Object.assign(Object.create(option), {
          localizedLabel: t(option.label),
        })
      ),
    [t, options]
  );

  function countryOptionTemplate(option) {
    if (!option) {
      return null;
    }
    return (
      <div className={pvs.country_item}>
        <div className={pvs.name} title={option.name}>
          {option.value && <div className={`fflag ff-sm fflag-${String(option.value).toUpperCase()}`} />}&nbsp;
          {option.localizedLabel}
        </div>
      </div>
    );
  }

  const interceptedOnChange = useCallback(
    event => {
      const { value } = event;
      const foundItem = value && options.find(({ value: itemValue }) => itemValue === value);
      setSelectedItem(foundItem || null);
      onChange && onChange(event);
    },
    [options, onChange]
  );

  useEffect(() => {
    if (!defaultCountry) {
      return;
    }
    interceptedOnChange({
      value: defaultCountry,
      target: {
        name: primeReactDDProps.name,
      },
    });
  }, [defaultCountry, interceptedOnChange, primeReactDDProps.name]);

  const labelDisplayFunc = ({ label, name }) => (label ? t(label) : name);

  // TODO: onBlur={null} - is a hack for a bug in discrepancy for onChange/onBlur events
  return (
    <SortableDropdown
      options={localizedOptions}
      optionLabel="localizedLabel"
      optionValue="value"
      valueTemplate={
        <div className={pvs.country_item}>
          <div className={classNames(pvs.name, 'overflow-hidden text-ellipsis')}>
            {selectedItem && selectedItem.value && (
              <>
                <div className={`fflag ff-sm fflag-${String(selectedItem.value).toUpperCase()}`} />
                &nbsp;{t(selectedItem.label)}
              </>
            )}
            {!selectedItem && primeReactDDProps.placeholder}
          </div>
        </div>
      }
      itemTemplate={countryOptionTemplate}
      {...primeReactDDProps}
      onChange={interceptedOnChange}
      onBlur={null}
      sort
      sortCompareFunction={new Intl.Collator(currentLocale).compare}
      optionLabelFormatter={labelDisplayFunc}
      filterLocale={currentLocale}
    />
  );
}
