import { apiGetDemographics, apiPutDemographics } from '@model/api';
import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunkWithTokenRefresh, serializeErrorOption } from '@services/reduxToolkit';
import { signout } from '../auth';

const name = 'demographics';

/**
 * @typedef {Object} ProfileState
 * @property {Array<{type: string, name: string, label: string, value?: string | number | number[],
 * labels?: {placeholder?: string, errors?: {required?: string, max?: string, pattern?: string} gender_label?: string, gender_placeholder?: string, birth_month_label?: string, birth_month_placeholder?: string, birth_year_label?: string, birth_year_placeholder?: string, add?: string, remove?: string, help?: string},
 * options: {required?: boolean, value_key?: string, label_key?: string, label_i18n?: boolean, sort?: boolean, multiple?: boolean, max?: number, min?: string, pattern?: string, max_age?: number, min_age?: number, highlight?: boolean, new?: boolean, child_label?: string, month_label?: string, depends_on?: string[], hide_when_no_choice?: boolean},
 * values?: Array.<{}>
 * }>} fieldsData
 * @property {{title: {label: string, name: string, years: number}, subtitle: {label: string}}} titles
 */
/**
 * @type {ProfileState}
 */
const initialState = {
  fieldsData: [],
  titles: {},
};

export const apiGetDemographicsAsyncThunk = createAsyncThunkWithTokenRefresh(
  `api/${name}/getDemographics`,
  apiGetDemographics,
  serializeErrorOption
);

export const apiPutDemographicsAsyncThunk = createAsyncThunkWithTokenRefresh(
  `api/${name}/putDemographics`,
  apiPutDemographics,
  serializeErrorOption
);

const demographicsSlice = createSlice({
  name,
  initialState,
  reducers: {
    resetDemographics() {
      return { ...initialState };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(apiGetDemographicsAsyncThunk.fulfilled, (state, { payload }) => {
        if (null !== payload.response?.data) {
          state.fieldsData = payload.response.data.fields;
          state.titles = { title: payload.response.data.title, subtitle: payload.response.data.subtitle };
        }
      })
      .addCase(apiPutDemographicsAsyncThunk.fulfilled, (state, { payload }) => {
        const { response } = payload;
        if (!('errors' in response)) {
          // FIXME: WHY????????????
          state.fieldsData = response.data.fields;
          const { title, subtitle } = response.data;
          state.titles = { title, subtitle };
        }
      })
      .addCase(signout, () => initialState);
  },
});

const defaultExport = { [name]: demographicsSlice.reducer };

export default defaultExport;

export const { resetDemographics } = demographicsSlice.actions;
