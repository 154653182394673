import { RootState } from '@/store';
import { createSlice } from '@reduxjs/toolkit';
import { getSurveysAsync } from '../survey/action-creators';
import { userInfoAsyncThunk } from '../user';

type DashboardState = {
  title?: {
    label: string;
    data?: Record<string, unknown>;
  } | null;
  surveysTitle?: {
    label: string;
    data?: Record<string, unknown>;
  } | null;
  subtitle?: {
    label: string;
    data?: Record<string, unknown>;
  } | null;
  fill_profile?: {
    completed?: number;
    current?: number;
    total?: number;
  } | null;
  cards: {
    id: 'earnings' | 'traitscore' | 'profile' | 'referrals';
  }[];
};

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: {
    cards: [],
  } as DashboardState,
  reducers: {
    updateDashboard(state, action) {
      Object.assign(state, action.payload);
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getSurveysAsync.fulfilled, (state, { payload }) => {
        state.surveysTitle = payload.meta.dashboard?.labels.title;
        state.subtitle = payload.meta.dashboard?.labels.subtitle;
      })
      .addCase(userInfoAsyncThunk.fulfilled, (state, { payload }) => {
        state.cards = payload.data.dashboard.cards ?? [];
        state.title = payload.data.dashboard.labels?.title;
      });
  },
});

export default dashboardSlice.reducer;

export const selectDashboardTitle = (state: RootState) => state.dashboard.surveysTitle ?? state.dashboard.title;
export const selectDashboardSubTitle = (state: RootState) => state.dashboard.subtitle;
export const selectDashboardCards = (state: RootState) => state.dashboard.cards;
