export function formatCurrency(cents: number, locale: string, fallbackMessage = ''): string {
  if ('undefined' === typeof cents) {
    return fallbackMessage;
  }
  if (locale !== 'US') {
    return (Number(cents) / 100).toFixed(2);
  }
  return `$${(Number(cents) / 100).toFixed(2)}`;
}

export const centsToDollar = (cent: number) =>
  (cent / 100).toLocaleString('en-US', { style: 'currency', currency: 'USD' });

export function formatCurrencyWithSign(cents: Nullable<number>, sign: Nullable<string>) {
  if (null == cents) {
    return '';
  }

  const amount = (cents / 100).toFixed(2);

  if (null == sign) {
    return amount;
  }

  return `${sign}${amount}`;
}

export function formatCurrencyWithAbr(cents: Nullable<number>, abr: Nullable<string>) {
  if (null == cents) {
    return '';
  }

  const amount = (cents / 100).toFixed(2);

  if (null == abr) {
    return amount;
  }

  return `${amount} ${abr}`;
}
