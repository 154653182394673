import polyfilledStructuredClone from '@ungap/structured-clone';

export const collectFormValues = domForm =>
  Object.fromEntries(
    Array.from(domForm.elements, elem => (elem.name ? [elem.name, elem.value] : null)).filter(Boolean)
  );

const SYM_EMPTY_LIST_LENGTH = Number.MAX_SAFE_INTEGER;
//export const emptyFileList = Object.create(FileList.prototype, { length: { value: SYM_EMPTY_LIST_LENGTH } })

export function createFormDataForFiles(data) {
  const filteredPairs = Array.from(Object.entries(data), ([key, value]) =>
    value instanceof FileList ? [key, value] : null
  ).filter(Boolean);

  if (0 === filteredPairs.length) {
    return null;
  }

  return filteredPairs.reduce((fd, [key, list]) => {
    if (0 === list.length) {
      return fd;
    } else if (list.length === SYM_EMPTY_LIST_LENGTH) {
      fd.append(key, null);
    } else if (1 === list.length) {
      fd.append(key, list[0]);
    } else {
      /**
       *
       * @type {File[]}
       */
      const files = Array.from(list);
      files.forEach((file, idx) => {
        fd.append(`${key}[${idx}]`, file);
      });
    }
    return fd;
  }, new FormData());
}

export function createFormDataForJson(data) {
  return Object.fromEntries(
    Array.from(Object.entries(data), ([key, value]) => {
      if (value instanceof FileList) {
        return null;
      } else if (value instanceof Date) {
        return [key, value.toISOString()];
      } else {
        return [key, value];
      }
    }).filter(Boolean)
  );
}

export function unsetNullProperties(source) {
  if (Object(source) !== source) {
    return source;
  }
  return Object.fromEntries(
    Array.from(Object.entries(polyfilledStructuredClone(source)), ([key, value]) =>
      Object(value) === value ? [key, unsetNullProperties(value)] : [key, value]
    ).filter(([_key, value]) => value !== null)
  );
}
