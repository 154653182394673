export const SEC_INTO_MSEC = 1000;
export const MIN_INTO_MSEC = 60 * SEC_INTO_MSEC;
export const HOUR_INTO_MSEC = 60 * MIN_INTO_MSEC;
export const DAY_INTO_MSEC = 24 * HOUR_INTO_MSEC;

export const getTimestamp = () => Date.now();
export const getTodayDate = now => new Date(new Date(now || getTimestamp()).toLocaleDateString());

export const isTimeWithin = (timestamp, timeDiff, now = 0) => (now || getTimestamp()) - Number(timestamp) < timeDiff;
export function getPollingTimeoutForTime(count) {
  return 2000 * Math.ceil((count * count) / 36) + 50 * (count % 6);
}

export function formatTime(time = 0, scale = 1) {
  const date = new Date(time * scale);
  const hours = String(date.getUTCHours()).padStart(2, '0');
  const minutes = String(date.getUTCMinutes()).padStart(2, '0');
  const seconds = String(date.getUTCSeconds()).padStart(2, '0');
  return hours !== '00' ? `${hours}:${minutes}:${seconds}` : `${minutes}:${seconds}`;
}

export function getDateFormatString(lang = 'default') {
  const formatObj = new Intl.DateTimeFormat(lang).formatToParts(new Date());

  return formatObj
    .map(obj => {
      switch (obj.type) {
        case 'day':
          return 'DD';
        case 'month':
          return 'MM';
        case 'year':
          return 'YYYY';
        default:
          return obj.value;
      }
    })
    .join('');
}

export function getDateFormat(date, format = 'en-US') {
  return new Intl.DateTimeFormat(format).format(date);
}

export const isLeapYear = year => 29 === new Date(year, 1, 29).getDate();

export function splitIntoDateParts(value) {
  if ('string' === typeof value && value.includes('-')) {
    const [year, month, date] = value.split('-');
    return {
      year: Number(year),
      month: Number(month) - 1,
      date: Number(date),
    };
  }
  const date =
    'string' === typeof value ? new Date(Date.parse(value)) : 'number' === typeof value ? new Date(value) : Number.NaN;

  return isNaN(date)
    ? { month: -1, date: -1, year: -1 }
    : {
        month: date.getMonth(),
        date: date.getDate(),
        year: date.getFullYear(),
      };
}

export const parseFutureTimeOffset = (value, fallback = 0, now = Date.now()) => {
  if (typeof value !== 'string' && typeof value !== 'number') {
    return fallback;
  }
  const possiblyTimestamp = Number(value);
  const timestamp = Number.isNaN(possiblyTimestamp) ? Date.parse(value) : possiblyTimestamp;
  if (Number.isNaN(timestamp)) {
    return fallback;
  }
  return Math.max(0, timestamp - now);
};
