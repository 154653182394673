import { FC } from 'react';
import { NavLink } from 'react-router-dom';

type Props = {
  href: string;
  title: string;
  external?: boolean;
};

export const FooterNavLink: FC<PropsWithClassName<Props>> = ({ href, title, external, className }) =>
  external ? (
    <a className={className} aria-label={title} href={href}>
      {title}
    </a>
  ) : (
    <NavLink to={href} aria-label={title} className={className}>
      {title}
    </NavLink>
  );
