import { BALOONS_PATH } from '@/constants';

export const balloonsBasePath = BALOONS_PATH;
export const BALLOON = {
  ALREADY_COMPLETED: 'already-completed',
  INACTIVE_QUEUE: 'inactive-queue',
  KID: 'kid',
  KOALA_MINOR_PROBLEM: 'koala_minor_problem',
  NO_QUEUE_SURVEY_STOP: 'no-queue_in-excluded-list_completed-excluded-survey-has-stopped',
  SQUIRREL_WHOOPS: 'whoops',
  WE_WILL_MISS_YOU: 'we-will-miss-you',
  WE_WILL_MISS_YOU_2: 'we-will-miss-you-chimp',
  UNDER_MAINTENANCE: 'under-maintenance',
  UNDER_MAINTENANCE_2: 'under-maintenance-bison',
  UNDER_MAINTENANCE_3: 'under-maintenance-bird',
  THANKS_FOR_STAYING: 'thanks-for-staying',
  SURVEY_HAS_STOPPED: 'survey-has-stopped',
  JUST_MISSED_IT: 'just-missed-it',
  OUT_OF_REACH: 'out-of-reach',
  OUT_OF_REACH_CHINA: 'out-of-reach-china',
  OUT_OF_REACH_BAHAMAS: 'out-of-reach-bahamas',
  CHECK_BACK_SOON: 'check-back-soon',
  CHECK_BACK_SOON_V2: 'check-back-soon-v2',
  SMALL_PANDA: '8788326',
  SMALL_OSTRICH: '752035',
  SMALL_CHAMELEON: '62289',
  SMALL_PARROT: '2317904',
  SMALL_DOG: '1390361',
  404: '404',
  500: '500',
  OWL: 'owl',
  OWL_D: 'owl-d',
  OWL_OPEN_EYES: 'owl-open-eyes',
  OWL_OPEN_EYES_D: 'owl-open-eyes-d',
  UNSUBSCRIPTION_FAIL: 'unsubscribtion_failed',
  UNSUBSCRIPTION_FAIL_D: 'unsubscribtion_failed-d',
};

const availableDefinitions = [
  {
    infix: '@2x',
    mediaQuery:
      'only screen and (-webkit-min-device-pixel-ratio: 2), ' +
      'only screen and (min--moz-device-pixel-ratio: 2), ' +
      'only screen and (-o-min-device-pixel-ratio: 2/1), ' +
      'only screen and (min-device-pixel-ratio: 2), ' +
      'only screen and (min-resolution: 192dpi)',
  },
  { infix: '', mediaQuery: `` },
];

const availableMimeTypes = [
  { type: 'image/webp', ext: 'webp' },
  { type: 'image/png', ext: 'png' },
];

export const knownBalloonResourcesMap = Object.fromEntries(
  Array.from(Object.values(BALLOON), value => {
    const mostSpecificFirst = [].concat(
      ...availableDefinitions.map(({ infix, mediaQuery }) =>
        [].concat(
          ...availableMimeTypes.map(({ type, ext }) =>
            Object({
              name: value,
              pathAndName: `${balloonsBasePath}/${value}`,
              namePostfix: `${infix}.${ext}`,
              mimeType: type,
              mediaQuery,
            })
          )
        )
      )
    );
    const leastSpecific = mostSpecificFirst.pop();

    return [
      value,
      {
        sources: mostSpecificFirst,
        fallbackImage: leastSpecific,
      },
    ];
  })
);

export function getLocalizedName(item, locale) {
  const { pathAndName, namePostfix } = item;
  return `${pathAndName}-${locale ?? 'en'}${namePostfix}`;
}
