import { applicationEndpoints } from '@model/api';
import { provideOneHandlerForCodes } from '@model/api/endpointCallWrapper';
import { accessUserId } from '@model/features/user';
import { getAPIUrl } from '@services/context';
import { safelyAwait } from '@services/promises';
import { Button } from 'primereact/button';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';

export const VerifyForDevButton = () => {
  const userId = useSelector(accessUserId());
  //curl -X POST http://localhost:3200/tools/phone/users/:user_id/verify

  const handleDevOnlyVerify = useCallback(async () => {
    const apiEndpoint = new URL(getAPIUrl());
    apiEndpoint.pathname = `tools/phones/users/${userId}/activate`;
    const url = `tools/users/${userId}/phones/activate`;
    const [err, devOnlyVerifyResult] = await safelyAwait(
      provideOneHandlerForCodes(
        applicationEndpoints.DYNAMIC_TOOLS_URL_AUTH,
        () =>
          Object({
            method: 'POST',
            params: {
              url,
            },
          }),
        200
      )
    );
    void err;
    void devOnlyVerifyResult;
  }, [userId]);

  return (
    <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
      <Button type="button" onClick={handleDevOnlyVerify}>
        [DEV] Verify phone
      </Button>
    </div>
  );
};
