import { useCallback, useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import pvs from './checkbox.module.scss';
import { UI_TEST_ASSIST_EXT } from '@ui/testids';
import PropTypes from 'prop-types';

ControlledCustomCheckbox.propTypes = {
  name: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  ariaLabel: PropTypes.string,
  disabled: PropTypes.bool,
  hasError: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  checked: PropTypes.bool,
  submitOnChange: PropTypes.bool,
  testIdFn: PropTypes.func,
  props: PropTypes.object,
};
/**
 *
 * @param name - string, input name
 * @param label - DOM element
 * @param ariaLabel - string
 * @param disabled - boolean
 * @param hasError - boolean, error element
 * @param defaultValue - boolean
 * @param submitOnChange - boolean
 *
 * @param testIdFn
 * @param restProps
 * @returns {JSX.Element}
 */
export function ControlledCustomCheckbox({
  name,
  label,
  ariaLabel = '',
  disabled = false,
  hasError = false,
  checked,
  submitOnChange,
  testIdFn,
  ...restProps
}) {
  const { forceSubmit, formId } = useFormContext();

  const handleKeyDown = useCallback(
    event => {
      if (['Space', 'Enter'].includes(event.code) && !disabled) {
        event.preventDefault();
        event.target.click();
      }
    },
    [disabled]
  );

  const wrapperClasses = useMemo(
    () =>
      [pvs.checkbox, hasError ? pvs.error : null, disabled ? pvs.disabled : null, checked ? pvs.active : null]
        .filter(Boolean)
        .join(' '),
    [checked, disabled, hasError]
  );

  const makeHandleCheckChange = (value, onChange) =>
    disabled
      ? null
      : event => {
          if (event) {
            void event;
          }
          onChange(!value);
          submitOnChange && forceSubmit?.();
        };

  return (
    <label className={pvs.checkbox_wrap} onKeyDown={handleKeyDown} tabIndex="0" aria-label={ariaLabel}>
      <div className={wrapperClasses}>
        <Controller
          name={name}
          render={({ field: { onChange, name, ref, onBlur, value } }) => (
            <input
              form={formId}
              type="checkbox"
              checked={checked}
              className={pvs.input}
              name={name}
              tabIndex="-1"
              disabled={disabled}
              onChange={makeHandleCheckChange(value, onChange)}
              onBlur={onBlur}
              ref={ref}
              data-value={checked ? 'checked' : 'unchecked'}
              {...UI_TEST_ASSIST_EXT.FLD_CHECKBOX(name)}
              {...(testIdFn ? testIdFn(name) : {})}
              {...restProps}
            />
          )}
        />
      </div>
      {label && <div>{label}</div>}
    </label>
  );
}
