type GetPVContext = {
  (): Window['context'];
  <T extends keyof Window['context']>(propName: T): Window['context'][T];
  <T extends keyof Window['context'], F>(propName: T, fallback: F): Window['context'][T] | F;
};

export const getPvContext: GetPVContext = <F>(propName?: keyof Window['context'], fallback?: F) => {
  if (!window.context) {
    return fallback;
  }
  const context = window.context;
  if (!propName) {
    return context;
  }
  return propName in context ? context[propName] : fallback;
};

export function getAPIUrl(path = '/api/v1/') {
  const apiBaseUrl = getPvContext('api_base_url');
  if (apiBaseUrl) {
    return `${apiBaseUrl}${path}`;
  }
  const apiHost = getPvContext('api_host');
  if (apiHost) {
    return `https://${apiHost}${path}`;
  }

  // This is the way to communicate to devs of a proper usage
  // eslint-disable-next-line no-console
  console.info(`PV Context does not have an 'api_host' entry. Falling back to 'REACT_APP_API_ENDPOINT' env variable`);

  const fallbackResult = process.env.REACT_APP_API_ENDPOINT;
  if (fallbackResult) {
    return fallbackResult;
  }

  throw new Error(
    `Both PV Context ('api_host' prop) and env var 'REACT_APP_API_ENDPOINT' failed to provide API server's URL`
  );
}
