import * as yup from 'yup';

export const schemaGeoCountriesGetResponse = yup
  .object({
    data: yup.array().of(
      yup.object({
        code: yup.string(),
        name: yup.string(),
        label: yup.string(),
        min_age: yup.number(),
      })
    ),
    meta: yup
      .object({
        default_country_id: yup.string(),
      })
      .required(),
  })
  .required();
export const schemaGeoCountriesZipValidationGetParamsRequest = yup
  .object({
    country_code: yup.string().required(),
    zip_code: yup.string().required(),
  })
  .required();
export const schemaGeoCountriesZipValidationGetResponse = yup
  .object({
    data: yup
      .array()
      .of(
        yup.object({
          type: yup.string(),
          id: yup.string(),
          county: yup.object(),
        })
      )
      .required(),
  })
  .required();
export const schemaGeoCountriesStatesGetParamsRequest = yup
  .object({
    country_code: yup.string().required(),
  })
  .required();
export const schemaGeoCountriesStatesGetParamsResponse = yup
  .object({
    data: yup.array().of(
      yup.object({
        code: yup.string(),
        name: yup.string(),
      })
    ),
  })
  .required();
export const schemaGeoValidateGetParamsRequest = yup
  .object({
    country: yup.string(),
    state: yup.string(),
    zip: yup.string(),
    county: yup.string(),
    street_address: yup.string(),
  })
  .required();
export const schemaGeoValidateGetResponse = yup
  .object({
    data: yup.boolean(),
  })
  .required();
