import { safelyExecute } from '@services/functional';

export const onAppStartActionsManager = (() => {
  const _actions = [];

  const actions = async (originalAction, reduxAPI) => {
    for (const action of _actions) {
      safelyExecute(action)(originalAction, reduxAPI);
    }
  };

  const register = action => {
    _actions.push(action);
  };

  return { actions, register };
})();
