import { useLocaleServices } from '@ui/contextProviders/Locale';
import { routeNames } from '@ui/routes/routeNames';
import { SupportButton } from '@ui/support';
import React from 'react';
import { FooterNavLink } from './FooterLink';

export const AnonymousLinks: React.FC<PropsWithClassName<{ links: Window['tenant']['links'] }>> = ({
  links,
  className,
}) => {
  const { t } = useLocaleServices();

  return (
    <div className="footer-links flex flex-wrap items-center">
      <FooterNavLink className={className} title={t(links.landing.label)} href={links.landing.url} external />
      <FooterNavLink className={className} title={t(links.about.label)} href={links.about.url} external />
      <FooterNavLink className={className} title={t(links.community.label)} href={links.community.url} external />
      <FooterNavLink className={className} title={t(links.experience.label)} href={links.experience.url} external />
      <SupportButton data-testid="support-btn" className={className} />
      <FooterNavLink className={className} title={t('footer.register')} href={routeNames.signUpIndex} />
    </div>
  );
};
