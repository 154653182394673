import { captureException, captureMessage } from '@sentry/react';
import { ScopeContext } from '@sentry/types/build/types/scope';

export class ErrorLogger {
  static isLogEnabled = false; // TODO Fix this later
  static data: Partial<ScopeContext> = {};
  static setTag(key: string, value: string) {
    if (this.data.tags === undefined) {
      this.data.tags = {};
    }
    this.data.tags[key] = value;
    return this;
  }
  static setExtra(key: string, value: string) {
    if (this.data.extra === undefined) {
      this.data.extra = {};
    }
    this.data.extra[key] = value;
    return this;
  }

  static setFnName(name: string) {
    return this.setExtra('functionName', name);
  }

  static send(error: unknown) {
    if ('development' === process.env.NODE_ENV) {
      // eslint-disable-next-line no-console
      console.error(error);
    } else {
      captureException(error, ErrorLogger.data);
    }
    ErrorLogger.data = {};
  }

  static log(message: string, data?: ScopeContext['extra']) {
    if (data) {
      ErrorLogger.data.extra = {
        ...ErrorLogger.data.extra,
        ...data,
      };
    }
    if ('production' === process.env.NODE_ENV) {
      captureMessage(message, ErrorLogger.data);
    }
    // eslint-disable-next-line no-console
    console.info(message, ErrorLogger.data.extra);
    ErrorLogger.data = {};
  }
}
