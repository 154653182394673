import {
  apiUpdateEmailAndSendActivationAsyncThunk,
  userResetPasswordAsyncThunk,
} from '@model/features/user/asyncActions';
import { createSlice } from '@reduxjs/toolkit';
import { accessor } from '../../accessors';
import {
  apiRequestMagicLinkForEmailAsyncThunk,
  apiSignUpUserByEmailAsyncThunk,
  authenticationExchangeMagicLinkAsyncThunk,
} from './asyncActions';
import { name } from './name';
import { MAGIC_ACTION_NAMES } from './types';

const initialState = {
  actionName: null,
  actionPayload: null,
  magicCodePrefix: null,
  magicLinkMaskedEmail: null,
  magicLinkEmail: null,
};
export const magicPayloadExtractors = {
  [MAGIC_ACTION_NAMES.LOGIN]: () => {
    return {};
  },
  [MAGIC_ACTION_NAMES.ADMIN_TOOL_LOGIN]: () => {
    return {};
  },
  [MAGIC_ACTION_NAMES.RESET_PASSWORD]: ({ code }) => {
    return { code };
  },
  [MAGIC_ACTION_NAMES.PASSWORD_CHANGE]: ({ code }) => {
    return { code };
  },
  [MAGIC_ACTION_NAMES.REGISTRATION_EMAIL_ACTIVATION]: ({ activation_code: activationCode }) => {
    return { activationCode };
  },
  [MAGIC_ACTION_NAMES.SETTINGS_EMAIL_CONFIRMATION]: ({ activation_code: activationCode }) => {
    return { activationCode };
  },
  [MAGIC_ACTION_NAMES.SETTINGS_ALT_EMAIL_CONFIRMATION]: ({ activation_code: activationCode }) => {
    return { activationCode };
  },
};

export const magicSlice = createSlice({
  name,
  initialState,
  reducers: {
    recordMagicAction: (state, { payload }) => {
      if (payload) {
        const { action: actionName, payload: actionPayload } = payload;
        state.actionName = actionName;
        state.actionPayload = actionPayload;
      } else {
        state.actionName = null;
        state.actionPayload = null;
      }
    },
  },
  extraReducers: builder => {
    builder.addCase(authenticationExchangeMagicLinkAsyncThunk.fulfilled, (state, action) => {
      const code = action.payload?.code;
      if (code !== 201) {
        return;
      }
      state.magicCodePrefix = null;
      magicSlice.caseReducers.recordMagicAction(state, { payload: action.payload.response.meta });
    });

    builder.addCase(apiUpdateEmailAndSendActivationAsyncThunk.fulfilled, magicPrefixCodeKeeper);
    builder.addCase(apiRequestMagicLinkForEmailAsyncThunk.fulfilled, magicPrefixCodeKeeper);
    builder.addCase(apiSignUpUserByEmailAsyncThunk.fulfilled, magicPrefixCodeKeeper);
    builder.addCase(userResetPasswordAsyncThunk.fulfilled, maskedEmailKeeper);
  },
});

function maskedEmailKeeper(state, action) {
  const code = action.payload?.code;
  if (!(201 === code || 200 === code)) {
    return;
  }
  const { masked_email } = action.payload.response.data || {};
  const maskedEmail = action.payload.response.meta?.masked_email ?? masked_email ?? null;
  state.magicLinkMaskedEmail = maskedEmail;
}

function magicPrefixCodeKeeper(state, action) {
  const code = action.payload?.code;
  if (!(201 === code || 200 === code)) {
    return;
  }
  const { email } = action.meta.arg;
  const { code_head: magicCodePrefix } = action.payload.response.data;
  state.magicCodePrefix = magicCodePrefix;
  state.magicLinkEmail = email;
  maskedEmailKeeper(state, action);
}

const defaultExport = { [name]: magicSlice.reducer };

export default defaultExport;

export const { recordMagicAction } = magicSlice.actions;

const accessMagic = accessor(name);

export const accessMagicActionName = () => accessMagic('actionName', null);
export const accessMagicActionPayload = () => accessMagic('actionPayload', null);
export const accessMagicLinkCodePrefix = () => accessMagic('magicCodePrefix', null);
export const accessMagicLinkEmail = () => accessMagic('magicLinkEmail', null);
export const accessMagicLinkMaskedEmail = fallback => accessMagic('magicLinkMaskedEmail', fallback);
