import { useCallback, useEffect, useRef, useState } from 'react';

export function useCountdownSeconds(timeout: number) {
  const [time, setTime] = useState(timeout);

  useEffect(() => {
    let tm = 0;
    if (0 !== time) {
      tm = window.setTimeout(() => {
        setTime(prevTime => prevTime - 1);
      }, 1000);
    }
    return () => clearTimeout(tm);
  }, [time]);

  return time;
}

export function useCountdown(initialTimeout: number, onTimeout?: () => void) {
  const [time, setTime] = useState(initialTimeout);
  const [isActive, setIsActive] = useState(false);
  const timeoutId = useRef<number | null>(null);
  const onTimeoutRef = useRef(onTimeout);
  const isMountedRef = useRef(true);

  onTimeoutRef.current = onTimeout;

  const start = useCallback(() => {
    setIsActive(true);
  }, []);

  const stop = useCallback(() => {
    setIsActive(false);
  }, []);

  const reset = useCallback(
    (newTimeout: number = initialTimeout) => {
      stop();
      setTime(newTimeout);
    },
    [stop, initialTimeout]
  );

  useEffect(() => {
    if (isActive && time > 0) {
      timeoutId.current = window.setTimeout(() => {
        if (isMountedRef.current) {
          setTime(prevTime => prevTime - 1);
        } else {
          if (timeoutId.current) {
            clearTimeout(timeoutId.current);
          }
        }
      }, 1000);
    } else if (isActive && 0 === time) {
      stop();
      onTimeoutRef.current?.();
    }
    return () => {
      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
      }
    };
  }, [isActive, time, stop]);

  useEffect(
    () => () => {
      isMountedRef.current = false;
    },
    []
  );

  return { time, start, stop, reset };
}
