import { useEffect, useId } from 'react';
import { useFormContext } from 'react-hook-form';
import { InputText } from 'primereact/inputtext';
import { useLocaleServices } from '@ui/contextProviders';
import { UI_TEST_ASSIST_EXT } from '@ui/testids';
import { usePrevious } from '@ui/hooks';
import PropTypes from 'prop-types';

const tooltipOptionsStyle = { position: 'top' };

TextSmartFormComponent.propTypes = {
  name: PropTypes.string,
  fieldWrapperComponent: PropTypes.func,
  className: PropTypes.string,
  options: PropTypes.object,
  dependentsMap: PropTypes.object,
  focus: PropTypes.bool,
  positionErrorRight: PropTypes.bool,
};

/**
 * @type {SmartFormSimpleComponent} TextSmartFormComponent
 * @return {JSX.Element}
 * @constructor
 */
export function TextSmartFormComponent({ name, options = {}, dependentsMap = {}, focus }) {
  const { placeholder, tooltip, type, ariaLabel = '' } = options || {};

  const { t } = useLocaleServices();
  const fieldId = useId();
  const { register, formState, getFieldState, getValues, watch, formId, setValue, clearErrors } = useFormContext();

  const {
    dirtyFields,
    isDirty: isFormDirty,
    defaultValues,
    errors,
    touchedFields,
    isValid,
    isValidating,
    isSubmitting,
    isSubmitted,
    isSubmitSuccessful,
  } = formState;

  const voidProps = {
    dirtyFields,
    isFormDirty,
    defaultValues,
    errors,
    touchedFields,
    isValid,
    isValidating,
    isSubmitted,
  };
  void voidProps;

  const { isDirty, isTouched, error } = getFieldState(name, formState);

  const watchValue = watch(name);

  const field = register(name);

  const parentFieldValue = dependentsMap && dependentsMap[name] ? getValues(dependentsMap[name]) : null;
  const previousParentValue = usePrevious(parentFieldValue);

  useEffect(() => {
    // FYI: TEXT FIELD!
    if (previousParentValue && parentFieldValue && previousParentValue !== parentFieldValue) {
      if (watchValue) {
        setValue(name, '');
      }
      clearErrors(name);
    }
  }, [parentFieldValue, previousParentValue, getValues, setValue, name, clearErrors, watchValue]);

  return (
    <InputText
      {...UI_TEST_ASSIST_EXT.FLD_TEXT_INPUT(name)}
      className={`input_text ${(isDirty || isTouched || !isSubmitSuccessful) && error ? 'p-invalid' : ''}`}
      id={fieldId}
      {...field}
      {...(type ? { type } : {})}
      {...(isSubmitting ? { disabled: true } : {})}
      form={formId}
      autoComplete={options.autoComplete ?? name}
      placeholder={t(placeholder)}
      aria-label={t(ariaLabel)}
      tooltip={t(tooltip)}
      tooltipOptions={tooltipOptionsStyle}
      autoFocus={focus}
    />
  );
}
