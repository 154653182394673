import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';

PortalParent.propTypes = {
  element: PropTypes.node,
  children: PropTypes.any,
};

export function PortalParent({ element, children }) {
  if (!element) {
    return null;
  }
  return createPortal(children, element);
}
