import { preprocessFormFields } from '@services/formHelpers';
import { updatedDependenciesLookup } from '@model/features/registration/updatedDependenciesLookup';

export function updateFields(state, updatedFields, timestamp) {
  state.currentStepData.timestamp = timestamp;
  state.currentStepData.originalFields = updatedFields;
  const processedFields = preprocessFormFields(updatedFields);
  state.currentStepData.fields = processedFields;
  updatedDependenciesLookup(state, processedFields);
}
