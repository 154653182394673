import { FIELD_VALIDATIONS } from './fieldValidationTypes';
import { FieldsArrayType } from '@services/fields/types';
import { makeReverseObject } from '@services/objects/makeReverseObject';

type DependentsMap = { [name: string]: string | string[] };

export function makeDependentsMap(fields?: FieldsArrayType): DependentsMap {
  if (!fields) {
    return {};
  }

  const equalsTo = fields.reduce(
    (memo, { name, options = {} }) => {
      if ('string' === typeof options[FIELD_VALIDATIONS.EQUAL_TO]) {
        const pair: [string, string] = [name, options[FIELD_VALIDATIONS.EQUAL_TO] as string];
        memo.push(pair);
      }
      return memo;
    },
    [] as [string, string | string[]][]
  );

  const dependsOn = fields.reduce((memo, { name, options = {} }) => {
    if (
      'string' === typeof options[FIELD_VALIDATIONS.DEPENDS_ON] ||
      Array.isArray(options[FIELD_VALIDATIONS.DEPENDS_ON])
    ) {
      const pair: [string, string | string[]] = [name, options[FIELD_VALIDATIONS.DEPENDS_ON] as string | string[]];
      memo.push(pair);
    }
    return memo;
  }, equalsTo);

  return Object.fromEntries(dependsOn);
}

export function makeConnectedFieldNames(dependentsMap: DependentsMap): (arg0: string) => string[] {
  const reversedDepsMap = makeReverseObject(dependentsMap);

  return (name: string) => {
    if (!name) {
      return [];
    }
    const prefixIdx = name.lastIndexOf('.');
    const prefix = prefixIdx >= 0 ? name.slice(0, Math.max(0, prefixIdx + 1)) : '';
    const connectedNames = Array.prototype.concat
      .apply(
        [],
        [
          ...new Set([
            ...(dependentsMap[name] ? [dependentsMap[name]] : []),
            ...(reversedDepsMap[name] ? reversedDepsMap[name] : []),
          ]),
        ]
      )
      .map(fieldName => (fieldName !== name ? `${prefix}${fieldName}` : ''))
      .filter(Boolean);

    if (connectedNames.length) {
      // eslint-disable-next-line no-console
      console.debug(`Field: '${name}'. Deps: ${connectedNames.join()}' `);
    }

    return connectedNames;
  };
}
