import { useLocaleServices } from '@/ui/contextProviders/Locale';
import { InputTextarea, InputTextareaProps } from 'primereact/inputtextarea';
import { classNames } from 'primereact/utils';
import { FieldValues, useController } from 'react-hook-form';
import type { FormFieldBaseProps } from './types';

export const FormTextArea = <TFieldValues extends FieldValues>({
  label,
  name,
  control,
  rules,
  className,
  ...rest
}: FormFieldBaseProps<TFieldValues, Omit<InputTextareaProps, 'name'>>): JSX.Element => {
  const { t } = useLocaleServices();
  const { field, fieldState } = useController<TFieldValues>({
    name,
    control,
    rules,
  });

  const error = fieldState.error;

  return (
    <div className="field">
      <label className="flex flex-col">
        {label}
        <div className="mt-2 overflow-hidden !rounded-3xl">
          <InputTextarea
            className={classNames('form-input block w-full !rounded-3xl', className, {
              'p-invalid': error,
            })}
            aria-label={label}
            {...field}
            aria-invalid={error ? 'true' : 'false'}
            {...rest}
          />
        </div>
      </label>
      {error?.message != null && <small className="p-error mt-2">{t(error.message)}</small>}
    </div>
  );
};
