import { concatClsx } from '@/utils/classnames';
import { getYear, monthToLocale } from '@services/calendar';
import { FIELD_NAMES } from '@services/fields/fieldNames';
import { useLocaleServices } from '@ui/contextProviders';
import { Button } from '@ui/lib/Button';
import { Dropdown } from 'primereact/dropdown';
import PropTypes from 'prop-types';
import { useEffect, useMemo } from 'react';
import { useController } from 'react-hook-form';
import './index.scss';

const thisYear = getYear();

TraitChildrenComponent.propTypes = {
  removeChild: PropTypes.func,
  childrenLabels: PropTypes.object,
  childrenOptions: PropTypes.object,
  field: PropTypes.object,
  childrenIndex: PropTypes.number,
  genderValues: PropTypes.array,
  yearValues: PropTypes.array,
  fieldArrayName: PropTypes.string,
  control: PropTypes.object,
  methods: PropTypes.object,
};
export function TraitChildrenComponent({
  removeChild,
  childrenLabels,
  childrenOptions,
  field,
  fieldArrayName,
  childrenIndex,
  genderValues,
  yearValues,
  control,
  methods,
}) {
  const { t } = useLocaleServices();
  const { setError } = methods;
  const lastYear = yearValues[yearValues.length - 1].value;

  const { gender: childGender = null, birth_month: birthMonth = null, birth_year: birthYear = null } = field || {};

  const {
    field: {
      name: genderFieldName,
      onChange: genderFieldOnChange,
      value: watchGender,
      onBlur: genderOnBlur,
      ref: genderRef,
    },
    formState: { isSubmitting, isSubmitted },
    fieldState: { invalid: genderInvalid, error: genderError },
  } = useController({
    name: `${fieldArrayName}.${childrenIndex}.${FIELD_NAMES.GENDER_FIELD_NAME}`,
    control,
    rules: {
      required: 'fields.children.error_gender',
    },
    defaultValue: childGender,
  });

  const {
    field: {
      name: monthFieldName,
      onChange: monthFieldOnChange,
      value: watchMonth,
      onBlur: monthOnBlur,
      ref: monthRef,
    },
    fieldState: { invalid: monthInvalid, error: monthError },
  } = useController({
    name: `${fieldArrayName}.${childrenIndex}.${FIELD_NAMES.MONTH_FIELD_NAME}`,
    control,
    rules: {
      required: 'fields.children.error_month',
    },
    defaultValue: birthMonth,
  });

  const {
    field: { name: yearFieldName, onChange: yearFieldOnChange, value: watchYear, onBlur: yearOnBlur, ref: yearRef },
    fieldState: { invalid: yearInvalid, error: yearError },
  } = useController({
    name: `${fieldArrayName}.${childrenIndex}.${FIELD_NAMES.YEAR_FIELD_NAME}`,
    control,
    rules: {
      required: 'fields.children.error_year',
    },
    defaultValue: birthYear,
  });

  const childNumber = childrenIndex + 1;

  const { child_label: childNumberLabelKey } = childrenOptions || {};
  const {
    birth_month_label: monthLabel,
    birth_month_placeholder: birthMonthPlaceholder,
    birth_year_label: yearLabel,
    birth_year_placeholder: birthYearPlaceholder,
    gender_placeholder: genderPlaceholder,
  } = childrenLabels || {};

  const childNumberLabel = t(`${childNumberLabelKey.replace('#', '')}${childNumber}`);

  const monthValues = useMemo(() => {
    let monthsCount = 12;

    if (watchYear && [thisYear, lastYear].includes(watchYear)) {
      monthsCount = new Date().getMonth() + 1;
    }

    if (watchYear && watchYear === lastYear) {
      return Array.from({ length: 12 - monthsCount + 1 }, (_, i) => {
        return { name: monthToLocale(11 - i), value: 12 - i };
      }).reverse();
    }

    return Array.from({ length: monthsCount }, (_, i) => {
      return { name: monthToLocale(i), value: i + 1 };
    });
  }, [watchYear, lastYear]);

  const handleMonthChange = event => {
    monthFieldOnChange(event);
    isSubmitted && !watchYear && setError(yearFieldName, { type: 'validate', message: 'fields.children.error_year' });
  };

  const handleYearChange = event => {
    yearFieldOnChange(event);
    isSubmitted &&
      !watchMonth &&
      setError(monthFieldName, { type: 'validate', message: 'fields.children.error_month' });
  };

  const dateFieldsArray = [
    {
      fieldName: monthFieldName,
      fieldType: FIELD_NAMES.MONTH_FIELD_NAME,
      fieldLabel: monthLabel,
      placeholder: t(birthMonthPlaceholder),
      fieldValues: monthValues,
      fieldValue: watchMonth,
      fieldOnChange: handleMonthChange,
      fieldRef: monthRef,
      fieldOnBlur: monthOnBlur,
      ariaLabel: 'month',
      invalid: monthInvalid,
      error: monthError,
    },
    {
      fieldName: yearFieldName,
      fieldType: FIELD_NAMES.YEAR_FIELD_NAME,
      fieldLabel: yearLabel,
      placeholder: t(birthYearPlaceholder),
      fieldValues: yearValues,
      fieldValue: watchYear,
      fieldOnChange: handleYearChange,
      fieldRef: yearRef,
      fieldOnBlur: yearOnBlur,
      ariaLabel: 'year',
      invalid: yearInvalid,
      error: yearError,
    },
  ];

  const optionTemplate = option => t(option.name);

  const selectedTemplate = (option, data) => {
    if (option) return t(option.name);
    return data.placeholder;
  };

  useEffect(() => {
    if (watchYear && watchYear === thisYear) {
      const monthsCount = new Date().getMonth() + 1;
      if (watchMonth && monthsCount < watchMonth) {
        monthFieldOnChange({ target: { name: monthFieldName, value: null } });
      }
    }

    if (watchYear && watchYear === lastYear) {
      const monthsCount = new Date().getMonth() + 1;
      if (watchMonth && monthsCount > watchMonth) {
        monthFieldOnChange({ target: { name: monthFieldName, value: null } });
      }
    }
  }, [watchMonth, watchYear, monthFieldName, lastYear, monthFieldOnChange]);

  return (
    <div className="relative mb-8 flex pl-6 last:mb-0 md:mb-9 xl:pl-14" data-testid={`children-${childrenIndex}`}>
      <div className="w-[3px] shrink-0 rounded-full bg-accent-a" />
      <div className="childs_wrapper w-full pl-2 xl:pl-4">
        <div
          className={`children_label -left-[11rem] w-fit self-start text-right font-bitter max-xl:mb-2 max-xl:ml-6 xl:absolute xl:mt-3 xl:w-36 ${
            monthInvalid || yearInvalid ? 'p-error' : ''
          }`}
        >
          {childNumberLabel}
        </div>
        <Dropdown
          name={genderFieldName}
          focusInputRef={genderRef}
          onChange={genderFieldOnChange}
          onBlur={genderOnBlur}
          options={genderValues}
          optionLabel="name"
          optionValue="value"
          placeholder={t(genderPlaceholder)}
          disabled={isSubmitting}
          value={watchGender}
          itemTemplate={optionTemplate}
          valueTemplate={selectedTemplate}
          className={concatClsx('gender', genderInvalid ? 'p-invalid' : '')}
          ariaLabel="gender"
          data-role="gender"
        />
        {genderInvalid && <span className="gender_error p-error ml-6 whitespace-nowrap">{t(genderError.message)}</span>}
        <Button
          textButton
          variant="secondary"
          buttonType="rounded"
          type="button"
          aria-label="remove children"
          onClick={removeChild}
          icon="pi pi-times"
          className="cross mt-1"
          data-role="close-cross"
        />
        <div className="gap h-5 xl:h-6" />
        {dateFieldsArray.map(
          ({
            fieldName,
            fieldLabel,
            placeholder,
            fieldValues,
            fieldType,
            fieldValue,
            fieldOnChange,
            ariaLabel,
            fieldRef,
            fieldOnBlur,
            invalid,
            error,
          }) => (
            <div key={fieldName} className={fieldType}>
              <label>
                <div className={concatClsx('mb-2 ml-6 font-bitter', invalid && 'p-error')}>{t(fieldLabel)}</div>
                <Dropdown
                  name={fieldName}
                  options={fieldValues}
                  placeholder={placeholder}
                  value={fieldValue}
                  onChange={fieldOnChange}
                  onBlur={fieldOnBlur}
                  focusInputRef={fieldRef}
                  disabled={isSubmitting}
                  optionLabel="name"
                  optionValue="value"
                  ariaLabel={ariaLabel}
                  data-role={fieldName}
                  className={invalid ? 'p-invalid' : undefined}
                />
              </label>
              {invalid && <span className="p-error ml-6 whitespace-nowrap">{t(error.message)}</span>}
            </div>
          )
        )}
      </div>
    </div>
  );
}
