import { safelyAwait } from '@services/promises';
import { attemptToUseGeoLocation } from '@services/geo';
import { reportError } from '@model/features/errors';
import { postAcceptanceActionsResponseAsyncThunk } from '@model/features/user/asyncActions';

export const obtainAndReportGeoCoordsAction = url => async dispatch => {
  const [geolocationPositionError, geolocationPosition] = await safelyAwait(attemptToUseGeoLocation());
  if (geolocationPositionError) {
    const { code } = geolocationPositionError || {};
    // https://developer.mozilla.org/en-US/docs/Web/API/GeolocationPositionError
    switch (code) {
      case 1: {
        /*
         * PERMISSION_DENIED:
         * The acquisition of the geolocation information failed because the page didn't have
         * the necessary permissions, for example because it is blocked by a Permissions Policy
         */
        dispatch(
          reportError({
            message: `The acquisition of the geolocation information failed because the page didn't have the necessary permissions`,
          })
        );
        return null;
      }
      case 2: {
        /*
        // POSITION_UNAVAILABLE: //
        The acquisition of the geolocation failed because at least one internal source of position returned an internal error.
         */
        dispatch(
          reportError({
            message: 'The acquisition of the geolocation failed because position is unavailable',
          })
        );
        return null;
      }
      case 3: {
        /*
        // TIMEOUT
        The time allowed to acquire the geolocation was reached before the information was obtained.
         */
        dispatch(
          reportError({
            message: 'It took too long to acquire the geolocation',
          })
        );
        return null;
      }
      default: {
        /*
        Geolocation is not supported by your browser
         */
        dispatch(
          reportError({
            message: 'Geolocation is not supported by your browser',
          })
        );
        return null;
      }
    }
  }

  const { accuracy, altitude, altitudeAccuracy, heading, latitude, longitude, speed } =
    geolocationPosition?.coords ?? {};
  const repackedGeoPosition = { accuracy, altitude, altitudeAccuracy, heading, latitude, longitude, speed };

  return dispatch(
    postAcceptanceActionsResponseAsyncThunk({
      url,
      body: repackedGeoPosition,
    })
  );
};
