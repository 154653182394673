import { logged } from '@services/diagnostics';

export const CONST_FORM_ERROR = 'CONST_FORM_ERROR';

export function interpretSubmissionResults(clearErrors, setError, data) {
  return result => {
    clearErrors();
    if (!result) {
      return;
    }
    breakDownResultToErrors(result, data).forEach(errors => {
      errors.forEach(({ name, ...rest }) => {
        setError(name, { ...rest });
      });
    });
    if (result !== Object(result)) {
      return;
    }
    if (CONST_FORM_ERROR in result) {
      setError(CONST_FORM_ERROR, { type: 'form', message: result[CONST_FORM_ERROR] });
    }
  };
}

function breakDownResultToErrors(errorEnvelope, data) {
  const { name, cause, /*url,*/ code, response /*error*/ } = errorEnvelope;
  if (name || cause || !response) {
    return [];
  }
  if (code < 400) {
    return [];
  }
  const { errors = [] } = response;
  return logged(errors.map(interpretSingleError(data)), 'breakDownResultToErrors results');
}

function interpretSingleError(dataLookup) {
  void dataLookup; // TODO: this might be needed later

  return function _interpretSingleErrorMapperFn(singleError, idx, errors) {
    void idx;
    void errors;

    const { /*code,*/ context, error, /*exception,*/ message } = singleError;

    const contextKeys = Object.keys(context);
    //No user found
    if (0 === contextKeys.length) {
      switch (error) {
        case 'InvalidValue': {
          if ('errors.user_is_already_registered' === message) {
            return [
              {
                name: 'email',
                message,
                type: 'server',
              },
            ];
          }
          break;
        }
        case 'InvalidCredentials': {
          if ('No user found' === message) {
            return [
              {
                name: 'email',
                message,
                type: 'server',
              },
            ];
          }
          return [
            {
              name: 'email',
              message,
              type: 'server',
            },
            {
              name: 'password',
              message,
              type: 'server',
            },
          ];
        }
        default: {
          break;
        }
      }
    }

    const key = context.field ?? (1 === contextKeys.length ? contextKeys[0] : CONST_FORM_ERROR);
    return [
      {
        name: key,
        message,
        context,
        type: 'server',
      },
    ];
  };
}
