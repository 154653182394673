import { BALOONS_PATH } from '@/constants';
import { concatClsx } from '@/utils/classnames';
import { useLocaleServices } from '@ui/contextProviders/Locale';
import { Picture } from '@ui/elements/Picture';
import { RouterLink } from '@ui/lib/Link/AppLink';
import { paths } from '@ui/navigation';
import { FC } from 'react';

export const ForbidenSurveysBanner: FC<PropsWithClassName> = ({ className }) => {
  const { t } = useLocaleServices();
  return (
    <div
      className={concatClsx(
        'flex w-full max-w-[1210px] items-center rounded-3xl bg-dark-b max-md:flex-col md:ml-6 md:py-6 lg:py-12',
        className
      )}
    >
      <Picture
        className="w-4/5 max-md:-translate-y-8 md:w-auto md:-translate-x-12 lg:-my-20 lg:w-[735px] xl:flex-shrink-0"
        imageClassName="w-full"
        src={`${BALOONS_PATH}/majestic_monarch_butterfly.png`}
        srcSet={`${BALOONS_PATH}/majestic_monarch_butterfly.webp`}
      />
      <div className="px-6 max-md:pb-6 max-md:text-center lg:pl-0 lg:pr-12">
        <div className="font-bitter text-title-l font-bold text-accent-a">{t('dashboard.surveys.update_profile')}</div>
        <div className="mb-14 mt-6 text-body-l text-light-d">{t('dashboard.surveys.update_profile_subtitle')}</div>
        <RouterLink to={paths.toDemographics} button big className="whitespace-nowrap">
          {t('dashboard.surveys.update_profile_button')}
        </RouterLink>
      </div>
    </div>
  );
};
