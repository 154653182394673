import { makeConnectedFieldNames, makeDependentsMap } from '@services/fields/makeDependentsMap';

export function updatedDependenciesLookup(state, fields) {
  if (!fields) {
    state.dependentsMap = null;
    state.connectedFieldNamesLookup = null;
    return;
  }
  const dependentsMap = makeDependentsMap(fields);
  state.dependentsMap = dependentsMap;
  const connectedFieldNamesMaker = makeConnectedFieldNames(dependentsMap);
  state.connectedFieldNamesLookup = Object.fromEntries(
    fields.map(({ name }) => [name, connectedFieldNamesMaker(name)])
  );
}
