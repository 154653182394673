import { clearProfilingFormError } from '@model/features/registration';
import { useLocaleServices } from '@ui/contextProviders';
import { useDependentValuesLoaderWithEffect } from '@ui/elements/forms/controlledDropdown.hooks';
import { useAppDispatch, useAppSelector } from '@ui/hooks/redux';
import { Dropdown } from 'primereact/dropdown';
import PropTypes from 'prop-types';
import { useEffect, useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

ControlledDropdown.propTypes = {
  name: PropTypes.string,
  parentName: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  values: PropTypes.array,
  defaultValue: PropTypes.any,
  options: PropTypes.object,
  metadata: PropTypes.object,
  values_from: PropTypes.string,
  labels: PropTypes.object,
};

export function ControlledDropdown({
  name,
  parentName,
  defaultValue,
  placeholder: propsPlaceholder,
  label,
  values,
  options,
  values_from,
  labels,
}) {
  const {
    submitOnChange,
    depends_on,
    hide_when_no_choice,
    value_key,
    label_key,
    label_i18n: needTranslate,
  } = options || {};
  const { placeholder: labelsPlaceholder } = labels || {};
  const placeholder = propsPlaceholder || labelsPlaceholder || '';
  const { control, forceSubmit, register, setValue, setFieldMetadata, formId } = useFormContext();
  const dispatch = useAppDispatch();
  const { t } = useLocaleServices();

  const { profilingFormError } = useAppSelector(state => state.registration);

  const dependsOn = useMemo(
    () => (Array.isArray(depends_on) ? depends_on.map(item => `${parentName}.${item}`) : depends_on),
    [depends_on, parentName]
  );
  const valuesFrom = useMemo(
    () => (parentName && values_from ? values_from.replace(/{/g, `{${parentName}.`) : values_from),
    [parentName, values_from]
  );

  const loadedValues = useDependentValuesLoaderWithEffect(
    name,
    dependsOn,
    valuesFrom,
    ({ [value_key ?? 'value']: value }) => value
  );

  const effectiveValues = loadedValues ?? values;

  const optionTemplate = option => (needTranslate ? t(option.name) : option.name);

  const selectedItemTemplate = option => {
    if (option) {
      return needTranslate ? t(option.name) : option.name;
    }
    return t(placeholder) || t(label);
  };

  useEffect(() => {
    if (null == effectiveValues) {
      return;
    }
    if (hide_when_no_choice && effectiveValues.length < 2) {
      setValue(name, 1 === effectiveValues.length ? effectiveValues[0].value : null);
      setFieldMetadata(name, state => (state.hidden ? state : Object.assign({}, state, { hidden: true })));
    } else {
      setFieldMetadata(name, state => (state?.hidden ? Object.assign({}, state, { hidden: false }) : state));
    }
  }, [effectiveValues, hide_when_no_choice, name, setValue, setFieldMetadata]);

  if (hide_when_no_choice && (effectiveValues?.length ?? 0) < 2) {
    register(name);
    return null;
  }

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { name, value, onChange }, fieldState }) => {
        const onFieldChange = event => {
          onChange(event.value);
          profilingFormError && dispatch(clearProfilingFormError());
          submitOnChange && forceSubmit?.();
        };

        return (
          <Dropdown
            id={name}
            name={name}
            form={formId}
            value={value}
            defaultValue={defaultValue ? String(defaultValue) : defaultValue}
            options={effectiveValues}
            optionLabel={label_key ?? 'name'}
            optionValue={value_key}
            placeholder={(placeholder ? t(placeholder) : t(label)) || 'Placeholder'}
            onChange={onFieldChange}
            className={`md:w-14rem w-full ${fieldState.error ? 'p-invalid' : ''}`}
            itemTemplate={optionTemplate}
            valueTemplate={selectedItemTemplate}
          />
        );
      }}
    />
  );
}
