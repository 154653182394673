import { logged } from '@services/diagnostics';

export const blockAsync = (asyncFn, description = '') => {
  const context = { lock: null };
  const cleanup = () => {
    //    console.debug(`Unlocked async call for ${description}`)
    context.lock = null;
    logged('blockAsync finalized', description);
  };
  return async (...args) => {
    if (context.lock) {
      logged('blockAsync blocked', description);
      return void 0;
    }
    logged('blockAsync started', description);
    //    console.debug(`Locked async call for ${description}`)
    (context.lock = asyncFn(...args)).then(cleanup, cleanup);
    //    forTimeout(30 * SEC_INTO_MSEC).then(cleanup).then() // timeout to unblock
    return context.lock;
  };
};
