import { GTags } from '@/constants/google_tags';
import { gTagSender } from '@services/analytics/gTagSender';
import { FC, HTMLProps } from 'react';
import { Link, LinkProps } from 'react-router-dom';

import { clsx } from '@/utils/classnames';
import './link.scss';

type AppLinkProps = {
  variant?: 'primary' | 'accent-a' | 'accent-b' | 'accent-c' | 'accent-d';
  button?: boolean;
  big?: boolean;
  tag?: GTags;
  disabled?: boolean;
};

export const AppLink: FC<AppLinkProps & HTMLProps<HTMLAnchorElement>> = ({
  variant = 'primary',
  button,
  className,
  big,
  tag,
  onClick,
  ...props
}) => (
  <a
    className={clsx(
      button ? `p-button p-button-${variant}` : `p-link ${variant}`,
      button && big ? 'p-button-lg' : undefined,
      className
    )}
    onClick={event => {
      tag && gTagSender(tag);
      onClick && onClick(event);
    }}
    data-gtag={tag}
    {...props}
  />
);

export const RouterLink: FC<AppLinkProps & LinkProps> = ({
  variant = 'primary',
  button,
  className,
  big,
  tag,
  onClick,
  disabled,
  ...props
}) => (
  <Link
    className={clsx(
      button ? `p-component p-button p-button-${variant}` : `p-link ${variant}`,
      button && big ? 'p-button-lg' : undefined,
      disabled && 'p-disabled',
      className
    )}
    onClick={event => {
      tag && gTagSender(tag);
      onClick && onClick(event);
    }}
    data-gtag={tag}
    {...props}
  />
);
