export const sharingUrls = {
  facebook: 'https://www.facebook.com/sharer/sharer.php',
  twitter: 'https://x.com/share?ref_src=twsrc%5Etfw',
  twitterBaseUrl: 'http://x.com/share',
};

export const socialUrls = {
  insta: 'https://www.instagram.com/paidviewpointcommunity/',
  facebook: 'https://www.facebook.com/paidviewpoint',
  twitter: 'https://x.com/paidviewpoint',
};

export const appUrls = {
  googlePlay: 'https://play.google.com/store/apps/details?id=com.umongous.paidviewpoint',
  appStore: 'https://apps.apple.com/app/paidviewpoint/id1578685918',
};
