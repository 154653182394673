import { GTags } from '@/constants/google_tags';
import { gTagSender } from '@services/analytics/gTagSender';
import { ButtonProps, Button as PrimeButton } from 'primereact/button';
import { classNames } from 'primereact/utils';
import { FC } from 'react';
import { ButtonVariant } from './commonTypes';

export type AppButtonProps = {
  variant?: ButtonVariant;
  textButton?: boolean;
  buttonType?: 'outlined' | 'rounded';
  big?: boolean;
  loadingLabel?: string;
  fixedSize?: boolean;
  tag?: GTags;
} & ButtonProps;

export const Button: FC<AppButtonProps> = ({
  className,
  variant = 'primary',
  type = 'button',
  buttonType,
  big,
  textButton,
  disabled,
  loading,
  loadingLabel = 'Loading',
  fixedSize,
  tag,
  onClick,
  children,
  ...props
}) => (
  <PrimeButton
    {...props}
    type={type}
    disabled={loading || disabled}
    className={classNames(className, `p-button-${variant}`, {
      'p-button-text': textButton,
      'p-button-lg': big,
      'p-button-loading': loading,
      'fixed-size': fixedSize,
      [`p-button-${buttonType}`]: buttonType,
    })}
    onClick={event => {
      tag && gTagSender(tag);
      onClick && onClick(event);
    }}
    data-gtag={tag}
  >
    {loading ? (
      <span className="p-loading-label flex items-center justify-center">
        <i className="p-button-icon p-c p-button-loading-icon p-button-icon-left pi pi-spinner pi-spin"></i>
        {loadingLabel}
      </span>
    ) : (
      children
    )}
  </PrimeButton>
);
