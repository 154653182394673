import { RecaptchaInline } from '@ui/components/Recaptcha/Recaptcha.inline';
import { useLocaleServices } from '@ui/contextProviders/Locale';
import { Button } from '@ui/lib/Button';
import { FC, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { usePersonalDataExport } from './usePersonalDataExport';

export const DownloadInfoBlock: FC<{ url: string }> = ({ url }) => {
  const [code, setCode] = useState<string | null>(null);
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const { t } = useLocaleServices();

  const [isLoading, handleDownload] = usePersonalDataExport(url);

  return (
    <div className="flex flex-col items-center pt-3 md:flex-row">
      <RecaptchaInline
        ref={recaptchaRef}
        onCode={code => {
          setCode(code);
        }}
      />
      <Button
        type="button"
        role="button"
        onClick={() => {
          if (code != null) {
            handleDownload(code);
            recaptchaRef.current?.reset();
            setCode(null);
          }
        }}
        className="max-md:mt-4 md:ml-4"
        label={t('labels.download')}
        loading={isLoading}
        disabled={null == code}
        data-role="download"
      />
    </div>
  );
};
