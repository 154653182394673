export function createExponentialBackoffGenerator(seed = 0.5, max = Infinity) {
  let delay = seed;
  return function () {
    delay = Math.min(delay * 2, max);
    return delay;
  };
}

export function createFibonacciGenerator(max = Infinity) {
  let delay = 1;
  let prev = 0;
  return function () {
    const next = prev + delay;
    prev = delay;
    delay = Math.min(next, max);
    return delay;
  };
}
