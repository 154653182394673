import { obtainAndReportGeoCoordsAction } from '@ui/elements/Banners/obtainAndReportGeoCoordsAction';

export const processGeoLocationActionCreator = actions => async dispatch => {
  if (!actions || !actions.length) {
    return;
  }
  for (const action of actions) {
    const { type, url } = action;
    switch (type) {
      case 'postGeoLocationData': {
        await dispatch(obtainAndReportGeoCoordsAction(url));
        break;
      }
      default: {
        break;
      }
    }
  }
};
