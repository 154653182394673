import { useRef, useSyncExternalStore } from 'react';

export const MEDIA_BP = Object.freeze({
  isOrGreaterThan640: '(min-width: 640px)',
  isOrGreaterThan768: '(min-width: 768px)',
  isOrSmallerThan560: '(max-width: 560px)',
  isOrGreaterThan900: '(min-width: 900px)',
  isOrGreaterThan1024: '(min-width: 1024px)',
  isOrGreaterThan1280: '(min-width: 1280px)',
  isMobile: '(max-width: 767px)',
});

export const MEDIA_PREFERS = Object.freeze({
  darkColorScheme: '(prefers-color-scheme: dark)',
});

/**
 *
 * @param {MediaQueryList} mediaQueryRef
 * @returns {function(*): function(): void}
 */
function getSubscribeForMedia(mediaQueryRef) {
  const isModernAPI = 'addEventListener' in mediaQueryRef && 'function' === typeof mediaQueryRef.addEventListener;
  return isModernAPI
    ? function subscribe(callback) {
        mediaQueryRef.addEventListener('change', callback);
        return () => {
          mediaQueryRef.removeEventListener('change', callback);
        };
      }
    : function subscribeLegacyAPI(callback) {
        mediaQueryRef.addListener(callback);
        return () => {
          mediaQueryRef.removeListener(callback);
        };
      };
}

/**
 *
 * @param {string} query
 * @returns {boolean}
 */
export function useMediaQuery(query) {
  const mediaQuery = useRef(window.matchMedia(query));
  const subscribe = useRef(getSubscribeForMedia(mediaQuery.current));

  return useSyncExternalStore(subscribe.current, () => mediaQuery.current.matches);
}
