import { FieldType } from '@model/features/settings/types';
import { array, mixed, object, string, tuple } from 'yup';

export const schemaSettingsGroup = object({
  name: string().defined(),
  label: string().required(),
  fields: array()
    .of(
      object({
        name: string().required(),
        type: mixed<FieldType>().oneOf(Object.values(FieldType)).required(),
        label: string().required(),
        labels: object(),
        options: object({
          description: string().optional(),
        }),
        value: mixed(),
      }).required()
    )
    .required(),
});

export const schemaSettingsGetResponse = object({
  data: object({
    groups: array().of(schemaSettingsGroup).required(),
  }).required(),
  // TODO: remove this optional meta field after backend fix
  meta: object({
    password: object({
      tokens: tuple([string(), string()]),
    }).optional(),
  }).optional(),
}).required();
