import { getAPIUrl } from '@services/context';
import i18next from 'i18next';
import Backend from 'i18next-http-backend';
import resourcesToBackend from 'i18next-resources-to-backend';
import { initReactI18next } from 'react-i18next';
import { getTranslations, persistTranslations } from './utils';

const data = getTranslations();

const i18nextConfig = {
  compatibilityJSON: 'v3',
  lng: 'en',
  fallbackLng: 'en',
  // debug: true,
  initImmediate: true,
  interpolation: {
    prefix: '%{',
    suffix: '}',
  },
  // Backend will load translation if not found in local storage
  backend: {
    loadPath: lngs => `${getAPIUrl()}locales/${lngs[0]}`,
    parse: data => {
      if (data) {
        const resources = JSON.parse(data)?.data;
        if (resources) {
          // Save translation to local storage
          persistTranslations(data);
        }
        return resources ?? {};
      }
      return null;
    },
  },
  react: {
    // react-i18next options
    useSuspense: true,
  },
};

if (data) {
  // add resource bundle
  i18nextConfig.resources = {
    en: {
      translation: data,
    },
  };
}

if ('true' === process.env.REACT_APP_LOCAL_I18N && 'production' !== process.env.NODE_ENV) {
  i18next.use(resourcesToBackend(language => import(`./locales/translations.${language}.json`)));
} else {
  i18next.use(Backend);
}

i18next.use(initReactI18next).init(i18nextConfig);
export { i18next };
