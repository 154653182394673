import { MagicLinkCodeActivation } from '@ui/components/MagicLinkCodeActivation';
import { useAuth, useLocaleServices, useShowError } from '@ui/contextProviders';
import { IconPlane } from '@ui/elements/icons';
import { RouterLink } from '@ui/lib/Link/AppLink';
import { routeNames } from '@ui/routes/routeNames';
import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router';

export default function LoginByCode() {
  const { t } = useLocaleServices();
  const showError = useShowError();
  const navigate = useNavigate();
  const { state } = useLocation();

  const email = state?.email;
  const codePrefix = state?.code;

  const { handleSignInEmail } = useAuth();
  const navigateBack = useCallback(() => navigate(routeNames.loginEmailIndex), [navigate]);

  const handleResend = useCallback(async () => {
    handleSignInEmail({ email }).then(res => {
      if (res.response?.data?.code_head) {
        navigate('.', { replace: true, state: { email, code: res.response.data.code_head } });
      } else {
        // TODO: Move to labels
        showError('Something went wrong. Please try again.');
        navigate(routeNames.loginEmailIndex);
      }
    });
  }, [email, handleSignInEmail, showError, navigate]);

  return (
    <div data-testid="code-container" className="w-full max-w-[900px]">
      <MagicLinkCodeActivation
        email={email}
        onNoEmail={navigateBack}
        onResendRequest={handleResend}
        headerElement={<IconPlane />}
        headerLabel={t('sign_in_email_code.we_sent_email')}
        className="flex w-full flex-col items-center pb-10"
        headerSubtitleElement={
          <div className="mt-4 text-center text-body-s text-accent-a max-sm:max-w-64 dark:text-light-d">
            {t('sign_in_email_code.resend_label')}
          </div>
        }
        inputLabel={t('sign_in_email_code.enter_code_label')}
        submitButtonLabel={t('sign_in_email_code.verify_button')}
        resendLabel={t('sign_in_email_code.resend_button')}
        codePrefix={codePrefix}
      />
      {/* TODO: Fix flex gap */}
      <div className="mb-16 flex flex-col items-center gap-4 text-body-s">
        <RouterLink to="../login" data-role="login-instead" variant="accent-b">
          {t('sign_up_email_code.use_other_email')}
        </RouterLink>
        <RouterLink to="../login/password" data-role="login-instead" variant="accent-b">
          {t('sign_in_email_code.use_password_instead')}
        </RouterLink>
      </div>
    </div>
  );
}
