import { dismissDialog } from '@model/features/dialogs';
import { WhyWindowPageData } from '@model/features/survey/types';
import { useLocaleServices } from '@ui/contextProviders/Locale';
import { useAppDispatch } from '@ui/hooks/redux';
import { Icon, Icons } from '@ui/lib/Icon';
import { RouterLink } from '@ui/lib/Link/AppLink';
import { routeNames } from '@ui/routes/routeNames';
import { FC } from 'react';

type DialogDescriptionPageProps = {
  pageData: WhyWindowPageData;
  dialogName: string;
  onBackClick?: () => void;
};

export const DialogDescriptionPage: FC<DialogDescriptionPageProps> = ({ pageData, onBackClick, dialogName }) => {
  const { t } = useLocaleServices();
  const dispatch = useAppDispatch();

  const dismissModalDialog = () => {
    dispatch(dismissDialog(dialogName));
  };

  return (
    <div className="relative pb-10 pl-16">
      <button
        className="clean-button absolute left-0 top-0"
        type="button"
        aria-label="back"
        onClick={onBackClick}
        data-role="back"
      >
        <Icon icon={Icons.Arrow} className="text-[2rem] text-accent-b" />
      </button>
      <h2 className="font-bitter text-title-l font-bold">{t(pageData.title.label)}</h2>
      <p className="mt-6 text-body-l">{t(pageData.message.label)}</p>
      <div className="mt-10">
        {pageData.paragraphs?.map((p, i) => (
          <div key={i} className="mb-6 flex">
            <div className="min-w-[1.5rem] text-title-s font-bold text-accent-a">{i + 1}.</div>
            <div className="flex flex-col text-body">
              <div className="flex">
                <div className="text-dimmed-d">Q:</div>
                <div className="ml-1 text-dark-i dark:text-light-b">{t(p.title)}</div>
              </div>
              <div className="flex">
                <div className="text-dimmed-d">A:</div>
                <div className="ml-1 text-accent-b">{t(p.message)}</div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <RouterLink
        button
        className="ml-6 mt-10"
        variant="primary"
        to={routeNames.demographicsIndex}
        onClick={dismissModalDialog}
      >
        {t('surveys.update_profile')}
      </RouterLink>
    </div>
  );
};
