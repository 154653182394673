import { createContext } from 'react';
import { i18next } from './i18n';

export type LocaleStringGetter = (key: string | [string, string], options?: { [key: string]: any }) => string;

export type LocaleContextType = {
  t: LocaleStringGetter;
};

export const LocaleContext = createContext<LocaleContextType>({
  t: i18next.t,
});
