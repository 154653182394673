/**
 * @typedef {Object} RecaptchaAPI
 * @property {(() => Promise<string>) | null} invokeRecaptcha
 */

/**
 * @type {RecaptchaAPI}
 */
export const recaptchaAPI = {
  invokeRecaptcha: null,
};
