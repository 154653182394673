import { accessor } from '@model/accessors';
import { apiGetCountriesList, apiGetGeoValidate, apiZipValidationForCountry } from '@model/api/apiCommunication';
import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunkWithTokenRefresh, serializeErrorOption } from '@services/reduxToolkit';

const name = 'geo';

export const validateZipCodeForCountryAsyncThunk = createAsyncThunkWithTokenRefresh(
  `api/${name}/validate-zip`,
  apiZipValidationForCountry,
  serializeErrorOption
);

export const apiGetCountriesListAsyncThunk = createAsyncThunkWithTokenRefresh(
  `api/${name}/countries`,
  apiGetCountriesList,
  serializeErrorOption
);

export const apiGetGeoValidateAsyncThunk = createAsyncThunkWithTokenRefresh(
  `api/${name}/validate`,
  apiGetGeoValidate,
  serializeErrorOption
);

const initialState = {};

const slice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(apiGetCountriesListAsyncThunk.fulfilled, (state, action) => {
      if (action.payload.code !== 200) {
        return;
      }
      const { data, meta } = action.payload.response;
      state.defaultCountryId = meta?.default_country_id;
      state.countries = data.map(({ code, name, ...rest }) =>
        Object({
          id: code,
          value: code,
          name,
          ...rest,
        })
      );
    });
  },
});

const defaultExport = { [name]: slice.reducer };

export default defaultExport;

export const accessGeo = accessor(name);

export const accessGeoCountriesList = () => accessGeo('countries', []);
export const accessGeoDefaultCountryId = () => accessGeo('defaultCountryId', null);
