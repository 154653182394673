import { MEDIA_BP, useMediaQuery } from '@ui/hooks';
import { PvDialog } from '@ui/lib/PvDialog';
import { Dialog } from 'primereact/dialog';
import { FC, useCallback, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { HashEnum } from '../../utils/statusMapper';
import { NewTicketForm } from '../NewTicketForm';
import { Ticket } from '../Ticket';
import TicketList from '../TicketList';

export const SupportDialog: FC = () => {
  const isMobile = useMediaQuery(MEDIA_BP.isOrSmallerThan560);
  const isLargeScreen = useMediaQuery(MEDIA_BP.isOrGreaterThan1024);
  const { hash } = useLocation();
  const navigate = useNavigate();
  const dialogRef = useRef<Dialog | null>(null);

  const url = hash.split('/')[1];
  const visible = hash.startsWith('#support');

  const handleHide = useCallback(() => {
    navigate(window.location.pathname, { replace: true });
  }, [navigate]);

  return (
    <PvDialog
      ref={dialogRef}
      visible={visible}
      onHide={handleHide}
      className="support-dialog"
      resizable={false}
      draggable={false}
      maximized={visible && isMobile}
    >
      {() => {
        switch (url) {
          case HashEnum.NEW:
            return <NewTicketForm dialogRef={dialogRef} isMobile={!isLargeScreen} />;
          case HashEnum.TICKET:
            return <Ticket dialogRef={dialogRef} isMobile={!isLargeScreen} />;
          default:
            return <TicketList />;
        }
      }}
    </PvDialog>
  );
};
