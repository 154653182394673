/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */
// legacy code
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
import { Pvec } from './evercookie'
import { Logger } from './logger'

const logger = new Logger('DID')

const context = window.context || {}
window.context = context

class DID {
  create() {
    try {
      return `PVDID-${Date.now().toString(36).toUpperCase()}-${Math.round(Math.random() * Math.pow(10, 16))
        .toString(36)
        .toUpperCase()}`
    } catch (error) {
      logger.error('create', error)
      SentryLogger.setExtra('did', 'createDid').send(error)
      return ''
    }
  }

  select(dids) {
    try {
      let lastDidTime = 0
      let lastDid = ''
      let i = 0

      while (i < dids.length) {
        const didParts = dids[i].split('-', 3)
        if ('PVDID' === didParts[0]) {
          const didTime = parseInt(didParts[1], 36)
          if (lastDidTime < didTime) {
            lastDidTime = didTime
            lastDid = dids[i]
          }
        }
        i++
      }
      return lastDid
    } catch (error) {
      logger.error('select', error)
      SentryLogger.setExtra('did', 'getDid').send(error)
      return ''
    }
  }

  async setContext() {
    if ('PVDID' === context?.did?.slice(0, 5)) {
      return context.did
    }
    return new Promise((rs, rj) => {
      try {
        const ec = new Pvec()
        return ec.get('pvdid', function (best, all) {
          const dids = []
          for (const d in all) {
            if ('string' === typeof all[d] && 'PVDID' === all[d].slice(0, 5)) {
              dids.push(all[d])
            }
          }
          let id = ''
          if (dids.length > 0) {
            id = didManager.select(dids)
          }
          if (0 === id.length) {
            id = didManager.create()
            logger.debug('id', id)
            ec.set('pvdid', id)
          }
          //        if (typeof context === 'undefined' || context === null) { window.context = {}; }
          context.did = id
          rs(id)
        })
      } catch (error) {
        logger.error('setContext', error)
        SentryLogger.setExtra('did', 'setContext').send(error)
        rj(error)
      }
    })
  }
}

const didManager = new DID()

window.did = didManager

export { context, didManager }
