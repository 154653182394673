import { signOutThunk } from '@model/actions/signoutAction';
import { accessCloseAccountStage, updateCloseAccountStage } from '@model/features/user';
import { userDeleteAccountAsyncThunk } from '@model/features/user/asyncActions';
import { ConfirmState } from '@model/features/user/types';
import { safelyAwait } from '@services/promises';
import { useLocaleServices } from '@ui/contextProviders';
import { useAppDispatch, useAppSelector } from '@ui/hooks/redux';
import { Button } from '@ui/lib/Button';
import { ButtonVariant } from '@ui/lib/commonTypes';
import { routeNames } from '@ui/routes/routeNames';
import { isSuccessResponse } from '@ui/support/utils/guards';
import { classNames } from 'primereact/utils';
import { useState } from 'react';
import { RegistrationCloseAccountConfirm } from './RegistrationCloseAccountConfirm';

export type RegistrationCloseAccountLabelsType = Readonly<{
  label: string;
  warning: string;
  hint: string;
  confirm: string;
  cancel: string;
}>;

export const defaultCloseLabels: RegistrationCloseAccountLabelsType = {
  label: 'close_account.label',
  warning: 'close_account.warning',
  hint: 'close_account.hint',
  confirm: 'close_account.confirm',
  cancel: 'close_account.cancel',
} as const;

export function RegistrationCloseAccount({
  className,
  label,
  variant = 'secondary',
  textButton,
  testId,
}: {
  className?: string;
  textButton?: boolean;
  label?: string;
  variant?: ButtonVariant;
  testId?: string;
}) {
  const { t } = useLocaleServices();
  const dispatch = useAppDispatch();
  const [accountClosingIndicator, setAccountClosingIndicator] = useState<boolean>(false);

  const showConfirm = useAppSelector(accessCloseAccountStage());
  const setShowConfirm = (stage: ConfirmState) => {
    dispatch(updateCloseAccountStage(stage));
  };

  const handleDeleteAcc = async () => {
    setAccountClosingIndicator(true);
    const [_, result] = await safelyAwait(dispatch(userDeleteAccountAsyncThunk()).unwrap());
    setAccountClosingIndicator(false);
    setShowConfirm(0);
    if (result != null && isSuccessResponse(result.response)) {
      await dispatch(signOutThunk());
      window.navigate(routeNames.root);
    }
  };

  return (
    <div
      className={
        className ||
        classNames({
          'mt-10': 0 === showConfirm,
          'mt-0': 0 !== showConfirm,
        })
      }
    >
      {0 === showConfirm && (
        <Button
          type="button"
          textButton={textButton}
          variant={variant}
          className={classNames('-ml-3', {
            'py-0 text-base': textButton,
          })}
          role="button"
          onClick={() => setShowConfirm(1)}
          label={t(label ?? defaultCloseLabels.label)}
          data-role="close account"
          data-testid={testId}
        />
      )}
      {1 === showConfirm && (
        <RegistrationCloseAccountConfirm
          labels={defaultCloseLabels}
          onCancel={() => setShowConfirm(0)}
          onSubmit={handleDeleteAcc}
          disabled={accountClosingIndicator}
        />
      )}
    </div>
  );
}
