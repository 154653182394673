import { SurveyItem } from '@model/features/survey/types';
import { formatCurrency } from '@services/currency';
import { useLocaleServices } from '@ui/contextProviders/Locale';
import { Icon, Icons } from '@ui/lib/Icon';
import { RouterLink } from '@ui/lib/RouterLink';
import { paths } from '@ui/navigation';
import { ProgressBar } from 'primereact/progressbar';
import { classNames } from 'primereact/utils';
import { FC } from 'react';

export const MobileSurveyItem: FC<{ survey: SurveyItem; tablet?: boolean }> = ({ survey, tablet: isTablet }) => {
  const { t } = useLocaleServices();

  const {
    id, //: "bb1b4c65bd8c6f2e",
    enabled: isEnabled, //: true,
    incentive_cents: incentiveCents, //: 343,
    incentive_percent: incentivePercent, //: 82,
    loi_seconds: loiSeconds, //: 150,
    loi_percent: loiPercent, //: 25,
    button: {
      label: buttonLabel,
      emphasis: buttonEmphasis, // 'primary'|'secondary'
    },
  } = survey;

  const loiSecondsToOneDecimal = loiSeconds ? (loiSeconds / 60).toFixed() : 0;

  // TODO: update after set theme variables
  return (
    <>
      <div className="grid grid-cols-[auto,1fr] gap-x-6 gap-y-4 max-md:sibling:mt-4 md:grid-cols-[auto,1fr,auto,1fr] md:items-center md:rounded-full md:bg-light-e md:px-8 dark:md:bg-dark-h">
        <Icon
          icon={Icons.DollarCircle}
          className="text-[24px] leading-[14px] text-accent-b max-md:-translate-y-[2px] max-md:self-end"
        />
        <div className="flex flex-1 flex-col">
          <span className="font-bitter text-body-l max-md:text-dark-a dark:max-md:text-light-b">
            up to <span className="font-bold">{formatCurrency(incentiveCents ?? 0, 'US')}</span>
          </span>
          {!isTablet && (
            <ProgressBar
              value={incentivePercent}
              aria-label="money"
              showValue={false}
              className="p-progressbar-accent-b mt-2 w-full"
            />
          )}
        </div>
        <Icon
          icon={Icons.Timer}
          className="text-[24px] leading-[14px] text-accent-a max-md:-translate-y-[2px] max-md:self-end"
        />
        <div className="flex flex-1 flex-col">
          <span className="font-bitter text-body-l max-md:text-dark-a dark:max-md:text-light-b">
            <span className="font-bold">~ {loiSecondsToOneDecimal}</span> min
          </span>
          {!isTablet && <ProgressBar value={loiPercent} aria-label="time" showValue={false} className="mt-2 w-full" />}
        </div>
      </div>
      <RouterLink
        to={paths.toSurveyCode(id)}
        tabIndex={0}
        disabled={!isEnabled}
        variant={'primary' === buttonEmphasis ? 'accent-a' : 'accent-b'}
        buttonLink
        className={classNames('peer justify-self-center whitespace-nowrap')}
      >
        {t(buttonLabel)}
      </RouterLink>
    </>
  );
};
