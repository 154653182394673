import { get } from '@services/objects/get';
import { AppLink, RouterLink } from '@ui/lib/Link/AppLink';
import i18next from 'i18next';
import { AnchorHTMLAttributes, ComponentType, DetailedHTMLProps } from 'react';
import { ReactMarkdownProps } from 'react-markdown/lib/complex-types';

const SPECIAL_LINK = 'special_link';

export const linkRenderer: ComponentType<
  Omit<DetailedHTMLProps<AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>, 'ref'> & ReactMarkdownProps
> = ({ href, children, title, node, ...props }) => {
  if (null == href) {
    return null;
  }

  if (href.startsWith('http')) {
    return (
      <AppLink className="md-link" href={href} {...props} data-role="link" rel="noreferrer">
        {children}
      </AppLink>
    );
  }
  if (SPECIAL_LINK === title && href != null) {
    const path = href.split('/').slice(1);
    const linkData = get<{
      label: string;
      url: string;
    }>(window, path);

    if (!linkData) {
      return null;
    }

    const defaultLabel = i18next.t<string>(linkData.label);
    const label = children ?? defaultLabel;
    return (
      <AppLink
        className="md-link"
        href={linkData.url}
        target="_blank"
        {...props}
        title={defaultLabel}
        data-role="special-link"
        rel="noreferrer"
      >
        {label}
      </AppLink>
    );
  }
  return (
    <RouterLink className="md-link" to={href as string} {...props} data-role="router-link">
      {children}
    </RouterLink>
  );
};

export const routerLinkRenderer: ComponentType<
  Omit<DetailedHTMLProps<AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>, 'ref'> & ReactMarkdownProps
> = ({ href, children, title, node, ...props }) => (
  <RouterLink className="md-link" to={href as string} {...props} data-role="router-link">
    {children}
  </RouterLink>
);
