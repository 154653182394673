export function objectWalker(fn, treatArraysAsObjects) {
  return function _recursiveWalk(obj, path = '') {
    if (Object(obj) !== obj) {
      if (!path) {
        return void 0;
      }
      return fn(path, obj);
    } else if (Array.isArray(obj) && !treatArraysAsObjects) {
      for (let i = 0, iMax = obj.length; i < iMax; i++) {
        _recursiveWalk(obj[i], `${path}[${String(i)}]`);
      }
    } else {
      const keys = Object.keys(obj);
      for (let i = 0, iMax = keys.length; i < iMax; i++) {
        _recursiveWalk(obj[keys[i]], path ? `${path}.${keys[i]}` : keys[i]);
      }
    }
    return void 0;
  };
}

export function flattenObject(obj, options = {}) {
  const result = {};
  const { treatArraysAsObjects } = options;
  objectWalker((path, val) => Object.assign(result, { [path]: val }), treatArraysAsObjects)(obj);
  return result;
}

export function serializableOnly(object) {
  const set = new Set();
  return JSON.parse(
    JSON.stringify(object, function (_key, value) {
      if (value === Object(value)) {
        if (set.has(value)) {
          return null;
        }
        set.add(value);
      }
      return value;
    })
  );
}

/**
 * Check if object is empty
 * @param {Object} object
 * @returns {Boolean}
 */
export function isEmpty(object) {
  for (const _key in object) return false;
  return true;
}
