import { getYear } from '@services/calendar';
import { FIELD_TYPES } from '@services/fields';

const currentYear = getYear();
const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const presetDateFields = [
  {
    name: 'month',
    label: 'profiling.birthdate.month',
    type: FIELD_TYPES.DROPDOWN_CLEAN,
    options: { placeholder: 'Month' },
    values: Array.from({ length: 12 }, (_, idx) => {
      return {
        value: idx,
        name: MONTHS[idx],
      };
    }),
  },
  {
    name: 'date',
    label: 'profiling.birthdate.day',
    type: FIELD_TYPES.DROPDOWN_CLEAN,
    options: { placeholder: 'Day', depends_on: ['year', 'month'] },
    values: Array.from({ length: 31 }, (_, idx) => {
      return {
        value: idx + 1,
        name: String(idx + 1),
      };
    }),
  },
  {
    name: 'year',
    label: 'profiling.birthdate.year',
    type: FIELD_TYPES.DROPDOWN_CLEAN,
    options: { placeholder: 'Year' },
    values: Array.from({ length: 150 }, (_, idx) => {
      const year = currentYear - idx;
      return {
        value: year,
        name: String(year),
      };
    }),
  },
] as const;
