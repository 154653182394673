import * as yup from 'yup';

export const schemaUnsubscribePostRequest = yup
  .object({
    code: yup.string(),
    mailer: yup.string(),
    template: yup.string(),
    email: yup.string(),
  })
  .required();

export const schemaResubscribePostRequest = yup
  .object({
    code: yup.string(),
    mailer: yup.string(),
    template: yup.string(),
    email: yup.string(),
  })
  .required();
