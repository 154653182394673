import { getTenantStaticResourceUrl } from '@/utils/tenant';
import { getPvContext } from '@services/context';
import { useLocaleServices } from '@ui/contextProviders';
import { IconSprite } from '@ui/elements';
import { getDistanceInWordsToNow } from '@ui/support/utils/time';
import { Avatar } from 'primereact/avatar';
import { Image } from 'primereact/image';
import { FC } from 'react';
import { SupportTicketComment } from '../../api/schemas';
import styles from './ChatBubble.module.scss';

type ChatBubbleProps = {
  item: SupportTicketComment;
  avatarUrl?: string;
};

export const ChatBubble: FC<ChatBubbleProps> = ({ item, avatarUrl }) => {
  let apiBaseUrl = getPvContext('api_base_url');
  if (!apiBaseUrl) {
    apiBaseUrl = `https://${getPvContext('api_host')}`;
  }
  const { t } = useLocaleServices();

  const charBubbleContent = (
    <div>
      <div className={styles.bubble}>
        <div className={styles.comment}>{item.content}</div>
        {getDistanceInWordsToNow(new Date(item.created_at), t, t('labels.ago'))}
      </div>
      {item.images.length > 0 && (
        <div className={styles.images}>
          {item.images.map(image => (
            <Image
              key={image.thumb50_url}
              src={`${apiBaseUrl}${image.thumb50_url}`}
              zoomSrc={`${apiBaseUrl}${image.original_url}`}
              preview
            />
          ))}
        </div>
      )}
    </div>
  );

  return (
    <div className={item.is_admin ? styles.chatBubbleAdmin : styles.chatBubbleUser}>
      {item.is_admin ? (
        <>
          <Avatar
            icon={<IconSprite iconId="pv-logo-small-beta" className={styles.avatarIcon} />}
            size="normal"
            shape="circle"
          />
          {charBubbleContent}
        </>
      ) : (
        <>
          {charBubbleContent}
          <Avatar
            image={avatarUrl}
            size="normal"
            shape="circle"
            imageFallback={getTenantStaticResourceUrl('images/user/default_thumb.png')}
          />
        </>
      )}
    </div>
  );
};
