import { useFormContext } from 'react-hook-form';
import PropTypes from 'prop-types';
import { useLocaleServices } from '@ui/contextProviders';
import { MultiSelect } from 'primereact/multiselect';

MultiselectComponent.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  values: PropTypes.array,
  defaultValue: PropTypes.any,
  options: PropTypes.object,
  labels: PropTypes.object,
};

export function MultiselectComponent({ name, defaultValue, label, values, options, labels }) {
  const { placeholder: optionsPlaceholder, value_key, label_key } = options || {};
  const { t } = useLocaleServices();
  const { placeholder: labelsPlaceholder } = labels || {};
  const placeholder = optionsPlaceholder || labelsPlaceholder || '';

  const { register, formId, getFieldState, watch } = useFormContext();
  const { error } = getFieldState(name);
  const { onChange } = register(name);

  const watchField = watch(name) || defaultValue;

  const showSelectAllCheckbox = !['demographic_race_v2'].includes(name);

  return (
    <MultiSelect
      onChange={onChange}
      name={name}
      id={name}
      form={formId}
      options={values}
      value={watchField}
      optionLabel={label_key ?? 'name'}
      optionValue={value_key}
      placeholder={label ? t(label) : placeholder}
      showSelectAll={showSelectAllCheckbox}
      className={`w-full ${error ? 'p-invalid' : ''}`}
    />
  );
}
