export const FIELD_VALIDATIONS = {
  REQUIRED: 'required',
  MIN: 'min',
  MAX: 'max',
  MIN_AGE: 'min_age',
  MAX_AGE: 'max_age',
  PATTERN: 'pattern',
  EMAIL: 'email',
  EQUAL_TO: 'equal_to',
  DEPENDS_ON: 'depends_on',
  CUSTOM_VALIDATIONS: 'custom_validations',
} as const;
