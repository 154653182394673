import { FIELD_VALIDATIONS } from './fieldValidationTypes';

const fieldValidationKeysSet = new Set(Object.values(FIELD_VALIDATIONS));

export function onlyRequestedValidations(options = {}) {
  if (Object(options) !== options) {
    return {};
  }
  if (Object.keys(options).length < fieldValidationKeysSet.size) {
    return Object.entries(options).reduce((memo, [key, value]) => {
      if (fieldValidationKeysSet.has(key)) {
        memo[key] = value;
      }
      return memo;
    }, {});
  }
  return Array.from(fieldValidationKeysSet.values()).reduce((memo, keyName) => {
    if (keyName in options) {
      memo[keyName] = options[keyName];
    }
    return memo;
  }, {});
}
