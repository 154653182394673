import { ErrorLogger } from '@/utils/ErrorLogger';
import { Logger } from '@/utils/Logger';
import { apiPushSubscription } from '@model/api/apiCommunication';
import { captureException } from '@sentry/react';
import { FirebaseApp, initializeApp } from 'firebase/app';
import { Messaging, getMessaging, getToken, isSupported, onMessage } from 'firebase/messaging';
import { showNotification } from './notification';

let activeRegistration: ServiceWorkerRegistration | undefined;
let firebaseApp: FirebaseApp | undefined;
let messaging: Messaging | undefined;

export async function initFirebase(registration: ServiceWorkerRegistration) {
  const isFcmSupported = await isSupported();
  if (!isFcmSupported) {
    throw new Error('FCM Not supported');
  }

  let config = window.context.firebase_config;

  if ('string' === typeof config) {
    try {
      config = JSON.parse(config);
    } catch (error) {
      ErrorLogger.setExtra('fcm', 'Invalid Firebase config').send(error);
    }
  }

  Logger.info('Init Firebase Cloud Messaging', config);
  activeRegistration = registration;
  firebaseApp = initializeApp(config);
  messaging = getMessaging(firebaseApp);

  onMessage(messaging, payload => {
    Logger.info('firebase message', payload);
    if (payload.notification) {
      showNotification(payload.notification, payload.data);
    }
  });
}

export async function subscribeToNotifications(did: string) {
  if (!firebaseApp || !messaging) {
    captureException(new Error('Firebase not initialized'));
    return null;
  }

  try {
    const token = await getToken(messaging, {
      serviceWorkerRegistration: activeRegistration,
    });

    await apiPushSubscription({
      token,
      did,
    });
    Logger.info('FCM Token obtained', token);
    return token;
  } catch (error) {
    Logger.error('Error obtaining FCM token', error);
    ErrorLogger.setExtra('fcm', 'Error obtaining FCM token').send(error);
    throw error;
  }
}
