import { UserStatus } from '@/api/user/user-status';
import { AppDispatch } from '@/store';
import { signOutThunk } from '@model/actions/signoutAction';
import { CLIENT_ERRORS, EndpointError } from '@model/api/endpointError';
import { resetDialog } from '@model/features/dialogs';
import { DIALOG_NAMES } from '@model/features/dialogs/dialogNames';
import { reportError } from '@model/features/errors';
import { updateUserStatus } from '@model/features/user';
import { handleImperativeActionThunk } from '@model/middleware/imperativeAction';
import { logged } from '@services/diagnostics';
import { setRedirectPath } from '@ui/routes/ProtectedRoute';
import { routeValues, toErrorPage } from '@ui/routes/routeNames';
import { isFailureResponse } from '@ui/support/utils/guards';
import { Action, Middleware } from 'redux';

// TODO: brainstorm type hinting for middleware
export const unexpectedAPIResponseListener: Middleware<unknown, unknown> = storeApi => next => action => {
  if (!isAPIAction(action)) {
    return next(action);
  }
  if (action.meta?.requestStatus !== 'rejected' && action.meta?.requestStatus !== 'fulfilled') {
    return next(action);
  }

  if (action.payload?.response && isFailureResponse(action.payload.response)) {
    const errorObject = action.payload.response.errors[0];

    if (451 === errorObject.code) {
      const dispatch = storeApi.dispatch as AppDispatch;
      const { min_age } = errorObject.context;

      const minor = async () => {
        dispatch(updateUserStatus({ status: UserStatus.MINOR_PROBLEM, statusData: min_age }));
        await dispatch(signOutThunk());
        window.navigate(`${routeValues.errorMinorProblem}?min_age=${min_age}`, { replace: true });
        dispatch(resetDialog(DIALOG_NAMES.FILL_PROFILE));
      };

      minor();
    }
  }

  const error = action.meta?.rejectedWithValue ? action.payload : action.error;

  if (EndpointError.isEndpointError(error) && error.cause === CLIENT_ERRORS.SERVER_UNEXPECTED_STATUS_CODE) {
    const dispatch = storeApi.dispatch as AppDispatch;
    switch (error.code) {
      case 401: {
        logged(action, 'Unhandled 401. Sign out');
        setRedirectPath({
          state: {
            from: window.location.href,
            reauth: action.payload?.redirectToReAuth || action.error?.redirectToReAuth,
          },
        });
        dispatch(signOutThunk());
        return null;
      }
      case 403: {
        const imperativeAction = error?.response?.meta?.imperative_action;
        if (imperativeAction) {
          handleImperativeActionThunk(imperativeAction);
        }
        break;
      }
      case 429: {
        window.navigate(toErrorPage('429'), { state: { from: window.location.href } });
        return null;
      }
      // case 451: {
      //   const min_age = action.payload.response?.errors?.[0]?.context?.min_age ?? 0;
      //   dispatch(updateUserStatus({ status: UserStatus.MINOR_PROBLEM, statusData: min_age }));
      //   dispatch(recordMinorAge(min_age));
      //   window.navigate(routeValues.errorMinorProblem);
      //   return null;
      // }
      case 498: {
        // Falls here if user dismissed captcha
        window.navigate(routeValues.errorCaptcha);
        break;
      }
      default: {
        dispatch(reportError(action.payload));
      }
    }
  }
  return next(action);
};

function isAPIAction(action: Action) {
  return /^api\//.test(action.type);
}
