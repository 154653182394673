import { ErrorLogger } from '@/utils/ErrorLogger';

/**
 *
 * @param name
 * @return {string}
 */
export const ensureEnvVar = name => {
  if (null == name) {
    throw new Error(`parameter 'name' is not defined`);
  }
  if (process.env[name] === undefined) {
    throw new Error(`Environment variable '${name}' is not set`);
  }
  return process.env[name];
};

/**
 *
 * @param {string} name
 * @param {void|string} fallback
 * @return {string}
 */
export const safelyEnsureEnvVar = (name, fallback) => process.env[name] ?? fallback;

const unwrapSyncResult =
  fn =>
  (...args) => {
    try {
      return [null, fn(...args)];
    } catch (error) {
      ErrorLogger.setFnName('unwrapSyncResult').send(error);
      return [error, args];
    }
  };

/**
 *
 */
export const ensureEnvVars = (...names) => {
  const result = names.map(unwrapSyncResult(ensureEnvVar));
  const [errors, results] = result.reduce(
    ([errors, results], [error, result]) =>
      error ? [[...errors, result[0]], results] : [errors, [...results, result]],
    [[], []]
  );
  if (errors.length) {
    throw new Error(`These environment variables are not set: ${errors.map(str => `'${str}'`).join()}`);
  }
  return results;
};
