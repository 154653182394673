import { makeControlledPromise } from '@services/promises';
import { Recaptcha } from '@ui/components/Recaptcha';
import React, { useCallback, useContext, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { recaptchaAPI } from './recaptchaAPI';
import PropTypes from 'prop-types';

const CaptchaContext = React.createContext(null);

CaptchaProvider.propTypes = {
  children: PropTypes.any,
};

export function CaptchaProvider({ children }) {
  const [captchaKey, setCaptchaKey] = useState(0);
  const [showRecaptcha, setShowRecaptcha] = useState(false);
  const recaptchaLifetime = useRef(null);

  const handleRecaptchaCode = useCallback(code => {
    if (!recaptchaLifetime.current) {
      return;
    }
    recaptchaLifetime.current.resolve(code);
  }, []);

  const handleRecaptchaClose = useCallback(() => {
    handleRecaptchaCode(null);
    setCaptchaKey(captchaKey + 1);
    setShowRecaptcha(false);
    recaptchaLifetime.current = null;
  }, [captchaKey, handleRecaptchaCode]);

  const invokeRecaptcha = useCallback(async () => {
    setShowRecaptcha(true);
    recaptchaLifetime.current = makeControlledPromise(recaptchaLifetime.current); // || makeControlledPromise()
    const code = await recaptchaLifetime.current;
    handleRecaptchaClose();
    return code;
  }, [handleRecaptchaClose]);

  const contextValue = useMemo(() => {
    return {
      invokeRecaptcha,
    };
  }, [invokeRecaptcha]);

  useLayoutEffect(() => {
    recaptchaAPI.invokeRecaptcha = invokeRecaptcha;
    return () => {
      recaptchaAPI.invokeRecaptcha = null;
    };
  }, [invokeRecaptcha]);

  return (
    <CaptchaContext.Provider value={contextValue}>
      <Recaptcha
        key={`r_${captchaKey}`}
        show={showRecaptcha}
        onHide={handleRecaptchaClose}
        onCommit={handleRecaptchaCode}
      />
      {children}
    </CaptchaContext.Provider>
  );
}

export const useInvokeCaptcha = () => {
  const result = useContext(CaptchaContext);
  if (!result) {
    throw new Error(
      `Invalid usage. 'useCaptchaContext()' needs to be called in elements descending from <CaptchaProvider />`
    );
  }
  const { invokeRecaptcha } = result;
  return invokeRecaptcha;
};
