import { maskEmail } from '@/utils/string/email';
import { createSlice } from '@reduxjs/toolkit';
import { signout } from '../auth';
import { getSettingsGroupsAsyncThunk, saveSettingsDataAsyncThunk } from './asyncActions';
import { FieldType, ICheckboxField, SettingsField, SettingsGroup, SettingsState } from './types';

const name = 'settings';

const initialState: SettingsState = {
  groups: [],
  groupsByName: {},
  primaryMail: null,
};

export const settingsSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(signout, state => {
        state.groups = [];
        state.groupsByName = {};
      })
      .addCase(getSettingsGroupsAsyncThunk.fulfilled, (state, action) => {
        state.groups = [];
        state.groupsByName = action.payload.reduce((acc, g) => {
          const name = g.name;
          state.groups.push(name);
          acc[name] = g as SettingsGroup;
          const mailfield = g.fields.find(f => f.type === FieldType.EMAIL);
          if (mailfield) {
            state.primaryMail = maskEmail(mailfield.value);
          }
          return acc;
        }, {});
      })
      .addCase(saveSettingsDataAsyncThunk.fulfilled, (state, action) => {
        // Just update values in state
        action.payload.forEach(g => {
          g.fields.forEach((f, index) => {
            const group = state.groupsByName[g.name];
            if (group) {
              const value = group.fields[index]?.value;
              if (value !== f.value) {
                if ('object' === typeof f.value) {
                  const valOld = JSON.stringify(value);
                  const valNew = JSON.stringify(f.value);
                  if (valOld !== valNew) {
                    group.fields[index] = f as SettingsField;
                  }
                } else {
                  group.fields[index] = f as SettingsField;
                }
              } else if (
                f.type === FieldType.PAYPAL_EMAIL_CHANGE &&
                // as any hack because of yup
                (f.options as any).paypal_email !== (f.options as any).pending_paypal_email
              ) {
                group.fields[index] = f as SettingsField;
              } else if (
                group.fields[index]?.type === FieldType.CHECKBOX &&
                (group.fields[index] as ICheckboxField).options.description !== f.options.description
              ) {
                group.fields[index] = f as SettingsField;
              }
            }
          });
        });
      });
  },
});

export default settingsSlice.reducer;
