import { InputText, InputTextProps } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';
import { useId } from 'react';
import { FieldPath, FieldValues, UseControllerProps, useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

type FormFieldBaseProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
  T,
> = UseControllerProps<TFieldValues, TName> &
  T & {
    label?: string;
    className?: string;
  };

export const FormInputText = <TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>>({
  label,
  name,
  rules,
  className,
  control,
  defaultValue,
  ...rest
}: FormFieldBaseProps<TFieldValues, TName, Omit<InputTextProps, 'name'>>) => {
  const { field, fieldState } = useController<TFieldValues, TName>({
    name,
    control,
    rules,
    defaultValue,
  });
  const error = fieldState.error;
  const id = useId();
  const { t } = useTranslation();
  return (
    <div className="app-form-control">
      {label && (
        <label className="flex flex-col" htmlFor={id}>
          {label}
        </label>
      )}
      <InputText
        className={classNames('form-input mt-2', className, {
          'p-invalid': error,
        })}
        aria-label={label}
        {...field}
        {...rest}
        id={id}
        aria-invalid={error ? 'true' : 'false'}
      />
      {error?.message != null && <div className="p-error">{t(error.message)}</div>}
    </div>
  );
};
