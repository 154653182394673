import { CLIENT_ERRORS } from '@model/api/endpointError';
import { reportError } from '@model/features/errors';
import { isOnErrorPage } from '@ui/navigation';
import { ERROR_PAGE, toErrorPage } from '@ui/routes/routeNames';

export const reportNetworkErrorListener = async (originalAction, { dispatch }) => {
  const from = location.pathname;
  if (isOnErrorPage(from)) {
    return;
  }
  const { rejectedWithValue } = originalAction.meta;
  const errorPayload = rejectedWithValue ? originalAction.payload : originalAction.error;

  if (errorPayload?.cause === CLIENT_ERRORS.SERVER_UNEXPECTED_RESPONSE) {
    dispatch(reportError(errorPayload));
  } else if (errorPayload?.cause === CLIENT_ERRORS.NETWORK_ERROR) {
    // to network error
    window.navigate(toErrorPage(ERROR_PAGE.NETWORK), { state: { from } });
  }
};
