import { createSlice } from '@reduxjs/toolkit';
import { unwrapResponseDataFromResolvedAction } from '@services/reduxToolkit';
import { getTimestamp } from '@services/time';
import { authenticationExchangeMagicLinkAsyncThunk } from '../magic/asyncActions';
import { MAGIC_ACTION_NAMES } from '../magic/types';
import { oauthFailureResult, oauthSuccessResult } from './actions';
import { allAuthFulfilled, allAuthPending, allAuthRejected, changePasswordAsyncThunk } from './authAsyncThunks';
import { name } from './name';

/**
 * @typedef {Object} AuthState
 * @property {string | null} token
 * @property {number} tokenTs
 * @property {string | null} refresh_token
 * @property {string | null} error
 * @property {string | null} lastErrorMessage
 * @property {boolean} authenticating
 */
/**
 * @type {AuthState}
 */
const initialState = {
  token: null,
  tokenTs: 0,
  refresh_token: null,
  authenticating: false,
  error: null,
  lastErrorMessage: null,
};

export const authSlice = createSlice({
  name,
  initialState,
  reducers: {
    signout(state) {
      state.token = undefined;
      state.refresh_token = undefined;
      state.tokenTs = undefined;
    },
    updateToken(state, action) {
      if (!action.payload) {
        return;
      }
      state.token = action.payload.token;
      state.refresh_token = action.payload.refresh_token;
      state.tokenTs = getTimestamp();
    },
  },
  extraReducers(builder) {
    builder
      .addCase(oauthSuccessResult, (state, { payload }) => {
        state.authenticating = false;
        state.error = null;
        state.lastErrorMessage = null;
        state.token = payload.token;
        state.tokenTs = getTimestamp();
      })
      .addCase(oauthFailureResult, (state, { payload }) => {
        state.authenticating = false;
        state.error = payload.message;
        state.lastErrorMessage = payload.message;
      })
      .addCase(changePasswordAsyncThunk.fulfilled, (state, action) => {
        if (action.payload?.code !== 201) {
          return;
        }
        const { tokens } = action.payload.response.meta.password;
        const [token, refresh_token] = tokens;
        state.token = token ?? null;
        state.refresh_token = refresh_token ?? null;

        state.authenticating = false;
        state.tokenTs = getTimestamp();
      })
      .addMatcher(allAuthPending, (state, _action) => {
        state.authenticating = true;
        state.token = undefined;
        state.error = null;
        state.lastErrorMessage = null;
      })
      .addMatcher(allAuthFulfilled, (state, action) => {
        const code = action.payload?.code ?? 201;
        if (code !== 201) {
          errorReducer(state, action);
          return;
        }
        const isSurveyInvitation = MAGIC_ACTION_NAMES.SURVEY_INVITATION === action.payload.response?.meta?.action;
        const { token, refresh_token } = unwrapResponseDataFromResolvedAction(action, {});

        if (isSurveyInvitation) {
          // Remain token the same if any
          state.token = state.token ?? token;
          state.refresh_token = state.refresh_token ?? refresh_token;
        } else {
          state.token = token ?? null;
          state.refresh_token = refresh_token ?? null;
        }

        state.authenticating = false;
        state.tokenTs = getTimestamp();
      })
      .addMatcher(allAuthRejected, errorReducer);
  },
});

export const { signout, updateToken } = authSlice.actions;

function errorReducer(state, action) {
  if (authenticationExchangeMagicLinkAsyncThunk.fulfilled.match(action)) {
    // we do not wipe token if magic link is unsuccessful
    return;
  }

  state.authenticating = false;
  state.token = undefined;
  state.tokenTs = undefined;
}
