import { accessor } from '@model/accessors';
import {
  STORAGE_KEY_LIST,
  STORAGE_VALUE_LIST,
  persistDataToStorage,
  removeDataFromStorage,
  restoreDataFromStorage,
} from '@services/storage';
import { name } from './name';
import { authSlice } from './slice';
export { checkIfOAuthAuthenticationThunkAction } from './authAsyncThunks';

export { changePasswordAsyncThunk, loginAsyncThunk, registerAsyncThunk } from './authAsyncThunks';

export { signout, updateToken } from './slice';

export default { [name]: authSlice.reducer };

export const accessAuthInfo = accessor(name);

export const accessAuthToken = () => accessAuthInfo('token', null);
export const accessIsAuthenticating = () => accessAuthInfo('authenticating', false);

/**
 * @param {import('@/store').RootState} state
 * @returns {string | null}
 */
export const selectToken = state => state.auth.token;

/**
 * @param {import('@/store').RootState} state
 * @returns {boolean}
 */
export const selectIsLoggedIn = state => state[name].token != null;

export const setIsKeepMeLoggedIn = keep => {
  if (keep) {
    persistDataToStorage(STORAGE_KEY_LIST.KEEP_ME_LOGGED_IN, STORAGE_VALUE_LIST.YES);
  } else {
    removeDataFromStorage(STORAGE_KEY_LIST.KEEP_ME_LOGGED_IN);
  }
};

export const getIsKeepMeLoggedIn = () =>
  STORAGE_VALUE_LIST.YES === restoreDataFromStorage(STORAGE_KEY_LIST.KEEP_ME_LOGGED_IN);

export const persistTokenPair = ({ token, refreshToken }) => {
  persistDataToStorage(STORAGE_KEY_LIST.AUTH_TOKEN, token);
  persistDataToStorage(STORAGE_KEY_LIST.AUTH_REF_TOKEN, refreshToken);
};
