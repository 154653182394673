import PropTypes from 'prop-types';

LoadingSpinner.propTypes = {
  className: PropTypes.string,
};
export function LoadingSpinner({ className = '' }) {
  return (
    <div className={className} data-testid="spinner">
      <i className="pi pi-spinner pi-spin text-4xl" />
    </div>
  );
}
