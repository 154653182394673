import { safelyAwait } from './safelyAwait';

export function memoizeAsync(asyncFn, { skipArgs = 0, unsafe } = {}) {
  const memoCache = new Map();
  return async (...args) => {
    const [isFound, result] = attemptLocateCacheResult(memoCache, skipArgs, ...args);
    if (isFound) {
      return result;
    }
    //noinspection ES6MissingAwait
    const asyncResult = unsafe ? asyncFn(...args) : safelyAwait(asyncFn(...args));
    storeCacheResult(memoCache, skipArgs, asyncResult, ...args);
    return asyncResult;
  };
}

function attemptLocateCacheResult(cache, skipArgs, ...args) {
  let current = cache;
  for (const arg of args.slice(skipArgs)) {
    if (current instanceof Map && current.has(arg)) {
      current = current.get(arg);
      continue;
    }
    current = null;
    break;
  }
  const isFound = current !== null;
  return [isFound, current];
}

function storeCacheResult(cache, skipArgs, result, ...args) {
  let current = cache;
  const copyArgs = Array.from(args.slice(skipArgs));
  copyArgs.reverse();
  const [last, ...allButLast] = copyArgs;
  allButLast.reverse();
  for (const arg of allButLast) {
    if (current.has(arg)) {
      current = current.get(arg);
    } else {
      const newMap = new Map();
      current.set(arg, newMap);
      current = newMap;
    }
  }
  current.set(last, result);
}
