import { useAuth } from '@ui/contextProviders';
import { useLocaleServices } from '@ui/contextProviders/Locale';
import { Button } from '@ui/lib/Button';

export function HeaderRightPartOnlySignOut() {
  const { t } = useLocaleServices();
  const { handleSignOutAndNavigate } = useAuth();

  return (
    <Button
      label={t('Logout')}
      aria-label={t('sign_in.menu_button_label')}
      className="mr-6"
      type="button"
      onClick={handleSignOutAndNavigate}
    />
  );
}
