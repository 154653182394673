import { EndpointError } from '@model/api/endpointError';
import { isFailureResponse } from '@ui/support/utils/guards';

const defaultErrorLabel = 'errors.server_error';

export function getErrorMessage<T extends object>(result: null | AppResponse<T> | EndpointError) {
  if (null === result) {
    return defaultErrorLabel;
  }
  if (EndpointError.isEndpointError(result)) {
    return result.message;
  }
  if (isFailureResponse(result.response)) {
    return getSpecificErrorLabel(result.response, defaultErrorLabel);
  }
  return 200 <= (result.code ?? 0) && (result.code ?? 0) < 400 ? null : defaultErrorLabel;
}

export function getErrorContext<T extends object>(result: null | AppResponse<T> | EndpointError) {
  if (null === result || EndpointError.isEndpointError(result)) {
    return null;
  }
  if (isFailureResponse(result.response)) {
    const error = result.response.errors[0];
    const { context } = error;
    return context;
  }
  return null;
}

export function getSpecificErrorLabel(errorResponse: ApiErrorResponseType, fallbackLabel: string) {
  const error = errorResponse.errors[0];
  const { code, error: backendError, message: errorLabel, context } = error;
  if (code < 400) {
    return null;
  }
  switch (backendError) {
    case 'ClosedAccount': {
      if (403 === code) {
        return 'errors.closed_account';
      }
      break;
    }
    case 'ForbiddenCountry': {
      if (403 === code) {
        return 'errors.forbidden_country';
      }
      break;
    }
    case 'BannedAccount': {
      if (403 === code) {
        return 'errors.banned_account';
      }
      break;
    }
    case 'Conflict': {
      if ('profiling_paypal_email' === context?.field) {
        return 'profiling.paypal.errors.exists';
      }
      break;
    }
    // here is a place to other cases where server-side errors get more specifics due to their properties
    default: {
      break;
    }
  }
  return errorLabel ?? fallbackLabel;
}
