import {
  accessAvailableLocales,
  accessAvailableLocalesFailure,
  accessCurrentLocale,
  accessCurrentResource,
  accessDefaultLocale,
  accessLoadingLocale,
  accessPreferredLocale,
  loadAvailableLocalesAsyncThunk,
  loadLocaleStringsAsyncThunk,
} from '@model/features/locale';
import { getTimestamp } from '@services/time';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LocaleContext } from './context';
import './i18n';

LocaleProvider.propTypes = {
  children: PropTypes.any,
};

export function LocaleProvider({ children }) {
  const dispatch = useDispatch();
  const [timestamp, setTimestamp] = useState(getTimestamp());

  const availableLocales = useSelector(accessAvailableLocales());
  const defaultLocale = useSelector(accessDefaultLocale());
  const preferredLocale = useSelector(accessPreferredLocale());
  const currentLocale = useSelector(accessCurrentLocale());
  const loadingLocale = useSelector(accessLoadingLocale());
  const currentResource = useSelector(accessCurrentResource());
  const isLocaleLoading = loadingLocale && loadingLocale !== currentLocale;

  const [proceedToLoadLocale, setProceedToLoadLocale] = useState(false);
  const [proceedToInitI18n, setProceedToInitI18n] = useState(false);

  const availableLocalesFailure = useSelector(accessAvailableLocalesFailure());
  const ongoingAsyncProcessRef = useRef(null);

  const handleLoadingLocaleList = useCallback(() => dispatch(loadAvailableLocalesAsyncThunk()), [dispatch]);
  const handleLoadingLocale = useCallback(locale => dispatch(loadLocaleStringsAsyncThunk({ locale })), [dispatch]);

  const setCurrentLocale = useCallback(
    locale => {
      if (!locale) {
        return;
      }
      if (!(locale in availableLocales)) {
        return;
      }
      if (locale === currentLocale) {
        return;
      }
      if (locale === loadingLocale) {
        return;
      }
      if (ongoingAsyncProcessRef.current) {
        return;
      }
      ongoingAsyncProcessRef.current = handleLoadingLocale(locale);
      ongoingAsyncProcessRef.current.then(
        () => {
          ongoingAsyncProcessRef.current = null;
          setProceedToInitI18n(true);
        },
        () => {
          ongoingAsyncProcessRef.current = null;
        }
      );
    },
    [availableLocales, currentLocale, handleLoadingLocale, loadingLocale]
  );

  useEffect(() => {
    if (availableLocalesFailure) {
      return;
    }
    if (ongoingAsyncProcessRef.current) {
      return;
    }

    ongoingAsyncProcessRef.current = handleLoadingLocaleList();
    ongoingAsyncProcessRef.current.then(
      () => {
        ongoingAsyncProcessRef.current = null;
        setProceedToLoadLocale(true);
      },
      () => {
        ongoingAsyncProcessRef.current = null;
      }
    );
  }, [availableLocalesFailure, handleLoadingLocaleList]);

  useEffect(() => {
    if (currentLocale) {
      return;
    }
    if (isLocaleLoading) {
      return;
    }
    if (!proceedToLoadLocale) {
      return;
    }
    if (proceedToInitI18n) {
      return;
    }
    setCurrentLocale(preferredLocale || defaultLocale);
  }, [
    currentLocale,
    defaultLocale,
    isLocaleLoading,
    preferredLocale,
    proceedToInitI18n,
    proceedToLoadLocale,
    setCurrentLocale,
  ]);

  useEffect(() => {
    if (proceedToInitI18n) {
      i18next.addResourceBundle(currentLocale, 'translation', currentResource);
      i18next.changeLanguage(currentLocale).then(() => {
        setTimestamp(getTimestamp());
      });
      setProceedToInitI18n(false);
    }
  }, [availableLocales, currentLocale, currentResource, loadingLocale, proceedToInitI18n, proceedToLoadLocale]);

  const value = useMemo(() => {
    return {
      availableLocales,
      currentLocale,
      setCurrentLocale,
      t: i18next.t,
      timestamp,
    };
  }, [availableLocales, currentLocale, setCurrentLocale, timestamp]);

  return <LocaleContext.Provider value={value}>{children}</LocaleContext.Provider>;
}

export const getLocaleProviderForTests = value => {
  LocaleProvider.propTypes = {
    context: PropTypes.object,
    children: PropTypes.any,
  };

  function LocaleProvider({ context, children }) {
    return <LocaleContext.Provider value={context ?? value}>{children}</LocaleContext.Provider>;
  }

  return LocaleProvider;
};

export const useLocaleServices = () => {
  const context = useContext(LocaleContext);
  if (!context) {
    throw new Error(
      `Invalid usage. 'useLocaleServices()' needs to be called in elements descending from <LocaleProvider />`
    );
  }
  return context;
};
