import { useLocaleServices } from '@ui/contextProviders';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';

ErrorGroup.propTypes = {
  name: PropTypes.string,
};

export function ErrorGroup({ name }) {
  const { t } = useLocaleServices();
  const { getFieldState } = useFormContext();
  const fieldState = getFieldState(name);
  if (!fieldState.invalid) {
    return null;
  }
  return <div className="text-accent-b">{t(fieldState.error?.message)}</div>;
}
