// AudioContext is optional
// eslint-disable-next-line compat/compat
const AudioCtx = window.AudioContext || window.webkitAudioContext;
const audioContext = AudioCtx ? new AudioCtx() : null;
const signal = new Audio('https://static.paidviewpoint.com/pv/sounds/survey.mp3');

let count = 0;

export function soundNotification() {
  if (!audioContext || 0 === count) {
    return;
  }

  audioContext.resume().then(() => {
    if ('suspended' === audioContext?.state) {
      return;
    }

    //ios safari
    if ('interrupted' === audioContext.state) {
      audioContext.resume().then(() => signal.play());
      return;
    }
    signal.play();
  });
}

const resumeAudio = () => {
  audioContext.resume().then(() => count++);

  // Once the audio context is resumed, remove the event listener
  document.removeEventListener('click', resumeAudio);
  document.removeEventListener('touchstart', resumeAudio);
};

// Add event listeners to resume the audio context
document.addEventListener('click', resumeAudio);
document.addEventListener('touchstart', resumeAudio);
