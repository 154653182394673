import { ICloseAccountField } from '@model/features/settings/types';
import { useLocaleServices } from '@ui/contextProviders/Locale';
import { AppButtonProps, Button } from '@ui/lib/Button';
import { FC } from 'react';
import ReactMarkdown from 'react-markdown';

type CloseAccountConfirmProps = {
  labels: ICloseAccountField['labels'];
  onCancel?: AppButtonProps['onClick'];
  onSubmit?: AppButtonProps['onClick'];
};

export const CloseAccountConfirm: FC<CloseAccountConfirmProps> = ({ labels, onCancel, onSubmit }) => {
  const { t } = useLocaleServices();

  return (
    <div className="w-setting-field relative flex h-full flex-col justify-center max-md:text-center">
      <ReactMarkdown className="text-body-l text-accent-b">{t(labels.warning)}</ReactMarkdown>
      <div>
        <Button
          variant="secondary"
          type="button"
          role="button"
          onClick={onCancel}
          label={t(labels.cancel)}
          data-role="cancel"
          className="mr-4 mt-4"
        />
        <Button
          type="button"
          role="button"
          onClick={onSubmit}
          label={t(labels.confirm)}
          data-role="confirm"
          variant="accent-a"
          className="mt-4"
        />
      </div>
    </div>
  );
};
