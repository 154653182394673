import { SurveyItem } from '@model/features/survey/types';
import { MEDIA_BP, useMediaQuery } from '@ui/hooks';
import { FC } from 'react';
import { MobileSurveyItem } from './MobileSurveyItem';

export const MobileSurveyList: FC<{ surveys: SurveyItem[] }> = ({ surveys }) => {
  const isTablet = useMediaQuery(MEDIA_BP.isOrGreaterThan768);

  return (
    <div className="grid gap-8 md:grid-cols-[1fr,auto]">
      {surveys.map(survey => (
        <MobileSurveyItem key={survey.id} survey={survey} tablet={isTablet} />
      ))}
    </div>
  );
};
