import { captureException } from '@sentry/react';
import { logged } from '@services/diagnostics';
import { dataCollector } from '@services/fcm/dataCollector';
import { isNotificationSupported } from '@services/fcm/utils';

export function processWebPushBannerAction() {
  if ('production' !== process.env.NODE_ENV) {
    logged(`processWebPushBannerAction request denied for non-production environment`);
    return;
  }
  if (!isNotificationSupported()) {
    captureException(new Error(`Notifications are not supported in this browser. ${navigator.userAgent}`));
    return;
  }

  dataCollector.putData({
    type: 'pushEnabled',
    value: true,
  });
}
