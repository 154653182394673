import { assignCurrentFetchImplementation } from '@model/api/endpointsContext';
import { fetchImplementation } from '@model/api/fetchImplementation';
import { registerApplicationEndpoints } from './applicationEndpoints';
import * as applicationEndpoints from './publishedEndpoints';

export * from './apiCommunication';
export { applicationEndpoints };

assignCurrentFetchImplementation(fetchImplementation);

registerApplicationEndpoints();
