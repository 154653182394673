import { isApiAxiosError } from '@/api/helpers/redux';
import { Logger } from '@/utils/Logger';
import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';

interface ApiCaller<R, T> {
  (signal?: AbortSignal): Promise<R>;
  (data: T, signal?: AbortSignal): Promise<R>;
}

export function useAxiosData<R, T>(requestFunction: ApiCaller<R, T>, requestData?: T) {
  const [data, setData] = useState<R | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const executeRequest = useCallback(() => {
    const abortController = new AbortController();
    setLoading(true);

    const promise =
      requestData === undefined
        ? requestFunction(abortController.signal)
        : requestFunction(requestData, abortController.signal);

    promise
      .then(response => {
        setData(response);
        setError(null);
      })
      .catch(error_ => {
        if (!axios.isCancel(error_)) {
          if (isApiAxiosError(error_)) {
            setError(error_.response.data.errors[0].message);
          } else {
            setError('errors.general');
          }
        } else {
          Logger.log('Request canceled');
        }
      })
      .finally(() => setLoading(false));

    return () => abortController.abort();
  }, [requestFunction, requestData]);

  useEffect(() => {
    const cancelRequest = executeRequest();
    return () => cancelRequest();
  }, [executeRequest]);

  return { data, error, loading };
}

export function useAxiosCall<T, R>(requestFunction: (data?: T) => Promise<R>) {
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const executeRequest = useCallback(
    async (requestData: T) => {
      setLoading(true);

      try {
        setError(null);
        return await requestFunction(requestData);
      } catch (error_) {
        if (!axios.isCancel(error_)) {
          if (isApiAxiosError(error_)) {
            setError(error_.response.data.errors[0].message);
          } else {
            setError('errors.general');
          }
        } else {
          Logger.log('Request canceled');
        }
        return null;
      } finally {
        setLoading(false);
      }
    },
    [requestFunction]
  );

  return { error, loading, executeRequest };
}
