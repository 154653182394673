import { getIsKeepMeLoggedIn } from '@model/features/auth';
import DataCollector from '@services/fcm/dataCollector';
import { useAuth, useDidCodePromise, useLocaleServices } from '@ui/contextProviders';
import { SocialHelperProvider, useSocialHelperContext } from '@ui/contextProviders/Social';
import { useRefCode } from '@ui/hooks/useRefCode';
import { paths } from '@ui/navigation';
import { classNames } from 'primereact/utils';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { socialButtonsData } from './socialButtons.data';
import pvs from './socialButtons.module.scss';

export const rememberMeListener = new DataCollector();

SocialButtons.propTypes = {
  role: PropTypes.oneOf(['sign_in', 'sign_up', 'link']),
  behavior: PropTypes.oneOf(['dynamic', 'expanded', 'compact']),
  className: PropTypes.string,
  rememberMe: PropTypes.bool,
};

export function SocialButtons({ role, behavior, className }) {
  const compact = 'compact' === behavior;
  const expanded = 'expanded' === behavior;
  const dynamic = 'dynamic' === behavior;
  const [rememberMe, setRememberMe] = useState(getIsKeepMeLoggedIn());

  useEffect(() => {
    const cb = data => {
      setRememberMe(data.get('rememberMe'));
    };
    rememberMeListener.subscribe(cb);
    return () => {
      rememberMeListener.unsubscribe(cb);
    };
  }, []);

  return (
    <div
      data-testid="social-buttons"
      className={classNames('flex w-full', pvs.social, className, {
        'flex-row justify-center [&>*]:mx-2': compact,
        'flex-col': dynamic,
        expanded: expanded,
      })}
    >
      <SocialHelperProvider>
        {socialButtonsData.map(buttonData => (
          <SocialButton
            key={buttonData.name}
            buttonData={buttonData}
            compact={compact}
            labelBase={role}
            className={buttonData.className}
            rememberMe={rememberMe}
          />
        ))}
      </SocialHelperProvider>
    </div>
  );
}

SocialButton.propTypes = {
  buttonData: PropTypes.shape({
    bgColor: PropTypes.string,
    color: PropTypes.string,
    name: PropTypes.string,
    alt: PropTypes.string,
    logo: PropTypes.any,
    label: PropTypes.string,
  }),
  compact: PropTypes.bool,
  labelBase: PropTypes.oneOf(['sign_in', 'sign_up', 'link']),
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  rememberMe: PropTypes.bool,
};

export function SocialButton({ buttonData, compact, labelBase, className, labelClassName, rememberMe }) {
  const { t } = useLocaleServices();
  const { name, alt, label, logo, bgColor, color } = buttonData;
  const { token } = useAuth();

  const didPromise = useDidCodePromise();
  const [didCode, setDidCode] = useState(null);
  const [referralCode] = useRefCode();

  const buttonLabelKey = label.replace('sign_in', labelBase);

  const handleSubmit = useSocialHelperContext();

  useEffect(() => {
    didPromise.then(setDidCode);
  }, [didPromise]);

  return (
    <div className="social-button__container">
      <form method="post" action={paths.toOAuthAuthorization(name)} onSubmit={handleSubmit}>
        <input type="hidden" name="callback_behavior" value="api" />
        {referralCode && <input type="hidden" name="refcode" value={referralCode} />}
        {null !== didCode && <input type="hidden" name="did" value={didCode} />}
        {token && <input type="hidden" name="authorization_token" value={token} />}
        {rememberMe != null && <input type="hidden" name="remember_me" value={rememberMe} />}
        <button
          data-testid={`register-by-${name}`}
          aria-label={`register by ${name}`}
          type="submit"
          className={[pvs.socialButton, compact ? pvs.compact : '', className].join(' ')}
          style={{ backgroundColor: bgColor, color }}
        >
          {logo && <img className={pvs.logo} src={logo} alt={alt} />}
          {!compact && <span className={classNames(pvs.label, labelClassName)}>{t(buttonLabelKey)}</span>}
        </button>
      </form>
    </div>
  );
}
