import { getIsKeepMeLoggedIn } from '@model/features/auth';
import { preprocessFormFields } from '@services/formHelpers';
import { STORAGE_KEY_LIST } from '@services/storage';
import { loginFieldsData } from '@ui/components/Login/login.fields.data';
import { ProfilingFieldWrapper } from '@ui/components/UpdateProfileSteps/profilingFieldWrapper';
import { useAuth } from '@ui/contextProviders';
import { FieldsFormWrapper } from '@ui/elements/FormWrapper';
import { HomeNext } from '@ui/elements/homeButtons/HomeNext';
import PropTypes from 'prop-types';
import { useCallback } from 'react';

const fields = preprocessFormFields(loginFieldsData);

PasswordSignIn.propTypes = {
  onFieldFocus: PropTypes.func,
};

function PasswordSignIn({ onFieldFocus }) {
  const { handleSignIn, isAuthenticating } = useAuth();
  const isRememberMe = getIsKeepMeLoggedIn();
  const onSubmitHandler = useCallback(
    async (data, _evt, _formAPI) => {
      if (isAuthenticating) {
        return void 0;
      }
      const payload = {
        email: data['email'],
        password: data['password'],
        remember_me: data['remember_me'],
      };
      localStorage.setItem(STORAGE_KEY_LIST.LOGIN_TYPE, '/login/password');
      return handleSignIn(payload);
    },
    [handleSignIn, isAuthenticating]
  );

  return (
    <FieldsFormWrapper
      name="login-form"
      ignoreStoredValues
      defaultValues={{
        remember_me: isRememberMe,
      }}
      onSubmit={onSubmitHandler}
      fields={fields}
      className="pass_man-fix checkbox-small"
      fieldsWrapperComponent={ProfilingFieldWrapper}
      onFocus={onFieldFocus}
      afterFormElement={<HomeNext className="p-button-accent-a !mt-10 w-full" label="login.login" />}
      hasPassword
    />
  );
}

export { PasswordSignIn };
