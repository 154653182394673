import { actualizeUserInfoThunkAct } from '@model/features/auth/authAsyncThunks';
import { savePreviousPath } from '@model/features/navigation';
import { accessUserStatusChecks } from '@model/features/user';
import { logged } from '@services/diagnostics';
import { AuthProvider, SurveyProvider, UIReportingProvider } from '@ui/contextProviders';
import { UIReporting } from '@ui/contextProviders/ErrorReporting';
import { BannersList } from '@ui/elements/Banners';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { useEffect } from 'react';
import { Outlet, ScrollRestoration, useLocation, useNavigate } from 'react-router-dom';
import { Footer, Header } from './components';
import { HomePageLogo } from './components/Header/elements';
import { SurveyResultPopup } from './components/SurveyResultPopup';
import { EnvironmentHint, SupportButton } from './elements';
import { usePrevious } from './hooks';
import { useAppDispatch, useAppSelector } from './hooks/redux';
import { isOnErrorPage, isOnParentPage, paths } from './navigation';
import { Support } from './support';

export function RootLayout() {
  //https://dev.to/basileleroy/google-analytics-on-react-js-components-4d2a

  window.navigate = useNavigate();

  const { pathname: currentPath } = useLocation();
  const previousPath = usePrevious(currentPath);

  const dispatch = useAppDispatch();
  const userStatusFlags = useAppSelector(accessUserStatusChecks());

  // TODO: WTF is this????
  useEffect(() => {
    if (previousPath === currentPath) {
      return;
    }
    logged(`AppRoute change: ${previousPath} -> ${currentPath}`);
    dispatch(savePreviousPath(previousPath));
    if (isOnErrorPage(currentPath) || isOnParentPage(paths.toAnyCountry)(currentPath)) {
      return;
    }
    if (userStatusFlags.isUserNotLoggedIn || userStatusFlags.isUserMinor) {
      return;
    }
    dispatch(actualizeUserInfoThunkAct);
  }, [currentPath, dispatch, previousPath, userStatusFlags.isUserMinor, userStatusFlags.isUserNotLoggedIn]);

  return (
    <AuthProvider>
      <SurveyProvider>
        <UIReportingProvider>
          <ConfirmDialog />
          <Outlet />
          {/*<LanguageSelector />*/}
        </UIReportingProvider>
      </SurveyProvider>
    </AuthProvider>
  );
}

export function MainLayout() {
  return (
    <>
      <header className="z-10 bg-dark-a/90 dark:bg-dark-a/60">
        <EnvironmentHint />
        <Header />
        <BannersList />
      </header>
      <main>
        <ScrollRestoration />
        <UIReporting />
        <Outlet />
        <SupportButton />
        <Support />
        <SurveyResultPopup />
      </main>
      <Footer />
    </>
  );
}

export function ErrorLayout() {
  return (
    <>
      <header className="app-header z-10 flex justify-center bg-dark-a/90 dark:bg-dark-a/60">
        <HomePageLogo />
      </header>
      <Outlet />
    </>
  );
}
