import { getAPIUrl } from '@services/context';
import { awaitWithFallbacks } from '@services/promises';
import { STORAGE_KEY_LIST, restoreDataFromStorage } from '@services/storage';

/**
 *
 * @type {import('@model/api/apiTypes').InternalFetchInterface}
 */
export const fetchImplementation = async ({
  url,
  method,
  params,
  body,
  authenticate,
  fullPath,
  headers = {},
  ...options
}) => {
  const fetchUrl = getPathForApiEndpoint(url, params, fullPath);

  const requestHeaders = {
    'Content-Type': 'application/json',
    ...headers,
  };
  if ('multipart/form-data' === headers['Content-Type']) {
    delete requestHeaders['Content-Type'];
  }

  if (authenticate) {
    const token = restoreDataFromStorage(STORAGE_KEY_LIST.AUTH_TOKEN);
    if (token) {
      requestHeaders.Authorization = `Bearer ${token}`;
    }
  }

  const requestArg = {
    method,
    ...(body ? { body: body instanceof FormData ? body : JSON.stringify(body) } : {}),
    referrerPolicy: 'no-referrer',
    headers: requestHeaders,
    mode: 'cors',
    credentials: 'include',
    ...options,
  };

  const fetchResult = await fetch(fetchUrl, requestArg);

  const fetchResultCopy = fetchResult.clone();

  const { status: code, type, url: responseUrl, redirected, headers: responseHeaders } = fetchResult;

  const response = 'error' === type ? null : await awaitWithFallbacks(fetchResult.json(), () => fetchResultCopy.text());

  return {
    code,
    response,
    request: requestArg,
    type,
    url: responseUrl,
    redirected,
    headers: Array.from(responseHeaders || []),
    rawHeaders: responseHeaders,
  };
};

const API_ENDPOINT = getAPIUrl();

/**
 *
 * @param url
 * @param params
 * @param fullPath
 * @return {module:url.URL}
 */
function getPathForApiEndpoint(url, params = {}, fullPath = false) {
  const effectiveFullPath = 'boolean' === typeof params ? params : fullPath;
  const effectiveParams = ('boolean' === typeof params ? {} : params) || {};

  const [effectiveUrl, search] = url.split('?');
  const result = new URL(`${API_ENDPOINT}${effectiveUrl}`);

  const searchParams = new URLSearchParams(search || '');
  for (const [key, val] of new URLSearchParams(effectiveParams).entries()) {
    if (!searchParams.has(key)) {
      searchParams.append(key, val);
    }
  }
  const searchString = searchParams.toString();
  if (searchString.length) {
    result.search = searchString;
  }
  if (effectiveFullPath) {
    result.pathname = effectiveUrl;
  }
  return result;
}
