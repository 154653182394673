import { accessUserStatusChecks } from '@model/features/user';
import { selectBanners } from '@model/features/user/selectors';
import { useAppSelector } from '@ui/hooks/redux';
import { BannerWrapper } from './bannerWrapper';

export function BannersList() {
  const { isUserAlive } = useAppSelector(accessUserStatusChecks());
  const [bannerData] = useAppSelector(selectBanners);

  if (!isUserAlive || !bannerData) {
    return null;
  }
  return <BannerWrapper key={bannerData.token} {...bannerData} />;
}
