import { BALLOON } from '@ui/components/InfoBalloon/resources';
import { ButtonVariant } from '@ui/lib/commonTypes';
import { BalloonButtonActions, BalloonPageName } from './types';

export const balloonPageData: Record<
  BalloonPageName,
  {
    balloonName: string;
    title: string;
    content: string;
    small?: string;
    closeAction?: BalloonButtonActions;
    buttons?: Array<{ action: BalloonButtonActions; label: string; variant?: ButtonVariant; testId?: string }>;
  }
> = {
  [BalloonPageName.UnsupportedCountry]: {
    balloonName: BALLOON.OUT_OF_REACH,
    title: 'balloons.bs.title',
    content: 'balloons.bs.content',
    small: 'balloons.bs.small',
    closeAction: BalloonButtonActions.Dismiss,
    buttons: [
      {
        action: BalloonButtonActions.CloseAccount,
        label: 'balloons.bs.buttons.close.label',
        variant: 'accent-a',
        testId: 'balloon-button-close-account',
      },
    ],
  },
  [BalloonPageName.ForbiddenCountry]: {
    balloonName: BALLOON.OUT_OF_REACH_CHINA,
    title: 'balloons.cn.title',
    content: 'balloons.cn.content',
    closeAction: BalloonButtonActions.Dismiss,
  },
  [BalloonPageName.Minor]: {
    balloonName: BALLOON.KOALA_MINOR_PROBLEM,
    title: 'balloons.minor.title',
    content: 'balloons.minor.content',
    small: 'balloons.minor.small',
    closeAction: BalloonButtonActions.Dismiss,
  },
};
