import { useLocaleServices } from '@ui/contextProviders/Locale';
import { routeNames } from '@ui/routes/routeNames';
import { SupportButton } from '@ui/support';
import { FC } from 'react';
import { FooterNavLink } from './FooterLink';

export const UserLinks: FC<PropsWithClassName> = ({ className }) => {
  const { t } = useLocaleServices();

  return (
    <div className="footer-links flex flex-wrap items-center">
      <FooterNavLink className={className} title={t('footer.dashboard')} href={routeNames.dashboard} />
      <FooterNavLink className={className} title={t('footer.earnings')} href={routeNames.earningsIndex} />
      <FooterNavLink className={className} title={t('footer.profile')} href={routeNames.demographicsIndex} />
      <FooterNavLink className={className} title={t('footer.settings')} href={routeNames.settingsIndex} />
      <FooterNavLink className={className} title={t('footer.friends')} href={routeNames.friendsIndex} />
      <SupportButton className={className} data-testid="support-btn" />
    </div>
  );
};
