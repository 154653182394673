import { RootState } from '@/store';
import { ErrorLogger } from '@/utils/ErrorLogger';
import { errorSchema } from '@model/api/schemas';
import { FetchArgs, createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getAPIUrl } from '@services/context';
import { recaptchaAPI } from '@ui/contextProviders/CaptchaProvider/recaptchaAPI';
import { Schema } from 'yup';
import {
  DemographicTraits,
  TraitsResponse,
  TraitsUpdateRequest,
  TraitsWithMetaResponse,
  resSchemaWithMeta,
  traitUpdateSchema,
} from './types';

const rowsPerPage = 10;

const baseQuery = fetchBaseQuery({
  baseUrl: getAPIUrl(),
  prepareHeaders(headers, api) {
    const state = api.getState() as RootState;
    headers.set('Authorization', `Bearer ${state.auth.token}`);
    return headers;
  },
});

const baseQueryWithCaptcha: typeof baseQuery = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  if (498 === result.error?.status) {
    const captchaCode = await recaptchaAPI.invokeRecaptcha?.();

    if (captchaCode) {
      if (isFetchArgs(args) && 'body' in args) {
        args.body['g-recaptcha-response'] = captchaCode;
      } else {
        args + '&g-recaptcha-response=' + captchaCode;
      }
      result = await baseQuery(args, api, extraOptions);
    }
  }
  return result;
};

export const traitsApi = createApi({
  reducerPath: 'demographicsTags',
  tagTypes: ['DemographicsTraits'],
  baseQuery: baseQueryWithCaptcha,
  endpoints(builder) {
    return {
      getTraits: builder.query<DemographicTraits, string | void>({
        query: (page = '1') => `/users/current/tags?page=${page}&per_page=${rowsPerPage}`,
        providesTags: [{ type: 'DemographicsTraits' as const, id: 'LIST' }],
        transformResponse: (response: TraitsWithMetaResponse) => {
          validate(resSchemaWithMeta, response);
          return {
            traits:
              response.data.tag_assignments?.map(t => {
                return {
                  id: t.id,
                  name: t.name,
                  value: t.value,
                  values: new Map(t.values),
                };
              }) ?? [],
            rowsPerPage,
            currentPage: response.meta.current_page,
            totalCount: response.meta.total_count,
          };
        },
        transformErrorResponse(res) {
          try {
            validate(errorSchema, res.data);
            return (res.data as ApiErrorResponseType).errors?.[0]?.message;
          } catch (error) {
            ErrorLogger.setExtra('context', 'traitsApi').setFnName('getTraits').send(error);
            return (error as Error).message ?? 'Something went wrong';
          }
        },
      }),
      updateTrait: builder.mutation<TraitsResponse['data']['tag_assignments'], TraitsUpdateRequest>({
        query: data => {
          validate(traitUpdateSchema, data);
          return {
            url: '/users/current/tags',
            method: 'PUT',
            body: data,
          };
        },
        transformResponse: (response: TraitsResponse) => response.data.tag_assignments,
        transformErrorResponse(res) {
          try {
            validate(errorSchema, res.data);
            return (res.data as ApiErrorResponseType).errors?.[0]?.message;
          } catch (error) {
            ErrorLogger.setExtra('context', 'traitsApi').setFnName('getTraits').send(error);
            return (error as Error).message ?? 'Something went wrong';
          }
        },
        invalidatesTags: [{ type: 'DemographicsTraits' as const, id: 'LIST' }],
      }),
    };
  },
});

export const { reducer, useGetTraitsQuery, useUpdateTraitMutation } = traitsApi;

function validate(schema: Schema, data: any) {
  if ('production' !== process.env.NODE_ENV) {
    schema.validateSync(data);
  }
}

function isFetchArgs(v: string | FetchArgs): v is FetchArgs {
  return typeof v !== 'string';
}
