import { appleLogoSvg, googleLogoSvg, paypalLogoSvg } from '@ui/components/SocialButtons/resources';

export const socialButtonsData = [
  //  { name: 'twitter', alt: 'twitter', ico: 'images/home/authbuttons2/tw.png' },
  {
    name: 'google_oauth2',
    alt: 'Google',
    label: 'sign_in.with_google',
    logo: googleLogoSvg,
    className: 'btn-gblue',
  },
  //  { name: 'linkedin', alt: 'linkedin', ico: 'images/home/authbuttons2/ld.png' },
  //  { name: 'yahoo', alt: 'yahoo', ico: 'images/home/authbuttons2/yh.png' },
  //  { name: 'windowslive', alt: 'windowslive', ico: 'images/home/authbuttons2/wl.png' },
  {
    name: 'apple',
    alt: 'Apple',
    label: 'sign_in.with_apple',
    logo: appleLogoSvg,
    className: 'btn-apple',
  },
];

export const cashOutButtonsData = [
  {
    name: 'paypal',
    alt: 'PayPal',
    label: 'cash_out.paypal',
    logo: paypalLogoSvg,
    bgColor: 'transparent',
    color: '#F6F7F9',
  },
];
