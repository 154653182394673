import { ErrorLogger } from '@/utils/ErrorLogger';

export const carefully =
  (fn, message) =>
  (...args) => {
    try {
      if (typeof fn !== 'function') {
        throw new TypeError('fn is not callable');
      }
      // eslint-disable-next-line no-console
      console.log('carefully. calling', message, fn, ...args);
      const result = fn(...args);
      // eslint-disable-next-line no-console
      console.log(`carefully. successful result:`, result);
      return result;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('carefully. exception raised:');
      // eslint-disable-next-line no-console
      console.error(error);
      ErrorLogger.setExtra('carefully', 'exception raised').send(error);
      throw error;
    }
  };

export const logged = (arg, message = '') => {
  if (process.env.NODE_ENV !== 'development' && message !== '!') {
    return arg;
  }
  if ('function' === typeof arg) {
    return carefully(arg, message);
  }
  // the purpose of this function is to use console.log
  // eslint-disable-next-line no-console
  console.log(message || 'logged:', typeof arg, arg);
  return arg;
};

export function debugged(arg, condition) {
  if ('production' === process.env.NODE_ENV) {
    return arg;
  }
  if (typeof condition !== 'undefined' && !condition) {
    return arg;
  }
  if ('function' === typeof arg) {
    return function _debugged(...args) {
      // the purpose of this function is to use debugger before a call to inspected function
      // eslint-disable-next-line no-debugger
      debugger;
      return arg(...args);
    };
  }
  // the purpose of this function is to use debugger to inspect a value
  // eslint-disable-next-line no-debugger
  debugger;
  return arg;
}
