import { getPvContext } from '@services/context';
import { resources } from '@ui/contextProviders/Survey/survey.resources';
void resources;

export const uninstallResource = (context, elementId) => {
  // eslint-disable-next-line unicorn/prefer-query-selector
  const domElement = document.getElementById(elementId);
  if (domElement) {
    domElement.parentNode.removeChild(domElement);
  }
  context.placement = null;
  context.url = null;
  context.loaded = false;
};

/**
 * @param {string} resourceType
 * @returns {(context: any, elementId: string, url: string, options?: any) => void}
 */
export const installResource =
  resourceType =>
  (context, elementId, url, options = {}) => {
    if (!url) {
      //      console.debug(`${elementId}. url not set, attempting to wipe previous ${resourceType}`)
      uninstallResource(context, elementId);
      return;
    }

    // Need to locate element by Id, for query selectors I will have to concatenate strings, one more operation
    // eslint-disable-next-line unicorn/prefer-query-selector
    const domElement = document.getElementById(elementId);
    if (domElement) {
      return;
    }

    const elementPlacement = new Promise((rs, rj) => {
      const body = document.querySelector('body');
      const domElement = document.createElement('script' === resourceType ? 'script' : 'link');
      const aytmDomain = getPvContext('aytmUri');
      domElement.setAttribute('id', elementId);
      domElement.setAttribute('nonce', window.nonce);
      domElement.onload = rs;
      domElement.onerror = rj;

      if ('script' === resourceType) {
        domElement.setAttribute('src', url.startsWith('//') || url.startsWith('http') ? url : aytmDomain + url);
        domElement.setAttribute('async', 'async');
      } else {
        domElement.setAttribute('rel', 'stylesheet');
        domElement.setAttribute('href', aytmDomain + url);
      }
      for (const [key, value] of Object.entries(options)) {
        domElement.setAttribute(key, String(value));
      }
      body.appendChild(domElement);
    });

    context.placement = elementPlacement;

    elementPlacement.then(
      () => {
        //        console.debug(`${elementId}. Installed: ${resourceType} ${url}`)
        context.placement = null;
        context.url = url;
        context.loaded = true;
      },
      _error => {
        //        console.debug(`${elementId}. Failed to install: ${resourceType} ${url}`, error)
        context.placement = null;
        context.url = null;
        context.loaded = false;
      }
    );
  };

export function installResources(resources) {
  const installationProgress = resources.map(({ type: resourceType, url, id: elementId }) => {
    const ctx = {};
    installResource(resourceType)(ctx, elementId, url);
    return [elementId, ctx];
  });
  const readinessPromise = Promise.all(installationProgress.map(([_, { placement }]) => placement));

  return {
    resources: installationProgress,
    readinessPromise,
  };
}
