import { ErrorLogger } from '@/utils/ErrorLogger';
import { EndpointCallerParametersType, EndpointHandlerType, fallbackHandlerName } from '@model/api/apiTypes';
import {
  AllowedSchemaType,
  EndpointDescriptionContext,
  EndpointName,
  getEndpoint,
  isSchema,
} from '@model/api/endpoint';
import { CLIENT_ERRORS, EndpointError } from '@model/api/endpointError';
import { endpointsContext } from '@model/api/endpointsContext';
import { safelyAwait } from '@services/promises';
import type { AnySchema } from 'yup';
import * as yup from 'yup';

const fallbackHandlerValidationSchema = yup.object({
  [fallbackHandlerName]: yup.mixed().test(value => 'function' === typeof value),
});

const isNotProduction = process.env.NODE_ENV !== 'production';

export async function callEndpoint<T>(
  name: EndpointName,
  parameters: EndpointCallerParametersType,
  asyncFetch = endpointsContext.fetch,
  context = endpointsContext
): Promise<AppResponse<T>> {
  // 0.
  const { params, body, method, ...handlers } = parameters || {};

  // 1. Get endpoint
  const endpointInfo = getEndpoint(name);
  if (!endpointInfo) {
    throw new EndpointError(`Undefined endpoint`, { endpointName: name, cause: CLIENT_ERRORS.UNDEFINED_ENDPOINT });
  }

  //  console.debug(`Using endpoint`, String(name), endpointInfo.description)

  // 2. Validate params if defined
  if (endpointInfo.params && isNotProduction && !endpointInfo.params.isValidSync(params)) {
    // this message is needed
    // eslint-disable-next-line no-console
    console.error(
      new EndpointError('Params validation', {
        endpointName: name,
        params,
        expected: endpointInfo.params,
        cause: CLIENT_ERRORS.INVALID_MISSING_PARAMS,
        validationError: endpointInfo.params.validateSync(params),
      })
    );
  }

  // 3. Validate body if defined
  if (endpointInfo.body && isNotProduction && !endpointInfo.body.isValidSync(body)) {
    // this message is needed
    // eslint-disable-next-line no-console
    console.error(
      new EndpointError('Body validation', {
        endpointName: name,
        body,
        expected: endpointInfo.body,
        cause: CLIENT_ERRORS.INVALID_MISSING_BODY,
        validationError: endpointInfo.body.validateSync(body),
      })
    );
  }

  // 4. Compose url
  const url = 'function' === typeof endpointInfo.url ? endpointInfo.url(params || {}) : endpointInfo.url;

  if (!url) {
    throw new EndpointError('Required request part is missing or empty', {
      endpointName: name,
      cause: CLIENT_ERRORS.INVALID_MISSING_PARTS,
      context: { url },
    });
  }

  // 5. Last minute check for handlers availability
  const codeHandlersSchema = endpointInfo.redirects
    ? getHandlersSchema(endpointInfo, getEndpoint(endpointInfo.redirects))
    : getHandlersSchema(endpointInfo);

  if (
    isNotProduction &&
    !codeHandlersSchema.isValidSync(handlers) &&
    !fallbackHandlerValidationSchema.isValidSync(handlers)
  ) {
    const error = new EndpointError('Handlers validation', {
      endpointName: name,
      cause: CLIENT_ERRORS.INVALID_MISSING_HANDLERS,
      handlers,
      expected: [
        fallbackHandlerName,
        ...Object.keys(endpointInfo.statusCodes || {}),
        ...Object.keys(endpointInfo.redirects ? getEndpoint(endpointInfo.redirects)?.statusCodes || {} : {}),
      ],
    });

    // this message is needed
    // eslint-disable-next-line no-console
    console.error(error);
    // eslint-disable-next-line no-console
    // console.debug({ ...error });
  }

  if (!asyncFetch) {
    throw new EndpointError('Fetch implementation', {
      endpointName: name,
      cause: CLIENT_ERRORS.MISSING_FETCH_ENGINE,
    });
  }

  const fetchArgCorrection = {};
  let error;
  let fetchResponse;

  let retries = 0;

  if (params?.url) {
    delete params.url;
  }

  for (;;) {
    const fetchArg = {
      url,
      method: method ?? endpointInfo.method ?? 'GET',
      params: 'object' === typeof params ? params : undefined,
      headers: endpointInfo.headers,
      body,
      authenticate: endpointInfo.authenticate ?? false,
      fullPath: endpointInfo.fullPath ?? false,
    };

    [error, fetchResponse] = await safelyAwait(asyncFetch(Object.assign({}, fetchArg, fetchArgCorrection)));
    if (error) {
      ErrorLogger.setTag('scope', 'fetch')
        .setExtra('fetch', fetchArg.url)
        .setExtra('params', JSON.stringify(params))
        .setExtra('body', JSON.stringify(body))
        .setExtra('isOnline', window.navigator.onLine?.toString())
        .send(error);
      throw new EndpointError('Network error', {
        endpointName: name,
        url,
        error: error.message,
        cause: CLIENT_ERRORS.NETWORK_ERROR,
      });
    }

    if (context.checkRepeatAsync && !endpointInfo.noRepeat) {
      const { checkRepeatAsync } = context;

      const breakSym = Symbol('break');
      const repeatResult = await checkRepeatAsync({
        fetchArg,
        error,
        request: fetchResponse.request,
        response: fetchResponse,
        retries,
        breakSym,
      });

      if (repeatResult === breakSym) {
        // no handling
        return { ...fetchResponse };
      } else if (repeatResult) {
        retries++;
        Object.assign(fetchArgCorrection, repeatResult);
        continue;
      }
    }

    break;
  }

  const { code, response, redirected } = fetchResponse;

  const receivingEndpointInfo =
    redirected && endpointInfo.redirects ? getEndpoint(endpointInfo.redirects) || endpointInfo : endpointInfo;

  if (
    isNotProduction &&
    receivingEndpointInfo.statusCodes &&
    receivingEndpointInfo.statusCodes[code] &&
    isSchema(receivingEndpointInfo.statusCodes[code]) &&
    !(receivingEndpointInfo.statusCodes[code] as AnySchema).isValidSync(response)
  ) {
    throw new EndpointError('Response validation', {
      endpointName: name,
      code,
      cause: CLIENT_ERRORS.SERVER_UNEXPECTED_RESPONSE,
      response,
      expected: receivingEndpointInfo.statusCodes[code],
      validationError: (receivingEndpointInfo.statusCodes[code] as AnySchema).validateSync(response),
    });
  }

  const result: AppResponse<T> = { ...fetchResponse };
  const handlerName = `on${code}`;

  if ('function' === typeof handlers[handlerName]) {
    (handlers[handlerName] as EndpointHandlerType)(result);
    return Object.assign(result, { handled: true });
  }

  if ('function' === typeof handlers[fallbackHandlerName]) {
    handlers[fallbackHandlerName](result);
    return Object.assign(result, { handled: true });
  }

  throw new EndpointError(`Missing handler for ${code} response`, {
    endpointName: name,
    code,
    cause: CLIENT_ERRORS.SERVER_UNEXPECTED_STATUS_CODE,
    originalUrl: url,
    ...fetchResponse,
  });
}

/**
 *
 * @param endpointInfo - initial endpoint description context
 * @param redirectedEndpointInfo - endpoint description context the initial endpoint redirects to
 */
function getHandlersSchema(
  endpointInfo: EndpointDescriptionContext,
  redirectedEndpointInfo?: EndpointDescriptionContext
): AllowedSchemaType {
  return (
    endpointInfo.codeHandlersSchema ||
    (endpointInfo.codeHandlersSchema = yup.object(
      Object.fromEntries(
        Object.keys(endpointInfo.statusCodes || {})
          .concat(redirectedEndpointInfo ? Object.keys(redirectedEndpointInfo.statusCodes || {}) : [])
          .filter(code => !redirectedEndpointInfo || !/^3\d\d$/.test(String(code)))
          .map(code => [`on${code}`, yup.mixed().test(value => 'function' === typeof value)])
      )
    ))
  );
}
