import * as yup from 'yup';
import { array, number, object, string } from 'yup';

export const errorSchema = object({
  errors: array()
    .of(
      object({
        code: number().required(),
        message: string().required(),
        error: string<'NotFound' | 'InvalidValue'>().required(),
      })
    )
    .required(),
});

export const metaSchema = object({
  current_page: number().required(),
  next_page: number().nullable(),
  prev_page: number().nullable(),
  total_pages: number().required(),
  total_count: number().required(),
});

export const schemaError422Response = yup.object({
  errors: yup.array().of(
    yup.object({
      code: yup.number(),
      message: yup.string(),
      message_en: yup.string(),
      exception: yup.string(),
      original_exception: yup.string(),
      backtrace: yup.array(),
      extra: yup.object().nullable(),
      context: yup.object({
        field: yup.string(),
      }),
    })
  ),
});

export const schemaDebugInfoGetResponse = yup
  .object({
    csrf_token: yup.string().nullable(),
    tenant: yup
      .object({
        title: yup.string().required(),
        slug: yup.string(),
        features: yup.object(),
      })
      .required(),
  })
  .required();

export const schemaParamsIdOnly = yup
  .object({
    id: yup.string(),
  })
  .required();

export const stringSchema = yup.string().required();

export const schemaParamsUrlOnly = yup.object({ url: yup.string().required() }).required();

export const schemaURLTemplateParams = yup
  .object({ urlTemplate: yup.string().required(), data: yup.object().optional() })
  .required();

export type URLTemplateParams = yup.InferType<typeof schemaURLTemplateParams>;
