import { ApiClient } from '@/api/ApiClient';
import { NewTicketFormData } from '@/api/support/types';
import type { RootState } from '@/store';
import { useLocaleServices } from '@/ui/contextProviders';
import { stringifyJson } from '@/utils/json';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAxiosCall } from '@ui/hooks/useAxios';
import { Button } from '@ui/lib/Button';
import { AppLink } from '@ui/lib/Link/AppLink';
import { BlockUI } from 'primereact/blockui';
import { confirmDialog } from 'primereact/confirmdialog';
import { Dialog } from 'primereact/dialog';
import { FC, RefObject } from 'react';
import { useForm } from 'react-hook-form';
import { useStore } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { object, string } from 'yup';
import { files } from '../../api/schemas';
import { FormInputFile } from '../FormInputs/FormInputFile';
import { FormInputText } from '../FormInputs/FormInputText';
import { FormTextArea } from '../FormInputs/FormTextArea';
import styles from './index.module.scss';

type NewTicketFormProps = {
  dialogRef?: RefObject<Dialog>;
  isMobile?: boolean;
};

const stateDumpWhiteList = [
  'router',
  'navigation',
  'auth',
  'user',
  'forms',
  'errors',
  'registration',
  'magic',
  'settings',
  'referrals',
  'survey',
  'earnings',
] as const;

export const NewTicketForm: FC<NewTicketFormProps> = ({ dialogRef, isMobile }) => {
  const { t } = useLocaleServices();
  const store = useStore();
  const navigate = useNavigate();

  const { loading, error, executeRequest: submitTicket } = useAxiosCall(ApiClient.support.submitTicket);

  const {
    control,
    handleSubmit,
    formState: { isValid },
    trigger,
  } = useForm<NewTicketFormData>({
    resolver: yupResolver(
      object({
        subject: string().required(t('support.subject_required')),
        content: string().required(t('support.comment_required')),
        images: files()
          .maxSize(2048000, t('support.file_size'))
          .fileType('image/', t('support.error_type'))
          .maxFiles(5, t('support.max_files'))
          .optional(),
        debug_context: string().optional(),
      })
    ),
    defaultValues: {
      subject: '',
      content: '',
      images: undefined,
    },
  });

  const handleNewTicket = async (data: NewTicketFormData) => {
    const state = store.getState() as RootState;
    const debugData: Partial<RootState> = stateDumpWhiteList.reduce((d, key) => {
      d[key] = state[key];
      return d;
    }, {});

    const res = await submitTicket({
      ...data,
      debug_context: stringifyJson(debugData),
    });

    if (res) {
      confirmDialog({
        message: (
          <>
            <p>{t('support.confirm_title')}</p>
            <p className="mt-4 font-proxi text-body font-normal text-dark-e dark:text-light-d">
              {t('support.confirm_text')}
            </p>
          </>
        ),
        closable: false,
        acceptLabel: t('acceptances.got_it'),
        className: 'pv-dialog pv-dialog-confirm',
        contentClassName: 'text-center child:w-full',
        headerClassName: 'pv-dialog-header',
        maskClassName: 'pv-dialog-mask',
        rejectClassName: 'hidden',
        acceptClassName: 'p-button-accent-b mr-0 px-4 md:px-8',
        accept: () => {
          navigate(window.location.pathname + `#support/ticket/${res.data.id}`, { replace: true });
        },
      });
    }
  };

  return (
    <>
      <div className="text-center font-bitter text-title-l font-bold">
        <Button
          className="absolute left-4 top-8 !px-3 !py-1 !text-base md:left-8"
          textButton
          icon="pi pv-arrow-back text-accent-a"
          data-role="go-back"
          type="button"
          onClick={() => navigate(window.location.pathname + '#support', { replace: true })}
        >
          {!isMobile && <span className="uppercase text-dark-a dark:text-white">{t('back')}</span>}
        </Button>
        <span data-testid="dialog-title">{t('support.new_ticket')}</span>
      </div>
      <BlockUI blocked={loading}>
        <form className={styles.form} onSubmit={handleSubmit(handleNewTicket)} data-form="new-ticket">
          <div className={styles.scroller}>
            <div className={styles.inner}>
              <FormInputText
                name="subject"
                placeholder={t('support.start_typing')}
                label={t('support.subject')}
                control={control}
              />
              <FormTextArea name="content" label={t('support.comment')} rows={6} control={control} />
              <FormInputFile
                name="images"
                control={control}
                dialogRef={dialogRef}
                trigger={trigger}
                isMobile={isMobile}
              />
            </div>
          </div>
          {error && (
            <div aria-errormessage={t(error)} className="p-error peer mx-auto mt-4 max-w-[31.25rem] text-center">
              {t(error)}
            </div>
          )}
          <div className="my-10 text-center peer-[.p-error]:mt-4">
            <Button
              type="submit"
              variant="accent-b"
              disabled={!isValid}
              label={t('support.submit')}
              data-role="submit"
              loading={loading}
            />
          </div>
          <div className="mx-18 border-t-[1px] border-light-d dark:border-dark-i" />
          <div className="mb-18 mt-12 text-center">
            <div className="text-title font-bold">{t('support.faqs')}</div>
            <div className="mb-6 mt-2 text-dimmed-a dark:text-light-d">{t('support.faq_caption')}</div>
            <AppLink
              href={window.tenant.links.faq.url}
              variant="accent-b"
              className="capitalize"
              target="_blank"
              data-link="to-faq"
            >
              {t('support.faq_full')}
            </AppLink>
          </div>
        </form>
      </BlockUI>
    </>
  );
};
