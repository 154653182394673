import { clsx, concatClsx } from '@/utils/classnames';
import { MEDIA_BP, useMediaQuery } from '@ui/hooks';
import { classNames } from 'primereact/utils';
import React, { useEffect, useId, useMemo, useState } from 'react';
import pvs from './SegmentedInput.module.scss';

export function SegmentedInput({
  codeLength = 6,
  devValue,
  onSubmit,
  onChange,
  testId,
  hasError = false,
  label,
  className,
}: {
  label?: string;
  codeLength?: number;
  onSubmit?: (value: string) => void;
  onChange?: (newValue: string) => void;
  testId?: string;
  hasError?: boolean;
  devValue?: string | null;
  className?: string;
}) {
  const isMobile = useMediaQuery(MEDIA_BP.isMobile);
  const inputId = useId();
  const codeDigits = useMemo(() => Array.from({ length: codeLength }).fill(0), [codeLength]);
  const [value, setValue] = useState(devValue ?? '');
  const values = value.split('');
  const selectedIndex = values.length < codeLength ? values.length : codeLength - 1;

  const [focused, setFocused] = useState(false);
  const input = React.createRef<HTMLInputElement>();
  const hideInput = values.length >= codeLength;
  const handleClick = () => input.current?.focus?.();
  const handleFocus = () => setFocused(true);
  const handleBlur = () => setFocused(false);

  const handleChange = event => {
    const { value: lastSymbol } = event.target;
    const newValue = (value.length >= codeLength ? value : (value + lastSymbol).slice(0, codeLength)).toUpperCase();
    setValue(newValue);
    onChange && onChange(newValue);
  };

  const handleKeyUp = event => {
    if ('Backspace' === event.key) {
      setValue(value => {
        const newVal = value.slice(0, -1);
        onChange?.(newVal);
        return newVal;
      });
    }
  };

  const handleOnSubmit = event => {
    event?.preventDefault();
    if (value.length === codeLength) {
      onSubmit && onSubmit(value);
    }
  };

  useEffect(() => {
    if (devValue != null) {
      setValue(devValue);
    }
  }, [devValue]);

  useEffect(() => {
    const handleFocus = () => {
      const inputEl = document.querySelector<HTMLInputElement>(`#${inputId.replace(/:/g, '\\:')}`);
      if (document.activeElement === document.body) {
        inputEl?.focus?.();
      }
    };
    window.addEventListener('focus', handleFocus);
    handleFocus();
    return () => window.removeEventListener('focus', handleFocus);
  }, [inputId]);

  return (
    <form onSubmit={handleOnSubmit} className={classNames('form-w-inherit', className)}>
      {label && (
        <label htmlFor={inputId} className={['font-bitter text-body-s', hasError ? 'p-error' : ''].join(' ')}>
          {label}
        </label>
      )}
      <div className={[pvs.wrap, 'mt-2'].join(' ')} onClick={handleClick}>
        {codeDigits.map((_, index) => {
          const selected = values.length === index;
          const filled = values.length === codeLength && index === codeLength - 1;

          return (
            <div
              className={clsx(
                pvs.display,
                'bg-light-c text-dimmed-f dark:bg-dark-b dark:text-light-a',
                hasError ? pvs.error : undefined
              )}
              key={`pos${index}-${values[index]}-${(selected || filled) && focused}`}
            >
              {values[index]}
              {(selected || filled) && focused && <div className={pvs.shadows} />}
            </div>
          );
        })}
        <input
          value=""
          ref={input}
          id={inputId}
          className={concatClsx(
            pvs.input,
            'bg-light-c text-dimmed-f focus:bg-light-a dark:bg-transparent dark:text-light-a'
          )}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onKeyUp={handleKeyUp}
          data-testid={testId}
          style={{
            width: isMobile ? '3rem' : '4.375rem',
            top: '0px',
            bottom: '0px',
            left: `${selectedIndex * (isMobile ? 3.5 : 4.875)}rem`,
            opacity: hideInput ? 0 : 1,
          }}
        />
      </div>
    </form>
  );
}
