import { selectIsUserAlive } from '@model/features/user/selectors';
import { useAppSelector } from '@ui/hooks/redux';
import { memo } from 'react';
import { FooterBase } from './FooterBase';

export const Footer = memo(() => {
  const links = window.tenant.links;
  const isAlive = useAppSelector(selectIsUserAlive);

  return <FooterBase links={links} isAlive={isAlive} />;
});

Footer.displayName = 'Footer';
