import { RootState } from '@/store';
import { ensureEnvVar } from '@services/env';
import { FC, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { SupportTicketComment } from '../../api/schemas';
import styles from './Chat.module.scss';
import { ChatBubble } from './ChatBubble';

type ChatProps = {
  comments: SupportTicketComment[];
};

// TODO move to user reducer
const fallbakThumb = `${ensureEnvVar('REACT_APP_PV_STATIC_BASE_URL')}/images/users/avatars/default_thumb.png`;

export const Chat: FC<ChatProps> = ({ comments }) => {
  const avatarUrl = useSelector((state: RootState) => state.user.profile?.avatar?.thumb ?? fallbakThumb);
  const isFirstRender = useRef(true);

  const chatRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (null != chatRef.current) {
      if (isFirstRender.current) {
        isFirstRender.current = false;
        chatRef.current.scrollTop = chatRef.current.scrollHeight;
      } else {
        chatRef.current.scroll({ top: chatRef.current.scrollHeight, behavior: 'smooth' });
      }
    }
  }, [comments]);

  return (
    <div className={`support-chat ${styles.chat}`} ref={chatRef}>
      {comments.map(comment => (
        <ChatBubble key={comment.id} item={comment} avatarUrl={avatarUrl} />
      ))}
    </div>
  );
};
