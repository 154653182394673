import { InfoBalloon } from '@ui/components/InfoBalloon';
import { Button } from '@ui/lib/Button';
import { Icon, Icons } from '@ui/lib/Icon';
import { routeNames } from '@ui/routes/routeNames';
import { Dialog } from 'primereact/dialog';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router';

// TODO: Update styles
BalloonPopUp.propTypes = {
  name: PropTypes.string,
  title: PropTypes.string,
  buttonAction: PropTypes.func,
  buttonVariant: PropTypes.string,
  buttonLabel: PropTypes.string,
  label: PropTypes.string,
  onClose: PropTypes.func,
  show: PropTypes.bool,
  setShow: PropTypes.func,
};
export function BalloonPopUp({
  name,
  title = '',
  buttonAction,
  buttonLabel,
  buttonVariant,
  onClose,
  show,
  setShow,
  label,
}) {
  const navigate = useNavigate();

  const handleClose = () => {
    onClose && onClose();
    setShow(null);
  };

  const buttonHandler = () => {
    buttonAction && buttonAction();
    setShow(null);
  };

  if (!name) {
    navigate(routeNames.dashboard);
  }

  return (
    <Dialog
      showHeader={false}
      visible={show}
      className="w-full items-center !shadow-none"
      maskClassName="bg-black/50 backdrop-blur-lg !z-[20001]"
      contentClassName="w-full flex flex-col items-center max-w-3xl !bg-transparent"
      data-testid="balloon-dialog"
    >
      <div className={`relative w-full`}>
        <Button
          textButton
          buttonType="rounded"
          className="absolute right-0 top-0 p-0 text-light-b hover:text-accent-b md:right-4 md:top-4"
          aria-label="close"
          data-role="close-cross"
          onClick={handleClose}
        >
          <div className="flex h-8 w-8 items-center justify-center rounded-full bg-dimmed-d">
            <Icon icon={Icons.Close} className="h-4 w-4 text-body leading-[0]" />
          </div>
        </Button>
        <InfoBalloon name={name} title={title} />
      </div>

      {label && <div className="mb-8 mt-10 text-center font-bitter text-body-l font-bold text-light-b">{label}</div>}

      {buttonAction && buttonLabel && (
        <Button label={buttonLabel} big onClick={buttonHandler} variant={buttonVariant} data-role="next-button" />
      )}
    </Dialog>
  );
}
