import PropTypes from 'prop-types';
import { useLocaleServices } from '@ui/contextProviders';
import { MEDIA_BP, useMediaQuery } from '@ui/hooks';
import pvs from './index.module.scss';

StaticText.propTypes = {
  defaultValue: PropTypes.string,
  labels: PropTypes.object,
};
export function StaticText({ defaultValue, labels }) {
  const isLargeScreen = useMediaQuery(MEDIA_BP.isOrGreaterThan768);
  const { t } = useLocaleServices();
  const { help: hepText } = labels || {};

  return (
    <>
      {isLargeScreen ? (
        <div className={pvs.static_text}>
          <span className="text-2xl">{defaultValue}</span>
          <span className="pl-3 text-sm">{t(hepText ?? '')}</span>
        </div>
      ) : (
        <div className="p-inputtext p-component p-filled w-full">{defaultValue}</div>
      )}
    </>
  );
}
