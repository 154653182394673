import { ErrorLogger } from '@/utils/ErrorLogger';
import { Logger } from '@/utils/Logger';
import { subscribeToNotifications } from '@services/fcm';

export function enableWebpushNotifications(data: Map<'token' | 'did' | 'swLoaded' | 'pushEnabled', any>) {
  Logger.log('enableWebpushNotifications data', data);
  // eslint-disable-next-line compat/compat
  Logger.log('enableWebpushNotifications Notification.permission', Notification?.permission);

  if (
    data.has('token') &&
    data.has('did') &&
    data.has('swLoaded') &&
    true === data.get('pushEnabled') &&
    Notification?.permission !== 'denied'
  ) {
    const did = data.get('did').trim();

    if (typeof did !== 'string' || 0 === did.length) {
      const error = new Error('Invalid did parameter');
      ErrorLogger.setExtra('did', 'Invalid did parameter').send(error);
      throw error;
    }
    if ('default' === Notification.permission) {
      Notification.requestPermission().then(permission => {
        if ('granted' === permission) {
          Logger.info('Notification permission already granted, call `subscribeToNotifications`');
          subscribeToNotifications(did);
        }
      });
    } else {
      Logger.info('Notification permission already granted, call `subscribeToNotifications`');
      subscribeToNotifications(did);
    }
  }
}
