import React from 'react';
import PropTypes from 'prop-types';

If.propTypes = {
  condition: PropTypes.bool,
  children: PropTypes.any,
  rest: PropTypes.object,
};
/**
 * @deprecated
 */
export function If({ condition, children, ...rest }) {
  if (!condition) {
    return null;
  }
  if (Object.keys(rest).length && 1 === React.Children.count(children)) {
    return React.Children.map(children, child => {
      if (React.isValidElement(child)) {
        return React.cloneElement(child, rest);
      }
      return child;
    });
  }
  return <>{children}</>;
}

IfNot.propTypes = {
  condition: PropTypes.bool,
  rest: PropTypes.object,
};
/**
 *
 * @deprecated
 * @returns
 */
export function IfNot({ condition, ...rest }) {
  return If({ condition: !condition, ...rest });
}

IfDebug.propTypes = {
  propNamesCSV: PropTypes.string,
  rest: PropTypes.object,
};
export function IfDebug({ propNamesCSV = '', ...rest }) {
  const propNames = propNamesCSV.split(/[,|]\w*/);
  const elements = propNames.map(name => (
    <div key={`${name}_${String(rest[name])}`} data-name={name} data-value={String(rest[name])} />
  ));
  return <div>{elements}</div>;
}
