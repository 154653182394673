import { accessor } from '@model/accessors';
import { validateZipCodeForCountryAsyncThunk } from '@model/features/geo';
import {
  apiProfilingGetCurrentStepAsyncThunk,
  apiProfilingSaveStepAsyncThunk,
  getGeneralProtectedDataAsyncThunk,
} from '@model/features/registration/asyncThunks';
import { name } from '@model/features/registration/name';
import { updateCountiesFieldOrDoNothing } from '@model/features/registration/updateCounties';
import { createSlice } from '@reduxjs/toolkit';
import { preprocessFormFields } from '@services/formHelpers';
import { getTimestamp } from '@services/time';
import { isFailureResponse } from '@ui/support/utils/guards';
import { signout } from '../auth';

function getInitialState() {
  return {
    profilingData: {},
    profilingFormError: null,
    profilingProgress: {},
    profilingStep: null,
    completed: false,
    progress: 0,
    currentStep: 0,
    totalSteps: 0,
    links: null,
    /**
     * @type {null|{fields:SmartFormFieldsArray, id:string, labels:{[key:string]:string}}}
     */
    data: null,
    currentStepData: null,
  };
}
const initialState = getInitialState();

const registrationSlice = createSlice({
  name,
  initialState,
  reducers: {
    clearProfilingFormError(state) {
      return {
        ...state,
        profilingFormError: null,
      };
    },
    updateCountiesField(state, { payload }) {
      const { data, zip } = payload;
      if (!Array.isArray(data) || data.length < 2) {
        // locate and possibly wipe counties
        updateCountiesFieldOrDoNothing(state, null);
        return;
      }
      const counties = data
        .filter(({ id }) => id === zip)
        .map(({ county }) => county)
        .filter(Boolean);

      if (counties.length < 2) {
        // locate and possibly wipe counties
        updateCountiesFieldOrDoNothing(state, null);
        return;
      }
      // add or let be the field of county
      updateCountiesFieldOrDoNothing(state, counties, zip);
    },
    resetProfiling() {
      return getInitialState();
    },
    resetProfilingStepData(state) {
      state.profilingStep = null;
      state.profilingStepName = null;
      state.profilingProgress = {};
      state.profilingData = {};
      state.currentStepData = null;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(apiProfilingGetCurrentStepAsyncThunk.fulfilled, (state, { payload }) => {
        const { code, response } = payload;
        if (code !== 200) {
          return;
        }
        const { meta, data, links } = response;
        const { progress, current_step: currentStep, total_steps: totalSteps, completed } = meta;
        //NonNullable<Dashboard['fill_profile']>completed/total
        state.profilingProgress = completed
          ? {
              progress: 100,
              current: totalSteps,
              total: totalSteps,
              completed,
              timestamp: getTimestamp(),
            }
          : {
              progress,
              current: currentStep,
              total: totalSteps,
              completed,
              timestamp: getTimestamp(),
            };
        if (!data) {
          state.profilingStep = null;
          state.profilingStepName = null;
          state.profilingData = {};
          return;
        }
        const { id: stepId, name, labels, fields } = data;
        const { prev_id: prevId, prev: prevURL } = links;

        state.profilingStep = stepId;
        state.profilingStepName = name;
        state.profilingData[stepId] = {
          stepId,
          name: state.profilingStepName,
          labels,
          fields: preprocessFormFields(fields),
          prevId,
          prevURL,
          progress,
          currentStep,
          totalSteps,
          completed,
        };
      })
      .addCase(apiProfilingSaveStepAsyncThunk.fulfilled, (state, { payload, meta: actionMeta }) => {
        const { response } = payload;
        const { stepId } = actionMeta.arg;

        if (isFailureResponse(response)) {
          state.profilingFormError = response.errors[0];
          return;
        }

        const { meta } = response;
        const { completed } = meta;

        state.profilingData[stepId] = {
          ...(state.profilingData[stepId] ?? {}),
          completed,
          ...(completed ? { progress: 100 } : {}),

          prevURL: null,
          prevId: null,
          fields: null,
        };

        state.profilingProgress = {
          ...(state.profilingProgress ?? {}),
          completed,
          ...(completed ? { progress: 100 } : {}),
          timestamp: getTimestamp(),
        };
      })
      .addCase(validateZipCodeForCountryAsyncThunk.rejected, (state, _action) => {
        // locate and possibly wipe counties
        updateCountiesFieldOrDoNothing(state, null);
      })
      .addCase(getGeneralProtectedDataAsyncThunk.fulfilled, (state, { payload, meta }) => {
        const { arg } = meta;
        if (!(arg || '').includes('/api/v1/phones/')) {
          return;
        }
        const {
          data: { number, status },
        } = payload.response;
        state.phoneNumber = number;
        state.phoneStatus = status;
      })
      .addCase(signout, () => getInitialState());
  },
});

const defaultExport = { [name]: registrationSlice.reducer };

export default defaultExport;

export const { updateCountiesField, resetProfiling, resetProfilingStepData, clearProfilingFormError } =
  registrationSlice.actions;

const accessRegistrationState = accessor(name);

export const accessRegistrationStepData = propName =>
  accessRegistrationState(state => (propName ? state.data[propName] : state.data), null);

export const accessRegistrationPhoneNumber = () => accessRegistrationState('phoneNumber');

export const accessProfilingStepId = () => accessRegistrationState('profilingStep', -1);
export const accessProfilingStepName = () => accessRegistrationState('profilingStepName', null);
export const accessProfilingProgress = () => accessRegistrationState('profilingProgress', null);
export const accessProfilingCurrentData = () =>
  accessRegistrationState(state => state.profilingData?.[state.profilingStep ?? 0], {});

export const accessProfilingDataByStep = stepId =>
  accessRegistrationState(profilingState => profilingState.profilingData?.[stepId], {});
