import { AsyncCheckRepeatContainer, AsyncCheckRepeatFunc, InternalFetchInterface } from '@model/api/apiTypes';

export interface EndpointsExecutionContext {
  fetch?: InternalFetchInterface;
  checkRepeatAsync?: null | AsyncCheckRepeatFunc;
}
export const endpointsContext: EndpointsExecutionContext = {};
export const assignCurrentFetchImplementation = (fetchImpl: InternalFetchInterface) => {
  endpointsContext.fetch = fetchImpl;
};
export const assignFetchRepeatConditioningManager = (checkRepeatManager?: AsyncCheckRepeatContainer) => {
  if (!checkRepeatManager || !checkRepeatManager.checkRepeatAsync) {
    endpointsContext.checkRepeatAsync = null;
    return;
  }
  endpointsContext.checkRepeatAsync = checkRepeatManager.checkRepeatAsync;
};
