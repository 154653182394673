export function get<T>(obj: any, path: string[], defaulValue: T | null = null): T | null {
  if (null == obj || typeof obj !== 'object' || !Array.isArray(path)) return null;
  let val = obj;
  const length = path.length;

  for (let i = 0; i < length; i++) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    if (path[i] != null && val != null && Object.prototype.hasOwnProperty.call(val, path[i]!)) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      val = val[path[i]!];
    } else {
      return defaulValue;
    }
  }
  return val;
}
