/**
 *
 * @param name
 * @returns {function(propName, ?fallback): function({}): *}
 */
export const accessSliceStatePropWithFallback =
  name =>
  (propName, fallback) =>
  ({ [name]: state }) =>
    propName ? state[propName] ?? fallback : state;
