export function formatDate(date: Date | string, options: Intl.DateTimeFormatOptions): string {
  const d = 'string' === typeof date ? new Date(date) : date;
  return new Intl.DateTimeFormat('en-US', options).format(d);
}

/**
 * Checks if a given string is a valid date.
 * @param date - The date string to check in the format 'YYYY-MM-DD'.
 * @returns True if the date string is valid, false otherwise.
 */

export function isValidDate(date?: string | null) {
  if (!date) {
    return false;
  }
  // If the date is an invalid date, it will return `null` when calling `toJSON()`
  return (new Date(date).toJSON() || '').startsWith(date);
}

/**
 * Returns a string representation of a date in the format 'YYYY-MM-DD'.
 * @param year - The year of the date.
 * @param month - The month of the date (0-indexed, where 0 is January).
 * @param date - The day of the month.
 * @returns A string representation of the date in the format 'YYYY-MM-DD', wrong values will still produce the expected format
 */
export function toJSONDate(year = 1970, month = 0, date = 1) {
  return `${year}-${String(1 + month).padStart(2, '0')}-${String(date).padStart(2, '0')}`;
}

const SAMPLE_LEAP_YEAR = 2020;
/**
 * Returns the number of days in a month for a given year, assumes the maximum number of days when the year/month are not specified (`-1`)
 * @param month - The month of the date (0-indexed, where 0 is January), -1 - not specified
 * @param year - The year of the date, -1 - not specified
 * @returns 28, 29, 30, or 31
 */
export function numberOfDaysInMonth(month: number, year: number) {
  return new Date(-1 === year ? SAMPLE_LEAP_YEAR : year, (-1 === month ? 0 : month) + 1, 0).getDate();
}
