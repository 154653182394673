import { useLocaleServices } from '@ui/contextProviders';
import { AppButtonProps, Button } from '@ui/lib/Button';
import { FC } from 'react';
import ReactMarkdown from 'react-markdown';
import { RegistrationCloseAccountLabelsType } from './RegistrationCloseAccount';

type RegistrationCloseAccountConfirmProps = {
  labels: RegistrationCloseAccountLabelsType;
  onCancel?: AppButtonProps['onClick'];
  onSubmit?: AppButtonProps['onClick'];
  disabled?: boolean;
};
export const RegistrationCloseAccountConfirm: FC<RegistrationCloseAccountConfirmProps> = ({
  labels,
  onCancel,
  onSubmit,
  disabled,
}) => {
  const { t } = useLocaleServices();
  return (
    <div className="relative mx-auto flex flex-col items-center justify-center text-center">
      <ReactMarkdown className="mx-auto w-2/3 font-bitter text-body dark:text-white">{t(labels.warning)}</ReactMarkdown>
      <div className="mx-auto mb-4 mt-3 w-2/3 font-proxi text-body-xs dark:text-light-d">{t(labels.hint)}</div>
      <div className="mt-2 flex w-full flex-row flex-wrap items-start justify-around">
        <Button
          disabled={disabled}
          variant="secondary"
          type="button"
          role="button"
          onClick={onCancel}
          label={t(labels.cancel)}
          data-role="cancel"
          className="mr-4 mt-4"
        />
        <Button
          disabled={disabled}
          type="button"
          role="button"
          onClick={onSubmit}
          label={t(labels.confirm)}
          data-role="confirm"
          variant="accent-a"
          className="mt-4"
        />
      </div>
    </div>
  );
};
