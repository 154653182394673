/**
 *
 * @type {AsyncCheckRepeatManager}
 */
export const checkRepeatAsyncManager = (() => {
  const _repeatCheckers = [];
  const checkRepeatAsync = async arg => {
    //    const { fetchArg, error, response, retries } = arg;
    for (const repeatAsyncFn of _repeatCheckers) {
      const result = await repeatAsyncFn(arg);
      if (result) {
        return result;
      }
    }
    return void 0;
  };

  const api = {
    checkRepeatAsync,
    register,
  };

  function register(fn) {
    fn && _repeatCheckers.push(fn);
    return api;
  }

  return api;
})();
