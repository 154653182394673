import { recaptchaAPI } from '@ui/contextProviders/CaptchaProvider/recaptchaAPI';

export const repeatDue498ResponseConditionAsync = async ({ fetchArg, error, response, retries }) => {
  if (error && !response) {
    return null;
  }
  if (retries >= 1) {
    return null;
  }
  if (/^(head)$/i.test(fetchArg.method)) {
    return null;
  }
  const { code } = response;
  if (code !== 498) {
    return null;
  }
  if (!recaptchaAPI.invokeRecaptcha) {
    // this message is needed
    // eslint-disable-next-line no-console
    console.warn('recaptchaAPI.invokeRecaptcha is null. Check CaptchaProvider for more info');
    return null;
  }
  const captchaCode = await recaptchaAPI.invokeRecaptcha();
  if (!captchaCode) {
    return null;
  }
  const newFetchArg = Object.assign({}, fetchArg);
  if ('get' === fetchArg.method.toLowerCase()) {
    newFetchArg.params = Object.assign({}, fetchArg.params, {
      'g-recaptcha-response': captchaCode,
    });
  } else {
    if (newFetchArg.body instanceof FormData) {
      newFetchArg.body.append('g-recaptcha-response', captchaCode);
    } else {
      newFetchArg.body = Object.assign({}, fetchArg.body, {
        'g-recaptcha-response': captchaCode,
      });
    }
  }
  return newFetchArg;
};

export const repeatDueToCfCaptcha = async ({ fetchArg, error, response }) => {
  if (error && !response) {
    return null;
  }

  const { rawHeaders } = response;

  if (rawHeaders.has('cf-mitigated') && 'challenge' === rawHeaders.get('cf-mitigated')) {
    const token = await window.cf.showChallenge();
    if (!token) {
      return null;
    }
    return fetchArg;
  }
  return null;
};
