/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */
// legacy code
/*
 * decaffeinate suggestions:
 * DS002: Fix invalid constructor
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * DS207: Consider shorter variations of null checks
 * DS208: Avoid top-level this
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
class BaseLogger {
  constructor() {
    this.isEnabled = this.isEnabled.bind(this)
    this.enable = this.enable.bind(this)
    this.disable = this.disable.bind(this)
    this.setLevel = this.setLevel.bind(this)
    this.debug = this.debug.bind(this)
    this.info = this.info.bind(this)
    this.warn = this.warn.bind(this)
    this.error = this.error.bind(this)
    this.trace = this.trace.bind(this)
    this.log = this.log.bind(this)
    this.stackTrace = this.stackTrace.bind(this)
  }

  static initClass() {
    this.prototype.levels = ['debug', 'trace', 'info', 'log', 'warn', 'error']
    this.prototype.currentLevel = 'debug'
  }

  static isEnabled() {
    // TODO: refactor for PV context to be a standalone entity
    const context = window.context || {}

    return (
      document.cookie.match('(^|;) ?logger_enabled=([^;]*)(;|$)') != null ||
      context?.admin != null ||
      'staging' === context?.env ||
      'development' === context?.env
    )
  }

  static enable() {
    return (document.cookie = `logger_enabled=1; expires=${new Date(Date.now() + 99999999 * 99).toGMTString()}; path=/`)
  }

  static disable() {
    return (document.cookie = 'logger_enabled=; expires=Thu, 01 Jan 1970 00:00:01 GMT;')
  }

  isEnabled() {
    return this.enabled != null ? this.enabled : BaseLogger.isEnabled()
  }

  enable() {
    return (this.enabled = true)
  }

  disable() {
    return (this.enable = false)
  }

  setLevel(level) {
    if (this.levels.includes(level)) {
      this.currentLevel = level
      return true
    } else {
      return false
    }
  }

  debug(...args) {
    const level = 'debug'
    if (this.isSend(level)) {
      return this.send(level, this.formatString(level, args))
    }
  }

  info(...args) {
    const level = 'info'
    if (this.isSend(level)) {
      return this.send(level, this.formatString(level, args))
    }
  }

  warn(...args) {
    const level = 'warn'
    if (this.isSend(level)) {
      return this.send(level, this.formatString(level, args))
    }
  }

  error(...args) {
    const level = 'error'
    if (this.isSend(level)) {
      return this.send(level, this.formatString(level, args))
    }
  }

  trace(...args) {
    const level = 'trace'
    if (this.isSend(level)) {
      return this.send(level, this.formatString(level, args))
    }
  }

  log(...args) {
    const level = 'log'
    if (this.isSend(level)) {
      return this.send(level, this.formatString(level, args))
    }
  }

  send(level, args) {}

  isSeverity(level) {
    return this.levels.indexOf(level) >= this.levels.indexOf(this.currentLevel)
  }

  isSend(level) {
    return this.isEnabled() && this.isSeverity(level)
  }

  prefixString(level) {
    let prefix = level.toUpperCase() + ' '
    if (this.prefix) {
      prefix += `[${this.prefix}]:`
    }
    return prefix
  }

  formatString(level, args) {
    args.unshift(this.prefixString(level))
    return args
  }

  stackTrace() {
    const err = new Error()
    return err.stack
  }
}

BaseLogger.initClass()

class ConsoleLogger extends BaseLogger {
  constructor(prefix) {
    super()
    this.prefix = prefix
  }

  send(level, args) {
    return 'function' === typeof console[level] ? console[level].apply(console, args) : console.log.apply(console, args)
  }
}

class HttpLogger extends BaseLogger {
  static initClass() {
    this.prototype.DEFAULT_ENDPOINT = '/log'
  }

  // @prefix
  // @endpoint - ex: "/log"
  // ext - ex: {user_id: 123}
  constructor(prefix, endpoint, ext) {
    super()
    this.send = this.send.bind(this)
    this.prefix = prefix
    this.endpoint = endpoint
    if (ext != null) {
      this.ext = ext
    }
    if (!this.endpoint) {
      this.endpoint = this.DEFAULT_ENDPOINT
    }
    if (!this.contentType) {
      this.contentType = 'json'
    }
    this.logUserAgent = true
  }

  send(level, message) {
    const $ = window.$

    if ($?.ajax != null) {
      let contentType
      let data = {
        message,
        level,
        prefix: this.prefix != null ? this.prefix : undefined,
        stack: ['warn', 'error'].includes(level) ? this.stackTrace() : undefined,
        ext: this.ext != null ? this.ext : undefined, // custom data
        user_agent: this.logUserAgent ? navigator.userAgent : undefined
      }
      switch (this.contentType) {
        case 'form':
          contentType = 'application/x-www-form-urlencoded'
          break
        default:
          contentType = 'application/json; charset=utf-8'
          data = JSON.stringify(data)
      }

      $.ajax({
        type: 'POST',
        url: this.endpoint,
        data,
        contentType,
        success: null
      })
      return true
    }
  }
}

HttpLogger.initClass()

class FakeLogger extends BaseLogger {}

const Logger = BaseLogger.isEnabled() && typeof console !== 'undefined' && console !== null ? ConsoleLogger : FakeLogger

const logger = new Logger()

export { logger, Logger }
