import { Stringifiable } from '@model/api/endpoint';
import { Dispatch } from 'redux';

export interface InternalFetchArgument {
  url: string;
  method: string;
  fullPath: boolean;
  headers?: Record<string, string>;
  authenticate: boolean;
  params?: Record<string, Stringifiable>;
  body?: FormData | { [name: string]: unknown };
}
export type InternalFetchResultType = {
  code: number;
  response: null | Response;
  request: Request;
  type: ResponseType;
  url: string;
  redirected: boolean;
  headers: Record<string, string>;
};

export type InternalFetchInterface = (fetchArg: InternalFetchArgument) => Promise<InternalFetchResultType>;

export type AsyncCheckRepeatFunc = (arg: {
  fetchArg: object;
  error: null | Error;
  request: Request; // fetchResponse.request,
  response: Response; // fetchResponse,
  retries: number;
  thunkAPI?: { dispatch: Dispatch };
  breakSym: symbol;
}) => Promise<null | object | symbol>;

export interface AsyncCheckRepeatContainer {
  checkRepeatAsync?: AsyncCheckRepeatFunc;
}

export interface AsyncCheckRepeatManager extends AsyncCheckRepeatContainer {
  register: (checkFunc: AsyncCheckRepeatFunc) => AsyncCheckRepeatManager;
}

export interface APICallerResult {
  handled?: boolean;
}

export const fallbackHandlerName = 'onAny';

export type EndpointHandlerType = <T>(arg: T) => void;

export type EndpointBaseRequestPropertiesType = {
  params?: Record<string, Stringifiable>;
  body?: FormData | { [name: string]: unknown };
  method?: string;
};

export type EndpointCallerParametersType<T = never> =
  | EndpointBaseRequestPropertiesType
  | T
  | ((EndpointBaseRequestPropertiesType | T) & {
      [fallbackHandlerName]?: EndpointHandlerType;
    } & {
      [handlerName: string]: EndpointHandlerType;
    });
