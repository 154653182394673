import { metaSchema } from '@model/api/schemas';
import * as yup from 'yup';
import { array, number, object, string } from 'yup';

type EarningStatus = 'pending' | 'frozen' | 'verification' | 'ready' | 'cooldown';

const statusSchema = string<EarningStatus>()
  .oneOf(['pending', 'frozen', 'verification', 'ready', 'cooldown'] as EarningStatus[])
  .defined()
  .required();

const earningsSchema = object({
  current: number().required(),
  progress: number().required(),
  total: number().required(),
  threshold: number().required(),
  ytd: number().required(),
  frozen: object({
    days_left: number().required(),
    end: string().required(),
    start: string().required(),
  })
    .nullable()
    .default(null),
}).required();

export const getCashoutResponseSchema = object({
  data: object({
    status: statusSchema,
    reason: string()
      .when('status', ([status], schema) => {
        switch (status as EarningStatus) {
          case 'pending':
            return schema
              .oneOf([
                'not_alive',
                'no_enough_money',
                'no_paypal_email',
                'no_phone',
                'no_available_vi_programs',
                'invalid_user_fields',
                'disqualified',
                'country_not_supported',
                'mismatch_country',
                'no_cashouts',
                'venmo_disqualified',
                'venmo_country_not_supported',
              ])
              .required();
          case 'frozen':
            return schema.oneOf(['frozen_earnings']).required();
          case 'verification':
            return schema.oneOf(['phone_verification', 'paypal_verification']).required();
          case 'ready':
            return schema.oneOf(['ready']).required();
          case 'cooldown':
            return schema.oneOf(['cooldown']).required();
          default:
            return schema;
        }
      })
      .required(),
    earnings: earningsSchema,
    message: object({
      label: string().required(),
      i18n: object({
        cooldown_end: string(),
        remaining: string(),
      }).required(),
    }),
    programs: array()
      .of(
        object({
          arrives_in_label: string(),
          arrives_in_hours: string(),
          currency: string(),
          currency_sign: string(),
          current_amount_cents: number().required(),
          description: string().required(),
          footnote_label: string(),
          image: string().required(),
          max_amount_cents: number(),
          min_amount_cents: number(),
          name: string().required(),
          tip_label: string(),
          title: string().required(),
          note_label: string().optional(),
        })
      )
      .nullable()
      .default(null),
    phone: object({
      nice_number: string().required(),
    })
      .nullable()
      .default(null),
    urls: object({
      cancel: string(),
    })
      .nullable()
      .default(null),
    verifications: array()
      .of(
        object({
          type: string<'phone' | 'paypal_email'>().required(),
          status: string<'pending' | 'verified' | 'fail'>().required(),
          message: object({
            title: string().optional(),
            content: string().optional(),
          }).optional(),
          options: object().required(),
        }).required()
      )
      .nullable()
      .default(null),
    paypal: object({
      email: string(),
    })
      .nullable()
      .default(null),
  }),
});

export const postCashoutResponseSchema = object({
  data: object({
    status: statusSchema,
    earnings: earningsSchema,
    result: object({
      status: string().defined().required(),
      type: string<'paypal' | 'gift_card' | 'venmo'>().required(),
      arrives_in_hours: number().required(),
      redeem_link: string().nullable(),
    }).required(),
  }).required(),
});

export const earningsHistoryRequestSchema = object({
  page: number().required(),
  per_page: number().required(),
}).required();

export const earningsHistoryResponseSchema = object({
  data: array()
    .of(
      object({
        activity: string().required(),
        activity_label: string().required(),
        created_at: string().required(), // Date
        amount: number().required(),
        running_total: number().required(),
        details: string(),
        action_url: string(),
      })
    )
    .required(),
  meta: metaSchema.required(),
});

export const schemaEarningsCashOutPostRequest = yup
  .object({
    program: yup.string().required(),
  })
  .required();
