import { EMAIL_PATTERN } from '@/constants/validation';
import { TFunction } from 'i18next';

export const getEmailRules = (t: TFunction) => {
  return {
    required: t('errors.validation.email.required'),
    pattern: {
      value: new RegExp(EMAIL_PATTERN),
      message: t('errors.validation.email.invalid'),
    },
    maxLength: {
      value: 255,
      message: t('errors.validation.email.max', { max: 255 }),
    },
  };
};

export const getPasswordRules = (t: TFunction) => {
  return {
    required: t('errors.validation.password.required'),
    minLength: {
      value: 6,
      message: t('errors.validation.min', { min: 6 }),
    },
  };
};
