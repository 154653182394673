import { ErrorBoundary } from '@sentry/react';
import { RecaptchaWrapper } from '@services/recaptchaWrapper';
import { ErrorFallback } from '@ui/components/ErrorFallback';
import { CaptchaProvider, DedupeScriptProvider, DidScriptProvider, LocaleProvider } from '@ui/contextProviders';
import { LoadingSpinner } from '@ui/elements/spinners';
import PropTypes from 'prop-types';
import { Suspense } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { getStore } from './configureStore';

import '@ui/styles';

export const getRootElementFor = (Component, initialState, options = {}) => {
  const { store } = getStore(initialState, options);

  const rootElement = (
    <RootComponent reduxStore={store}>
      <Component />
    </RootComponent>
  );

  return { store, rootElement, history };
};

function RootComponent({ children, reduxStore }) {
  return (
    <ErrorBoundary fallback={ErrorFallback}>
      <RecaptchaWrapper>
        <Provider store={reduxStore}>
          <DedupeScriptProvider>
            <DidScriptProvider>
              <HelmetProvider>
                <LocaleProvider>
                  <CaptchaProvider>
                    <Suspense
                      fallback={
                        <LoadingSpinner
                          className="fixed bottom-0 left-0 right-0 top-0 flex items-center justify-center"
                          data-suspense="true"
                        />
                      }
                    >
                      {children}
                    </Suspense>
                  </CaptchaProvider>
                </LocaleProvider>
              </HelmetProvider>
            </DidScriptProvider>
          </DedupeScriptProvider>
        </Provider>
      </RecaptchaWrapper>
    </ErrorBoundary>
  );
}
RootComponent.propTypes = {
  children: PropTypes.any,
  reduxStore: PropTypes.object,
  history: PropTypes.object,
};
