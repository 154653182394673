import { FC, HTMLProps, PropsWithChildren } from 'react';

type Props = {
  imageId: string;
  path: string;
} & HTMLProps<SVGSVGElement>;

export const SvgImage: FC<PropsWithChildren<Props>> = ({ imageId, children, path, ...rest }) => (
  <svg {...rest}>
    {children}
    <use xlinkHref={`${path}#${imageId}`} />
  </svg>
);
