import { selectUsersEarnings } from '@model/features/user/selectors';
import { formatCurrency } from '@services/currency';
import { useAppSelector } from '@ui/hooks/redux';
import { Icon, Icons } from '@ui/lib/Icon';

export function FlameLabelOne() {
  const earnings = useAppSelector(selectUsersEarnings);
  return (
    <div className="inline-flex w-28 shrink-0 flex-col items-center justify-center">
      <Icon icon={Icons.Flame} className="text-[6rem] leading-[0] text-pink-a" />
      <div className="crisscross mt-4 px-2 font-bitter text-display-s dark:text-white">
        {earnings ? formatCurrency(earnings, 'US') : '$$'}
      </div>
    </div>
  );
}

export function FlameLabelTwo() {
  return (
    <div className="inline-flex w-28 shrink-0 flex-col items-center justify-center">
      <Icon icon={Icons.Flame} className="text-[6rem] leading-[0] text-pink-a" />
      <div className="crisscross mt-4 flex flex-row flex-nowrap px-2 text-center text-display-s">
        <Icon icon={Icons.User} className="h-8 w-8" />
        <Icon icon={Icons.DollarCircle} className="h-8 w-8 text-error" />
        <Icon icon={Icons.Award} className="h-8 w-8 text-accent-a" />
      </div>
    </div>
  );
}
