import { ErrorLogger } from '@/utils/ErrorLogger';

export const safelyAwait = async <T, E = Error>(
  promise: T | Promise<T>
): Promise<readonly [E, null | undefined] | readonly [null, Awaited<T>]> => {
  try {
    return [null, await promise] as const;
  } catch (error) {
    ErrorLogger.setFnName('safelyAwait').setExtra('isOnline', window.navigator.onLine?.toString()).send(error);
    return [error as E, null] as const;
  }
};

export const awaitWithFallbacks = async <T = unknown>(
  promise: Promise<T>,
  ...fallbackFns: ReadonlyArray<Promise<T>>
): Promise<T> => {
  try {
    return await promise;
  } catch (error) {
    if (0 === fallbackFns.length) {
      ErrorLogger.setFnName('awaitWithFallbacks').send(error);
      throw error;
    }
    const [nextPromiseFn, ...rest] = fallbackFns as [() => Promise<T>, ...Promise<T>[]];
    return awaitWithFallbacks(nextPromiseFn(), ...rest);
  }
};
