import { tryExecute } from '@services/functional';
import { updatedYupEmailPattern } from '@services/validation/validationPatterns';
import * as yup from 'yup';
import { isNotTooYoungChecker, isTooOldChecker } from '../calendar';
import { FIELD_TYPES, FIELD_VALIDATIONS } from '../fields';
import { knownValidations } from './knownValidations';

// single_choice - value type is inferred from available values
// text - value type is postulated by the `inputType` options prop (*new) conformant to
//        https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input specs
// date - date
// there can be more

/**
 *
 * @param {string} fieldType
 * @param {string} name
 * @return {yup.AnySchema}
 */
export function getValidationStartingPoint({ fieldType, name }) {
  switch (fieldType) {
    // no need to validate by form
    case FIELD_TYPES.AVATAR:
    case FIELD_TYPES.STATIC_TEXT:
    case FIELD_TYPES.PHONE_ACTIVATION:
    case FIELD_TYPES.EMAIL_ACTIVATION:
    case FIELD_TYPES.SOCIAL_AUTHORIZATION:
    case FIELD_TYPES.TRAIT_CHILDREN:
    case FIELD_TYPES.TRAIT_PARENTAL:
    case FIELD_TYPES.ADDRESS: {
      return null;
    }

    case FIELD_TYPES.PROFILING_BIRTHDATE: {
      return yup
        .object({
          date: yup.string().required('errors.validation.date.required'),
          age_consent: yup.boolean().oneOf([true], 'errors.validation.consent.required'),
        })
        .required('errors.validation.date.required');
    }

    case FIELD_TYPES.PROFILING_PHONE: {
      return yup
        .object({
          phone: yup.string().required(),
        })
        .required();
    }

    case FIELD_TYPES.TEXT: {
      return yup.string().trim();
    }

    case FIELD_TYPES.SINGLE_CHOICE: {
      return yup.string();
    }

    case FIELD_TYPES.SEGMENTED_DATE: {
      return yup.mixed().nullable('SEGMENTED_DATE is nullable');
    }
    case FIELD_TYPES.BIRTHDATE:
    case FIELD_TYPES.DATE: {
      return yup.date().typeError(`errors.validation.${fieldType}.invalid`);
    }
    case FIELD_TYPES.DROPDOWN:
    case FIELD_TYPES.DROPDOWN_CLEAN: {
      return yup.mixed().nullable();
    }
    case FIELD_TYPES.PLACES_AUTOCOMPLETE: {
      return yup.mixed();
    }
    case FIELD_TYPES.MULTISELECT: {
      return yup.mixed();
    }

    case FIELD_TYPES.EMAIL: {
      return yup
        .string()
        .trim()
        .email(`errors.validation.${fieldType}.invalid`)
        .matches(updatedYupEmailPattern, {
          message: `errors.validation.${fieldType}.invalid`,
          excludeEmptyString: true,
        });
    }

    case FIELD_TYPES.PASSWORD:
    case FIELD_TYPES.PAYPAL_EMAIL_CHANGE: {
      return yup.string();
    }
    case FIELD_TYPES.PUSH_NOTIFICATION:
    case FIELD_TYPES.CHECKBOX_CLEAN:
    case FIELD_TYPES.CHECKBOX: {
      return yup.bool();
    }

    default: {
      // Need this line as API is changing
      // eslint-disable-next-line no-console
      console.warn(`Validation not implemented. Field type: ${fieldType}. Field name: ${name}`);
      return null;
    }
  }
}

/**
 *
 * @param {Array<{ name: string, type: string, options:Object<string,*>}>} fields
 * @param {Object?} options
 * @return {yup.ObjectSchema}
 */
export function formValidationComposer(fields, options = {}) {
  const { errorMessage, knownValidationRuleObjects = [], ...otherOptions } = options;

  const formValidationEntries = fields
    .map(field => [field.name, validationComposer(field, otherOptions)])
    .filter(([, v]) => v != null);

  return yup
    .object()
    .shape(Object.assign(Object.fromEntries(formValidationEntries), ...knownValidationRuleObjects))
    .required(errorMessage ?? 'errors.validation.empty_form');
}

/**
 *
 * @param {string} fieldType
 * @param {Object<string,*>}>} requirements
 * @param {?Array} values
 * @param {?string} name
 * @param {?Object} options
 * @param {?Object} labels
 * @param {?Array} fields
 * @return {yup.AnySchema}
 */
export function validationComposer(field, options = {}) {
  const { type: fieldType, options: requirements = {}, values = [], labels = {}, name, fields } = field;
  if (fields?.length) {
    return formValidationComposer(fields, options);
  }

  const validationStartingPoint = getValidationStartingPoint({ fieldType, name, requirements });
  if (null == validationStartingPoint) {
    return null;
  }

  if (fieldType === FIELD_TYPES.PROFILING_BIRTHDATE) {
    return validationStartingPoint;
  }

  return [
    FIELD_VALIDATIONS.REQUIRED,

    FIELD_VALIDATIONS.MIN,
    FIELD_VALIDATIONS.MAX,

    FIELD_VALIDATIONS.MIN_AGE,
    FIELD_VALIDATIONS.MAX_AGE,

    FIELD_VALIDATIONS.PATTERN,
    FIELD_VALIDATIONS.EMAIL,

    // last to go
    FIELD_VALIDATIONS.EQUAL_TO,

    FIELD_VALIDATIONS.CUSTOM_VALIDATIONS,
    FIELD_VALIDATIONS.DEPENDS_ON,
  ].reduce((yupResult, requirementName) => {
    if (!(requirementName in requirements)) {
      // skip
      return yupResult;
    }

    const requirementValue = requirements[requirementName];
    const errorLabel =
      labels?.['errors']?.[requirementName] ??
      labels?.[`errors.${requirementName}`] ??
      `errors.validation.${requirementName}`;

    //noinspection JSUnreachableSwitchBranches
    switch (requirementName) {
      case FIELD_VALIDATIONS.REQUIRED: {
        if (fieldType === FIELD_TYPES.CHECKBOX) {
          return yupResult.oneOf([true], errorLabel);
        } else if (fieldType === FIELD_TYPES.DROPDOWN || fieldType === FIELD_TYPES.DROPDOWN_CLEAN) {
          if (requirements.multiple) {
            return yupResult.test('empty_values', errorLabel, val => val?.length);
          }
          return values.length ? yupResult.required(errorLabel) : yupResult;
        }
        return yupResult.required(errorLabel);
      }

      case FIELD_VALIDATIONS.MIN: {
        if (fieldType === FIELD_TYPES.PLACES_AUTOCOMPLETE) {
          return yupResult.test({
            message: errorLabel,
            type: FIELD_VALIDATIONS.MIN,
            name: FIELD_VALIDATIONS.MIN,
            test: function (val) {
              if (!val) return false;
              return (val?.length ?? 0) > requirementValue; // temporary
            },
          });
        }
        return yupResult.min(requirementValue, errorLabel);
      }

      case FIELD_VALIDATIONS.MAX: {
        if (fieldType === FIELD_TYPES.PLACES_AUTOCOMPLETE) {
          return yupResult.test({
            name: FIELD_VALIDATIONS.MIN,
            type: FIELD_VALIDATIONS.MIN,
            message: errorLabel,
            test: function (val) {
              if (!val) return false;
              // return val?.value.description.length < requirementValue
              return true;
            },
          });
        }
        return yupResult.max(requirementValue, errorLabel);
      }

      case FIELD_VALIDATIONS.MIN_AGE: {
        const isNotTooYoung = isNotTooYoungChecker(requirementValue);
        return yupResult.test(FIELD_VALIDATIONS.MIN_AGE, errorLabel, isNotTooYoung);
      }

      case FIELD_VALIDATIONS.MAX_AGE: {
        const isTooOld = isTooOldChecker(requirementValue);
        return yupResult.test(FIELD_VALIDATIONS.MAX_AGE, errorLabel, isTooOld);
      }

      case FIELD_VALIDATIONS.PATTERN: {
        if (requirementValue instanceof RegExp) {
          return yupResult.matches(requirementValue, errorLabel);
        }
        const [error, pattern] = tryExecute((...args) => new RegExp(...args))(requirementValue, 'gu');
        return error ? yupResult : yupResult.matches(pattern, errorLabel);
      }

      case FIELD_VALIDATIONS.EMAIL: {
        return requirementValue ? yupResult.email(errorLabel) : yupResult;
      }

      case FIELD_VALIDATIONS.EQUAL_TO: {
        return yupResult.when(requirementValue, (_referencedFieldValue, schema) =>
          // referencedFieldValue
          schema.oneOf([yup.ref(requirementValue)], errorLabel)
        );
      }

      case FIELD_VALIDATIONS.DEPENDS_ON: {
        return validationStartingPoint.when(requirementValue, {
          is: Boolean, // val => Boolean(val)
          then: schemaToIgnore => {
            void schemaToIgnore;
            return yupResult;
          }, // compiled validation if master field is set
          otherwise: schema => schema, // no validation if master field is not defined
        });
      }

      case FIELD_VALIDATIONS.CUSTOM_VALIDATIONS: {
        return (Array.isArray(requirementValue) ? requirementValue : [requirementValue]).reduce(
          (result, requirementItem) => {
            if (requirementItem in knownValidations) {
              return result.test({
                name: requirementItem, // i.e. zip_code_validation
                //                exclusive: true,
                test(value, yupAPI) {
                  // const { path, createError, from, options, originalValue, parent, path, resolve, schema, type } = yupAPI
                  return knownValidations[requirementItem](value, yupAPI, {
                    preconditionSchema: yupResult,
                    name,
                    values,
                    options: requirements,
                    type: fieldType,
                    extra: options,
                  });
                },
              });
            } else {
              // Need this log message, API is changing
              // eslint-disable-next-line no-console
              console.warn(`Unknown custom_validation: ${requirementItem} in ${fieldType} (${name})`);
              return result;
            }
          },
          yup.mixed()
        );
      }

      default: {
        return yupResult;
      }
    }
  }, validationStartingPoint);
}
