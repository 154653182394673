import { useLocaleServices } from '@ui/contextProviders';
import { Button } from '@ui/lib/Button';
import { Dialog } from 'primereact/dialog';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import { IconExclamationMarkAccentA } from '../icons';
import { linkRenderer } from '../markdown/renderers/link';

const mdComponents = {
  a: linkRenderer,
  strong: ({ children }) => (
    <span
      className={`banner_classic_title ${
        children.includes('Claim your earnings now') ? '' : 'mb-3 block'
      } text-title-s font-bold text-dark-i dark:text-light-b`}
    >
      {children}
    </span>
  ),
};

Banner.propTypes = {
  type: PropTypes.string,
  message: PropTypes.string,
  acceptLabel: PropTypes.string,
  rejectLabel: PropTypes.string,
  closeLabel: PropTypes.string,
  acceptAction: PropTypes.func,
  rejectAction: PropTypes.func,
  closeAction: PropTypes.func,
};

/**
 * @param {string} type
 * @param {string} message
 * @param {string} acceptLabel - label accept button
 * @param {string} rejectLabel - label reject button
 * @param {function} acceptAction - action on accept
 * @param {function} rejectAction - action on reject
 * @param {string} closeLabel
 * @param {function} closeAction
 * @returns {JSX.Element}
 */
export function Banner({ message, acceptLabel, rejectLabel, acceptAction, rejectAction, closeAction }) {
  const { t } = useLocaleServices();

  return (
    <Dialog
      visible
      closable={Boolean(closeAction)}
      position="top"
      onHide={closeAction}
      draggable={false}
      closeOnEscape={false}
      resizable={false}
      headerClassName="hidden"
      contentClassName="rounded-xl bg-light-c dark:bg-dark-i p-0"
      className="banner_classic_theme top-18 mx-4 flex w-full max-w-[51.1875rem] flex-col"
      maskClassName="banner_classic_mask backdrop-blur-[5px] dark:backdrop-blur-none dark:bg-dark-h/70"
    >
      <div className="banner_classic_top h-10 w-full bg-dark-i md:hidden dark:bg-accent-a/20" />
      <div className="flex">
        <div className="banner_classic_left flex shrink-0 px-4 max-md:pt-7 md:items-center md:justify-center md:bg-dark-i dark:md:bg-accent-a/20">
          <IconExclamationMarkAccentA className="h-5 w-5" />
        </div>
        <div className="flex max-md:mb-6 max-md:flex-col md:mr-4">
          <ReactMarkdown
            className="flex flex-col py-6 pr-6 text-body-l text-dark-i md:px-6 dark:text-light-b"
            components={mdComponents}
          >
            {t(message)}
          </ReactMarkdown>
          <div className="flex items-center">
            {(rejectAction || rejectLabel) && (
              <Button label={t(rejectLabel) ?? 'No'} textButton onClick={rejectAction} className="mr-4" />
            )}
            {(acceptAction || acceptLabel) && <Button label={t(acceptLabel) ?? 'Ok'} onClick={acceptAction} />}
          </div>
        </div>
      </div>
    </Dialog>
  );
}
