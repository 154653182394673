import { GTags } from '@/constants/google_tags';
import { appUrls } from '@/constants/socialUrls';
import sprite from '@/resources/public/images/footer.svg';
import { AppLink } from '@ui/lib/Link/AppLink';
import { FC } from 'react';
import { SvgImage } from '../../base/SvgImage';

export const AppMarketLinks: FC = () => (
  <div className="flex">
    <AppLink href={appUrls.appStore} target="_blank" rel="noreferrer" aria-label="apple app store" tag={GTags.appStore}>
      <SvgImage
        path={sprite}
        imageId="app-store"
        className="h-[32px] w-[96px] transition-opacity duration-300 hover:opacity-60"
      />
    </AppLink>
    <AppLink
      href={appUrls.googlePlay}
      target="_blank"
      rel="noreferrer"
      aria-label="google play market"
      className="ml-4"
      tag={GTags.googlePlay}
    >
      <SvgImage
        path={sprite}
        imageId="play-market"
        className="h-[32px] w-[108px] transition-opacity duration-300 hover:opacity-60"
      />
    </AppLink>
  </div>
);
