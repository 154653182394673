import { onlyRequestedValidations } from '@services/fields';
import { linkRenderer } from '@ui/elements/markdown/renderers/link';
import { omitParaRenderer } from '@ui/elements/markdown/renderers/paragraph';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';

EarningBonus.propTypes = {
  value: PropTypes.string,
  className: PropTypes.string,
  condition: PropTypes.bool,
  hide: PropTypes.bool,
};
/**
 * @deprecated
 */
export function EarningBonus({ value = '', condition = false, hide = false, className }) {
  if (hide) {
    return null;
  }
  return <span className={`earning_bonus ${className ?? ''} ${condition ? 'done' : ''}`}>{value}</span>;
}

Tick.propTypes = {
  condition: PropTypes.bool,
  className: PropTypes.string,
  classNameDone: PropTypes.string,
};
/**
 * @deprecated
 */
export function Tick({ condition = false, className = '', classNameDone = '' }) {
  return <div className={`tick ${className} ${condition ? `done ${classNameDone}` : ''}`} />;
}

const mdComponents = {
  p: omitParaRenderer,
  a: linkRenderer,
};

export function extraMessages({ message, t, options }) {
  return (
    <ReactMarkdown
      components={mdComponents}
      className="ml-4 inline-block self-center font-proxi text-body-s nested-link:!font-proxi"
      linkTarget="_blank"
    >
      {t(message, onlyRequestedValidations(options))}
    </ReactMarkdown>
  );
}

getBeautyName.propTypes = {
  name: PropTypes.string,
};
export function getBeautyName(name) {
  switch (name) {
    case 'paypal_email':
      return 'paypal email';
    case 'paypal_email_confirmation':
      return 'paypal email confirm';
    default:
      return name;
  }
}
