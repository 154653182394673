import { concatClsx } from '@/utils/classnames';
import { ICloseAccountField } from '@model/features/settings/types';
import { useLocaleServices } from '@ui/contextProviders/Locale';
import { AppButtonProps, Button } from '@ui/lib/Button';
import { FC } from 'react';

type CloseAccountConfirmProps = {
  labels: ICloseAccountField['labels'];
  onCancel?: AppButtonProps['onClick'];
  onSubmit?: AppButtonProps['onClick'];
  inSettings?: boolean;
};

export const CloseAccountConfirmFinal: FC<CloseAccountConfirmProps> = ({
  labels,
  onCancel,
  onSubmit,
  inSettings = true,
}) => {
  const { t } = useLocaleServices();

  return (
    <div
      className={concatClsx(
        'max-md:text-center',
        inSettings ? 'w-setting-field relative flex h-full flex-col justify-center' : null
      )}
    >
      <b className="text-body-l text-accent-b">{t(labels.delete_message)}</b>
      <div>
        <Button
          type="button"
          role="button"
          variant="secondary"
          onClick={onCancel}
          label={t(labels.cancel2)}
          data-role="cancel"
          className="mr-4 mt-4"
        />
        <Button
          variant="accent-a"
          type="button"
          role="button"
          onClick={onSubmit}
          label={t(labels.confirm2)}
          data-role="confirm"
          className="mt-4"
        />
      </div>
    </div>
  );
};
