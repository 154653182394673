import { FC, memo } from 'react';

import { useAuth } from '@ui/contextProviders';
import { SupportDialog } from './components/SupportDialog';
import './index.scss';

export const Support: FC = () => {
  const { isAuthenticated } = useAuth();
  return isAuthenticated ? <SupportDialog /> : null;
};

export default memo(Support);
