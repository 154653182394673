import { getYear } from '@services/calendar';
import { useLocaleServices } from '@ui/contextProviders/Locale';
import { PVImage } from '@ui/elements';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { FooterLegalLink } from './FooterLegalLink';
import { SocialIcons } from './SocialIcons';
import { getSocialLinks } from './helpers';

type Props = {
  links: Window['tenant']['links'];
};

export const FooterLegal: FC<Props> = ({ links }) => {
  const { t } = useLocaleServices();

  const socialLinks = getSocialLinks(links);
  const divider = <span className="h-3 w-[1px] bg-white max-[480px]:hidden" />;

  return (
    <div
      className="container flex items-center justify-between text-body-xs max-[899px]:flex-col"
      data-testid="footer-legal"
    >
      <div className="flex grid-flow-col flex-wrap items-center justify-center gap-2 text-dimmed-d max-[480px]:gap-x-3 md:grid md:justify-start">
        {links && (
          <>
            <FooterLegalLink href={links.rules.url} title={t(links.rules.label)} />
            {divider}
            <FooterLegalLink href={links.tou.url} title={t(links.tou.label)} />
            {divider}
            <FooterLegalLink href={links.privacy.url} title={t(links.privacy.label)} />
            {divider}
            <FooterLegalLink href={links.faq.url} title={t(links.faq.label)} />
          </>
        )}
        {divider}
        <Link
          className="inline-flex items-center transition-colors duration-300 hover:text-accent-b"
          to="/your-privacy-choices"
        >
          <span className="flex-shrink-0">Your privacy choices</span>
          <PVImage className="ml-1 w-6" url="images/icons/privacyoptions.svg" alt="privacy options" />
        </Link>
      </div>

      <div className="copyright flex items-center text-dimmed-d max-[899px]:mt-4">
        <div className="text-body-xs max-md:pt-1">
          © {getYear()}, Umongous, Inc. <span>{t('footer.rights')}</span>.
        </div>
        <SocialIcons links={socialLinks} />
      </div>
    </div>
  );
};
