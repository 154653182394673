//storeLastKnownFormData

import { accessor } from '@model/accessors';
import { createSlice } from '@reduxjs/toolkit';
import { serializableOnly } from '@services/objects';
import { signout } from '../auth';

const name = 'forms';

const initialState = {
  name: null,
};

const slice = createSlice({
  name,
  initialState,
  reducers: {
    resetForms() {
      return { ...initialState };
    },
    storeLastKnownFormData(state, { payload }) {
      const { formData, defaultValues, name } = payload;
      if (!name) {
        return;
      }
      state.name = name;
      state[name] = {
        formData: serializableOnly(formData),
        ...(defaultValues ? { defaultValues: serializableOnly(defaultValues) } : {}),
      };
    },
  },
  extraReducers: builder => {
    builder.addCase(signout, () => initialState);
  },
});

const defaultExport = { [name]: slice.reducer };

export default defaultExport;

export const { storeLastKnownFormData, resetForms } = slice.actions;

const accessFormState = accessor(name);

export const accessFormsLastKnownFormName = () => accessFormState('name', null);
export const accessFormsFormData = name => accessFormState(({ [name]: state }) => state?.formData, null);
export const accessFormsDefaultValues = name => accessFormState(({ [name]: state }) => state?.defaultValues, null);

export const accessFormField = (formName, fieldName, fallback) =>
  accessFormState(formState => formState[formName]?.formData?.[fieldName], fallback);
