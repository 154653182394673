import { useLocaleServices } from '@/ui/contextProviders/Locale';
import { InputText, InputTextProps } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';
import { FieldValues, useController } from 'react-hook-form';
import type { FormFieldBaseProps } from './types';

export const FormInputText = <TFieldValues extends FieldValues>({
  label,
  name,
  rules,
  className,
  control,
  ...rest
}: FormFieldBaseProps<TFieldValues, Omit<InputTextProps, 'name'>>): JSX.Element => {
  const { t } = useLocaleServices();
  const { field, fieldState } = useController<TFieldValues>({
    name,
    control,
    rules,
  });

  const error = fieldState.error;

  return (
    <div className="field">
      <label className="flex flex-col">
        {label}
        <InputText
          className={classNames('form-input mt-2', className, {
            'p-invalid': error,
          })}
          aria-label={label}
          {...field}
          {...rest}
          aria-invalid={error ? 'true' : 'false'}
        />
      </label>
      {error?.message != null && <small className="p-error mt-2">{t(error.message)}</small>}
    </div>
  );
};
