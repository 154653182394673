import * as yup from 'yup';

export const schemaDemographicsGetResponse = yup
  .object({
    data: yup
      .object({
        fields: yup.array().of(yup.mixed()).required(),
      })
      .required(),
  })
  .required();
