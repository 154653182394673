import { createSlice } from '@reduxjs/toolkit';
import { accessor } from '../../accessors';

const name = 'navigation';

/**
 * @typedef {Object} NavigationState
 * @property {boolean} blockNavigation
 * @property {string | null} previousPath
 *
 */
/**
 * @type {NavigationState}
 */
const initialState = {
  blockNavigation: false,
  previousPath: null,
};

export const navigationSlice = createSlice({
  name,
  initialState,
  reducers: {
    blockNavigation: (state, { payload }) => {
      state.blockNavigation = payload;
    },
    savePreviousPath: (state, { payload }) => {
      state.previousPath = payload;
    },
  },
});

const defaultExport = { [name]: navigationSlice.reducer };

export default defaultExport;

export const { blockNavigation, savePreviousPath } = navigationSlice.actions;

const navigationAccessor = accessor(name);

export const accessIsNavigationBlockRequested = () => navigationAccessor('blockNavigation', false);
export const accessPreviousPath = () => navigationAccessor('previousPath', null);
