import { useLocaleServices } from '@ui/contextProviders';
import { RouterLink } from '@ui/lib/Link/AppLink';
import PropTypes from 'prop-types';
import { memo, useState } from 'react';
import { SocialButtons } from '../SocialButtons';

import '@ui/styles';
import { EmailSignUp } from './elements/EmailSignUp';

function Register() {
  const { t } = useLocaleServices();
  const [collapsed, setCollapsed] = useState(false);

  const rememberMe = false;

  const handleFieldFocus = event => {
    if (!collapsed && 'email' === event.target.name) {
      setCollapsed(true);
    }
  };

  return (
    <div data-testid="register-container" className="auth-form">
      <div className="title auth-form__title text-[2.75rem] md:text-[4.7rem]">{t('sign_up.title')}</div>
      <div className="auth-form__subtitle">
        {/* {t('sign_up.subtitle')} */}
        {t('sign_up.temporary_message')}
      </div>
      <div className="p-fluid auth-form__content">
        <SocialButtons role="sign_up" behavior={collapsed ? 'compact' : 'dynamic'} rememberMe={rememberMe} />
        <div className="auth-layout__or-divider my-5">{t('register.or')}</div>
        <EmailSignUp onFieldFocus={handleFieldFocus} />
      </div>
      <RouterLink to="../login" data-role="login-instead" className="mt-9" variant="accent-b">
        {t('sign_up.login_instead')}
      </RouterLink>
    </div>
  );
}

Register.propTypes = {
  rememberMe: PropTypes.bool,
};

export default memo(Register);
