export const persistDataToStorage = (
  propName: string,
  data: string | null | undefined,
  storage: Storage = window.localStorage
) => {
  if (null == data) {
    storage.removeItem(propName);
  } else {
    storage.setItem(propName, data);
  }
};

export function removeDataFromStorage(key: string, storage: Storage = window.localStorage) {
  storage.removeItem(key);
}

export function removeDataFromAllStorages(key: string) {
  window.localStorage.removeItem(key);
  window.sessionStorage.removeItem(key);
}

export const popDataFromStorage = (
  propName: string,
  fallback?: ReturnType<Storage['getItem']>,
  storage: Storage = window.localStorage
) => {
  const restoredData = storage.getItem(propName);
  removeDataFromStorage(propName, storage);
  return null == restoredData ? fallback : restoredData;
};

export const restoreDataFromStorage = (
  propName: string,
  fallback?: ReturnType<Storage['getItem']>,
  storage: Storage = window.localStorage
) => {
  const restoredData = storage.getItem(propName);
  return null == restoredData ? fallback : restoredData;
};

export const persistToStorageWipeFromAnother = (
  propName: string,
  data: string | null | undefined,
  isLocalStorage: boolean
) => {
  persistDataToStorage(propName, data, isLocalStorage ? window.localStorage : window.sessionStorage);
  removeDataFromStorage(propName, !isLocalStorage ? window.localStorage : window.sessionStorage);
};

export const restoreDataFromStorageWipeFromAll = (
  propName: string,
  fallback: ReturnType<Storage['getItem']>,
  isLocalStorage: boolean
) => {
  const restoredData = restoreDataFromStorage(
    propName,
    fallback,
    isLocalStorage ? window.localStorage : window.sessionStorage
  );
  removeDataFromAllStorages(propName);
  return null == restoredData ? fallback : restoredData;
};

export const STORAGE_KEY_LIST = Object.freeze({
  SOCIAL_AUTH_RESULT_TOKEN: 'token',
  SOCIAL_AUTH_REFRESH_TOKEN: 'refreshToken',
  SOCIAL_AUTH_RESULT_METADATA: 'tokenMeta',
  AUTH_TOKEN: 'authToken',
  AUTH_REF_TOKEN: 'rftkn',
  USER_STATUS: 'status',
  MAGIC_LINK_CODE: 'mlc',
  MAGIC_LINK_EMAIL: 'mle',
  MAGIC_LINK_PROGRESS: 'mlp',
  PASSWORD_CHANGE_CODE: 'pcc',
  SURVEY_INVITATION_CODE: 'sic',
  REDIRECT_TO: 'rd',
  REDIRECT_TO_TIMESTAMP: 'rdts',
  PREVIOUS_LOCATION: 'prvl',
  LAST_ACTION_EMAIL: 'lae',
  TRANSLATIONS: 'translations',
  KEEP_ME_LOGGED_IN: 'keepMeLoggedIn',
  DELETED: 'deleted',
  LOGIN_TYPE: 'loginType',
});

export const STORAGE_VALUE_LIST = {
  YES: String(true),
};
export const FIXED_STATE_KEYS = {
  PROFILING_PAYPAL_MODAL: 'pppm',
};

export function restoreDataFromStoragePersistIfAbsent(
  key: string,
  data: string,
  storage: Storage = window.localStorage
) {
  const existingData = restoreDataFromStorage(key, null, storage);
  if (null == existingData) {
    persistDataToStorage(key, data, storage);
  }
  return existingData ?? data;
}

export function hasDataInStorage(key: string, storage: Storage = window.localStorage) {
  return null != storage.getItem(key);
}
