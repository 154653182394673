import { UserStatus } from '@/api/user/user-status';
import { selectUserStatus } from '@model/features/user/selectors';
import { useAuth } from '@ui/contextProviders';
import { RegistrationCloseAccount } from '@ui/elements/CloseAccount/RegistrationCloseAccount';
import { useAppSelector } from '@ui/hooks/redux';
import { AppLink, RouterLink } from '@ui/lib/Link/AppLink';
import { Dropdown } from 'primereact/dropdown';
import { FC, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { DownloadInfoBlock } from '../Settings/components/AccountActions/DownloadInfoBlock';
import { CcpaLoginForm } from './CcpaLoginForm';
import { DeleteAccountForm } from './DeleteAccountForm';

const options = [
  {
    label: 'Do not sell or share my personal information',
    value: 'doNotSell',
  },
  {
    label: 'Limit the processing of my sensitive personal information',
    value: 'limitProcessing',
  },
  {
    label: 'Delete my personal information',
    value: 'forgetMe',
  },
  {
    label: 'Download my personal information',
    value: 'download',
  },
];

const P = ({ children }: { children: React.ReactNode }) => <p className="mt-4">{children}</p>;

const CcpaPage: FC = () => {
  const [value, setValue] = useState<string | null>(null);
  const { isAuthenticated } = useAuth();
  const status = useAppSelector(selectUserStatus);

  return (
    <>
      <Helmet>
        <title>Your Privacy Choices</title>
      </Helmet>
      <div className="container w-full max-w-[46rem] py-14 text-dark-a dark:text-light-b">
        <h1 className="mb-8 font-bitter text-display-s font-semibold max-md:text-center">Your Privacy Choices</h1>
        <P>
          As a member of PaidViewpoint, you voluntarily share your personal information with us, including your name and
          email address, when you register as a member.
        </P>

        <P>
          In connection with research related services we provide to our clients, we may also provide some personal
          information about you to our clients. Depending on your location, this delivery of research services may be
          deemed a “sale” of your personal information under applicable state law.
        </P>

        <P>
          For example, under the California Consumer Privacy Act (“CCPA”) the term “sell” includes “...renting,
          releasing, disclosing, disseminating, making available, [and] transferring...for monetary or other valuable
          consideration.”
        </P>

        <P>
          As a California consumer, or if you are located in another state which grants you a similar right, you have
          the right to opt-out of this “sale” of your personal information or to opt out of other profiling activities
          based on your personal information.
        </P>

        <P>
          In California, you also have the right to limit the processing of your sensitive personal information. Please
          note that if you exercise your opt out right, or your “right to limit” in California, because of the nature of
          our research business it may not be possible for you to continue serving as a research respondent.
        </P>

        <P>
          You may exercise these rights, or any other right under CCPA or other applicable state laws, by filling out
          the webform below, or by contacting us at{' '}
          <AppLink variant="accent-b" href="tel:18004613850">
            1-(800)-461-3850.
          </AppLink>
        </P>

        <P>
          If you choose to exercise your privacy rights, either directly or via an authorized agent, we may request
          certain personal information, such as your full name, birth date, phone number, email address, or other basic
          personal information about you that we already have on file, in order to verify that you (and, as applicable,
          your agent) do in fact authorize the request.
        </P>

        <P>
          For more information about how we collect, use, and store your personal information, and for more information
          about your privacy rights, please see our{' '}
          <AppLink variant="accent-b" href={window.tenant.links.privacy.url} target="_blank" rel="noreferrer">
            Privacy Policy.
          </AppLink>
        </P>

        <div className="mt-8 flex max-md:flex-col">
          <Dropdown
            placeholder="Your privacy choices"
            options={options}
            value={value}
            className="w-full md:w-[21rem]"
            onChange={event => {
              setValue(event.value);
            }}
          />
          {value != null && !isAuthenticated ? (
            <div className="ml-6 max-md:mt-6">
              Please login to proceed
              <br />
              or use{' '}
              <RouterLink to="/login" className="text-accent-b">
                other login options
              </RouterLink>
            </div>
          ) : null}
        </div>
        {value != null &&
          (isAuthenticated ? (
            'download' === value ? (
              <div className="mt-4">
                <DownloadInfoBlock url="/api/v1/users/current/export" />
              </div>
            ) : (
              <>
                <p className="my-6">
                  <b>We are in the Consumer Insights business.</b>
                  <br />
                  We need your permission to store, sell and share your personal information, including sensitive
                  personal information, in order to provide you with survey opportunities. If you wish to stop us from
                  selling or sharing your information or wish to delete your information or limit the processing of your
                  sensitive information, you will need to close your account. Please click the button below to proceed.
                </p>
                {status === UserStatus.REGISTRATION_PROCESS ? (
                  <div className="inline-block">
                    <RegistrationCloseAccount />
                  </div>
                ) : (
                  <DeleteAccountForm />
                )}
              </>
            )
          ) : (
            <CcpaLoginForm />
          ))}
      </div>
    </>
  );
};

export default CcpaPage;
