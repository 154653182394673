import { useFormContext } from 'react-hook-form';
import { MEDIA_BP, useDependsOnDataLoader, useMediaQuery, usePrevious } from '@ui/hooks';
import { useLocaleServices } from '@ui/contextProviders';
import React, { useCallback, useEffect, useId } from 'react';
import { If, IfNot } from '@ui/elements';
import { EarningBonus } from './elements';
import { centsToDollar } from '@services/currency';
import PropTypes from 'prop-types';
import { SortableDropdown } from '@ui/elements/sortableDropdown';

const potentiallyLongListNames = new Set(['country', 'state']);

DropdownSmartFormComponent.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  values: PropTypes.array,
  className: PropTypes.string,
  noInternalWrapper: PropTypes.bool,
  options: PropTypes.object,
  dependentsMap: PropTypes.object,
  connectedFields: PropTypes.array,
  moreLabel: PropTypes.string,
  ariaLabel: PropTypes.string,
  showIcon: PropTypes.bool,
};
/**
 *
 * @type {SmartFormSimpleComponent} DropdownSmartFormComponent
 * @returns {JSX.Element}
 * @constructor
 */
export function DropdownSmartFormComponent({
  name,
  label,
  values,
  className: classNameField = '',
  noInternalWrapper,
  options,
  dependentsMap,
  connectedFields,

  moreLabel = '',
  ariaLabel = '',
  showIcon,
}) {
  const {
    placeholder,
    show_filter, // TODO: ask backend to introduce this flag for long lists,
    sort = false,
    absolute: isAbsolutePositioned,
    submitOnChange,
  } = options;

  const isFilterOn = 'undefined' === typeof show_filter ? potentiallyLongListNames.has(name) : show_filter;

  const { t, currentLocale } = useLocaleServices();

  const { forceSubmit, getFieldState, getValues, watch, trigger, setValue, register, clearErrors } = useFormContext();

  if (dependentsMap && dependentsMap[name]) {
    watch(dependentsMap[name]);
  }

  const { ref: inputRef, ...field } = register(name, {
    deps: connectedFields,
  });
  const dropdownWatch = watch(name);
  const { error: fieldError } = getFieldState(name);

  const parentFieldValue =
    dependentsMap && dependentsMap[name]
      ? Array.isArray(dependentsMap[name])
        ? dependentsMap[name].map(getValues)
        : getValues(dependentsMap[name])
      : null;
  const previousParentValue = usePrevious(parentFieldValue);

  const { reload: reloadDataSource } = useDependsOnDataLoader(options, parentFieldValue, name);

  const handleOnChange = useCallback(
    event => {
      field.onChange(event);
      forceSubmit?.();
    },
    [field, forceSubmit]
  );

  useEffect(() => {
    if (previousParentValue && parentFieldValue && previousParentValue !== parentFieldValue) {
      if (dropdownWatch) {
        setValue(name, '');
      }
      clearErrors(name);
      void reloadDataSource(parentFieldValue);
    }
  }, [
    parentFieldValue,
    previousParentValue,
    reloadDataSource,
    getValues,
    setValue,
    name,
    trigger,
    clearErrors,
    dropdownWatch,
  ]);

  const labelDisplayFunc = ({ label, name }) => (label ? t(label) : name);
  //  const selectedItemTemplate = (option) => option?.label ? t(option.label) : option?.name || ''

  const fieldElement = (
    <SortableDropdown
      disabled={!values || !values.length}
      className={fieldError ? 'input_select p-invalid' : 'input_select'}
      options={values || []}
      optionLabel="name"
      optionValue="value"
      aria-label={ariaLabel}
      filter={isFilterOn}
      showFilterClear={isFilterOn}
      placeholder={t(placeholder)}
      // filterBy={t(values.label)}
      itemTemplate={labelDisplayFunc}
      name={name}
      {...field}
      {...(submitOnChange ? { onChange: handleOnChange } : {})}
      onBlur={null}
      inputRef={inputRef}
      {...(isAbsolutePositioned ? { appendTo: document.body } : {})}
      // SortableDropdown own props:
      sort={sort}
      sortCompareFunction={new Intl.Collator(currentLocale).compare}
      optionLabelFormatter={labelDisplayFunc}
    />
  );

  if (noInternalWrapper) {
    return fieldElement;
  }

  const wrapperProps = {
    name,
    classNameField,
    label,
    options,
    moreLabel,
    showIcon,
  };
  return <InternalWrapper {...wrapperProps}>{fieldElement}</InternalWrapper>;
}

InternalWrapper.propTypes = {
  children: PropTypes.any,
  name: PropTypes.string,
  classNameField: PropTypes.string,
  label: PropTypes.string,
  moreLabel: PropTypes.any,
  options: PropTypes.object,
  showIcon: PropTypes.bool,
};
function InternalWrapper({ children: fieldElement, name, classNameField, label, moreLabel, options, showIcon }) {
  const { t } = useLocaleServices();

  const isLargeScreen = useMediaQuery(MEDIA_BP.isOrGreaterThan768);
  const fieldId = useId();

  const { getFieldState, watch } = useFormContext();

  const dropdownWatch = watch(name);
  const { error: fieldError } = getFieldState(name);
  const validField = !fieldError;

  const { incentive_cents } = options;

  return (
    <div className={['field', classNameField, name].join(' ')}>
      <div className="field_label">
        <label className="label_left" htmlFor={fieldId}>{`${t(label)}${moreLabel}`}</label>
        <IfNot condition={isLargeScreen}>
          <EarningBonus
            value={`+ ${centsToDollar(incentive_cents)}`}
            condition={Boolean(!fieldError && validField && dropdownWatch)}
            hide={!incentive_cents || 0 === incentive_cents}
          />
          {showIcon && <div className={['ico', name].join(' ')} />}
        </IfNot>
      </div>

      <div className="input_wrap select">
        {fieldElement}
        <If condition={String(fieldError?.message) !== ''}>
          <div className={`error_msg`}>
            <span>{t(String(fieldError?.message ?? ''))}</span>
          </div>
        </If>
      </div>

      <If condition={isLargeScreen}>
        {showIcon && <div className={['ico', name].join(' ')} />}
        <EarningBonus
          value={`+ ${centsToDollar(incentive_cents)}`}
          condition={Boolean(!fieldError && validField && dropdownWatch)}
          hide={(fieldError && fieldError.message !== '') || !incentive_cents}
        />
      </If>
    </div>
  );
}
