import { signOutThunk } from '@model/actions/signoutAction';
import { userDeleteAccountAsyncThunk } from '@model/features/user/asyncActions';
import { forTimeout, safelyAwait } from '@services/promises';
import { STORAGE_KEY_LIST, persistDataToStorage } from '@services/storage';
import { useAppDispatch } from '@ui/hooks/redux';
import { routeNames } from '@ui/routes/routeNames';
import { isSuccessResponse } from '@ui/support/utils/guards';
import { useCallback } from 'react';
import { useNavigate } from 'react-router';

export function useDeleteAccountHandler() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleDeleteAccount = useCallback(async () => {
    // Call close ac action
    const [_, result] = await safelyAwait(dispatch(userDeleteAccountAsyncThunk()).unwrap()); // TODO: Replace with plain Api call instead of using redux
    // if success
    if (result != null && isSuccessResponse(result.response)) {
      // sign out
      dispatch(signOutThunk());
      // and persist data to storage
      const persistingValue = JSON.stringify(result.response);
      persistDataToStorage(STORAGE_KEY_LIST.DELETED, persistingValue);
      // Dirty hack
      await forTimeout(100); // TODO: WTF???? Remove this line
      // and navigate to account closed page
      navigate(routeNames.accountClosed, {
        state:
          result.response.subscription_code != null
            ? {
                code: result.response.subscription_code,
                valid_until: result.response.valid_until,
              }
            : {},
      });
    }
  }, [dispatch, navigate]);

  return handleDeleteAccount;
}
