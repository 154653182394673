import { useFormContext } from 'react-hook-form';
import { useCallback, useState, useRef } from 'react';
import { MEDIA_BP, useMediaQuery } from '@ui/hooks';
import { useLocaleServices } from '@ui/contextProviders';
import { Calendar } from 'primereact/calendar';
import { getTodayDate } from '@services/time';
import PropTypes from 'prop-types';

import pvs from './index.module.scss';

DateSmartFormComponent.propTypes = {
  name: PropTypes.string,
  options: PropTypes.object,
  ariaLabel: PropTypes.string,
};

/**
 * @type {SmartFormSimpleComponent} DateSmartFormComponent
 * @returns {JSX.Element}
 * @constructor
 */
export function DateSmartFormComponent({ name, options, ariaLabel = '' }) {
  const [fieldError, setFieldError] = useState(null);
  const isLargeScreen = useMediaQuery(MEDIA_BP.isOrGreaterThan768);
  const { t } = useLocaleServices();
  const inputElement = useRef();

  const { placeholder } = options;

  const { getFieldState, register, watch, formState, trigger } = useFormContext();
  const { ref, onChange, ...field } = register(name);
  void formState;
  const calendarWatch = watch(name);

  const maxDate = getTodayDate();
  const calendarValue =
    'string' === typeof calendarWatch && calendarWatch ? new Date(Date.parse(calendarWatch)) : calendarWatch;

  const handleChange = useCallback(
    async event => {
      event.preventDefault();
      onChange(event);
      trigger(name).then(() => {
        const { error, isTouched } = getFieldState(name);
        isTouched && setFieldError(error);
      });
    },
    [getFieldState, name, onChange, trigger]
  );

  return (
    <Calendar
      {...field}
      inputRef={ref}
      ref={inputElement}
      className={`w-full ${fieldError ? 'p-invalid' : ''}`}
      touchUI={!isLargeScreen}
      showButtonBar
      aria-label={ariaLabel}
      maxDate={maxDate}
      placeholder={t(placeholder)}
      value={calendarValue}
      onChange={handleChange}
      showIcon
      appendTo={inputElement.current?.getElement()}
      panelClassName={isLargeScreen ? `left-0 ${pvs.panel}` : ''}
    />
  );
}
