/**
 * Source: https://github.com/jquense/yup/blob/master/src/string.ts#LL22C3-L23C1
 * @type {RegExp}
 * @private
 */
const _latestYupEmailPattern =
  // eslint-disable-next-line unicorn/no-unsafe-regex
  /^[\w!#$%&'*+./=?^`{|}~-]+@[\dA-Za-z](?:[\dA-Za-z-]{0,61}[\dA-Za-z])?(?:\.[\dA-Za-z](?:[\dA-Za-z-]{0,61}[\dA-Za-z])?)*$/;

// see: https://stackoverflow.com/questions/201323/how-can-i-validate-an-email-address-using-a-regular-expression?answertab=trending#tab-top
export const updatedYupEmailPattern =
  // eslint-disable-next-line unicorn/no-unsafe-regex, no-control-regex
  /^(?:[a-z0-9#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\u0001-\u0008\u000B\u000C\u000E-\u001F\u0021\u0023-\u005B\u005D-\u007F]|\\[\u0001-\u0009\u000B\u000C\u000E-\u007F])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9][a-z0-9](?:[a-z0-9-]*[a-z])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\u0001-\u0008\u000B\u000C\u000E-\u001F\u0021-\u005A\u0053-\u007F]|\\[\u0001-\u0009\u000B\u000C\u000E-\u007F])+)\])$/iu;
