import { useLocaleServices } from '@ui/contextProviders/Locale';
import { MEDIA_BP, useMediaQuery } from '@ui/hooks';
import { Button } from '@ui/lib/Button';
import { paths } from '@ui/navigation';
import { routeNames } from '@ui/routes/routeNames';
import { FC } from 'react';
import { useLocation, useNavigate } from 'react-router';

export const AuthButtons: FC = () => {
  const { t } = useLocaleServices();
  const navigate = useNavigate();
  const location = useLocation();
  const isLarge = useMediaQuery(MEDIA_BP.isOrGreaterThan768);
  const isDesktop = useMediaQuery(MEDIA_BP.isOrGreaterThan1280);

  const isSignUp = location.pathname === paths.toSignUp;

  const toSignIn = () => {
    navigate(routeNames.loginEmailIndex);
  };

  const toSignUp = () => {
    navigate(routeNames.signUpIndex);
  };

  if (!isLarge) {
    return isSignUp ? (
      <Button
        label={t('sign_in.menu_button_label')}
        aria-label={t('sign_in.menu_button_label')}
        className="auth-button whitespace-nowrap"
        variant="primary"
        type="button"
        onClick={toSignIn}
      />
    ) : (
      <Button
        label={t('sign_up.menu_button_label')}
        aria-label={t('sign_up.menu_button_label')}
        variant="accent-a"
        className="auth-button whitespace-nowrap"
        type="button"
        onClick={toSignUp}
      />
    );
  }

  return (
    <div className="auth-button flex justify-end">
      <Button
        label={t('sign_in.menu_button_label')}
        aria-label={t('sign_in.menu_button_label')}
        variant="primary"
        type="button"
        big={isDesktop}
        onClick={toSignIn}
      />
      <Button
        label={t('sign_up.menu_button_label')}
        aria-label={t('sign_up.menu_button_label')}
        variant="accent-a"
        className="ml-4"
        type="button"
        big={isDesktop}
        onClick={toSignUp}
      />
    </div>
  );
};
