import { GTags } from '@/constants/google_tags';
import { getIsKeepMeLoggedIn } from '@model/features/auth';
import { apiSignUpUserByEmailAsyncThunk } from '@model/features/magic/asyncActions';
import { accessUserStatusChecks } from '@model/features/user';
import { apiUpdateEmailAndSendActivationAsyncThunk, userInfoAsyncThunk } from '@model/features/user/asyncActions';
import { gTagSender } from '@services/analytics/gTagSender';
import { getErrorMessage } from '@services/errors';
import { preprocessFormFields } from '@services/formHelpers';
import { safelyAwait } from '@services/promises';
import { registerByEmailFieldsData, setupEmailFieldsData } from '@ui/components/Register/register.fields.data';
import { ProfilingFieldWrapper } from '@ui/components/UpdateProfileSteps/profilingFieldWrapper';
import { useDidCodePromise, useLocaleServices } from '@ui/contextProviders';
import { FieldsFormWrapper } from '@ui/elements/FormWrapper';
import { CONST_FORM_ERROR } from '@ui/elements/FormWrapper/submissionResultInterpreter';
import { FormButton } from '@ui/elements/buttons';
import { useRefCode } from '@ui/hooks/useRefCode';
import { routeNames } from '@ui/routes/routeNames';
import { func } from 'prop-types';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

const name = 'login-form';

EmailSignUp.propTypes = {
  onFieldFocus: func,
};

/**
 *
 * This component is used both for sign up flow and for email setup when user is in email_setup state
 * @returns {JSX.Element}
 */
export function EmailSignUp({ onFieldFocus }) {
  const didPromise = useDidCodePromise();
  const { isUserInEmailSetup } = useSelector(accessUserStatusChecks());
  const fields = preprocessFormFields(isUserInEmailSetup ? setupEmailFieldsData : registerByEmailFieldsData);
  const { t } = useLocaleServices();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isRememberMe = getIsKeepMeLoggedIn();

  const [referralCode] = useRefCode();

  const onSubmitHandler = useCallback(
    async data => {
      const payload = isUserInEmailSetup
        ? {
            email: data['email'],
          }
        : {
            email: data['email'],
            terms_accepted: Boolean(data['read_policy']),
            refcode: referralCode,
            did: await didPromise,
            remember_me: data['remember_me'],
          };
      const [error, result] = await safelyAwait(
        dispatch(
          isUserInEmailSetup
            ? apiUpdateEmailAndSendActivationAsyncThunk(payload)
            : apiSignUpUserByEmailAsyncThunk(payload)
        ).unwrap()
      );
      if (error || result?.code !== 201) {
        const errorLabel = getErrorMessage(error || result);
        return errorLabel ? { [CONST_FORM_ERROR]: errorLabel } : null;
      }
      if (isUserInEmailSetup) {
        // expecting a status change after the successful result
        await dispatch(userInfoAsyncThunk({ noAutoRedirects: true }));
      }
      gTagSender(GTags.signUp);
      navigate(routeNames.usersEmailActivate, {
        state: { email: data['email'], code: result.response.data.code_head },
      });
      return null;
    },
    [referralCode, didPromise, dispatch, navigate, isUserInEmailSetup]
  );

  return (
    <FieldsFormWrapper
      hasPassword
      ignoreStoredValues
      name={name}
      className="pass_man-fix checkbox-small"
      onSubmit={onSubmitHandler}
      fields={fields}
      fieldsWrapperComponent={ProfilingFieldWrapper}
      defaultValues={{
        remember_me: isRememberMe,
      }}
      onFocus={onFieldFocus}
      afterFormElement={
        <FormButton
          label={t(isUserInEmailSetup ? 'setup_email.button' : 'sign_up.with_email_submit')}
          data-role="submit"
          data-testid={name}
          type="submit"
          variant="accent-a"
          className="!mt-8 w-full uppercase"
        />
      }
    />
  );
}
