import { getIsKeepMeLoggedIn, setIsKeepMeLoggedIn } from '@model/features/auth';
import { FIELD_TYPES } from '@services/fields';

import { Icons } from '@ui/lib/Icon';
import { rememberMeListener } from '../SocialButtons';

export const emailFieldWithoutMutation = Object.freeze({
  type: FIELD_TYPES.EMAIL,
  name: 'email',
  label: 'labels.email.label',
  options: {
    placeholder: 'labels.email.placeholder',
    ariaLabel: 'labels.email.aria',
    required: true,
    max: 255,
  },
  labels: {
    errors: {
      required: 'errors.validation.email.required',
      email: 'errors.validation.email.invalid',
      max: 'errors.validation.email.max',
    },
  },
});

export const emailField = {
  type: FIELD_TYPES.EMAIL,
  name: 'email',
  label: 'labels.email.label',
  options: {
    placeholder: 'labels.email.placeholder',
    ariaLabel: 'labels.email.aria',
    required: true,
    max: 255,
  },
  labels: {
    errors: {
      required: 'errors.validation.email.required',
      email: 'errors.validation.email.invalid',
      max: 'errors.validation.email.max',
    },
  },
};

export const rememberMeField = {
  type: FIELD_TYPES.CHECKBOX,
  name: 'remember_me',
  value: getIsKeepMeLoggedIn(),
  options: {
    checkbox_label: 'login.remember_me',
    ariaLabel: 'login.remember_me',
    tooltipIcon: {
      icon: Icons.Question,
      tooltip: 'login.keep_me_logged_in_tooltip',
    },
    onChangeCallback: checked => {
      rememberMeListener.putData({
        type: 'rememberMe',
        value: checked,
      });
      setIsKeepMeLoggedIn(checked);
    },
  },
  labels: {
    tooltip: 'login.keep_me_logged_in_tooltip',
  },
};

export const emailActivationLoginFieldsData = [emailField];
export const emailLoginFieldsData = [emailFieldWithoutMutation, rememberMeField];
export const loginFieldsData = [
  emailFieldWithoutMutation,
  {
    type: FIELD_TYPES.PASSWORD,
    name: 'password',
    label: 'labels.password.label',
    options: {
      placeholder: 'labels.password.simple_placeholder',
      ariaLabel: 'labels.password.simple_placeholder',
      footerLabel: 'labels.forgot_password',
      required: true,
    },
  },
  rememberMeField,
];
