export enum MAGIC_ACTION_NAMES {
  LOGIN = 'login',
  ADMIN_TOOL_LOGIN = 'silent_login',
  RESET_PASSWORD = 'reset_password',
  PASSWORD_CHANGE = 'change_password',
  REGISTRATION_EMAIL_ACTIVATION = 'email_activation',
  SETTINGS_EMAIL_CONFIRMATION = 'email_confirmation',
  SETTINGS_ALT_EMAIL_CONFIRMATION = 'alt_email_confirmation',
  SURVEY_INVITATION = 'survey_invitation',
}
