const MINUTE_VALUE = 0;

export function formatTimeFromNumber(num: number): string {
  if (num < 0 || num > 24) {
    throw new Error('Invalid input');
  }

  const date = new Date();
  date.setHours(24 === num ? 0 : num);
  date.setMinutes(MINUTE_VALUE);

  const options: Intl.DateTimeFormatOptions = {
    hour: 'numeric',
    minute: 'numeric',
  };
  return date.toLocaleTimeString(undefined, options);
}

export function formatSecondsToTime(totalSeconds?: number | null) {
  if (!totalSeconds) {
    return '00:00';
  }
  const hours = Math.floor(totalSeconds / 3600);
  if (hours > 24) {
    throw new Error('Invalid input');
  }
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;
  const time = `${padTo2(minutes)}:${padTo2(seconds)}`;

  if (hours > 0) {
    return `${hours}:${time}`;
  }

  return time;
}

function padTo2(number: number) {
  return (number < 10 ? '0' : '') + number;
}
