import { SupportButton as Button } from '@/ui/support';
import { useLocaleServices } from '@ui/contextProviders';
import { IconSprite } from '../../lib/IconSprite';

export const SupportButton = () => {
  const { t } = useLocaleServices();

  return (
    <div className="fixed right-[18px] top-[30vh] z-[1200] w-0 rotate-90 max-md:hidden">
      <Button
        data-testid="support-btn"
        className="flex w-fit items-center rounded-none bg-light-f !px-3 !py-2 !font-proxi text-dark-n !no-underline hover:bg-light-f dark:bg-dark-e dark:text-light-d dark:hover:bg-[#307694]"
      >
        <IconSprite iconId="question-mark" className="mr-2 h-4 w-4 fill-accent-a dark:fill-light-d" />
        <div className="whitespace-nowrap">{t('links.contact_us')}</div>
      </Button>
    </div>
  );
};
