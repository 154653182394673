import sprite from '@/resources/public/sprite.svg';
import { FC, HTMLProps, PropsWithChildren } from 'react';

export type IconName =
  | 'facebook_icon'
  | 'Instagram_icon'
  | 'heart-solid'
  | 'x-twitter-filled'
  | 'x-twitter'
  | 'x-twitter-outline'
  | 'youtube'
  | 'star'
  | 'sand-clock'
  | 'pv-logo'
  | 'pv-logo-beta'
  | 'pv-logo-small'
  | 'pv-logo-small-beta'
  | 'trash'
  | 'paypal-text'
  | 'paypal-logo'
  | 'ig-icon'
  | 'question-mark'
  | 'snowflake'
  | 'icon-celebration'
  | 'award'
  | 'user'
  | 'users'
  | 'dollar-circle';

type IconSprite = {
  iconId: IconName;
  className?: string;
} & HTMLProps<SVGSVGElement>;

export const IconSprite: FC<PropsWithChildren<IconSprite>> = ({ iconId, children, ...rest }) => (
  <svg {...rest}>
    {children}
    <use xlinkHref={`${sprite}#${iconId}`} />
  </svg>
);
