import * as yup from 'yup';

export const schemaSurveyItemResponse = yup.object({
  id: yup.string(), // 'bb1b4c65bd8c6f2e', or 'welcome'
  enabled: yup.boolean(), // true,
  type: yup.string(), // e.g.: 'MR'; the exact enum is in the making
  incentive_cents: yup.number(), // 343,
  incentive_percent: yup.number(), // 82,
  loi_seconds: yup.number(), // 150,
  loi_percent: yup.number(), // 25,
  button: yup
    .object({
      label: yup.string().required(),
      emphasis: yup.string().oneOf(['primary', 'secondary']),
    })
    .required(),
  affect_traitscore: yup.boolean(),
});

export const schemaSurveysResponse = yup.object({
  data: yup.array().of(schemaSurveyItemResponse).required(),
  meta: yup.object({
    refresh: yup.number().optional(),
    dashboard: yup
      .object({
        labels: yup.object({
          title: yup
            .object({
              label: yup.string().required(),
              data: yup.object().optional(),
            })
            .nullable()
            // eslint-disable-next-line unicorn/no-useless-undefined
            .default(undefined),
          subtitle: yup
            .object({
              label: yup.string().required(),
              data: yup.object().optional(),
            })
            .nullable()
            // eslint-disable-next-line unicorn/no-useless-undefined
            .default(undefined),
        }),
      })
      .optional(),
  }),
});

export const schemaSurveySessionGetResponse = yup
  .object({
    data: yup.object(),
  })
  .required();
export const schemaSurveySessionPostResponse = yup
  .object({
    data: yup
      .object({
        id: yup.string(), // "29220623ec1273da2d61a241"
      })
      .required(),
  })
  .required();

export const schemaSurveyItemGetRequestForm = yup.object({ surveyInvitationCode: yup.string().required() }).required();
export const schemaSurveySessionIdRequestForm = yup.object({ sessionId: yup.string().required() }).required();
