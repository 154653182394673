import {
  apiCashOutAsyncThunk,
  apiGetCashOutInformationAsyncThunk,
  apiGetEarningsHistoryAsyncThunk,
} from '@model/features/earnings/asyncActions';
import { createSlice } from '@reduxjs/toolkit';
import { formatCurrency } from '@services/currency';
import { isSuccessResponse } from '@ui/support/utils/guards';
import type { CashoutReason, EarningsState, PaypalVerification, PhoneVerification, Verifications } from './types';

const initialState: EarningsState = {
  methodName: null,
  status: null,
  earnings: null,
  verifications: null,
  historyData: null,
  historyMeta: null,
  amountToCashout: '0',
  isTableLoading: false,
  programs: [],
  result: null,
  reason: undefined,
  message: null,
};

const slice = createSlice({
  name: 'earnings',
  initialState,
  reducers: {
    resetCashOutMethod(state) {
      state.methodName = null;
    },
    setCashOutMethod(state, { payload }) {
      state.methodName = payload;
    },
    clearResult(state) {
      state.result = null;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(apiGetCashOutInformationAsyncThunk.fulfilled, (state, action) => {
        if (isSuccessResponse(action.payload.response)) {
          const {
            data: { status, earnings, verifications, programs, reason, message },
          } = action.payload.response;
          state.status = status;
          state.earnings = earnings;
          state.reason = reason as CashoutReason;
          state.message = message;
          if (programs != null) {
            state.programs = programs;
          }

          if (verifications != null) {
            const v: Verifications = { size: verifications.length + 2 };
            verifications.forEach(verification => {
              switch (verification.type) {
                case 'phone':
                  v.phone = verification as PhoneVerification;
                  break;
                case 'paypal_email':
                  v.paypal = verification as PaypalVerification;
                  break;
              }
            });
            state.verifications = v;
          }

          if ('pending' === status) {
            state.amountToCashout = formatCurrency(earnings.threshold - earnings.current, 'US');
          }
        }
      })
      .addCase(apiGetEarningsHistoryAsyncThunk.fulfilled, (state, action) => {
        if (!isSuccessResponse(action.payload.response)) {
          return;
        }
        const { data, meta } = action.payload.response;
        state.historyData = data ?? [];
        state.historyMeta = meta;
        state.isTableLoading = false;
      })
      .addCase(apiGetEarningsHistoryAsyncThunk.pending, state => {
        state.isTableLoading = true;
      })
      .addCase(apiCashOutAsyncThunk.fulfilled, (state, action) => {
        state.earnings = action.payload.earnings;
        state.status = action.payload.status;
        state.result = action.payload.result;
      });
  },
});

export default slice.reducer;

export const { resetCashOutMethod, setCashOutMethod, clearResult } = slice.actions;
