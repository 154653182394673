import { getDropdownValuesAsyncThunk } from '@model/features/registration/asyncThunks';
import { memoizeAsync, unwrapReduxAsyncAction } from '@services/promises';
import { usePrevious } from '@ui/hooks/usePrevious';
import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';

export const useDependsOnDataLoader = ({ depends_on, depends_on_value, values_from }, dependedValue, name) => {
  const previousValue = usePrevious(dependedValue);

  const dispatch = useDispatch();

  const memoizedDataLoader = useMemo(
    () =>
      memoizeAsync(async (_name, values_from, depends_on, value) => {
        //        console.debug(`Loading data source for ${name}`)
        const dataPayload = Array.isArray(depends_on)
          ? Object.fromEntries(depends_on.map((key, idx) => [key, value[idx]]))
          : {
              [depends_on]: String(value),
            };

        const {
          response: { data },
        } = await unwrapReduxAsyncAction(
          dispatch(
            getDropdownValuesAsyncThunk({
              urlTemplate: values_from, // '/api/v1/geo/countries/{country}/states',
              data: dataPayload,
            })
          )
        );
        return data;
      }),
    [dispatch]
  );

  const handleLoadData = useCallback(
    async ({ name, values_from, depends_on, value }) => {
      const [error, result] = await memoizedDataLoader(name, values_from, depends_on, value);
      if (error) {
        return null;
      }

      return result?.code !== 200 || result?.code !== 201 ? null : result.response.data;
    },
    [memoizedDataLoader]
  );

  const reload = useCallback(
    dependedValue => handleLoadData({ value: dependedValue, depends_on, values_from, name }),
    [depends_on, handleLoadData, values_from, name]
  );

  useEffect(() => {
    if (!values_from) {
      return;
    }
    if (depends_on_value === dependedValue) {
      return;
    }
    if (dependedValue && dependedValue !== previousValue) {
      void handleLoadData({ value: dependedValue, depends_on, values_from, name });
    }
  }, [depends_on, handleLoadData, previousValue, values_from, dependedValue, name, depends_on_value]);

  return { reload };
};
