export const FIELD_NAMES = {
  DEMOGRAPHICS_PARENTAL_FIELD_NAME: 'demographic_parental',
  PARENTAL_FIELD_NAME: 'parental',
  CHILDREN_FIELD_NAME: 'children',
  GENDER_FIELD_NAME: 'gender',
  MONTH_FIELD_NAME: 'birth_month',
  YEAR_FIELD_NAME: 'birth_year',
  COUNTY: 'county',
  ZIP_CODE: 'zip_code',
  ZIP: 'zip',
  PHONE: 'phone',
  COUNTRY: 'country',
  CONSENT: 'consent',
  RESET_PASSWORD: 'reset_password',
  STREET_ADDRESS: 'street_address',
  STATE: 'state',
  DEMOGRAPHICS_GEO: 'demographic_geo',
  COUNTRY_STATIC: 'country_static',
  REFERRAL_INPUT_NAME: 'input_referral',
  INVITE_INPUT_NAME: 'invite',
  DEMOGRAPHIC_BIRTHDAY: 'demographic_birthdate',
};
