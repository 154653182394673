import { useEffect } from 'react';
const originalTitle = 'PaidViewpoint | Get paid to take surveys online';

export const usePageTitle = (title: string) => {
  useEffect(() => {
    document.title = title || originalTitle;

    // eslint-disable-next-line consistent-return
    return () => {
      document.title = originalTitle;
    };
  }, [title]);
};
