import { routeNames } from '@ui/routes/routeNames';

export const paths = {
  toRoot: `/`,
  toHome: `/`,
  toLogin: `/login/password`,
  toLoginWithEmail: `/login`, // *
  toLoginConfirmation: `/login/email/code`,
  toSignUp: `/register`,
  toVideo: `/video`,
  toAnyCountry: '/country/',
  toUnsupported: routeNames.countryUnsupported,
  toForbidden: routeNames.countryForbidden,

  toDashboard: '/dashboard',

  toMagicLink: `/magic`,
  toMagicLinkCode: code => `/magic/${code}`,

  toAbout: `/pages/about`,
  toToS: `/pages/tos`,
  toPrivacy: `/pages/privacy`,
  toSupport: `/pages/support`,
  toFAQ: `/pages/faq`,
  toNotSellInfo: `/pages/not_sell_info`,
  toRules: `/pages/rules`,
  toOurTeam: `/pages/team`,

  toFriends: routeNames.friendsIndex,
  toRegistered: routeNames.friendsRegistered,
  toEarnings: routeNames.earningsIndex,
  toEarningsHistory: routeNames.earningsHistory,
  toDemographics: `/demographics`,
  toDemographicTraits: `/demographics/traits`,
  toTraitScore: `/traitscore`,
  toSettings: `/settings`,
  toSurvey: `/survey`,
  toSurveyConflict: `/survey/conflict`,
  toSurveyCode: code => `/survey/${code}`,

  toSandbox: `/sandbox`,

  toRegistration: `/registration`,
  toEmailActivation: '/users/email/activate',

  toError: `/error`,
  /**
   * @param {string|number} code
   * @param {{from: string}|string} state
   */
  toErrorCode: (code, state) => (state ? [`/error/${code}`, state] : `/error/${code}`),

  atPassword: `/users/password`,
  // sample: https://paidviewpoint.com/users/password/forgot?email=obuhov.gh@gmail.com
  toForgotPassword: `/user/password/forgot`,
  // sample: https://pv-beta-1044-spa-react-rails.review.vpn.aytm.com/users/password/reset?reset_code=23eb39a9163a3f2d71368de41ed71f2082a02e18
  toResetPassword: `/user/password/reset`,
  toOAuthAuthorization: providerName => `/auth/${providerName}?callback_behavior=api`,
};
/**
 * @type {Record<string, keyof typeof paths>}
 */
export const usedPaths = Object.entries(paths).reduce((ret, entry) => {
  const [key, value] = entry;
  if ('string' === typeof value) {
    ret[value] = key;
  }
  return ret;
}, {});

export const isOnPage = path => pathname => pathname === path;
export const isOnParentPage = path => pathname => pathname.startsWith(path);
export const isOnPages =
  (...paths) =>
  pathname =>
    paths.some(routePath => routePath.startsWith(pathname));
export const isOnErrorPage = isOnParentPage(routeNames.errorIndex);
export const isOnTopHomePagesCheck = isOnPages(routeNames.root, routeNames.dashboard, routeNames.registrationIndex);
