import { useAuth } from '@ui/contextProviders';
import { IconSprite } from '@ui/elements';
import { MEDIA_BP, useMediaQuery } from '@ui/hooks';
import { paths } from '@ui/navigation';
import { Link } from 'react-router-dom';

export function HomePageLogo() {
  const landing = window.tenant?.config?.landing;
  const isDesktop = useMediaQuery(MEDIA_BP.isOrGreaterThan1024);
  const { isAuthenticated } = useAuth();

  if (!isAuthenticated && landing?.host) {
    const landingHost = landing.host;
    return (
      <a
        href={`//${landingHost}`}
        className="app-header__logo block"
        tabIndex={0}
        aria-label="home"
        data-testid="header-logo"
      >
        <IconSprite iconId={isDesktop ? 'pv-logo' : 'pv-logo-small'} className="pv-logo" />
      </a>
    );
  }

  return (
    <Link
      to={paths.toDashboard}
      className="app-header__logo block"
      tabIndex={0}
      aria-label="home"
      data-testid="header-logo"
    >
      <IconSprite iconId={isDesktop ? 'pv-logo' : 'pv-logo-small'} className="pv-logo" />
    </Link>
  );
}
