import { initFirebase } from '@services/fcm';
import { dataCollector } from '@services/fcm/dataCollector';
import { isNotificationSupported } from '@services/fcm/utils';
import { ErrorLogger } from './utils/ErrorLogger';
import { Logger } from './utils/Logger';

export function registerSwWithFcm() {
  window.addEventListener('load', async () => {
    try {
      const registration = await handleSwReg();
      if (registration) {
        initFirebase(registration);
        // Check if SW installing and wait for it to be activated
        if (registration.installing) {
          Logger.log('Service worker installing', registration);
          registration.installing.addEventListener('statechange', handleStateChange);
        } else if (registration.waiting) {
          Logger.log('Service worker waiting', registration);
          registration.waiting.addEventListener('statechange', handleStateChange);
        } else if (registration.active) {
          Logger.log('Service worker active', registration);
          handleSwLoaded();
        }
      }
    } catch (error) {
      ErrorLogger.setExtra('debugInfo', 'Error during service worker registration').send(error);
    }
  });
}

function handleStateChange(event: Event) {
  const state = (event.target as ServiceWorker).state;
  if ('activated' === state) {
    handleSwLoaded();
  }
}

function handleSwLoaded() {
  // TODO Show user notification that SW is ready and we ask to enable pushes
  // Will be done in separate task
  dataCollector.putData({
    type: 'swLoaded',
    value: true,
  });
}

async function handleSwReg() {
  if ('development' !== process.env.NODE_ENV) {
    const publicUrl = new URL(process.env.PUBLIC_URL as string, window.location.href);
    if (publicUrl.origin !== window.location.origin) {
      // Our service worker won't work if PUBLIC_URL is on a different origin
      // from what our page is served on. This might happen if a CDN is used to
      // serve assets; see https://github.com/facebook/create-react-app/issues/2374
      throw new Error('PUBLIC_URL must be on the same origin as the page');
    }
    const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;
    return registerValidSW(swUrl);
  } else {
    const swUrl = `spa-assets/service-worker.js`;
    return registerValidSW(swUrl);
  }
}

async function registerValidSW(swUrl: string) {
  try {
    if (navigator.serviceWorker) {
      const registration = await navigator.serviceWorker.register(swUrl, {
        type: 'module',
      });
      registration.update();
      return registration;
    }
    return null;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error during service worker registration:', error);
    throw error;
  }
}

export function unregister() {
  if (isNotificationSupported()) {
    navigator.serviceWorker.ready
      .then(registration => {
        registration.unregister();
      })
      .catch(error => {
        ErrorLogger.setExtra('debugInfo', 'Error during service worker unregistration').send(error);
      });
  }
}
