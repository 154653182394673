import { isNotTooYoungChecker } from '@services/calendar';
import { FIELD_TYPES } from '@services/fields';
import { isValidDate } from '@services/time/date';
import { FieldsFormWrapper } from '@ui/elements/FormWrapper';
import PropTypes from 'prop-types';
import { useCallback, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { ProfilingBirthdayWrapper } from './profilingBirthdayWrapper';

const profilingBirthdayFieldsData = [
  {
    type: FIELD_TYPES.SEGMENTED_DATE,
    name: 'date',
    options: {},
  },
  {
    type: FIELD_TYPES.CHECKBOX_CLEAN,
    name: 'age_consent',
    label: 'profiling.birthdate.allowed_age',
    options: {},
  },
];

function useBirthdayComponentFields(options, birthday) {
  return useMemo(() => {
    const isNotTooYoung = isNotTooYoungChecker(options.min_age);
    const [date, consent] = profilingBirthdayFieldsData;
    date.options.min_age = options.min_age;
    consent.options.min_age_value = options.min_age;
    if (!birthday || isNotTooYoung(birthday) || !isValidDate(birthday)) {
      return [date];
    }
    return [date, consent];
  }, [birthday, options.min_age]);
}

ProfilingBirthdayComponent.propTypes = {
  name: PropTypes.string,
  options: PropTypes.object,
};

export function ProfilingBirthdayComponent({ name, options }) {
  const { register, setValue, trigger, clearErrors } = useFormContext();

  const [birthday, setBirthday] = useState(null);
  const fields = useBirthdayComponentFields(options, birthday);
  const formBuildingMapperOptions = useMemo(
    () =>
      Object({
        preferClean: true,
        masterOptions: options,
        noInternalWrapper: true,
      }),
    [options]
  );

  void register(name);

  const onChangeHandler = useCallback(
    data => {
      const { date, age_consent } = data || {};
      if (date) {
        setBirthday(date);
        if (isNotTooYoungChecker(options.min_age)(date)) {
          setValue(`${name}.date`, date);
          setValue(`${name}.age_consent`, true);
        } else {
          if (age_consent) {
            setValue(`${name}.date`, date);
            setValue(`${name}.age_consent`, age_consent);
          } else {
            setValue(`${name}`, null);
          }
        }
      } else {
        setBirthday(null);
        setValue(`${name}`, null);
      }
      clearErrors(name);
      void trigger(name);
    },
    [clearErrors, name, options.min_age, setValue, trigger]
  );

  // TODO: Fix flex gap
  return (
    <div className="flex flex-col gap-1">
      <FieldsFormWrapper
        bareFields
        submitOnChange
        onSubmit={onChangeHandler}
        formClassName="profilingBirthdateAndConsent"
        name={`complex-${name}`}
        onInit={onChangeHandler}
        onChange={onChangeHandler}
        fields={fields}
        formBuildingMapperOptions={formBuildingMapperOptions}
        fieldsWrapperComponent={ProfilingBirthdayWrapper}
      />
    </div>
  );
}
