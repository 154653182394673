import { apiService } from '@/api/ApiService';
import { signOutThunk } from '@model/actions/signoutAction';
import { CLIENT_ERRORS, EndpointError } from '@model/api/endpointError';
import { PluggableConditionCheckerType } from '@services/reduxToolkit/pluggableConditionChecker';
import { setRedirectPath } from '@ui/routes/ProtectedRoute';

// TODO Remove this bullshit
export const pluggable401ConditionChecker: PluggableConditionCheckerType = async (
  err,
  retries,
  refreshToken,
  dispatch
) => {
  if (
    EndpointError.isEndpointError(err) &&
    err.cause === CLIENT_ERRORS.SERVER_UNEXPECTED_STATUS_CODE &&
    401 === err.code &&
    retries < 3
  ) {
    if (!refreshToken) {
      return false;
    }

    // TODO: TMP. Need to be removed and moved to new client
    // if error is AuthorizationInsufficientScopes that means that user has no access to the resource and we need to reauth
    if ('AuthorizationInsufficientScopes' === err.response?.errors[0].error) {
      setRedirectPath({ state: { reauth: true } });
      dispatch(signOutThunk());
    }

    const refreshResult = await apiService.refreshToken(refreshToken);
    if (refreshResult) {
      return true;
    }
  }

  return false;
};
