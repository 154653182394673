import { classNames } from 'primereact/utils';
import { FC } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { ButtonVariant } from './commonTypes';

type RouterLinkProps = {
  buttonLink?: boolean;
  disabled?: boolean;
  variant?: ButtonVariant;
} & LinkProps;

export const RouterLink: FC<RouterLinkProps> = ({
  className,
  buttonLink,
  disabled,
  variant = 'primary',
  children,
  ...props
}) => (
  <Link
    className={classNames(className, `p-button p-component p-button-${variant}`, {
      'justify-center !no-underline': buttonLink,
      'p-button-text': !buttonLink,
      'p-disabled': disabled,
    })}
    {...props}
  >
    <span className="p-button-label">{children}</span>
  </Link>
);
