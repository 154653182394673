import { timeDifference } from '@services/time/difference';

export function getDistanceInWordsToNow(date: Date, t: (key: string) => string, suffix?: string) {
  const distance = timeDifference.differenceBetween(new Date(), date);
  const path = timeDifference.getLocaleUnitPath(distance);

  let str = t(path);

  if (path.endsWith('other')) {
    const count = timeDifference.getDifferenceCount(distance);
    str = str.replace('%{count}', count.toString());
  }

  if (suffix) {
    return `${str} ${suffix}`;
  }
  return str;
}
