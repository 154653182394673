import { centsToDollar } from '@services/currency';
import { useLocaleServices } from '@ui/contextProviders';
import { MEDIA_BP, useMediaQuery } from '@ui/hooks';
import { UI_TEST_ASSIST_EXT } from '@ui/testids';
import { InputText } from 'primereact/inputtext';
import PropTypes from 'prop-types';
import { useCallback, useId, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { EarningBonus, Tick, getBeautyName } from '../../elements';

TextConfirmSmartFormComponent.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  showTick: PropTypes.bool,
  options: PropTypes.object,
};

const MessageOnHover = () => (
  <div className="position">
    <div className="message paypal_msg" role="alert">
      <strong>We want to make sure you get</strong>
      <br />
      <strong>your rewards!</strong> We request your
      <br />
      PayPal email to validate your
      <br />
      unique PV account, and ensure
      <br />
      we’re sending your money
      <br />
      to the right place.
      <br />
    </div>
  </div>
);

/**
 * @type {SmartFormComplexComponent} TextConfirmSmartFormComponent
 * @return {JSX.Element}
 * @constructor
 */
export function TextConfirmSmartFormComponent({
  name: masterName,
  label,
  className: classNameField = '',
  showTick,
  options,
}) {
  const confirmName = masterName + '_confirmation';
  const { placeholder = '', incentive_cents } = options || {};
  const arrayMasterSlaveFields = [
    {
      name: masterName,
      label: label,
      placeholder: placeholder,
      ariaLabel: `enter ${getBeautyName(masterName)}`,
    },
    {
      name: confirmName,
      label: 'labels.paypal_email_confirmation.label', // TODO: get from back 'salve_label' or same
      placeholder: '', // TODO: get from back 'salve_placeholder' or same
      ariaLabel: `enter ${getBeautyName(confirmName)}`,
    },
  ];
  const [fieldError, setFieldError] = useState(null);
  const [fieldTouched, setFieldTouched] = useState(false);

  const { t } = useLocaleServices();
  const isLargeScreen = useMediaQuery(MEDIA_BP.isOrGreaterThan768);
  const fieldId = useId();
  const { getFieldState, register, trigger, getValues } = useFormContext();
  const isRestoredValue = fieldTouched ? false : getValues(masterName) && getValues(confirmName);
  const isValid = Boolean(!fieldError && showTick && isRestoredValue);

  const handleChange = useCallback(async (event, onChange) => {
    event?.preventDefault?.();
    await onChange(event);
    setFieldTouched(true);
  }, []);

  const handleBlur = useCallback(
    async (event, onBlur) => {
      event?.preventDefault?.();
      await onBlur(event);
      await trigger([masterName, confirmName]);
      const { error: masterFieldError, isTouched: masterIsTouched } = getFieldState(masterName);
      const { error: confirmFieldError, isTouched: confirmIsTouched } = getFieldState(confirmName);
      if (masterIsTouched && confirmIsTouched) {
        const error = masterFieldError ?? confirmFieldError ?? null;
        setFieldError(error);
        !error && setFieldTouched(false);
      }
    },
    [masterName, confirmName, trigger, getFieldState]
  );

  return (
    <>
      {arrayMasterSlaveFields.map(({ name, label, placeholder, ariaLabel }, indexField) => {
        const id = fieldId + name;
        const { onBlur, onChange, ...field } = register(name);

        return (
          <div {...UI_TEST_ASSIST_EXT.CMP_CONFIRM(name)} className={['field', classNameField, name].join(' ')} key={id}>
            <div className="field_label">
              <label className="label_left" htmlFor={id}>
                {t(label)}
              </label>
              {!isLargeScreen && 0 === indexField && <EarningsElement {...{ incentive_cents, isValid }} />}
            </div>

            <div className="input_wrap">
              <InputText
                {...UI_TEST_ASSIST_EXT.FLD_CONFIRM_INPUT(name)}
                {...field}
                onBlur={event => handleBlur(event, onBlur)}
                onChange={event => handleChange(event, onChange)}
                id={id}
                aria-label={ariaLabel}
                placeholder={t(placeholder)}
                className={`input_text ${fieldError ? 'p-invalid' : ''}`}
                autoComplete="off"
              />
            </div>
            {isLargeScreen && 0 === indexField && (
              <>
                <Tick condition={isValid} />
                <EarningsElement {...{ incentive_cents, isValid }} />
              </>
            )}
          </div>
        );
      })}
      {fieldError && <ErrorMessage {...{ classNameField, masterName }} fieldError={t(fieldError?.message)} />}
      {'registration' === classNameField && isLargeScreen && <MessageOnHover />}
    </>
  );
}

EarningsElement.propTypes = {
  incentive_cents: PropTypes.number,
  isValid: PropTypes.bool,
};
function EarningsElement({ incentive_cents, isValid }) {
  return <EarningBonus value={`+ ${centsToDollar(incentive_cents)}`} condition={isValid} hide={!incentive_cents} />;
}

ErrorMessage.propTypes = {
  fieldError: PropTypes.string,
  classNameField: PropTypes.string,
  name: PropTypes.string,
};
function ErrorMessage({ fieldError, classNameField, name }) {
  return (
    <div {...UI_TEST_ASSIST_EXT.ERR_CONFIRM(name)} className={`field field_for_error ${classNameField}`}>
      <div className="field_label">
        <label className="label_left" />
      </div>
      <div className="input_wrap">
        <div className="error_msg">
          <span>{fieldError}</span>
        </div>
      </div>
    </div>
  );
}
