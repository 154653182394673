export const FIELD_TYPES = {
  TEXT: 'text',
  STATIC_TEXT: 'static_text',
  TEXT_CLEAN: 'TEXT_CLEAN',
  PASSWORD: 'password',
  EMAIL: 'email',
  DROPDOWN: 'dropdown',
  DROPDOWN_CLEAN: 'DROPDOWN_CLEAN',
  CHECKBOX_CLEAN: 'CHECKBOX_CLEAN',
  /**
   * @deprecated
   */
  MULTISELECT: 'multiselect',
  CHECKBOX: 'checkbox',
  SINGLE_CHOICE: 'single_choice',
  DATE: 'date',
  BIRTHDATE: 'birthdate',
  PLACES_AUTOCOMPLETE: 'places_auto_complete',
  // ??
  PUSH_NOTIFICATION: 'push_notification',

  // complex components
  AVATAR: 'avatar',
  PAYPAL_EMAIL_CHANGE: 'paypal_email_change',
  PHONE_CHANGE: 'phone_change',
  SOCIAL_AUTHORIZATION: 'social_authorization',
  EMAIL_ACTIVATION: 'email_activation',
  PHONE_ACTIVATION: 'phone_activation',
  TIME_RANGE: 'time_range',
  DATA_EXPORT: 'user_data_export',
  TRAIT_PARENTAL: 'trait_parental',
  TRAIT_CHILDREN: 'trait_children',

  PROFILING_USERNAME: 'profiling_username',
  PROFILING_PASSWORD: 'profiling_password',
  PROFILING_BIRTHDATE: 'profiling_birthdate',
  PROFILING_PHONE: 'profiling_phone',

  ADDRESS: 'trait_geo',
  ERROR_GROUP: 'errors',

  SEGMENTED_DATE: 'SEGMENTED_DATE',
} as const;
