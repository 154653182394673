import { RootState } from '@/store';

interface StateAccessor<V, Key extends keyof RootState> {
  (state: RootState[Key]): V | undefined | null;
}

export const accessor =
  <Key extends keyof RootState>(name: Key) =>
  <V, NKey extends keyof RootState[Key]>(prop: NKey | StateAccessor<V, Key>, fallback?: V | null) =>
    'function' === typeof prop
      ? (state: RootState) => prop(state[name]) ?? fallback
      : (state: RootState) => {
          const slice = state[name];
          return prop && slice ? slice[prop] ?? fallback : state[name];
        };
