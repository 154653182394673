import { acceptanceApi } from './acceptance';
import { authApi } from './auth';
import { commonAli } from './common';
import { earningsApi } from './earnings';
import { supportApi } from './support';
import { surveyApi } from './survey';
import { userApi } from './user';

export const ApiClient = {
  common: commonAli,
  auth: authApi,
  acceptance: acceptanceApi,
  user: userApi,
  survey: surveyApi,
  support: supportApi,
  earnings: earningsApi,
};
