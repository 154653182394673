import { blockNavigation } from '@model/features/navigation';
import { STORAGE_KEY_LIST, removeDataFromStorage, restoreDataFromStorage } from '@services/storage';

export const restoreSurveyInvitationCodeAndProcessIt = codeHandler => dispatch => {
  dispatch(blockNavigation(false));
  const code = restoreDataFromStorage(STORAGE_KEY_LIST.SURVEY_INVITATION_CODE, null, window.sessionStorage);
  return code ? codeHandler(code) : null;
};

export function cleanSurveyInvitationCode() {
  removeDataFromStorage(STORAGE_KEY_LIST.SURVEY_INVITATION_CODE, window.sessionStorage);
}
