interface NotificationLike {
  data: any;
}

export function getNotificationLink(notification: NotificationLike) {
  // TODO check mobile browsers
  const link = notification.data?.link;

  if (link) {
    return link;
  }

  return null;
}

export function canOpenWindow(link: string, globalThis: { location: { href: string; origin: string } }) {
  const url = new URL(link, globalThis.location.href);
  const originUrl = new URL(globalThis.location.origin);

  return url.host === originUrl.host;
}

export const isNotificationSupported = () =>
  'Notification' in window && 'serviceWorker' in navigator && 'PushManager' in window;
