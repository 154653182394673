import {
  getSupportTicket,
  getSupportTicketsList,
  postCommentToSupportTicket,
  resolveSupportTicket,
} from '@model/api/apiCommunication';
import { createAsyncThunkWithTokenRefresh, serializeErrorOption } from '@services/reduxToolkit';

export const getSupportTicketsListAsyncThunk = createAsyncThunkWithTokenRefresh(
  'api/support/getSupportTickets',
  getSupportTicketsList,
  serializeErrorOption
);

export const getSupportTicketAsyncThunk = createAsyncThunkWithTokenRefresh(
  'api/support/getSupportTickets',
  getSupportTicket,
  serializeErrorOption
);

export const submitTicketCommentAsyncThunk = createAsyncThunkWithTokenRefresh(
  'api/support/submitSupportTicketComment',
  postCommentToSupportTicket,
  serializeErrorOption
);

export const resolveSupportTicketAsyncThunk = createAsyncThunkWithTokenRefresh(
  'api/support/resolveSupportTicket',
  resolveSupportTicket,
  serializeErrorOption
);
