import { AppDispatch } from '@/store';
import { EndpointError } from '@model/api/endpointError';

export type PluggableConditionCheckerType = (
  err: Error | EndpointError | null,
  retries: number,
  refreshToken: string | null,
  dispatch: AppDispatch
) => Promise<boolean>;

const conditionChecker: { current: null | PluggableConditionCheckerType } = { current: null };

export function pluggableConditionCheckerRegister(fn: PluggableConditionCheckerType) {
  conditionChecker.current = fn;
}

export const pluggableConditionChecker: PluggableConditionCheckerType = async (err, retries, rToken, dispatch) =>
  conditionChecker.current?.(err, retries, rToken, dispatch) ?? false;
