import { useLocaleServices } from '@ui/contextProviders';
import { useFormContext } from 'react-hook-form';
import { MEDIA_BP, useMediaQuery } from '@ui/hooks';
import { If, IfNot } from '@ui/elements';
import { EarningBonus, Tick } from '@ui/components/SmartFormComponents/elements';
import { centsToDollar } from '@services/currency';
import { RadioButton } from 'primereact/radiobutton';
import PropTypes from 'prop-types';

SingleChoiceSmartFormComponent.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  options: PropTypes.object,
  values: PropTypes.array,
};
/**
 * @type {SmartFormSimpleComponent} SingleChoiceSmartFormComponent
 * @returns {JSX.Element}
 * @constructor
 */
export function SingleChoiceSmartFormComponent({ name, label, className: classNameField = '', options, values }) {
  const { className, incentive_cents } = options;
  const { t } = useLocaleServices();
  const { register, watch, getFieldState } = useFormContext();
  const isLargeScreen = useMediaQuery(MEDIA_BP.isOrGreaterThan768);
  const { error: fieldError } = getFieldState(name);

  const chosenValue = watch(name);

  return (
    <div className={['field', classNameField].join(' ')}>
      <div className="field_label">
        <label className="label_left">{t(label)}</label>
        <IfNot condition={isLargeScreen && incentive_cents}>
          <EarningBonus value={`+ ${centsToDollar(incentive_cents)}`} condition={!fieldError} />
        </IfNot>
      </div>

      <div className={className}>
        {values.map(({ value, label }, _index) => (
          <label key={value} className="radio_item">
            <RadioButton
              {...register(name)}
              className="input_radio"
              name={name}
              value={value}
              checked={chosenValue === value}
            />
            <span>{label}</span>
          </label>
        ))}
      </div>
      <If condition={isLargeScreen}>
        <Tick condition={!fieldError} />
      </If>
      <If condition={isLargeScreen && incentive_cents}>
        <EarningBonus value={`+ ${centsToDollar(incentive_cents)}`} condition={!fieldError} />
      </If>
    </div>
  );
}
