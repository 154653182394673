import { useLocaleServices } from '@ui/contextProviders';
import { UI_TEST_ASSIST_EXT } from '@ui/testids';
import { Password } from 'primereact/password';
import PropTypes from 'prop-types';
import { useId } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

PasswordSmartFormComponent.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.object,
};

/**
 * @type {SmartFormSimpleComponent} PasswordSmartFormComponent
 * @returns {JSX.Element}
 */
export function PasswordSmartFormComponent({ name, options = {} }) {
  if (!name) {
    throw new Error(`Missing prop 'name'`);
  }

  const { placeholder, ariaLabel, tooltip } = options || {};

  const { t } = useLocaleServices();
  const fieldId = useId();

  const { formState, formId } = useFormContext();

  const { isSubmitting } = formState;

  return (
    <Controller
      name={name}
      render={({ field: { ref, ...field }, fieldState: { error } }) => (
        <Password
          {...UI_TEST_ASSIST_EXT.FLD_PASSWORD_SMART(name)}
          name={name}
          {...field}
          inputRef={ref}
          placeholder={t(placeholder)}
          autoComplete={options.autoComplete ?? 'current-password'}
          aria-label={t(ariaLabel)}
          toggleMask
          feedback={false}
          inputId={fieldId}
          className="input_text"
          inputClassName={`input_text ${error ? ' p-invalid' : ''}`}
          tooltip={t(tooltip)}
          form={formId}
          {...(isSubmitting ? { disabled: true } : {})}
        />
      )}
    />
  );
}
