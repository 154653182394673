import { ErrorLogger } from './ErrorLogger';

/**
 * Converts a JavaScript object or value to a JSON string.
 * @param data - The value to convert to a JSON string.
 * @param replacer - A function that alters the behavior of the stringification process, or an array of String and Number objects that serve as a whitelist for selecting/filtering the properties of the value object to be included in the JSON string.
 * @param space - A String or Number object that's used to insert white space into the output JSON string for readability purposes.
 * @returns A JSON string representing the given value, or null if an error occurs during the stringification process.
 */
export function stringifyJson(
  data: any,
  replacer?: (this: any, key: string, value: any) => any,
  space?: string | number
) {
  if (null == data) return null;
  try {
    return JSON.stringify(data, replacer, space);
  } catch (error) {
    ErrorLogger.setFnName('stringifyJson').send(error);
    return null;
  }
}

/**
 * Parses a JSON string and returns the resulting JavaScript object.
 * @param text - The JSON string to parse.
 * @param reviver - A function that transforms the results. This function is called for each member of the object.
 * @returns The resulting JavaScript object, or null if the input is not valid JSON.
 */
export function parseJson<T>(text: string, reviver?: (this: any, key: string, value: any) => any): T | null {
  try {
    return JSON.parse(text, reviver);
  } catch (error) {
    ErrorLogger.setFnName('parseJson').send(error);
    return null;
  }
}
