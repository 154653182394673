import { concatClsx } from '@/utils/classnames';
import { BALLOON, getLocalizedName, knownBalloonResourcesMap } from '@ui/components/InfoBalloon/resources';
import PropTypes from 'prop-types';
import pvs from './styles.module.scss';

InfoBalloon.propTypes = {
  name: PropTypes.oneOf([...Object.values(BALLOON)]),
  className: PropTypes.string,
  title: PropTypes.string,
  locale: PropTypes.string,
};

export function InfoBalloon({ className, name, title, locale }) {
  const balloonData = knownBalloonResourcesMap[name] ?? null;
  if (null === balloonData) {
    return null;
  }
  const { fallbackImage, sources } = balloonData;
  return (
    <picture className={concatClsx(pvs.info_balloon, className)} title={title ?? fallbackImage.name}>
      {sources &&
        sources.map(imageData => (
          <source
            key={`${imageData.name}${imageData.namePostfix}`}
            srcSet={getLocalizedName(imageData, locale)}
            type={imageData.mimeType}
            media={imageData.mediaQuery}
          />
        ))}
      <img src={getLocalizedName(fallbackImage, locale)} alt={title ?? fallbackImage.name} />
    </picture>
  );
}
