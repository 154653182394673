import { useLocaleServices } from '@/ui/contextProviders/Locale';
import { IconCamera } from '@ui/elements/icons';
import { DropCallback, useWindowFileDrop } from '@ui/hooks/useWindowFileDrop';
import { Icon, Icons } from '@ui/lib/Icon';
import { Dialog } from 'primereact/dialog';
import React, { HTMLAttributes, RefObject, useCallback } from 'react';
import { createPortal } from 'react-dom';
import { FieldValues, useController } from 'react-hook-form';
import { toBlob } from '../../utils/screenshoter';
import { FilePreview } from './FilePreview';
import styles from './FormInputFile.module.scss';
import type { FormFieldBaseProps } from './types';

type FormInputFileProps<TFieldValues extends FieldValues> = Omit<
  FormFieldBaseProps<TFieldValues, HTMLAttributes<HTMLInputElement>>,
  'label'
> & {
  dialogRef?: RefObject<Dialog>;
  isMobile?: boolean;
};

export const FormInputFile = <TFieldValues extends FieldValues>({
  name,
  control,
  rules,
  className,
  dialogRef,
  trigger,
  isMobile,
  ...rest
}: FormInputFileProps<TFieldValues>): JSX.Element => {
  const { t } = useLocaleServices();
  const { field, fieldState } = useController<TFieldValues>({
    name,
    control,
    rules,
  });

  const handleDrop = useCallback<DropCallback>(
    files => {
      const fls: File[] = field.value ? [...field.value] : [];

      for (const file of files ?? []) {
        if (!fls.some(f => f.name === file.name)) {
          fls.push(file);
        }
      }
      field.onChange(fls);
      trigger?.(name);
    },
    [field, trigger, name]
  );

  const dropZoneRef = useWindowFileDrop<HTMLDivElement>(handleDrop, 'visible', 'invisible');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files: File[] = field.value ? [...field.value] : [];
    for (const file of event.target.files ?? []) {
      if (!files.some(f => f.name === file.name)) {
        files.push(file);
      }
    }
    if (files.length !== field.value?.length) {
      field.onChange(files);
      trigger?.(name);
    }
  };

  const handleRemove = useCallback(
    (file: File) => {
      const newFiles = field.value.filter((f: File) => f.name !== file.name);
      field.onChange(newFiles);
      trigger?.(name);
    },
    [field, trigger, name]
  );

  const error = fieldState.error;

  const handleTakeScreenshot = async () => {
    const el = dialogRef?.current?.getElement()?.parentElement;
    if (el != null) {
      el.style.visibility = 'hidden';
    }
    try {
      const bytes = await toBlob();
      if (bytes != null) {
        const file = new File([bytes], `screenshot_${Math.random().toString(16).slice(4)}.png`, {
          type: 'image/png',
        });
        if (field.value != null && Array.isArray(field.value)) {
          field.onChange([...field.value, file]);
        } else {
          field.onChange([file]);
        }
      }
    } finally {
      if (el != null) {
        el.style.visibility = 'visible';
      }
    }
  };

  return (
    <>
      <div className="flex sm:-ml-3">
        <div
          className="w-0 grow rounded-3xl p-6 sm:mx-3 md:bg-light-c md:dark:bg-dark-b"
          data-testid="support-upload-button"
        >
          <label className="flex flex-col items-center" data-role="file-label">
            {/* TODO: Check icon with design */}
            <Icon icon={Icons.ChatUpload} className="text-6xl text-accent-b" />
            <div className="mb-2 mt-4 text-center text-dark-a dark:text-light-d">
              {!isMobile && t('support.upload_description')}
              <span className={`${styles.browse} font-bitter text-body-s`}>
                {isMobile ? t('support.upload_file') : t('support.browse')}
              </span>
            </div>
            <input
              className="hidden"
              type="file"
              accept="image/*"
              name={field.name}
              ref={field.ref}
              onChange={handleChange}
              multiple
              aria-label={t('support.upload')}
              data-testid="support-upload-button-input"
              {...rest}
            />
            <div className="text-center text-xs text-dimmed-d dark:text-dimmed-a">
              {t('support.upload_sub_description')}
            </div>
          </label>
        </div>
        {!isMobile && (
          <button
            data-testid="support-take-screenshot"
            onClick={handleTakeScreenshot}
            type="button"
            role="button"
            data-role="take-screenshot"
            className="flex w-0 grow flex-col items-center justify-center rounded-3xl bg-light-c p-6 dark:bg-dark-b"
          >
            <IconCamera className="h-[60px] w-[60px]" />
            <span className="mt-4 text-blue-a underline decoration-dotted underline-offset-4 dark:text-accent-a">
              {t('support.capture_screenshot')}
            </span>
          </button>
        )}
      </div>
      {error?.message != null && <p className={`p-error block ${styles.error}`}>{error.message}</p>}
      {field.value?.length > 0 && (
        <div className={styles.fileList}>
          {field.value.map((file: File) => (
            <FilePreview file={file} key={file.name} onRemove={handleRemove} />
          ))}
        </div>
      )}
      {createPortal(
        <div ref={dropZoneRef} className={`${styles.dropZone} invisible`}>
          <i className="pi pi-image"></i>
          <span>{t('support.drop_files_here')}</span>
        </div>,
        document.body
      )}
    </>
  );
};
