import { ErrorLogger } from '@/utils/ErrorLogger';

export const unwrapReduxAsyncAction = async action => {
  const { type, payload } = await action;
  if (/\/rejected$/.test(type)) {
    throw action;
  }
  return payload;
};

export const unwrapFetchResponse = async fetchPromise => {
  const response = await fetchPromise;
  const json = await response.json();
  if (!response.ok || json.error || json.errors) {
    if (json.errors) {
      //"errors.user_is_already_registered"
      throw Object.assign(new Error(json?.errors?.[0] ?? json), {
        name: 'ServerError',
        status: response.status,
      });
    }
    throw Object.assign(new Error(json?.error ?? json), { name: 'ServerError', status: response.status });
  }
  return json;
};
export const safelyUnwrapFetchResponse = async fetchPromise => {
  try {
    const response = await fetchPromise;
    const json = await response.json();
    if (json.error) {
      return [{ name: 'ServerError', message: json.error }];
    }
    return [null, json];
  } catch (error) {
    ErrorLogger.setFnName('safelyUnwrapFetchResponse').send(error);
    return [{ name: error.constructor.name, message: error.message, stack: error.stack }];
  }
};
