import { ensureEnvVar } from '@services/env';
import { useLocaleServices } from '@ui/contextProviders/Locale';
import { PvDialog } from '@ui/lib/PvDialog';
import PropTypes from 'prop-types';
import { useCallback } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import './recaptcha.scss';

export const recaptchaV2Key = ensureEnvVar('REACT_APP_GOOGLE_RECAPTCHA_V2_PUBLIC_KEY', null);

Recaptcha.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  onCommit: PropTypes.func,
};

export function Recaptcha({ show, onHide, onCommit }) {
  const { t } = useLocaleServices();
  const handleRecaptchaCommit = useCallback(
    code => {
      onCommit?.(code);
    },
    [onCommit]
  );

  if (!recaptchaV2Key) {
    // eslint-disable-next-line no-console
    console.warn('Missing GOOGLE_RECAPTCHA_V2_PUBLIC_KEY');
    return null;
  }

  return (
    <PvDialog
      className="w-auto"
      visible={show}
      resizable={false}
      headerClassName="recaptcha_header"
      header={<div className="recaptcha_title">{t('captcha.title')}</div>}
      contentClassName="recaptcha_content "
      maskClassName="recaptcha_mask"
      modal
      keepInViewport
      onHide={onHide}
    >
      <ReCAPTCHA sitekey={recaptchaV2Key} onChange={handleRecaptchaCommit} theme="dark" />
    </PvDialog>
  );
}
