import { FC, ImgHTMLAttributes, SourceHTMLAttributes } from 'react';

type PictureProps = {
  sources?: Array<SourceHTMLAttributes<HTMLSourceElement>>;
  sourceSrcSet?: string;
  sourceType?: string;
  imageClassName?: string;
} & ImgHTMLAttributes<HTMLImageElement>;

export const Picture: FC<PictureProps> = ({
  sources,
  className,
  sourceSrcSet,
  sourceType,
  imageClassName,
  ...props
}) => (
  <picture className={className}>
    {sources?.map((source, index) => <source key={index} {...source} />)}
    {sourceSrcSet && <source srcSet={sourceSrcSet} type={sourceType} />}
    <img className={imageClassName} {...props} />
  </picture>
);
