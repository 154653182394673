import { useEffect, useRef, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { safelyAwait } from '@services/promises';
import { getDropdownValuesAsyncThunk } from '@model/features/registration/asyncThunks';
import { preprocessDropdownValues } from '@services/formHelpers';
import { get } from '@services/objects/get';
import polyfilledStructuredClone from '@ungap/structured-clone';
import { logged } from '@services/diagnostics';

const anEmptyArray = [];

export function useDependentValuesLoaderWithEffect(name, depends_on, values_from, valueGetter = ({ value }) => value) {
  const [result, setResult] = useState(null);
  const { setValue, getValues } = useFormContext();
  const watchedValue = useWatch({ name: depends_on });
  const dispatch = useDispatch();

  const dataLoader = useRef(async () => {
    const values = getValues();
    logged(`Requesting data from ${values_from} Based on '${depends_on}': '${JSON.stringify(values)}'`);
    const dependsOnArray = Array.isArray(depends_on) ? depends_on : depends_on.split(/,\s*/g);
    if (!dependsOnArray.reduce((memo, itemName) => memo && get(values, itemName.split('.'), false), true)) {
      return anEmptyArray;
    }
    const [error, result] = await safelyAwait(
      dispatch(
        getDropdownValuesAsyncThunk({
          urlTemplate: values_from, // '/api/v1/geo/countries/{country}/states',
          data: polyfilledStructuredClone(values), // a copy to disallow object-freeze situation
        })
      ).unwrap()
    );
    if (error || result?.code !== 200) {
      return anEmptyArray;
    }
    return preprocessDropdownValues(result.response.data);
  });

  const keepOrResetValueRef = useRef(function (data) {
    const currentValue = getValues(name);
    if (null == currentValue) {
      return;
    }
    if (data && data.some(item => Object.is(valueGetter(item), currentValue))) {
      return;
    }
    setValue(name, null);
  });

  useEffect(() => {
    if (!values_from || !depends_on || !watchedValue) {
      return;
    }
    dataLoader.current().then(nextResult => {
      setResult(currentResult =>
        JSON.stringify(currentResult) === JSON.stringify(nextResult) ? currentResult : nextResult
      );
      keepOrResetValueRef.current(nextResult);
    });
  }, [depends_on, values_from, watchedValue]);

  return result;
}
