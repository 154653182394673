import { useEffect, useRef } from 'react';

export function useOnAnimationEnd(node: HTMLElement | null | undefined, callback: VoidCallback<AnimationEvent>) {
  const callbackRef = useRef(callback);

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  useEffect(() => {
    const listener = (event: AnimationEvent) => {
      callbackRef.current(event);
    };
    node?.addEventListener('animationend', listener);
    node?.addEventListener('animationcancel', listener);
    return () => {
      node?.removeEventListener('animationend', listener);
      node?.removeEventListener('animationcancel', listener);
    };
  }, [node]);
}
