import { yupStringOrNumber } from '@model/api/endpointSchema';
import * as yup from 'yup';
import { boolean, object, string } from 'yup';

export const schemaUsersPhoneVerificationPostRequest = object({
  channel: string<'sms' | 'call'>().oneOf(['sms', 'call']).required(),
}).required();

export const schemaUsersPhoneVerificationConfirmPostRequest = object({
  code: string().required(),
}).required();

export const schemaUsersPhoneVerificationPostResponse = object({
  data: object({
    id: string().required(),
    active: boolean().required(),
    status: string<'active' | 'inactive'>().required(),
  }).required(),
});

export const schemaDeleteUsersCurrentResponse = yup
  .object({
    subscription_code: yup.string().required(),
    valid_until: yup.string().required(),
  })
  .required();

export const schemaUserRestoreResponse = yup.object({}).required();

export const schemaUserSignUpFormFields = yup
  .object({
    email: yup.string().email().required(),
    terms_accepted: yup.boolean().required(),
    refcode: yup.string().nullable(),
    did: yup.string(),
    remember_me: yup.boolean().optional(),
  })
  .required();

export const schemaUsersSignUpRequest = yup
  .object({
    user: schemaUserSignUpFormFields,
  })
  .required();

export const schemaUsersSignUpResponse = yup
  .object({
    data: yup
      .object({
        code_head: yup.string().required(),
        masked_email: yup.string().required(),
      })
      .required(),
  })
  .required();

export const schemaUsersEmailActivateRequest = yup
  .object({
    email: yup.string().email().required(),
    code: yup.string().required(),
  })
  .required();

export const schemaUsersPhonePostRequest = yup
  .object({
    national_number: yup.string().required(),
    send_code: yup.boolean().notRequired(),
    channel: yup.string().oneOf(['sms', 'call']).required(),
  })
  .required();

export const schemaUsersPhonePostForm = yup
  .object({
    phoneNumber: yup.string().required(),
    channel: yup.string().oneOf(['sms', 'call']).required(),
    sendCode: yup.boolean().notRequired(),
  })
  .required();

export const schemaUsersPhonePostResponse = yup
  .object({
    data: yup
      .object({
        id: yup.number().required(),
        active: yup.boolean().required(),
        status: yup.string().required(), //"inactive"
      })
      .required(),
  })
  .required();

export const schemaUsersPhonesActivatePostParamsRequest = yup
  .object({
    id: yupStringOrNumber.required(),
  })
  .required();

export const schemaUsersPhonesActivatePostBodyRequest = yup
  .object({
    code: yup.string().required(),
  })
  .required();

export const schemaUsersPhonesActivatePostRequestForm = yup
  .object({
    id: yupStringOrNumber.required(),
    phoneNumber: yup.string().required(),
    internationalPhoneNumber: yup.string().required(),
    code: yup.string().required(),
  })
  .required();

export const schemaUsersPhonesActivatePostResponse = yup
  .object({
    data: yup
      .object({
        id: yup.number().required(),
        active: yup.boolean().required(), // true
        status: yup.mixed().oneOf(['active', 'empty']).required(),
      })
      .required(),
  })
  .required();

export const schemaUsersActivationsPostRequest = yup
  .object({
    code: yup.string(),
  })
  .required();

export const schemaUsersActivationsPostResponse = yup
  .object({
    data: yup
      .object({
        id: yup.mixed(),
        email: yup.string().email(),
        username: yup.string(),
        status: yup.string(), // registration_phase
        registration_step: yup.number(),
        earnings: yup.number(),
        trustscore: yup.number(),
        trait: yup.object(),
        referral: yup.object(),
      })
      .required(),
  })
  .required();
export const schemaUsersRegistrationWithPasswordPostRequest = yup
  .object({
    user: yup
      .object({
        email: yup.string().email().required(),
        password: yup.string().required(),
        terms_accepted: yup.boolean().required(),
        refcode: yup.string().nullable(),
        bonus_code: yup.string().nullable(),
        did: yup.string().optional(),
      })
      .required(),
  })
  .required();
export const schemaUsersResetPasswordPostRequest = yup
  .object({
    email: yup.string().email().required(),
  })
  .required();
export const schemaUsersResetPasswordNewPasswordPutRequest = yup
  .object({
    reset_code: yup.string().required(),
    password: yup.string().required(),
    password_confirmation: yup.string().required(),
  })
  .required();

export const schemaUsersEmailsSendActivationPostRequest = yup
  .object({
    email: yup.string().email().required(),
    terms_accepted: yup.boolean().optional(),
    remember_me: yup.boolean().optional(),
  })
  .required();
export const schemaUsersEmailsSendActivationPostResponse = yup
  .object({
    data: yup
      .object({
        code_head: yup.string().required(),
      })
      .required(),
    meta: yup
      .object({
        masked_email: yup.string().required(),
      })
      .required(),
  })
  .required();

export const schemaUsersPushSubscriptionsPostRequest = yup
  .object({
    token: yup.string().required(),
    did: yup.string().optional(),
  })
  .noUnknown(false)
  .required();

export const schemaAcceptancePostRequest = yup
  .object({
    token: yup.string().oneOf(['gdpr', 'geolocation', 'privacy', 'webpush']).required(),
    status: yup.string().oneOf(['accept', 'reject']),
  })
  .noUnknown(true)
  .required();
