import { GTags } from '@/constants/google_tags';
import { gTagSender } from '@services/analytics/gTagSender';
import { STORAGE_KEY_LIST, persistDataToStorage } from '@services/storage';
import PropTypes from 'prop-types';
import React, { useCallback, useContext } from 'react';
import { useLocation } from 'react-router-dom';

const SocialHelperContext = React.createContext(null);

SocialHelperProvider.propTypes = {
  children: PropTypes.any,
  state: PropTypes.any,
};

export function SocialHelperProvider({ children, state }) {
  const location = useLocation();

  const handleSubmit = useCallback(
    _event => {
      const { pathname, search, hash, key } = location;
      // store location before letting the browser redirect to Social Auth
      persistDataToStorage(
        STORAGE_KEY_LIST.PREVIOUS_LOCATION,
        JSON.stringify({ pathname, search, hash, key, state }),
        window.sessionStorage
      );
      gTagSender(GTags.oauthSignUp);
    },
    [location, state]
  );

  return <SocialHelperContext.Provider value={handleSubmit}>{children}</SocialHelperContext.Provider>;
}

export const useSocialHelperContext = () => {
  const context = useContext(SocialHelperContext);
  if (null == context) {
    throw new Error(
      `Invalid usage. 'useSocialHelperContext()' needs to be called in elements descending from <SocialHelperProvider />`
    );
  }
  return context;
};
