import { postAcceptanceResponseAsyncThunk } from '@model/features/user/asyncActions';
import { safelyAwait } from '@services/promises';
import { processGeoLocationActionCreator } from '@ui/elements/Banners/processGeoLocationAction';
import { processWebPushBannerAction } from '@ui/elements/Banners/processWebPushBannerAction';

export const respondAcceptance = payload => dispatch =>
  safelyAwait(
    dispatch(
      postAcceptanceResponseAsyncThunk({
        ...payload,
        onAny: () => {
          // ignore
        },
      })
    ).unwrap()
  );

export const processBannerActions = (bannerToken, actions) => async dispatch => {
  switch (bannerToken) {
    case 'webpush': {
      // dispatch async action to register service workers
      return dispatch(processWebPushBannerAction);
    }
    case 'geolocation': {
      // dispatch async action to report geo to server
      return dispatch(processGeoLocationActionCreator(actions));
    }
    default: {
      break;
    }
  }
  return void 0;
};
