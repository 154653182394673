import { useCallback, useMemo } from 'react';
import { Dropdown } from 'primereact/dropdown';
import PropTypes from 'prop-types';

const fallbackCollator = new Intl.Collator();

SortableDropdown.propTypes = {
  sort: PropTypes.bool,
  sortCompareFunction: PropTypes.func,
  optionLabelFormatter: PropTypes.func,
  options: PropTypes.array,
  optionLabel: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
};

export function SortableDropdown({ sort, sortCompareFunction, optionLabelFormatter, ...restProps }) {
  const { options, optionLabel } = restProps;

  const labelAccessor = useCallback(item => (optionLabel ? item[optionLabel] : item), [optionLabel]);

  const effectiveLabelAccessor = useMemo(
    () => (optionLabelFormatter ? item => optionLabelFormatter(item) : item => labelAccessor(item)),
    [labelAccessor, optionLabelFormatter]
  );

  const effectiveSortCompareFunction = sort ? sortCompareFunction || fallbackCollator.compare : null;

  const dataSource = useMemo(() => {
    if (!sort) {
      return options;
    }
    return Array.from(options).sort((a, b) =>
      effectiveSortCompareFunction(effectiveLabelAccessor(a), effectiveLabelAccessor(b))
    );
  }, [effectiveLabelAccessor, effectiveSortCompareFunction, options, sort]);

  return (
    <Dropdown
      emptyFilterMessage="No items"
      filterMatchMode="startsWith"
      filterBy={`${optionLabel},alias`}
      {...restProps}
      options={dataSource}
    />
  );
}
