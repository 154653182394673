import { EndpointCallerParametersType } from '@model/api/apiTypes';
import { endpointNameTag } from '@model/api/constants';
import { callEndpoint } from '@model/api/endpointCaller';
import { logged } from '@services/diagnostics';
import { EndpointName } from './endpoint';

export function provideOneHandlerForCodes<ResponsePayloadType, RequestType = void, RequestPayloadType = never>(
  endpointName: EndpointName,
  argToPayloadTransform: null | ((arg: RequestType) => null | EndpointCallerParametersType<RequestPayloadType>),
  ...codes: number[]
): (arg: RequestType) => Promise<AppResponse<ResponsePayloadType>> {
  return Object.assign(
    (arg: RequestType) => {
      const parameters = argToPayloadTransform?.(arg) || {};
      const debugError = new Error('handler called from...');
      // we need both: controlled resolve/reject and async/await inside
      // eslint-disable-next-line no-async-promise-executor
      return new Promise<AppResponse<ResponsePayloadType>>(async (resolve, reject) => {
        const handlers = Object.fromEntries(
          codes.map(code => [
            `on${code}`,
            resolve as (arg: AppResponse<ResponsePayloadType>) => Promise<AppResponse<ResponsePayloadType>>,
          ])
        );
        try {
          await callEndpoint<ResponsePayloadType>(endpointName, Object.assign(parameters, handlers));
          // resolve() is called via one of the expected handlers
        } catch (error) {
          logged(error, `Error in provideOneHandlerForCodes('${endpointName}',...)`);
          logged(debugError.stack);
          reject(error);
        }
      });
    },
    { [endpointNameTag]: endpointName }
  );
}
