export class Logger {
  static log(message: string, data?: any) {
    if ('debug' in localStorage) {
      Logger.print('log', message, data);
    }
  }

  static error(message: string, data?: any) {
    if ('debug' in localStorage) {
      Logger.print('error', message, data);
    }
  }

  static info(message: string, data?: any) {
    if ('debug' in localStorage) {
      Logger.print('info', message, data);
    }
  }

  private static print(method: 'log' | 'info' | 'error', message: string, data?: any) {
    if ('debug' in localStorage) {
      if (data) {
        // eslint-disable-next-line no-console
        console[method](message, data);
      } else {
        // eslint-disable-next-line no-console
        console[method](message);
      }
    }
  }
}
