import { Button } from '@ui/lib/Button';
import { FC } from 'react';
import styles from './FilePreview.module.scss';

type FilePreviewProps = {
  file: File;
  onRemove?(file: File): void;
  showName?: boolean;
};

export const FilePreview: FC<FilePreviewProps> = ({ file, onRemove, showName = true }) => {
  const isImage = file.type.startsWith('image/');

  return (
    <div className={styles.item}>
      {isImage ? (
        <img src={URL.createObjectURL(file)} alt={file.name} className={styles.image} />
      ) : (
        <i className="pi pi-file"></i>
      )}
      {showName && <span>{file.name}</span>}
      <Button
        textButton
        buttonType="rounded"
        icon="pi pi-times"
        className="w-auto"
        type="button"
        onClick={() => onRemove?.(file)}
      />
    </div>
  );
};
