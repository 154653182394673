import { concatClsx } from '@/utils/classnames';
import { getYear } from '@services/calendar';
import { FIELD_NAMES } from '@services/fields/fieldNames';
import { useLocaleServices } from '@ui/contextProviders';
import { TraitChildrenComponent } from '@ui/elements/forms/';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import PropTypes from 'prop-types';
import { useEffect, useMemo } from 'react';
import { useController, useFieldArray } from 'react-hook-form';

const DEFAULT_KID_OBJECT = {
  [FIELD_NAMES.GENDER_FIELD_NAME]: null,
  [FIELD_NAMES.MONTH_FIELD_NAME]: null,
  [FIELD_NAMES.YEAR_FIELD_NAME]: null,
};

const genderValues = [
  { name: 'fields.children.boy', value: 'm' },
  { name: 'fields.children.girl', value: 'f' },
];

const thisYear = getYear();
const childrensMaxAge = 19;
const yearValues = Array.from({ length: childrensMaxAge }, (_, i) => {
  return { name: thisYear - i, value: thisYear - i };
});

ParentalComponent.propTypes = {
  field: PropTypes.object,
  methods: PropTypes.object,
  control: PropTypes.object,
};

export function ParentalComponent({ field, control, methods }) {
  const { t } = useLocaleServices();

  const [parentalField, childrenField] = field.fields;
  const {
    label: parentalLabel,
    options: parentalOptions,
    values: parentalValues,
    value: defaultValueParental,
  } = parentalField;
  const { label_key: labelKey, value_key: valueKey } = parentalOptions;

  const { value: childrenValue, options: childrenOptions, labels: childrenLabels } = childrenField;
  const childrenArray = useMemo(() => {
    if (!childrenValue || 0 === childrenValue.length) {
      return Array.from({ length: defaultValueParental - 1 }, () => DEFAULT_KID_OBJECT);
    }
    return childrenValue;
  }, [childrenValue, defaultValueParental]);
  const { max: childrenCountMax } = childrenOptions;
  const { add: childrenLabelAdd } = childrenLabels;

  const {
    field: { name: parentalFieldName, onChange: parentalFieldOnChange, value: watchParental, onBlur, ref },
    fieldState: { invalid, error },
    formState: { isSubmitting },
  } = useController({
    name: FIELD_NAMES.PARENTAL_FIELD_NAME,
    control,
    rules: {
      required: 'fields.parental.error_message',
      valueAsNumber: true,
      min: { value: 1, message: 'fields.parental.error_message' },
    },
    defaultValue: !defaultValueParental ? 0 : childrenArray.length + 1,
  });

  const { fields, append, remove, insert } = useFieldArray({ control, name: FIELD_NAMES.CHILDREN_FIELD_NAME });

  const handleAddChild = () => {
    const parentalFieldValue = watchParental + (0 === watchParental ? 2 : 1);
    parentalFieldOnChange({ target: { name: parentalFieldName, value: parentalFieldValue } });
    append(DEFAULT_KID_OBJECT);
  };

  const handleRemoveChild = childIndex => {
    const parentalFieldValue = watchParental - 1;
    parentalFieldOnChange({ target: { name: parentalFieldName, value: parentalFieldValue } });
    remove(childIndex);
  };

  const handleChangeChildrensCount = event => {
    parentalFieldOnChange(event);
    const parentalFieldValue = event.target.value;
    const newVal = parentalFieldValue - 1;
    const oldVal = fields.length;

    if (newVal > oldVal) {
      const indexToInsert = fields.length;
      const arrayForInsert = Array.from({ length: newVal - oldVal }, () => DEFAULT_KID_OBJECT);
      insert(indexToInsert, arrayForInsert);
    } else {
      const arrayOfIndexForRemove = [];
      for (let i = oldVal; i > newVal; i--) {
        arrayOfIndexForRemove.push(i - 1);
      }
      remove(arrayOfIndexForRemove);
    }
  };

  const selectedTemplate = (option, data) => {
    if (fields.length > 5) return parentalValues[parentalValues.length - 1].name;
    if (option) return option.name;
    return data.placeholder;
  };

  useEffect(() => {
    remove();
    insert(0, childrenArray);
  }, [childrenArray, remove, insert]);

  return (
    <div className="flex w-full flex-col">
      <Dropdown
        name={parentalFieldName}
        onChange={handleChangeChildrensCount}
        value={watchParental}
        onBlur={onBlur}
        inputRef={ref}
        options={parentalValues}
        optionLabel={labelKey}
        optionValue={valueKey}
        placeholder={t(parentalLabel)}
        valueTemplate={selectedTemplate}
        disabled={isSubmitting}
        className={invalid ? 'p-invalid' : undefined}
        data-role="dropdown-parental"
        ariaLabel="select the number of your children"
      />
      {invalid && <div className="p-error ml-6">{t(error.message)}</div>}

      {fields.length ? (
        <div className="mt-5 w-full md:mt-6 xl:mt-5">
          {fields.map((field, index) => (
            <TraitChildrenComponent
              key={field.id}
              removeChild={() => handleRemoveChild(index)}
              field={field}
              fieldArrayName={FIELD_NAMES.CHILDREN_FIELD_NAME}
              childrenIndex={index}
              childrenOptions={childrenOptions}
              childrenLabels={childrenLabels}
              genderValues={genderValues}
              yearValues={yearValues}
              control={control}
              methods={methods}
            />
          ))}
        </div>
      ) : null}

      <Button
        label={t(childrenLabelAdd)}
        type="button"
        variant="secondary"
        aria-label="add children"
        className={concatClsx('add-children mt-6 w-fit xl:mt-7', fields.length && 'ml-5 xl:ml-14')}
        onClick={handleAddChild}
        disabled={isSubmitting || childrenCountMax <= fields.length}
        data-role="button-add-child"
      />
    </div>
  );
}
