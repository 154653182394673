import pvs from './svgCircularProgressBar.module.scss';

export type SvgCircularProgressBarProps = {
  className?: string;
  width?: number | string;
  value?: number;
  bgColor?: string;
  size?: number;
  color?: string;
  barSize?: number;
  hasPoint?: boolean;
  pointColor?: string;
  pointSize?: number;
  animated?: boolean;
  viewBox?: string;
};

export function SvgCircularProgressBar({
  className,
  width = 374,
  value = 0,
  bgColor = '#555555',
  size = 30,
  color = '#B3E000',
  barSize = 20,
  hasPoint = true,
  pointColor = '#ffffff',
  pointSize = 8,
  animated = true,
  viewBox = '-5 -5 374 279',
}: SvgCircularProgressBarProps) {
  const effectivePercentage = Math.min(Math.max(Number(value || 0), 0), 100) / 100;
  const progressTotalLength = 697;
  const progressLength = progressTotalLength * effectivePercentage;
  const progressTailLength = progressTotalLength - progressLength + 1;

  const svnProps = {
    ...('undefined' === typeof width ? {} : { width }),
  };

  return (
    <svg
      className={[pvs.circularProgress, className].join(' ')}
      viewBox={viewBox}
      {...svnProps}
      aria-label={`${effectivePercentage * 100}%`}
    >
      <g>
        <path
          d="M27.478,257.259A170.832,170.832,0,0,1,10,181.741C10,86.891,87.007,10,182,10S354,86.891,354,181.741A170.826,170.826,0,0,1,336.156,258"
          stroke={bgColor}
          fill="transparent"
          strokeWidth={size}
          strokeLinecap="round"
          className="progressbar-bg"
        />
        <path
          className={animated ? `progressbar-fg ${pvs.foregroundBarAnimated}` : 'progressbar-bg'}
          d="M27.478,257.259A170.832,170.832,0,0,1,10,181.741C10,86.891,87.007,10,182,10S354,86.891,354,181.741A170.826,170.826,0,0,1,336.156,258"
          stroke={color}
          fill="transparent"
          strokeWidth={barSize}
          strokeLinecap="round"
          style={{
            strokeDasharray: `${progressLength} ${progressTailLength}`,
          }}
        />
        {hasPoint && (
          <path
            className={animated ? pvs.pinpointAnimated : undefined}
            d="M27.478,257.259A170.832,170.832,0,0,1,10,181.741C10,86.891,87.007,10,182,10S354,86.891,354,181.741A170.826,170.826,0,0,1,336.156,258"
            stroke={pointColor}
            fill="transparent"
            strokeWidth={pointSize}
            strokeLinecap="round"
            style={{
              strokeDashoffset: -Math.min(progressLength, progressTotalLength - 1),
              strokeDasharray: `.5 ${progressTotalLength}`,
            }}
          />
        )}
      </g>
    </svg>
  );
}
