import { apiService } from '../ApiService';
import { NewTicketFormData, TicketResponse } from './types';

export const supportApi = {
  submitTicket: (formData: NewTicketFormData) => {
    const data = new FormData();
    data.append('comment[content]', formData.content);
    data.append('ticket[subject]', formData.subject);
    if (formData.debug_context) {
      data.append('ticket[debug_context]', formData.debug_context);
    }
    if (formData.images) {
      for (const image of formData.images) {
        data.append('comment[images][]', image);
      }
    }
    // TODO Remove duplicated type
    return apiService.post<TicketResponse>('/support/tickets', data);
  },
};
