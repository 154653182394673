import { ErrorLogger } from '@/utils/ErrorLogger';
import { FC, useEffect } from 'react';

type Props = {
  onDone: (token: string | null) => void;
  widgetId: string;
  siteKey: string;
};

export const CfChallenge: FC<Props> = ({ onDone, widgetId, siteKey }) => {
  useEffect(() => {
    window.turnstile.render(`#${widgetId}`, {
      sitekey: siteKey,
      'error-callback': errorCode => {
        // eslint-disable-next-line no-console
        console.error('CfChallenge error:', errorCode);
        ErrorLogger.setExtra('errorCode', errorCode).send(`CfChallenge error: ${errorCode}`);
        onDone?.(null);
      },
      callback: (token, obtained) => {
        if (token) {
          // The visitor successfully solved the challenge on the page.
          setTimeout(() => {
            onDone?.(token);
          }, 1200);
        } else {
          // eslint-disable-next-line no-console
          console.error('CfChallenge error:', obtained);
          onDone?.(token);
        }
      },
    });
  }, [onDone, widgetId, siteKey]);

  return (
    <div className="fixed inset-0 z-[10000] bg-black/70">
      <p className="mt-[45vh] text-center text-light-b">One more step before you proceed...</p>
      <div className="mt-4 flex flex-nowrap items-center justify-center" id={widgetId} />
    </div>
  );
};
