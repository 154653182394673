import { formatSecondsToTime } from '@services/time/timeFromNumber';
import { useCountdownSeconds } from '@ui/hooks/useCountdownSeconds';
import { routeNames } from '@ui/routes/routeNames';
import { FC } from 'react';
import { Navigate } from 'react-router-dom';

type RestoreButtonProps = {
  seconds: number;
  label: string;
  onClick: () => void;
};

export const RestoreButton: FC<RestoreButtonProps> = ({ seconds, onClick, label }) => {
  const time = useCountdownSeconds(seconds);

  if (0 === time) {
    return <Navigate to={routeNames.loginEmailIndex} replace />;
  }

  return (
    <button className="p-button p-button-blue rounded-full uppercase" onClick={onClick}>
      {label} {formatSecondsToTime(time)}
    </button>
  );
};
