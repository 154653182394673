import { clearProfilingFormError } from '@model/features/registration';
import { useLocaleServices } from '@ui/contextProviders';
import { useAppDispatch, useAppSelector } from '@ui/hooks/redux';
import { InputMask } from 'primereact/inputmask';
import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';
import PropTypes from 'prop-types';
import { Controller, useFormContext } from 'react-hook-form';

ControlledTextInput.propTypes = {
  name: PropTypes.string,
  value: PropTypes.any,
  options: PropTypes.object,
};
export function ControlledTextInput({ name, value, options }) {
  const { t } = useLocaleServices();
  const dispatch = useAppDispatch();
  const { has_prefix, prefix, has_postfix, postfix, placeholder, has_mask, mask, autoFocus } = options;
  const { control, formId } = useFormContext();

  const { profilingFormError } = useAppSelector(state => state.registration);

  const isGroup = has_prefix || has_postfix;

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={value}
      render={({ field, fieldState }) => {
        const onChangeHandler = event => {
          event.preventDefault();
          field.onChange(event);
          profilingFormError && dispatch(clearProfilingFormError());
        };

        return (
          <div
            className={classNames({
              'p-inputgroup': isGroup,
              'p-invalid': fieldState.invalid && isGroup,
            })}
          >
            {has_prefix && <span className="p-inputgroup-addon">{prefix}</span>}
            {has_mask ? (
              <InputMask
                placeholder={t(placeholder)}
                id={field.name}
                name={name}
                form={formId}
                mask={mask}
                autoClear={false}
                unmask
                {...field}
                autoFocus={autoFocus}
                className={fieldState.invalid ? 'p-invalid' : ''}
              />
            ) : (
              <InputText
                placeholder={t(placeholder)}
                id={field.name}
                name={name}
                form={formId}
                onBlur={field.onBlur}
                onChange={onChangeHandler}
                ref={field.ref}
                value={field.value}
                autoFocus={autoFocus}
                className={fieldState.invalid ? 'p-invalid' : ''}
              />
            )}
            {has_postfix && <span className="p-inputgroup-addon">{postfix}</span>}
          </div>
        );
      }}
    />
  );
}
