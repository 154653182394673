import { FC } from 'react';

type Props = {
  href: string;
  title: string;
};

export const FooterLegalLink: FC<Props> = ({ href, title }) => (
  <a href={href} tabIndex={0} aria-label={title} className="text-xs transition-colors duration-300 hover:text-accent-b">
    {title}
  </a>
);
