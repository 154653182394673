import { Logger } from '@/utils/Logger';
import { NotificationPayload } from 'firebase/messaging';
import { canOpenWindow, getNotificationLink } from './utils';

export function showNotification(
  payload: NotificationPayload,
  data?: {
    [key: string]: string;
  }
) {
  if ('granted' === Notification.permission) {
    Logger.log('Notification permission granted. Showing notification', payload);
    const notification = new Notification(payload.title ?? 'Paidviewpoint.com', {
      body: payload.body,
      icon: payload.icon,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      image: payload.image,
      data: data,
    });
    notification.onclick = async event => {
      // eslint-disable-next-line no-console
      event.preventDefault();
      // event.stopPropagation()

      const notification = event.currentTarget as Notification;
      notification.close();

      const link = getNotificationLink(notification);

      if (!link) {
        return;
      }

      if (canOpenWindow(link, window)) {
        window.open(link);
      }
    };
  } else {
    // eslint-disable-next-line no-console
    console.error('Notification permission not granted');
  }
}
