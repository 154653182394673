import { apiService } from '../ApiService';
import { SaveCountryRequest, UserResponse } from './types';

export const userApi = {
  current() {
    return apiService.get<UserResponse>('/users/current');
  },
  saveCountry(data: SaveCountryRequest) {
    return apiService.post<UserResponse>('/users/current/country', data);
  },
};
