import { LoadingSpinner } from '@ui/elements/spinners';
import { FC, Suspense } from 'react';

type SuspenseRouteProps = {
  component: React.LazyExoticComponent<React.FC>;
};

export const SuspenseRoute: FC<SuspenseRouteProps> = ({ component: LazyComponent, ...rest }) => (
  <Suspense
    fallback={<LoadingSpinner className="flex min-h-[10rem] flex-1 items-center justify-center" data-suspense="true" />}
  >
    <LazyComponent {...rest} />
  </Suspense>
);
