import { schemaSettingsGetResponse } from '@model/features/settings/schema';
import { InferType } from 'yup';

export enum FieldType {
  TEXT = 'text',
  PASSWORD = 'password',
  EMAIL = 'email',
  DROPDOWN = 'dropdown',
  CHECKBOX = 'checkbox',
  PUSH_NOTIFICATION = 'push_notification',
  // complex components
  ALT_EMAIL = 'alt_email',
  AVATAR = 'avatar',
  PAYPAL_EMAIL_CHANGE = 'paypal_email_change',
  PHONE_CHANGE = 'phone_change',
  SOCIAL_AUTHORIZATION = 'social_authorization',
  TIME_RANGE = 'time_range',
  DATA_EXPORT = 'user_data_export',
  DONT_SELL_INFO = 'do_not_sell_info',
  CLOSE_ACCOUNT = 'close_account',
  THEME_SELECTOR = 'theme_selector',
}

export interface ISettingsFieldBase {
  readonly name: string;
  readonly type: FieldType;
  readonly label: string;
  readonly labels?: {
    [key: string]: string;
  };
  readonly options?: {
    [key: string]: unknown;
  };
  value: unknown;
}

type ChangeMode = {
  readonly label: string;
  readonly description?: string;
};

export type ChangeModes = Readonly<{
  burn: ChangeMode;
  freeze: ChangeMode;
  cancel: ChangeMode;
}>;

export type PhoneChannel = 'sms' | 'call';

export interface ICheckboxField extends ISettingsFieldBase {
  readonly type: FieldType.CHECKBOX;
  value: boolean;
  readonly options: {
    readonly description?: string;
  };
}

export interface IAvatarField extends ISettingsFieldBase {
  readonly type: FieldType.AVATAR;
  value: string;
  readonly labels: Readonly<{
    placeholder: string;
    validation_size: string;
    validation_type: string;
  }>;
}

export interface ITextFieldSimple extends ISettingsFieldBase {
  readonly type: FieldType.TEXT | FieldType.EMAIL;
  value: string;
  labels: Readonly<{
    placeholder: string;
  }>;
}

export interface IUsernameField extends ITextFieldSimple {
  readonly type: FieldType.TEXT;
  readonly labels: {
    readonly placeholder: string;
    readonly validation_min: string;
    readonly validation_max: string;
    readonly validation_pattern: string;
    readonly validation_required: string;
  };
  readonly options?: {
    readonly max: number;
    readonly min: number;
    readonly pattern: string;
  };
}

export interface IPaypalField extends ISettingsFieldBase {
  readonly type: FieldType.PAYPAL_EMAIL_CHANGE;
  value: string;
  readonly options: Readonly<{
    status: 'pending' | 'active';
    auth_url: string;
    change_modes: ChangeModes | null;
    paypal_email?: string;
    pending_paypal_email?: string;
  }>;
  readonly labels: {
    readonly placeholder: string;
    readonly burn: string;
    readonly burn_describe: string;
    readonly button: string;
    readonly change: string;
    readonly contact_our_support: string;
    readonly freeze: string;
    readonly freeze_describe: string;
    readonly notice: string;
    readonly validate_change: string;
    readonly validate_change_short: string;
    readonly warning: string;
  };
}

export interface IPasswordField extends ISettingsFieldBase {
  readonly type: FieldType.PASSWORD;
  value: string;
  readonly options: {
    readonly max?: number;
    readonly min?: number;
  };
  labels: {
    set_new_password: string;
    label: string;
  };
}

export interface IEmailField extends ITextFieldSimple {
  readonly type: FieldType.EMAIL;
  readonly labels: {
    readonly placeholder: string;
    readonly code_message: string;
  };
  readonly options: Readonly<{
    max: number;
  }>;
}

type TimeRangeValue = Readonly<{
  from: number;
  to: number;
  timezone?: string;
}>;

export type TimeZone = {
  readonly id: string;
  readonly code: string;
  readonly name: string;
  readonly utc_offset: number;
};
export interface ITimeRangeField extends ISettingsFieldBase {
  readonly type: FieldType.TIME_RANGE;
  value: TimeRangeValue | null | undefined;
  readonly options: {
    readonly timezones: TimeZone[];
  };
  readonly labels: {
    from: string;
    from_time: string;
    placeholder: string;
    placeholder_tz: string;
    service_charges: string;
    time_zone: string;
    to: string;
    to_time: string;
  };
}

export interface IPushNotificationField extends ISettingsFieldBase {
  readonly type: FieldType.PUSH_NOTIFICATION;
  value: boolean;
  readonly labels: Readonly<{
    description: string;
    disabled: string;
    disabled_description: string;
    not_supported: string;
  }>;
}

export type ThemeItem = {
  readonly id: string;
  readonly label: string;
  readonly icon: string;
};
export interface IThemeSelectorField extends ISettingsFieldBase {
  readonly type: FieldType.THEME_SELECTOR;
  value: 'light' | 'dark' | 'retro';
  readonly values: ThemeItem[];
}

export type ISoundNotificationField = ICheckboxField;

export interface IAltEmailNotificationField extends ISettingsFieldBase {
  readonly type: FieldType.ALT_EMAIL;
  value: {
    email?: string | null;
    enabled: boolean;
  } | null;
  readonly labels: Readonly<{
    placeholder: string;
    activation_code: string;
    add_email: string;
    code_message: string;
  }>;
}

export interface IDropdownFieldSingleSelect<T = unknown, V = any> extends ISettingsFieldBase {
  readonly type: FieldType.DROPDOWN;
  value: V;
  readonly options: Readonly<{
    label_key: string;
    value_key: string;
    multiple: false;
    default?: string;
  }>;
  readonly values: T[];
}

export interface IDropdownFieldMultiSelect<T = unknown, V = any> extends ISettingsFieldBase {
  readonly type: FieldType.DROPDOWN;
  value: V[];
  readonly options: Readonly<{
    label_key: string;
    value_key: string;
    multiple: true;
    default?: string;
  }>;
  readonly values: T[];
}

export enum SocialNetworkProvider {
  FACEBOOK = 'facebook',
  GOOGLE = 'google_oauth2',
  TWITTER = 'twitter',
  LINKEDIN = 'linkedin',
  APPLE = 'apple',
  PAYPAL = 'paypal',
}

type SocialNetworkProviderItem = {
  readonly provider: SocialNetworkProvider;
  readonly auth_path: string;
  readonly connected: boolean;
  readonly icon_url: string;
};
export interface ISocialNetworks extends ISettingsFieldBase {
  readonly type: FieldType.SOCIAL_AUTHORIZATION;
  readonly values: SocialNetworkProviderItem[];
  readonly labels: {
    readonly subtitle: string;
  };
}

export interface IDataExportField extends ISettingsFieldBase {
  readonly type: FieldType.DATA_EXPORT;
  readonly options: {
    readonly url: string;
  };
  // readonly labels: {

  // }
}

export interface IDoNotSellInfoField extends ISettingsFieldBase {
  readonly type: FieldType.DONT_SELL_INFO;
  readonly labels: {
    readonly title: string;
    readonly content: string;
  };
}

export interface ICloseAccountField extends ISettingsFieldBase {
  readonly type: FieldType.CLOSE_ACCOUNT;
  readonly labels: Readonly<{
    cancel: string;
    cancel2: string;
    confirm: string;
    confirm2: string;
    delete_message: string;
    title: string;
    warning: string;
  }>;
}

export interface IPhoneField extends ISettingsFieldBase {
  readonly type: FieldType.PHONE_CHANGE;
  value: string | null | undefined;
  readonly options: Readonly<{
    change_modes: ChangeModes | null;
    channels?: PhoneChannel[];
    code_size: number;
    country_code?: string;
    user_country_code: string;
    national_number_mask: string;
  }>;
  readonly labels: Readonly<{
    already_exists: string;
    burn: string;
    burn_describe: string;
    call_me: string;
    call_me_instead: string;
    code_call: string;
    code_sent: string;
    contact_our_support: string;
    error: string;
    freeze: string;
    freeze_describe: string;
    invalid_phone_number: string;
    placeholder: string;
    resend_code: string;
    text_me: string;
    text_me_instead: string;
    validate_description: string;
    voip_prohibited: string;
    warning: string;
    service_charges: string;
    button: string;
  }>;
}

export type SettingsField =
  | IAvatarField
  | IUsernameField
  | IEmailField
  | IPasswordField
  | IPushNotificationField
  | ISoundNotificationField
  | IDropdownFieldSingleSelect
  | IDropdownFieldMultiSelect
  | ISocialNetworks
  | IDataExportField
  | IDoNotSellInfoField
  | ICloseAccountField
  | IPhoneField
  | IPaypalField
  | ITimeRangeField
  | ICheckboxField
  | IAltEmailNotificationField
  | IThemeSelectorField;

export type SettingsGroup = Readonly<{
  name: string;
  label: string;
  labels?: Readonly<{
    [key: string]: string;
  }>;
  fields: SettingsField[];
}>;

export type SettingsState = {
  groups: string[];
  groupsByName: Record<string, SettingsGroup>;
  primaryMail: string | null;
};

export type SettingsGetResponse = InferType<typeof schemaSettingsGetResponse>;
