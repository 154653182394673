export function getYear(date = new Date()) {
  return date.getFullYear();
}

export function getMonth(date = new Date()) {
  const options = { month: 'long' };
  return new Intl.DateTimeFormat('en-US', options).format(date).toLowerCase();
}

export function isNotTooYoungChecker(minAge) {
  return (value, _testContext) => {
    if (!value) return true;
    const fullYearsAsOfToday = calculateFullYearsDifference(value);
    return minAge <= fullYearsAsOfToday;
  };
}

export function isTooOldChecker(maxAge) {
  return value => {
    if (!value) return true;
    const fullYearsAsOfToday = calculateFullYearsDifference(value);
    return maxAge > fullYearsAsOfToday;
  };
}

export function calculateFullYearsDifference(dateString, currentDate) {
  const firstDate = new Date(dateString);
  const secondDate = currentDate ? new Date(currentDate) : new Date();

  const [givenDate, effectiveCurrentDate] = firstDate <= secondDate ? [firstDate, secondDate] : [secondDate, firstDate];

  const yearsDiff = effectiveCurrentDate.getFullYear() - givenDate.getFullYear();
  const isSameMonthAndDay =
    effectiveCurrentDate.getMonth() < givenDate.getMonth() ||
    (effectiveCurrentDate.getMonth() === givenDate.getMonth() && effectiveCurrentDate.getDate() < givenDate.getDate());

  return (firstDate <= secondDate ? 1 : -1) * (isSameMonthAndDay ? yearsDiff - 1 : yearsDiff);
}

/**
 *
 * @param {date} d
 * @returns count years between now & date
 */
export function getFullYearsDiff(d) {
  const now = new Date().getFullYear();
  const date = new Date(d).getFullYear();
  return now - date;
}

/**
 * Per this comment: https://umongous.atlassian.net/browse/PV-1372?focusedCommentId=28322
 * Ref: https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
 * @return {string} TZ Identifier
 */
export function getTimeZone() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

/**
 *
 * @param {number} month
 * @param {} locale
 * @param {} format
 * @returns locale month name
 */
export function monthToLocale(month, locale = 'en-US', format = 'long') {
  const options = { month: format };
  return new Intl.DateTimeFormat(locale, options).format(new Date(Date.UTC(getYear(), month % 12)));
}
