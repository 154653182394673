import { blockNavigation } from '@model/features/navigation';
import { isOnPage, isOnParentPage, paths } from '@ui/navigation';

const isOnMagicSpecificPage = isOnParentPage(paths.toMagicLink);
const isOnMagicGeneralPage = isOnPage(paths.toMagicLink);
export const magicLinksSupportOnAppStart = async (originalAction, { dispatch }) => {
  const { pathname } = originalAction.payload;

  if (isOnMagicSpecificPage(pathname) && !isOnMagicGeneralPage(pathname)) {
    dispatch(blockNavigation(true));
  }
};
