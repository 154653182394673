import { userExportGDPRDataAsyncThunk } from '@model/features/user/asyncActions';
import { safelyAwait } from '@services/promises/safelyAwait';
import { useAppDispatch } from '@ui/hooks/redux';
import { isSuccessResponse } from '@ui/support/utils/guards';
import { saveAs } from 'file-saver';
import { useCallback, useState } from 'react';

export function usePersonalDataExport(url: string) {
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useAppDispatch();

  const exportPersonalData = useCallback(
    async (rCode: string) => {
      setIsLoading(true);
      const [_, result] = await safelyAwait(dispatch(userExportGDPRDataAsyncThunk({ url, code: rCode })).unwrap());
      if (result != null && isSuccessResponse(result.response)) {
        const { response, code } = result;
        if (code !== 200) {
          return;
        }
        const { data } = response;

        const dataToSave = JSON.stringify(data, null, 2);

        const blob = new Blob([dataToSave], { type: 'text/plain;charset=utf-8' });
        saveAs(blob, 'user-personal-data.json');
      }
      setIsLoading(false);
    },
    [dispatch, url]
  );

  return [isLoading, exportPersonalData] as const;
}
