import { endpoint } from '@model/api/endpoint';
import { ticketCommentResSchema, ticketResSchema, ticketsResSchema } from '@ui/support/api/schemas';
import * as yup from 'yup';
import * as BACKEND from './publishedEndpoints';
import {
  errorSchema,
  schemaDebugInfoGetResponse,
  schemaError422Response,
  schemaParamsIdOnly,
  schemaParamsUrlOnly,
  stringSchema,
} from './schemas';

import {
  schemaAuthMagicCodePostRequest,
  schemaAuthMagicCodePostResponse,
  schemaAuthMagicSendPostRequest,
  schemaAuthMagicSendPostResponse,
  schemaAuthSetUsersPasswordPostRequest,
  schemaAuthSetUsersPasswordPostResponse,
  schemaAuthTokensPostRequest,
  schemaAuthTokensPostResponse,
  schemaAuthTokensRefreshPostRequest,
  schemaAuthTokensRefreshPostResponse,
} from '@model/features/auth/schema';
import { schemaDemographicsGetResponse } from '@model/features/demographics/schema';
import {
  earningsHistoryRequestSchema,
  earningsHistoryResponseSchema,
  getCashoutResponseSchema,
  postCashoutResponseSchema,
  schemaEarningsCashOutPostRequest,
} from '@model/features/earnings/schema';
import {
  schemaFriendsGetParamsRequest,
  schemaFriendsGetResponse,
  schemaFriendsInvitationDeleteParams,
  schemaFriendsInvitationPostRequest,
  schemaFriendsInvitationsGetParamsRequest,
  schemaFriendsInvitationsGetResponse,
  schemaFriendsReferralPostRequest,
  schemaFriendsSettingsGetResponse,
} from '@model/features/friends/schema';
import {
  schemaGeoCountriesGetResponse,
  schemaGeoCountriesStatesGetParamsRequest,
  schemaGeoCountriesStatesGetParamsResponse,
  schemaGeoCountriesZipValidationGetParamsRequest,
  schemaGeoCountriesZipValidationGetResponse,
  schemaGeoValidateGetParamsRequest,
  schemaGeoValidateGetResponse,
} from '@model/features/geo/schema';
import {
  schemaLocalesAllResponse,
  schemaLocalesItemParamsRequest,
  schemaLocalesItemResponse,
} from '@model/features/locale/schema';
import {
  schemaProfilingGetStepParamsRequest,
  schemaProfilingGetStepResponse,
  schemaProfilingPutStepBodyRequest,
  schemaProfilingPutStepParamsRequest,
  schemaProfilingPutStepResponse,
} from '@model/features/registration/schema';
import { schemaSettingsGetResponse } from '@model/features/settings/schema';
import {
  schemaSurveySessionGetResponse,
  schemaSurveySessionPostResponse,
  schemaSurveysResponse,
} from '@model/features/survey/schema';
import { schemaUnsubscribePostRequest } from '@model/features/unsubscribe/schema';
import {
  schemaAcceptancePostRequest,
  schemaDeleteUsersCurrentResponse,
  schemaUsersActivationsPostRequest,
  schemaUsersActivationsPostResponse,
  schemaUsersEmailActivateRequest,
  schemaUsersEmailsSendActivationPostRequest,
  schemaUsersEmailsSendActivationPostResponse,
  schemaUsersPhonePostRequest,
  schemaUsersPhonePostResponse,
  schemaUsersPhoneVerificationConfirmPostRequest,
  schemaUsersPhoneVerificationPostRequest,
  schemaUsersPhoneVerificationPostResponse,
  schemaUsersPhonesActivatePostBodyRequest,
  schemaUsersPhonesActivatePostParamsRequest,
  schemaUsersPhonesActivatePostResponse,
  schemaUsersPushSubscriptionsPostRequest,
  schemaUsersRegistrationWithPasswordPostRequest,
  schemaUsersResetPasswordNewPasswordPutRequest,
  schemaUsersResetPasswordPostRequest,
  schemaUsersSignUpRequest,
  schemaUsersSignUpResponse,
} from '@model/features/user/schema';

/**
 * Application-wide endpoints description array
 */
export function registerApplicationEndpoints() {
  return [
    endpoint(BACKEND.DYNAMIC_URL)
      .GET(({ url }) => `${url}`)
      .params(schemaParamsUrlOnly)
      .auth()
      .fullPath()
      .code(200)
      .ok(),

    endpoint(BACKEND.DYNAMIC_GET_URL_AUTH)
      .GET(({ url }) => `${url}`)
      .params(schemaParamsUrlOnly)
      .auth()
      .fullPath()
      .code(200)
      .ok(),

    endpoint(BACKEND.DYNAMIC_DELETE_URL_AUTH)
      .DELETE(({ url }) => `${url}`)
      .params(schemaParamsUrlOnly)
      .auth()
      .fullPath()
      .code(200)
      .ok(),

    endpoint(BACKEND.DYNAMIC_POST_URL_AUTH)
      .POST(({ url }) => `${url}`)
      .params(schemaParamsUrlOnly)
      .auth()
      .fullPath()
      .body(yup.object().required())
      .code(200)
      .ok(),

    endpoint(BACKEND.DYNAMIC_TOOLS_URL_AUTH)
      .GET(({ url }) => `${url}`)
      .params(schemaParamsUrlOnly)
      .auth()
      .code(200)
      .ok(),

    endpoint(BACKEND.BACKEND_HEALTH).GET('health').code(200).ok(),

    endpoint(BACKEND.SETTINGS_GET).GET('settings').auth().code(200, schemaSettingsGetResponse).ok(),

    endpoint(BACKEND.SETTINGS_AVATAR_PUT)
      .PUT('settings')
      .auth()
      .header('Content-Type', 'multipart/form-data')
      .code(200)
      .code(422)
      .ok(),

    endpoint(BACKEND.SETTINGS_PATCH)
      .PATCH('settings')
      .auth()
      .body(yup.object().required())
      .code(200)
      .code(400)
      .code(403)
      .code(422)
      .code(429)
      .ok(),

    endpoint(BACKEND.USERS_RESET_PASSWORD_POST)
      .POST(`users/reset_password`)
      .body(schemaUsersResetPasswordPostRequest)
      .code(200)
      .code(404) // not_found
      .code(429) // too many requests
      .ok(),

    endpoint(BACKEND.USERS_RESET_PASSWORD_CONFIRM_PUT)
      .PUT(`users/reset_password`)
      .body(schemaUsersResetPasswordNewPasswordPutRequest)
      .code(200)
      .code(404) // not_found
      .code(422) // not_matching
      .ok(),

    endpoint(BACKEND.TOKENS_POST)
      .POST('authorization/tokens')
      .body(schemaAuthTokensPostRequest)
      .code(201, schemaAuthTokensPostResponse)
      .code(400)
      .code(401)
      .ok(),

    endpoint(BACKEND.TOKENS_DELETE).DELETE('authorization/tokens').code(200).ok(),

    endpoint(BACKEND.TOKENS_REFRESH)
      .POST('authorization/tokens/refresh')
      .body(schemaAuthTokensRefreshPostRequest)
      .noRepeat()
      .code(201, schemaAuthTokensRefreshPostResponse)
      .code(400)
      .code(401)
      .ok(),

    endpoint(BACKEND.USERS_EMAILS_SEND_ACTIVATION)
      .POST('users/current/emails')
      .auth()
      .body(schemaUsersEmailsSendActivationPostRequest)
      .code(201, schemaUsersEmailsSendActivationPostResponse)
      .code(422, 'Invalid email')
      .code(403, 'Sign-in error')
      .ok(),

    endpoint(BACKEND.USERS_POST_ACCEPTANCE)
      .POST('acceptance')
      .auth()
      .body(schemaAcceptancePostRequest)
      .code(200)
      .code(422)
      .ok(),

    endpoint(BACKEND.USERS_POST_EXPORT_GDPR_DATA).POST(`users/current/export`).auth().code(200).ok(),

    endpoint(BACKEND.USERS_POST_PHONES, 'Add phone')
      .POST(`users/current/phones`)
      .auth()
      .body(schemaUsersPhonePostRequest)
      .code(200, schemaUsersPhonePostResponse)
      .code(422, 'Invalid phone number')
      .ok(),

    endpoint(
      BACKEND.USERS_POST_PHONES_ACTIVATE,
      `Activate phone by id, international_number or national_number with code`
    )
      .POST(({ id }) => `users/current/phones/${id}/activate`)
      .auth()
      .params(schemaUsersPhonesActivatePostParamsRequest)
      .body(schemaUsersPhonesActivatePostBodyRequest)
      .code(200, schemaUsersPhonesActivatePostResponse)
      .code(404, 'phone not found')
      .code(422, 'invalid_code')
      .ok(),

    endpoint(BACKEND.DEBUG).GET('debug').code(200, schemaDebugInfoGetResponse).ok(),

    endpoint(BACKEND.FRIENDS_GET)
      .GET('friends/invitations')
      .auth()
      .params(schemaFriendsInvitationsGetParamsRequest)
      .code(200, schemaFriendsInvitationsGetResponse)
      .code(422, 'Invalid Range')
      .ok(),

    endpoint(BACKEND.FRIENDS_INVITATION_DELETE)
      .DELETE(({ id }) => `friends/invitations/${id}`)
      .auth()
      .params(schemaFriendsInvitationDeleteParams)
      .code(200)
      .ok(),

    endpoint(BACKEND.FRIENDS_INVITATION_POST)
      .POST('friends/invitations')
      .auth()
      .body(schemaFriendsInvitationPostRequest)
      .code(200, 'emails sent')
      .code(422, 'invalid field')
      .code(498, 'require captcha')
      .ok(),

    endpoint(BACKEND.FRIENDS_REFERRAL_POST)
      .POST('friends/invitations/referral_code')
      .auth()
      .body(schemaFriendsReferralPostRequest)
      .code(200, 'code created')
      .code(400, 'bad request')
      .code(422, 'invalid field')
      .ok(),

    endpoint(BACKEND.FRIENDS_GET_LIST)
      .GET('friends')
      .auth()
      .params(schemaFriendsGetParamsRequest)
      .code(200, schemaFriendsGetResponse)
      .code(422, 'Invalid Range')
      .ok(),

    endpoint(BACKEND.FRIENDS_GET_SETTINGS)
      .GET('friends/settings')
      .auth()
      .code(200, schemaFriendsSettingsGetResponse)
      .ok(),

    endpoint(BACKEND.UNSUBSCRIBE_POST)
      .POST('unsubscribe')
      .body(schemaUnsubscribePostRequest)
      .code(200, 'unsubscribe done')
      .ok(),

    endpoint(BACKEND.RESUBSCRIBE_POST)
      .POST('resubscribe')
      .body(schemaUnsubscribePostRequest)
      .code(200, 'resubscribe done')
      .ok(),

    endpoint(BACKEND.SURVEY_GET_QUESTIONS)
      .GET(({ id }) => `survey_sessions/${id}`)
      .auth()
      .params(schemaParamsIdOnly)
      .code(200, schemaSurveySessionGetResponse)
      .ok(),

    endpoint(BACKEND.SURVEY_POST_ACQUIRE_SESSION)
      .POST(({ id }) => `surveys/${id}/session`)
      .auth()
      .params(schemaParamsIdOnly)
      .code(201, schemaSurveySessionPostResponse)
      .code(404, 'Not found for invalid ID (code)')
      .code(409, 'Another session is active or user is not able to take this survey')
      .code(403)
      .ok(),

    endpoint(BACKEND.SURVEYS_GET_AVAILABLE).GET(`surveys`).auth().code(200, schemaSurveysResponse).code(403).ok(),

    endpoint(BACKEND.COUNTRIES_LIST_GET).GET('geo/countries').code(200, schemaGeoCountriesGetResponse).ok(),

    endpoint(BACKEND.ZIP_VALIDATION_GET)
      .GET(({ country_code, zip_code }) => `geo/countries/${country_code}/zip_counties/${zip_code}`)
      .params(schemaGeoCountriesZipValidationGetParamsRequest)
      .code(200, schemaGeoCountriesZipValidationGetResponse)
      .code(404)
      .ok(),

    endpoint(BACKEND.COUNTRY_STATES_LIST_GET)
      .GET(({ country_code }) => `geo/countries/${country_code}/states`)
      .params(schemaGeoCountriesStatesGetParamsRequest)
      .code(200, schemaGeoCountriesStatesGetParamsResponse)
      .ok(),

    endpoint(BACKEND.GEO_VALIDATION_GET)
      .GET('geo/validate')
      .params(schemaGeoValidateGetParamsRequest)
      .code(200, schemaGeoValidateGetResponse)
      .code(404, 'Invalid country')
      .code(422, 'County does not match state or zip')
      .ok(),

    endpoint(BACKEND.LOCALES_ALL_GET).GET('locales').code(200, schemaLocalesAllResponse).ok(),

    endpoint(BACKEND.LOCALES_GET)
      .GET(({ locale }) => `locales/${locale}`)
      .params(schemaLocalesItemParamsRequest)
      .code(200, schemaLocalesItemResponse)
      .ok(),

    endpoint(BACKEND.AUTHORIZATION_POST_MAGIC_SEND)
      .POST('authorization/magic/send')
      .body(schemaAuthMagicSendPostRequest)
      .code(201, schemaAuthMagicSendPostResponse)
      .code(403)
      .code(451, 'minor_problem')
      .ok(),

    endpoint(BACKEND.AUTHORIZATION_POST_MAGIC)
      .POST('authorization/magic')
      .body(schemaAuthMagicCodePostRequest)
      .code(201, schemaAuthMagicCodePostResponse)
      .code(400, 'invalid email login')
      .code(410, 'token_expired')
      .code(404, 'invalid_or_expired_code')
      .ok(),

    endpoint(BACKEND.PROFILING_GET_STEP_SCHEMA)
      .GET(({ stepId }) => `profiling/steps/${stepId}`)
      .auth()
      .params(schemaProfilingGetStepParamsRequest)
      .code(200, schemaProfilingGetStepResponse)
      .code(403, 'User is not alive')
      .code(451, 'minor_problem')
      .ok(),

    endpoint(BACKEND.PROFILING_GET_CURRENT_STEP)
      .GET('profiling/steps')
      .auth()
      .redirects(BACKEND.PROFILING_GET_STEP_SCHEMA)
      .ok(),

    endpoint(BACKEND.PROFILING_PUT_STEP, 'Send current Profiling step attributes')
      .PUT(({ stepId }) => `profiling/steps/${stepId}`)
      .auth()
      .params(schemaProfilingPutStepParamsRequest)
      .body(schemaProfilingPutStepBodyRequest)
      .code(201, schemaProfilingPutStepResponse)
      .code(404)
      .code(409)
      .code(422, schemaError422Response)
      .code(451, 'minor_problem')
      .ok(),

    endpoint(BACKEND.USERS_ACTIVATIONS_POST, 'Activate user by code from Email')
      .POST('users/activations')
      .auth()
      .body(schemaUsersActivationsPostRequest)
      .code(200, schemaUsersActivationsPostResponse)
      .code(422, 'errors.activation_code_is_blank') // Missing code
      .ok(),

    endpoint(BACKEND.USERS_DELETE_CURRENT, 'Deletes current user account')
      .DELETE('users/current')
      .auth()
      .code(200, schemaDeleteUsersCurrentResponse)
      .ok(),

    endpoint(BACKEND.USERS_RESTORE, 'Restore closed account')
      .POST('users/current/restore')
      .body(
        yup.object({
          subscription_code: yup.string().required(),
        })
      )
      .code(200)
      .code(404, errorSchema)
      .ok(),

    endpoint(BACKEND.USERS_POST_PASSWORD, "Set user's password")
      .POST('users/current/password')
      .auth()
      .body(schemaAuthSetUsersPasswordPostRequest)
      .code(201, schemaAuthSetUsersPasswordPostResponse)
      .code(403, errorSchema)
      .ok(),

    endpoint(BACKEND.USERS_SIGNUP_EMAIL, 'Creates a user by email')
      .POST(`signups/emails`)
      .body(schemaUsersSignUpRequest)
      .code(201, schemaUsersSignUpResponse)
      .code(422, 'bad request for not accepted terms')
      .code(403, 'bad user status')
      .ok(),

    endpoint(BACKEND.USERS_SIGNUP_EMAIL_ACTIVATE, 'Activates user email')
      .POST(`users/current/emails/activate`)
      .auth()
      .body(schemaUsersEmailActivateRequest)
      .code(200, 'ok')
      .code(422, 'Invalid activation_code')
      .ok(),

    //noinspection SpellCheckingInspection
    endpoint(BACKEND.USERS_REGISTRATION_POST, 'Creates a user')
      .POST('users/registrations')
      .body(schemaUsersRegistrationWithPasswordPostRequest)
      .code(201, 'User created')
      .code(422, 'bad request for not accepted terms')
      /*
        {
          "code": 422,
          "message": "errors.user_is_already_registered",
          "exception": "Errors::InvalidValue",
          "backtrace": [..]
        }
       */
      .ok(),

    endpoint(BACKEND.USERS_PUSH_SUBSCRIPTIONS, 'Subscribe to push notifications')
      .POST('users/current/push_subscriptions')
      .auth()
      .body(schemaUsersPushSubscriptionsPostRequest)
      .code(201, 'Subscription created')
      .code(422, 'Missing fields')
      .ok(),

    endpoint(BACKEND.SUPPORT_GET_TICKETS_LIST)
      .GET(({ page, perPage }) => `support/tickets?page=${page}&per_page=${perPage}`)
      .auth()
      .code(200, ticketsResSchema)
      .ok(),

    endpoint(BACKEND.SUPPORT_GET_TICKET)
      .GET(({ id }) => `support/tickets/${id}`)
      .auth()
      .code(200, ticketResSchema)
      .ok(),

    endpoint(BACKEND.SUPPORT_POST_TICKET)
      .POST('support/tickets')
      .header('Content-Type', 'multipart/form-data')
      .auth()
      .code(201, ticketResSchema)
      .code(422, 'Param is missing or the value is empty')
      .ok(),

    endpoint(BACKEND.SUPPORT_RESOLVE_TICKET)
      .POST(({ id }) => `support/tickets/${id}/close`)
      .auth()
      .code(200, ticketResSchema)
      .ok(),

    endpoint(BACKEND.SUPPORT_POST_COMMENT)
      .POST(({ id }) => `support/tickets/${id}/comments`)
      .header('Content-Type', 'multipart/form-data')
      .auth()
      .code(201, ticketCommentResSchema)
      .code(422, 'Param is missing or the value is empty')
      .ok(),

    endpoint(BACKEND.EARNINGS_POST_CASH_OUT, 'Cash out earnings')
      .POST(`earnings/cashout`)
      .auth()
      .body(schemaEarningsCashOutPostRequest)
      .code(201, postCashoutResponseSchema)
      .code(403, errorSchema)
      .code(422, errorSchema)
      .ok(),

    endpoint(BACKEND.EARNINGS_GET_CASH_OUT, `Get cash out information`)
      .GET(`earnings/cashout`)
      .auth()
      .code(200, getCashoutResponseSchema)
      .ok(),

    endpoint(BACKEND.EARNINGS_GET_TRANSACTIONS, `Show list of transactions`)
      .GET(`earnings/transactions`)
      .auth()
      .params(earningsHistoryRequestSchema)
      .code(200, earningsHistoryResponseSchema)
      .ok(),

    endpoint(BACKEND.DEMOGRAPHICS_GET).GET('demographics').auth().code(200, schemaDemographicsGetResponse).ok(),

    endpoint(BACKEND.DEMOGRAPHICS_PUT)
      .PUT('demographics')
      .auth()
      .header('Content-Type', 'application/json')
      .code(200)
      .code(422)
      .ok(),

    endpoint(BACKEND.USER_PHONES_SEND_VERIFICATION_CODE, 'Send verification code to phone')
      .POST(id => `users/current/phones/${id}/send_verification_code`)
      .auth()
      .params(stringSchema)
      .body(schemaUsersPhoneVerificationPostRequest)
      .code(200, schemaUsersPhoneVerificationPostResponse)
      .code(404, errorSchema)
      .code(422, errorSchema)
      .ok(),

    endpoint(BACKEND.USER_PHONES_VERIFY, 'Verify phone')
      .POST(id => `users/current/phones/${id}/verify`)
      .auth()
      .params(stringSchema)
      .body(schemaUsersPhoneVerificationConfirmPostRequest)
      .code(200, schemaUsersPhoneVerificationPostResponse)
      .code(404, errorSchema)
      .code(422, errorSchema)
      .ok(),

    endpoint(BACKEND.DEBUG_API)
      .GET(
        ({ code, format }: { code: number; format: 'html' | 'json' | 'text' | 'xml' | 'js' | 'css' }) =>
          `/tools/format/${code}.${format}`
      )
      .auth()
      .ok(),
  ];
}
