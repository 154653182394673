import { combineReducers } from '@reduxjs/toolkit';
import auth from './features/auth';
import dashboard from './features/dashboard';
import demographics from './features/demographics';
import { traitsApi } from './features/demographics/traits';
import dialogs from './features/dialogs';
import earnings from './features/earnings';
import errors from './features/errors';
import forms from './features/forms';
import friends from './features/friends';
import geo from './features/geo';
import locale from './features/locale';
import magic from './features/magic';
import navigation from './features/navigation';
import registration from './features/registration';
import settings from './features/settings';
import survey from './features/survey';
import user from './features/user';

export const createRootReducer = () =>
  combineReducers({
    ...navigation,
    ...auth,
    ...user,
    ...dialogs,
    ...forms,
    ...geo,
    ...errors,
    ...registration,
    ...locale,
    ...magic,
    ...survey,
    ...friends,
    ...demographics,
    earnings,
    settings,
    dashboard,
    [traitsApi.reducerPath]: traitsApi.reducer,
    // other reducers here
  });

export default createRootReducer;
