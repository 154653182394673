const tenantName = process.env.REACT_APP_TENANT_NAME || 'pv';

const staticFilesOrigin = process.env.REACT_APP_PV_STATIC_BASE_URL;

if (null == staticFilesOrigin) {
  throw new Error('REACT_APP_PV_STATIC_BASE_URL is not set');
}

export function getTenant() {
  return tenantName;
}

export function getTenantStaticResourceUrl(subPath: string) {
  return `${staticFilesOrigin}/${tenantName}/${subPath}`;
}
