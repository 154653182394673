import { MAGIC_ACTION_NAMES } from '@model/features/magic/types';
import { apiUpdateEmailAndSendActivationAsyncThunk } from '@model/features/user/asyncActions';
import { getPvContext } from '@services/context';
import { collectFormValues } from '@services/forms';
import { safelyAwait } from '@services/promises';
import { SEC_INTO_MSEC } from '@services/time';
import { changeEmailValidationSchema } from '@services/validation/knownValidations';
import { useLocaleServices } from '@ui/contextProviders';
import { If, IfNot, PVImage } from '@ui/elements';
import { useCountdown, useMagicKnowledge } from '@ui/hooks';
import { UI_TEST_ASSIST } from '@ui/testids';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useId, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import pvs from './activation.module.scss';
import { usePrefetchActivationForDev } from './usePrefetchActivationForDev';

const isProduction = 'production' === getPvContext('backend_env', 'production');

// https://pv-1044-spa-react-rails.review.vpn.aytm.com/users/activation/3ca0fc8e66fe7ad27ca85188
EmailActivationSmartFormComponent.propTypes = {
  name: PropTypes.string,
  options: PropTypes.object,
  onSubmit: PropTypes.func,
};
/**
 * @type {SmartFormComplexComponent} EmailActivationSmartFormComponent
 * @returns {JSX.Element}
 * @constructor
 */
export function EmailActivationSmartFormComponent({ name, options, onSubmit }) {
  const { placeholder /*, update_email_url */ } = options;
  const { t } = useLocaleServices();

  const [emailToActivate, setEmailToActivate] = useState(null);
  const [showChangeEmailPane, setShowChangeEmailPane] = useState(false);
  const [emailChangeError, setEmailChangeError] = useState(null);
  const [resendDisabled, setResendDisabled] = useState(true);

  const dispatch = useDispatch();
  const { register, setValue, formId, handleSubmit } = useFormContext();

  const updateEmailFormId = useId();
  const inputCodeId = useId();

  const field = register(`${name}.${'code'}`);

  const handleResend = useCallback(
    async event => {
      event?.preventDefault?.();

      setResendDisabled(true);
      void dispatch(apiUpdateEmailAndSendActivationAsyncThunk(emailToActivate));
    },
    [dispatch, emailToActivate]
  );

  const handlerActivation = useCallback(
    userData => {
      setValue(`${name}.${'code'}`, userData?.email_activation_code);
    },
    [name, setValue]
  );

  const devFetchActivationHandlerWrapper = usePrefetchActivationForDev();

  const handleEmailChangeInput = useCallback(() => {
    setEmailChangeError(null);
  }, []);

  const handleSendEmailChange = useCallback(
    async event => {
      event?.preventDefault?.();
      setEmailChangeError(null);
      const data = collectFormValues(event.target);
      const [err] = await safelyAwait(
        changeEmailValidationSchema.validate(data, { context: { old_email: emailToActivate } })
      );
      if (err) {
        setEmailChangeError(err.message);
        return void 0;
      }

      const { email } = data;
      setEmailToActivate(email);
      setShowChangeEmailPane(false);
      return handleResend(null);
    },
    [emailToActivate, handleResend]
  );

  const handleShowChangePane = () => {
    setShowChangeEmailPane(true);
  };

  const [countdown, countdownActions] = useCountdown(30 * SEC_INTO_MSEC, SEC_INTO_MSEC);

  useEffect(() => {
    if (!resendDisabled) {
      return;
    }

    if (0 === countdown && countdownActions.isFinished()) {
      setResendDisabled(false);
      countdownActions.reset();
    } else if (!countdownActions.isStarted() && !countdownActions.isFinished()) {
      countdownActions.start(30 * SEC_INTO_MSEC);
    }
  }, [countdown, countdownActions, resendDisabled]);

  const { activationCode } = useMagicKnowledge([
    MAGIC_ACTION_NAMES.REGISTRATION_EMAIL_ACTIVATION,
    MAGIC_ACTION_NAMES.SETTINGS_EMAIL_CONFIRMATION,
    MAGIC_ACTION_NAMES.SETTINGS_ALT_EMAIL_CONFIRMATION,
  ]);

  const activationCodeAutoSubmissionRef = useRef(null);

  useEffect(() => {
    if (!activationCode) {
      return;
    }
    setValue(`${name}.${'code'}`, activationCode);
  }, [activationCode, name, setValue]);

  useEffect(() => {
    if (!activationCode) {
      return;
    }
    if (activationCodeAutoSubmissionRef.current) {
      return;
    }
    activationCodeAutoSubmissionRef.current = handleSubmit(onSubmit)();
  }, [activationCode, handleSubmit, onSubmit, setValue]);

  const resendLabel = resendDisabled
    ? [t('activation.resend'), ' in ', String(Math.floor(countdown / SEC_INTO_MSEC)), ' seconds']
    : t('activation.resend');

  return (
    <div {...UI_TEST_ASSIST.CMP_EMAIL_ACTIVATION} className={pvs.form}>
      <div className={pvs.head}>
        <div className={pvs.img_wrap}>
          <PVImage url="images/registration/key.png" alt="key" />
        </div>

        <If condition={Boolean(emailToActivate)}>
          <p>
            {t('activation.thanks_for')}
            <br />
            <If condition={Boolean(emailToActivate && !showChangeEmailPane)}>
              {t('activation.please_verify')}
              <br />
              {t('activation.we_have_sent')}
              <br />
              E-mail: {emailToActivate}
              &nbsp;&nbsp;
              <button
                type="button"
                {...UI_TEST_ASSIST.BTN_EMAIL_ACTIVATION_CHANGE}
                className={pvs.button_in_text}
                onClick={handleShowChangePane}
              >
                {t('labels.change')}
              </button>
              <br />
            </If>
          </p>
        </If>
      </div>

      <If condition={Boolean(!emailToActivate || showChangeEmailPane)}>
        <div className={pvs.wrap}>
          <div className={pvs.resend_wrap}>
            <div className={pvs.filler} />
            <form className="form-singular" id={updateEmailFormId} onSubmit={handleSendEmailChange}></form>
            <div className={[pvs.button, pvs.change_email].join(' ')}>
              <InputText
                {...UI_TEST_ASSIST.FLD_EMAIL_ACTIVATION_EMAIL}
                className={[pvs.input_text, emailChangeError ? 'p-invalid' : ''].join(' ')}
                name="email"
                aria-label="enter e-mail"
                placeholder={t('labels.email.placeholder')}
                form={updateEmailFormId}
                defaultValue={String(emailToActivate || '')}
                onChange={handleEmailChangeInput}
              />
              <If condition={Boolean(emailChangeError)}>
                <p className={pvs.error} {...UI_TEST_ASSIST.ERR_EMAIL_ACTIVATION_EMAIL}>
                  {t(emailChangeError, 'errors.no_valid_email')}
                </p>
              </If>
              <div className={pvs.save_cancel_btns}>
                <Button
                  {...UI_TEST_ASSIST.BTN_EMAIL_ACTIVATION_EMAIL_SAVE}
                  type="submit"
                  label={'save and send e-mail'}
                  form={updateEmailFormId}
                  aria-label="send email"
                />
              </div>
            </div>
          </div>
        </div>
      </If>

      <If condition={Boolean(emailToActivate && !showChangeEmailPane)}>
        <div className={pvs.wrap}>
          <div className={pvs.input_btn_wrap}>
            <label htmlFor={inputCodeId}>{t('activation.please_follow')}</label>
            <div className={pvs.input_btn}>
              <InputText
                {...UI_TEST_ASSIST.FLD_EMAIL_ACTIVATION_CODE}
                {...field}
                id={inputCodeId}
                aria-label="enter the code"
                placeholder={t(placeholder)}
                //                disabled={ isInnerFormSubmitting }
                form={formId}
                type="text"
                autoComplete="off"
                className={pvs.input_text}
              />
              <Button
                {...UI_TEST_ASSIST.BTN_EMAIL_ACTIVATION_VERIFY_CODE}
                label={t('activation.verify_email')}
                aria-label="Verify e-mail"
                form={formId}
                type="submit"
              />
            </div>
          </div>
          <div className={pvs.resend_wrap}>
            <div className={pvs.filler} />
            <div className={pvs.button}>
              <Button
                {...UI_TEST_ASSIST.BTN_EMAIL_ACTIVATION_RESEND}
                label={resendLabel}
                aria-label="Re-send activation e-mail"
                disabled={resendDisabled}
                type="button"
                onClick={handleResend}
                loading={resendDisabled}
                loadingIcon={null}
              />
            </div>
          </div>
        </div>

        <br />
        <IfNot condition={Boolean(isProduction)}>
          <div className={pvs.dev_wrap}>
            <div className={pvs.filler} />
            <Button
              label="[dev] Activation"
              onClick={devFetchActivationHandlerWrapper(handlerActivation)}
              type="button"
            />
          </div>
        </IfNot>
      </If>
    </div>
  );
}
