import { GTags } from '@/constants/google_tags';
import { AppDispatch } from '@/store';
import { invokeDialog } from '@model/features/dialogs';
import { DIALOG_NAMES } from '@model/features/dialogs/dialogNames';
import { ISWidgetResultShow, IWhyLink, IWhyWindow } from '@model/features/survey/types';
import { gTagSender } from '@services/analytics/gTagSender';
import { ApplicationDialog } from '@ui/components/ApplicationDialog';
import { useLocaleServices } from '@ui/contextProviders/Locale';
import { routeNames } from '@ui/routes/routeNames';
import { FC, useState } from 'react';
import { useNavigate } from 'react-router';
import { cleanSurveyInvitationCode } from './actionHandlers';
import { DialogContent } from './components/DialogContent';
import { DialogDescriptionPage } from './components/DialogDescriptionPage';

type SurveyResultDialogProps = {
  name?: string;
  data: ISWidgetResultShow['data'];
};

export const SurveyResultDialog: FC<SurveyResultDialogProps> = ({ name = DIALOG_NAMES.SURVEY_RESULT, data }) => {
  const [showPage, setShowPage] = useState(false);
  const navigate = useNavigate();
  const { t } = useLocaleServices();

  const onClose = () => {
    gTagSender(GTags.closeSurvey);
    cleanSurveyInvitationCode();
    navigate(routeNames.dashboard, { replace: true });
  };

  return (
    <ApplicationDialog
      name={name}
      className="h-full sm:h-auto"
      contentClassName="h-full !max-w-full"
      onBeforeClose={onClose}
      title={showPage ? null : t(data.title.label)}
      closable={!showPage}
      maskClassName="!z-[20000]"
    >
      {showPage && isWhyWindow(data.why) ? (
        // Survey result explanation to user
        <DialogDescriptionPage pageData={data.why.page} onBackClick={() => setShowPage(false)} dialogName={name} />
      ) : (
        <DialogContent dialogName={name} data={data} onWhyClick={() => setShowPage(true)} />
      )}
    </ApplicationDialog>
  );
};

export function openResultDialog(dispatch: AppDispatch) {
  const data = {};
  dispatch(invokeDialog(DIALOG_NAMES.SURVEY_RESULT, { data }));
}

function isWhyWindow(data: IWhyWindow | IWhyLink): data is IWhyWindow {
  return data && 'page' in data;
}
