import type { AnySchema, ObjectSchema, StringSchema } from 'yup';
import { Primitive } from '@/types';

export type EndpointName = string;
export type Stringifiable = Exclude<Primitive, symbol>;

export type AllowedSchemaType = AnySchema | ObjectSchema<object> | StringSchema;

export type UrlStringOrFunction = string | ((urlSource: Record<string, Stringifiable>) => string);

export interface EndpointStaticDescriptionContext {
  name: EndpointName;
  description?: string;
  method?: string;
  url?: UrlStringOrFunction;
  authenticate?: boolean;
  noRepeat?: boolean;
  body?: AllowedSchemaType;
  params?: AllowedSchemaType;
  headers?: Record<string, string>;
  statusCodes?: {
    [statusCode: string]: boolean | string | AllowedSchemaType;
  };
  fullPath?: boolean;
  redirects?: EndpointName;
  codeHandlersSchema?: AllowedSchemaType;
}

export function isSchema(val: undefined | boolean | string | AllowedSchemaType): val is AllowedSchemaType {
  return Boolean(val) && typeof val !== 'boolean' && typeof val !== 'string';
}

export interface IEndpointSecondaryDefinition {
  auth(): Omit<this, 'auth'>;
  noRepeat(): Omit<this, 'noRepeat'>;
  code: (statusCode: string | number, schema: void | string | AllowedSchemaType) => this;
  body(schema: AllowedSchemaType): Omit<this, 'body'>;
  params(schema: AllowedSchemaType): Omit<this, 'params'>;
  header(key: Primitive, value: string): this;
  fullPath(): Omit<this, 'fullPath'>;
  redirects(endpointName: EndpointName): Omit<this, 'redirects'>;
  ok(): EndpointStaticDescriptionContext;
}
// need this interface for later merging and early type-forming
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IEndpointPrimaryDefinition {}

type IEndpointCombinedType = IEndpointPrimaryDefinition & IEndpointSecondaryDefinition;
export interface IEndpointPrimaryDefinition {
  GET: (url: UrlStringOrFunction) => IEndpointSecondaryDefinition;
  PUT: (url: UrlStringOrFunction) => IEndpointSecondaryDefinition;
  POST: (url: UrlStringOrFunction) => IEndpointSecondaryDefinition;
  PATCH: (url: UrlStringOrFunction) => IEndpointSecondaryDefinition;
  DELETE: (url: UrlStringOrFunction) => IEndpointSecondaryDefinition;
  copy: (
    name: EndpointName,
    newName: EndpointName,
    description?: string
  ) => IEndpointPrimaryDefinition | IEndpointSecondaryDefinition;
}
type ApiType = IEndpointPrimaryDefinition | Partial<IEndpointCombinedType>;

export interface IEndpointSecondaryDefinition {
  endpoint(name: EndpointName, description?: string): IEndpointPrimaryDefinition;
}

export interface EndpointDescriptionContext extends EndpointStaticDescriptionContext {
  api: ApiType;
}

export const registeredEndpoints: Map<EndpointName, EndpointDescriptionContext> = new Map();

export function getEndpoint(name: EndpointName): undefined | EndpointDescriptionContext {
  return registeredEndpoints.get(name);
}

export function endpoint(name: EndpointName, description = ''): IEndpointPrimaryDefinition {
  const api = _stepVerbAndAddress();
  const context: EndpointDescriptionContext = {
    name,
    description,
    api,
  };

  registeredEndpoints.set(name, context);

  return api;

  function GET(url: UrlStringOrFunction) {
    _register('GET', url);
    _stepPastAddress();
    return context.api as IEndpointSecondaryDefinition;
  }

  function PUT(url: UrlStringOrFunction) {
    _register('PUT', url);
    _stepPastAddress();
    return context.api as IEndpointSecondaryDefinition;
  }

  function POST(url: UrlStringOrFunction) {
    _register('POST', url);
    _stepPastAddress();
    return context.api as IEndpointSecondaryDefinition;
  }

  function PATCH(url: UrlStringOrFunction) {
    _register('PATCH', url);
    _stepPastAddress();
    return context.api as IEndpointSecondaryDefinition;
  }

  function DELETE(url: UrlStringOrFunction) {
    _register('DELETE', url);
    _stepPastAddress();
    return context.api as IEndpointSecondaryDefinition;
  }

  function _modifyApi(propName: string) {
    const nextApi = context.api;
    delete nextApi[propName];
    context.api = nextApi;
  }

  function auth() {
    context.authenticate = true;
    _modifyApi('auth');
    return context.api;
  }

  function noRepeat() {
    context.noRepeat = true;
    _modifyApi('noRepeat');
    return context.api;
  }

  function body(schema: AllowedSchemaType) {
    context.body = schema;
    _modifyApi('body');
    return context.api;
  }

  function params(schema: AllowedSchemaType) {
    context.params = schema;
    _modifyApi('params');
    return context.api;
  }

  function header(key, value) {
    if (!context.headers) {
      context.headers = {};
    }
    Object.assign(context.headers, { [key]: value });
    return context.api;
  }

  function code(statusCode: string | number, schema: void | string | AllowedSchemaType) {
    Object.assign(context, {
      statusCodes: {
        ...(context.statusCodes ?? {}),
        [statusCode]: schema ?? true,
      },
    });

    return context.api;
  }

  function fullPath() {
    context.fullPath = true;
    _modifyApi('fullPath');
    return context.api;
  }

  function copy(name: EndpointName, newName: EndpointName, description?: string) {
    const template = getEndpoint(name);
    if (!template) {
      return endpoint(newName, description);
    }
    Object.assign(context, template, { name: newName, description });
    _stepPastAddress();
    return context.api as IEndpointPrimaryDefinition | IEndpointSecondaryDefinition;
  }

  function redirects(name: EndpointName) {
    context.redirects = name;
    _modifyApi('redirects');
    return context.api;
  }

  function _register(method, url) {
    Object.assign(context, { method, url });
  }

  function _stepVerbAndAddress(): IEndpointPrimaryDefinition {
    const result: IEndpointPrimaryDefinition = {
      GET,
      PUT,
      POST,
      PATCH,
      DELETE,
      copy,
    };
    return result;
  }

  function ok(): EndpointStaticDescriptionContext {
    delete (context as Partial<EndpointDescriptionContext>).api;
    return context;
  }

  function _endpoint(...args: [EndpointName, string?]) {
    void ok();
    return endpoint(...args);
  }

  function _stepPastAddress() {
    Object.assign(context, {
      api: { auth, noRepeat, code, body, params, header, fullPath, redirects, ok, endpoint: _endpoint },
    });
  }
}
