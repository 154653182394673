import { accessAvailableSurveys, selectIsLoadingForWidget, selectUpdateRequired } from '@model/features/survey';
import { selectDashboardProfileProgress } from '@model/features/user/selectors';
import { LoadingSpinner } from '@ui/elements/spinners';
import { MEDIA_BP, useMediaQuery } from '@ui/hooks';
import { useAppSelector } from '@ui/hooks/redux';
import PropTypes from 'prop-types';
import { EmptyListBanner } from './EmptyListBanner';
import { ForbidenSurveysBanner } from './ForbidenSurveysBanner';
import { MobileSurveyList } from './ModileList';
import { SurveyTableRow } from './SurveyElement';
import './styles.scss';

DashboardSurveysList.propTypes = {
  surveys: PropTypes.array,
  traitScoreSurveys: PropTypes.array,
};

export function DashboardSurveysList() {
  const availableSurveys = useAppSelector(accessAvailableSurveys());
  const updateRequired = useAppSelector(selectUpdateRequired);
  const isLoading = useAppSelector(selectIsLoadingForWidget);
  const dashboardProfileProgress = useAppSelector(selectDashboardProfileProgress);

  const isDesktop = useMediaQuery(MEDIA_BP.isOrGreaterThan1024);

  if (isLoading) {
    return (
      <div className="mx-auto mt-12 w-full max-w-[1178px] rounded-3xl bg-dark-b py-8 text-center">
        <LoadingSpinner />
      </div>
    );
  }

  if (dashboardProfileProgress) {
    return null;
  }

  if (updateRequired) {
    return (
      <div className="flex justify-center max-md:mt-10 md:py-8 lg:mt-16">
        <ForbidenSurveysBanner />
      </div>
    );
  }

  if (!availableSurveys || 0 === availableSurveys?.length) {
    return (
      <div className="flex justify-center max-md:mt-10 md:py-8 lg:mt-16">
        <EmptyListBanner />
      </div>
    );
  }

  return (
    <section className="mx-auto mt-6 w-full max-w-[1164px] rounded-3xl bg-dark-b px-6 py-8 md:px-10 md:py-10 lg:mt-8 lg:py-8 2xl:mt-12 2xl:p-12">
      {isDesktop ? (
        <div className="dashboard-surveys">
          {availableSurveys.map(survey => (
            <SurveyTableRow key={survey.id} survey={survey} />
          ))}
        </div>
      ) : (
        <MobileSurveyList surveys={availableSurveys} />
      )}
    </section>
  );
}
