import { routeNames } from '@ui/routes/routeNames';

export function handleImperativeActionThunk(imperativeAction: ImperativeAction) {
  if (
    'page' === imperativeAction.type &&
    'profile' === imperativeAction.name &&
    location.pathname !== routeNames.usersPasswordReset
  ) {
    window.navigate(routeNames.demographicsIndex);
  }
}
