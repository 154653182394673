import {
  accessMagicActionName,
  accessMagicActionPayload,
  magicPayloadExtractors,
  recordMagicAction,
} from '@model/features/magic';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo } from 'react';

export const useMagicKnowledge = magicActionName => {
  const storedActionName = useSelector(accessMagicActionName());
  const storedActionPayload = useSelector(accessMagicActionPayload());

  const dispatch = useDispatch();

  const expectedActionNames = useMemo(
    () => new Set(Array.isArray(magicActionName) ? magicActionName : [magicActionName]),
    [magicActionName]
  );
  const magicPayload = useMemo(
    () =>
      !expectedActionNames.has(storedActionName) ? {} : magicPayloadExtractors[storedActionName]?.(storedActionPayload),
    [expectedActionNames, storedActionName, storedActionPayload]
  );

  useEffect(() => {
    if (!expectedActionNames.has(storedActionName)) {
      return void 0; // undefined
    }

    return () => {
      dispatch(recordMagicAction(null));
    };
  }, [magicPayload, dispatch, expectedActionNames, storedActionName]);

  return magicPayload;
};
