import { getTenantStaticResourceUrl } from '@/utils/tenant';
import PropTypes from 'prop-types';

const imageStyle = { boxSizing: 'border-box' };

const urlPattern = /^http(s)?:\/\//;

/**
 * @typedef {object} ImageProps
 * @property {string} [url]
 * @property {string} [className]
 * @property {string} [alt]
 * @property {string} [srcSet]
 * @property {React.CSSProperties} [style]
 *
 * @typedef {import('react').ImgHTMLAttributes<HTMLImageElement> & ImageProps} PVImageProps
 */
/**
 * @param {PVImageProps} Props
 * @returns {JSX.Element}
 */
export function PVImage({ url, className, alt = '', srcSet, ...restProps }) {
  const imageUrl = urlPattern.test(url) ? url : getTenantStaticResourceUrl(url);

  return (
    <img
      src={imageUrl}
      srcSet={srcSet}
      className={className}
      alt={alt}
      width={restProps.width}
      {...restProps}
      style={restProps.style ?? imageStyle}
    />
  );
}
PVImage.propTypes = {
  url: PropTypes.string.isRequired,
  srcSet: PropTypes.string,
  className: PropTypes.string,
  alt: PropTypes.string,
};
