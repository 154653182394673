import { getRandomItem } from '@/utils/array';
import { concatClsx } from '@/utils/classnames';
import { InfoBalloon } from '@ui/components/InfoBalloon';
import { BALLOON } from '@ui/components/InfoBalloon/resources';
import { useLocaleServices } from '@ui/contextProviders/Locale';
import { RouterLink } from '@ui/lib/Link/AppLink';
import { FC, useRef } from 'react';
import ReactMarkdown from 'react-markdown';

const balloonNames = [BALLOON.SMALL_PANDA, BALLOON.SMALL_CHAMELEON, BALLOON.SMALL_PARROT];

const mdComponents = {
  a: props => (
    <RouterLink to={props.href} className="px-0">
      {props.children}
    </RouterLink>
  ),
};

export const EmptyListBanner: FC<{ className?: string }> = ({ className }) => {
  const randomBalloonName = useRef(getRandomItem(balloonNames));
  const { t } = useLocaleServices();

  return (
    <div
      className={concatClsx(
        'empty-list-banner flex w-full max-w-[1210px] items-center rounded-3xl bg-light-c max-md:flex-col max-md:text-center md:ml-6 md:py-6 lg:py-12 dark:bg-dark-b',
        className
      )}
    >
      <div className="w-4/5 max-md:-translate-y-10 md:w-auto md:-translate-x-6 lg:-my-20 lg:w-[357px]">
        <InfoBalloon name={randomBalloonName.current} title="Stay tuned" className="[&_img]:mx-auto" />
      </div>
      <div className="px-6 lg:px-8">
        <div className="font-bitter text-title-l font-bold text-accent-a">{t('dashboard.surveys.empty_title')}</div>
        <ReactMarkdown components={mdComponents} className="text-body text-dark-a max-md:mb-4 dark:text-light-d">
          {t('dashboard.surveys.empty_subtitle')}
        </ReactMarkdown>
      </div>
    </div>
  );
};
