import { classNames } from 'primereact/utils';
import { ReactNode } from 'react';
import { SvgCircularProgressBar, SvgCircularProgressBarProps } from './SvgCircularProgressBar';
import './circle-progressbar.scss';

type CircularProgressBarProps = {
  width?: number | string;
  progressColor?: string;
  bgColor?: string;
  progress?: number;
  showDot?: boolean;
  icon?: ReactNode;
  iconColor?: string;
  title?: string | ReactNode;
  subTitle?: string | ReactNode;
  button?: ReactNode;
  animated?: boolean;
  subtitleClassName?: string;
  className?: string;
} & SvgCircularProgressBarProps;

export function CircularProgressBar({
  width,
  progressColor = 'rgb(var(--pv-dark-e))',
  bgColor = 'var(--p-progressbar-bg)',
  progress = 0,
  showDot = true,
  icon,
  iconColor,
  title,
  subTitle,
  button,
  animated,
  subtitleClassName,
  className,
  ...rest
}: CircularProgressBarProps) {
  return (
    <div
      className={classNames(className, 'p-component circular-progress relative')}
      data-role="circular-progress-bar"
      role="progressbar"
    >
      <div className="absolute left-0 right-0 top-0">
        {icon != null && (
          <div className="progress_ico" style={{ color: iconColor ?? progressColor ?? '#B3E000' }}>
            {icon}
          </div>
        )}
        {title && <div className="progress_title">{title}</div>}
        {subTitle && <div className={classNames('progress_description', subtitleClassName)}>{subTitle}</div>}
        {button}
      </div>

      <SvgCircularProgressBar
        value={progress}
        width={width}
        bgColor={bgColor}
        color={progressColor}
        hasPoint={showDot}
        animated={animated}
        {...rest}
      />
    </div>
  );
}
