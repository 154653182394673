import { getGeneralProtectedDataAsyncThunk } from '@model/features/registration/asyncThunks';
import { blockAsync, forTimeout, makeControlledPromise, safelyAwait } from '@services/promises';
import { getPollingTimeoutForTime } from '@services/time';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

export const usePhoneVerification = ({ /*value,*/ status_url }) => {
  const dispatch = useDispatch();

  const controlledPromiseRef = useRef(null);

  const [verified, setVerified] = useState(false);

  const handlePollTelephone = useMemo(
    () =>
      blockAsync(async () => {
        const result = await dispatch(getGeneralProtectedDataAsyncThunk(status_url)).unwrap();
        return result?.response?.data;
      }, 'handlePollTelephone'),
    [dispatch, status_url]
  );

  const handleAPollRequest = useCallback(
    async (context, BREAK_SYM) => {
      const [err, result] = await safelyAwait(handlePollTelephone());
      if (!err && result) {
        const { active } = result;
        if (true === active) {
          setVerified(true);
          controlledPromiseRef.current && controlledPromiseRef.current.resolve();
          return BREAK_SYM;
        }
      }

      context.pollAttemptsCount++;
      const pollingTimeout = getPollingTimeoutForTime(context.pollAttemptsCount);
      await forTimeout(pollingTimeout);
      return void 0;
    },
    [handlePollTelephone]
  );

  const startPollingLoop = useCallback(
    promise => {
      const context = {
        keepPolling: true,
        pollAttemptsCount: 0,
      };
      const cleanup = () => {
        context.keepPolling = false;
      };

      promise.then(cleanup, cleanup);
      promise.then(() => {
        setVerified(true);
      });

      void blockAsync(async () => {
        const BREAK_SYM = Symbol('BREAK_SYM');
        do {
          const result = await handleAPollRequest(context, BREAK_SYM);
          if (result === BREAK_SYM) {
            break;
          }
        } while (context.keepPolling);
      }, 'startPollingLoop')();

      return cleanup;
    },
    [handleAPollRequest]
  );

  const makeInterceptSubmissionHandler = useCallback(() => {
    // start polling
    if (controlledPromiseRef.current) {
      controlledPromiseRef.current.reject();
    }

    controlledPromiseRef.current = makeControlledPromise();
    void startPollingLoop(controlledPromiseRef.current);
    return controlledPromiseRef.current;
  }, [startPollingLoop]);

  const stopPolling = useCallback(() => {
    controlledPromiseRef.current && controlledPromiseRef.current.reject();
    controlledPromiseRef.current = null;
  }, []);

  return {
    verified,
    phonePollingProcessRef: controlledPromiseRef,
    startPhoneVerificationPolling: makeInterceptSubmissionHandler,
    stopPolling,
  };
};
