const mobile = window.innerWidth < 768;
export const resources = [
  {
    id: 'aytmfd_css',
    type: 'style',
    url: '/stylesheets/fonts/aytmfd.css',
  },
  {
    id: 'external_survey_main_css',
    type: 'style',
    url: mobile ? '/stylesheets/_ui/external_survey/mobile/main.css' : '/stylesheets/_ui/external_survey/main.css',
  },
  {
    id: 'jquery_min',
    type: 'script',
    url: '/javascripts/bundles/jquery_min.js',
  },
  {
    id: 'include_swidget_packs',
    type: 'script',
    url: '/polls/include_swidget_packs.js',
  },
];
