import { getIcon, getOsTheme } from '@/utils/osColorTheme';
import { hasSupportUpdates, selectUserAvatarThumb, selectUserName } from '@model/features/user/selectors';
import { restoreDataFromStorage } from '@services/storage';
import { useAuth, useLocaleServices } from '@ui/contextProviders';
import { useAppSelector } from '@ui/hooks/redux';
import { Icon, Icons } from '@ui/lib/Icon';
import { useThemeChange } from '@ui/pages/Settings/hooks/useThemeChange';
import { routeNames } from '@ui/routes/routeNames';
import { Avatar } from 'primereact/avatar';
import { Menu } from 'primereact/menu';
import { useId, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export function MenuElement() {
  const { t } = useLocaleServices();
  const navigate = useNavigate();
  const [theme, setTheme] = useState(restoreDataFromStorage('theme') || getOsTheme());
  const menuId = useId();
  const menu = useRef(null);
  const { handleSignOutAndNavigate } = useAuth();
  const avatarThumb = useAppSelector(selectUserAvatarThumb);
  const userName = useAppSelector(selectUserName);
  const hasUpdates = useAppSelector(hasSupportUpdates);
  const { updateTheme } = useThemeChange();

  const handleToggleMenu = event => {
    menu.current.toggle(event);
  };

  const handleKeyToggleMenu = event => {
    if (['Enter', 'Space'].includes(event.code)) {
      event?.preventDefault();
      menu.current.toggle(event);
    }
  };

  const handleToggleTheme = () => {
    // TODO fix this toggle + move selected theme to redux to update settings page
    const val = 'retro' === theme ? 'dark' : 'dark' === theme ? 'light' : 'retro';
    setTheme(val);
    updateTheme(val);
  };

  const menuIcon = icon => <Icon icon={icon} className="flex pr-4 text-[1.4rem] text-dimmed-a dark:text-light-d" />;
  const menuTitle = title => (
    <div className="text-body text-dark-a first-letter:uppercase dark:text-light-b">{t(title)}</div>
  );

  const menuItems = [
    {
      label: menuTitle('menu.profile'),
      icon: menuIcon(Icons.User),
      command: () => navigate(routeNames.demographicsIndex),
    },
    {
      label: menuTitle('menu.settings'),
      icon: menuIcon(Icons.Settings),
      command: () => navigate(routeNames.settingsIndex),
    },
    {
      label: (
        <div className="flex items-center">
          {menuTitle('menu.s_tickets')}
          {hasUpdates && <div className="ml-4 h-[.625rem] w-[.625rem] rounded-full bg-accent-a" />}
        </div>
      ),
      icon: menuIcon(Icons.Support),
      command: () => navigate(window.location.pathname + '#support/list'),
    },
    {
      label: menuTitle('menu.theme'),
      icon: menuIcon(getIcon(theme)),
      command: handleToggleTheme,
    },
    {
      label: menuTitle('menu.logout'),
      icon: menuIcon(Icons.Exit),
      command: handleSignOutAndNavigate,
    },
  ];

  return (
    <>
      <Menu model={menuItems} popup ref={menu} id={menuId} className="overflow-hidden" />
      <div
        className="cursor-pointer text-accent-b"
        onClick={handleToggleMenu}
        onKeyDown={handleKeyToggleMenu}
        aria-controls={menuId}
        aria-label="menu"
        tabIndex="0"
      >
        <div className="flex items-center justify-center font-bitter font-semibold text-light-d">
          {avatarThumb && (
            <Avatar
              image={avatarThumb}
              size="normal"
              shape="circle"
              className="rounded-full ring-4 ring-dark-b retro:ring-[#0d1727] 2xl:size-12 2xl:ring-8"
            />
          )}
          {Boolean(userName) && <span className="ml-8 max-md:hidden">{userName}</span>}
          <i className="pi pi-angle-down ml-2 text-accent-b max-md:hidden" />
        </div>
      </div>
    </>
  );
}
