import { getComponent } from '../componentMapping';

import { makeConnectedFieldNames, makeDependentsMap } from '@services/fields/makeDependentsMap';

export const formBuildingMapperContext = {
  getComponent: () => null,
};
/**
 *
 * @type {FormBuildingMapperCreator}
 * @returns {FormBuildingMapper}
 */
export const makeFormBuildingMapper = options => {
  if (!options) {
    throw new Error(`makeFormBuildingMapper: 'options' property is not set`);
  }

  const {
    fields,

    masterOptions,

    onSubmit,
    interceptSubmit,

    noInternalWrapper,

    fieldClassName,
  } = options;

  const dependentsMap = makeDependentsMap(fields || []);
  const connectedFieldNamesMaker = makeConnectedFieldNames(dependentsMap);
  const connectedFieldNamesLookup = Object.fromEntries(
    (fields || []).map(({ name }) => [name, connectedFieldNamesMaker(name)])
  );

  return formBuildingMapper;

  /**
   * @type {formBuildingMapper}
   */
  function formBuildingMapper(field /*, idx, fields*/) {
    const { type, name, value, options: fieldOptions, ...restField } = field;

    const options = { ...(masterOptions || {}), ...(fieldOptions || {}) };
    const { multiple } = fieldOptions || {};

    void value; // cutting off vale prop

    const Component = getComponent(type, name, { multiple, preferClean: masterOptions?.preferClean });

    if (!Component) {
      // As API is changing, it's desirable to see warnings for unexpected types
      // eslint-disable-next-line no-console
      console.warn(`No component is defined for field type: '${type}' (name: '${name}')`);
      return null;
    }

    const connectedFieldNames = connectedFieldNamesLookup[name];

    const masterProps = {
      className: fieldClassName,

      onSubmit,
      interceptSubmit,
      noInternalWrapper,
      masterOptions,
    };
    const calculatedProps = {
      dependentsMap,
      connectedFields: connectedFieldNames,
    };
    const fieldProps = {
      name,
      ...restField,
      defaultValue: value,
      options,
    };
    /**
     *
     * @type {SmartFormFieldWrapperProps}
     */
    const componentProps = {
      ...masterProps,
      ...calculatedProps,
      ...fieldProps,
    };

    return <Component key={name} {...componentProps} />;
  }
};
