export const routeNames = {
  root: '/',
  spaIndex: '/spa',
  countryIndex: '/country',
  countryForbidden: '/country/out-of-reach',
  countryUnsupported: '/country/no-survey-panel',
  magicIndex: '/magic',
  magicCode: '/magic/:code',
  pagesIndex: '/pages',
  pagesSpecific: '/pages/:pageName',
  usersPasswordForgot: '/user/password/forgot',
  usersPasswordReset: '/user/password/reset',
  usersEmailSetup: '/user/email/setup',
  usersTermsConsent: '/user/terms',
  usersEmailActivate: '/user/email/activate',
  sandboxIndex: '/sandbox',
  registrationIndex: '/registration',
  surveyIndex: '/survey',
  surveyCode: '/survey/:invitationCode',
  surveyConflict: '/survey/conflict',
  earningsIndex: '/earnings',
  earningsHistory: '/earnings/history',
  demographicsIndex: '/demographics',
  traitscoreIndex: '/traitscore',
  settingsIndex: '/settings',
  dashboard: '/dashboard',
  friendsIndex: '/friends',
  friendsRegistered: '/friends/registered',
  loginPassword: '/login/password',
  loginEmailCode: '/login/email/code',
  loginEmailIndex: '/login',
  signUpIndex: '/register',
  errorIndex: '/error',
  errorCode: '/error/:errorCode',
  accountClosed: '/account-closed',
  missYou: '/miss-you',
  unsubscribe: '/unsubscribe',
};

export const routeValues = {
  errorMinorProblem: '/error/minor',
  errorCaptcha: '/error/captcha',
};

export const ERROR_PAGE = {
  MAGIC_NO: 'magic-no',
  MAGIC_BAD: 'magic-bad',
  NETWORK: 'network',
  INVALID_USER_STATUS: 'invalid_user_status',
};

export const toErrorPage = (errorCode = ERROR_PAGE.NETWORK) => routeNames.errorCode.replace(':errorCode', errorCode);

export const toSurveyPage = (invitationCode: string) =>
  routeNames.surveyCode.replace(':invitationCode', invitationCode);
