import { accessLastError, accessLastErrorTimestamp, setLastReportedErrorHandled } from '@model/features/errors';
import { getTimestamp } from '@services/time';
import { UI_TEST_ASSIST } from '@ui/testids';
import { Toast } from 'primereact/toast';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import pvs from './index.module.scss';

/**
 * @typedef {Object} UIReportingContext
 * @property {React.MutableRefObject<null>} toastRef
 * @property {(error: string, timestamp?: number) => void} showError
 * @property {(message: string, head?: string) => void} showSuccess
 */

/**
 * @type {React.Context<UIReportingContext>}
 */
const ToastContext = React.createContext(null);

UIReportingProvider.propTypes = {
  children: PropTypes.any,
};

export function UIReportingProvider({ children }) {
  const toastRef = useRef(null);

  const dispatch = useDispatch();
  const lastError = useSelector(accessLastError());
  const lastErrorTimestamp = useSelector(accessLastErrorTimestamp());

  const showError = useCallback(
    (error, timestamp) => {
      error &&
        toastRef.current?.show?.({
          severity: 'error',
          summary: 'Error Message',
          sticky: false,
          value: error,
          timestamp,
          life: 10000,
          content: (
            <div
              {...UI_TEST_ASSIST.EL_ERROR_BALLOON}
              data-value={error}
              className="flex flex-col"
              style={{ flex: '1' }}
            >
              {error}
            </div>
          ),
        });
    },
    [toastRef]
  );

  const showSuccess = useCallback(
    (message, head, ico) => {
      message &&
        toastRef.current?.show?.({
          severity: 'success',
          summary: 'Success Message',
          value: message,
          sticky: false,
          timestamp: getTimestamp(),
          life: 1000000,
          className: pvs.success_toast,
          content: (
            <div className="flex flex-1 items-start" {...UI_TEST_ASSIST.EL_SUCCESS_BALLOON}>
              {ico ? <div className="mr-2 mt-1">{ico}</div> : null}
              <div data-value={message} className={`flex flex-1 flex-col`}>
                {head ? <span className={`text-2xl font-bold ${pvs.success_head}`}>{head}</span> : null}
                <span className={pvs.success_message}>{message}</span>
              </div>
            </div>
          ),
        });
    },
    [toastRef]
  );

  useEffect(() => {
    if (!lastError) {
      return;
    }
    showError(lastError, lastErrorTimestamp);
  }, [dispatch, lastError, lastErrorTimestamp, showError]);

  /**
   * @type {UIReportingContext}
   */
  const value = useMemo(() => {
    return {
      toastRef,
      showError,
      showSuccess,
    };
  }, [showError, showSuccess]);

  return <ToastContext.Provider value={value}>{children}</ToastContext.Provider>;
}

UIReportingProviderForTests.propTypes = {
  value: PropTypes.object,
  children: PropTypes.any,
};
export function UIReportingProviderForTests({ value, children }) {
  return <ToastContext.Provider value={value}>{children}</ToastContext.Provider>;
}

export const UIReporting = () => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error(`Invalid usage. '<UIReporting />' needs to be descending from <UIReportingProvider />`);
  }
  const { toastRef } = context;

  const dispatch = useDispatch();

  const onRemove = useCallback(
    message => {
      if (!message.timestamp) {
        return;
      }
      dispatch(setLastReportedErrorHandled(message.timestamp));
    },
    [dispatch]
  );

  return <Toast onRemove={onRemove} ref={toastRef} {...UI_TEST_ASSIST.CMP_INFO_BALLOON_CONTAINER} />;
};

export const useShowError = () => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error(
      `Invalid usage. 'useShowError()' needs to be called in elements descending from <UIReportingProvider />`
    );
  }
  const { showError } = context;
  return showError;
};

export const useShowSuccess = () => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error(
      `Invalid usage. 'useShowSuccess()' needs to be called in elements descending from <UIReportingProvider />`
    );
  }
  const { showSuccess } = context;
  return showSuccess;
};
