import { BALOONS_PATH } from '@/constants';
import { userRestoreAccountAsyncThunk } from '@model/features/user/asyncActions';
import { parseFutureTimeOffset } from '@services/time';
import { useLocaleServices } from '@ui/contextProviders/Locale';
import { PictureResource } from '@ui/elements/Picture/PictureResource';
import { linkRenderer } from '@ui/elements/markdown/renderers/link';
import { useAppDispatch } from '@ui/hooks/redux';
import { routeNames } from '@ui/routes/routeNames';
import { isFailureResponse } from '@ui/support/utils/guards';
import { FC, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { Navigate, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { RestoreButton } from './RestoreButton';

const img = Math.random() > 0.5 ? 'miss_you_space' : 'miss_you_owl';

const mdComponents = {
  a: linkRenderer,
};

const ClosedAccBalloonContent: FC = () => {
  const { t } = useLocaleServices();
  const [params] = useSearchParams();
  const { state } = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [error, setError] = useState<string | null>(null);

  const code = state?.code ?? params.get('code');
  const timer = state?.valid_until ?? params.get('valid_until');

  const hasCode = code && code.length > 0 && timer != null;
  const futureTimestamp = parseFutureTimeOffset(timer, 0);
  const diffInSeconds = Math.floor(futureTimestamp / 1000);

  const handleRestore = async () => {
    if (!code) return;

    const res = await dispatch(userRestoreAccountAsyncThunk(code)).unwrap();

    if (isFailureResponse(res.response)) {
      setError(res.response.errors[0].message);
      return;
    }
    navigate(routeNames.loginEmailIndex, { replace: true });
  };

  if (hasCode && 0 === diffInSeconds) {
    return <Navigate to={routeNames.loginEmailIndex} replace />;
  }

  return (
    <div className="flex flex-col pb-10">
      <PictureResource name={hasCode ? img : 'miss_you_monkey'} path={BALOONS_PATH} alt="balloon image" includeX2 />
      {hasCode && (
        <>
          <div className="-mt-4 text-center font-bitter text-body-l font-bold dark:text-white">
            <p>{t('remove_account.close')}</p>
          </div>
          <ReactMarkdown components={mdComponents} className="mt-6 text-center font-proxi text-dimmed-d">
            {t('remove_account.info')}
          </ReactMarkdown>

          <div className="mt-6 text-center">
            <RestoreButton seconds={diffInSeconds} label={t('remove_account.restore')} onClick={handleRestore} />
            {error && <small className="mt-2 block text-accent-a">{t(error)}</small>}
          </div>
        </>
      )}
    </div>
  );
};

export default ClosedAccBalloonContent;
