import PropTypes from 'prop-types';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

const recaptchaKey = process.env.REACT_APP_GOOGLE_RECAPTCHA_V3_PUBLIC_KEY;
const browserLanguage = navigator.language || navigator.userLanguage;
const nonce = window.nonce;

RecaptchaWrapper.propTypes = {
  children: PropTypes.any,
};
export function RecaptchaWrapper({ children }) {
  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={recaptchaKey}
      language={browserLanguage}
      // useRecaptchaNet="[optional_boolean_value]"
      // useEnterprise="[optional_boolean_value]"
      scriptProps={{
        async: false, // optional, default to false,
        defer: false, // optional, default to false
        appendTo: 'head', // optional, default to "head", can be "head" or "body",
        // TODO work only in dev mode
        nonce, // optional, default undefined
      }}
      container={{
        // optional to render inside custom element
        element: 'rc-root',
        parameters: {
          badge: 'none', // optional, default undefined
          theme: 'dark', // optional, default undefined
        },
      }}
    >
      {children}
    </GoogleReCaptchaProvider>
  );
}
