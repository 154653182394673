import { Status } from '@/api/support/types';

export const statusMapper: Record<Status, string> = {
  // TODO: Add translations
  in_progress: 'In Progress',
  open: 'Open',
  resolved: 'Resolved',
  new: 'New',
  closed: 'Closed',
};

export const HashEnum = {
  NEW: 'new',
  TICKET: 'ticket',
};
