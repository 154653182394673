import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

const REF_CODE_KEY = 'rc';

export function useRefCode() {
  const [searchParams] = useSearchParams();
  const referralCode = searchParams.get('r') || searchParams.get('promo');
  const [refCode, setRefCode] = useState<string | null | undefined>(
    referralCode || sessionStorage.getItem(REF_CODE_KEY)
  );

  useEffect(() => {
    if (null == refCode) {
      sessionStorage.removeItem(REF_CODE_KEY);
    } else {
      sessionStorage.setItem(REF_CODE_KEY, refCode);
    }
  }, [refCode]);

  return [refCode, setRefCode];
}

export function clearRefCode() {
  sessionStorage.removeItem(REF_CODE_KEY);
}

export function saveRefCodeIfPresent() {
  if (!window.location.search) {
    return;
  }
  const params = new URLSearchParams(window.location.search);
  const refCode = params.get('r') || params.get('promo');
  if (!refCode) {
    return;
  }
  sessionStorage.setItem(REF_CODE_KEY, refCode);
}
