const publishedEndpoints = {
  BACKEND_HEALTH: 'GET health',
  USERS_REGISTRATION_POST: 'POST users/registrations',
  USERS_SIGNUP_EMAIL: 'POST signups/emails',
  USERS_SIGNUP_EMAIL_ACTIVATE: 'POST users/current/emails/activate',
  USERS_DELETE_CURRENT: 'DELETE users/current',
  USERS_POST_PASSWORD: 'POST users/current/password',
  USERS_RESTORE: 'POST users/current/restore',
  USERS_ACTIVATIONS_POST: 'users-activations-post',
  USERS_EMAILS_SEND_ACTIVATION: 'POST users/current/emails',
  USERS_POST_ACCEPTANCE: 'POST acceptance',
  USERS_POST_EXPORT_GDPR_DATA: 'users-post-export-gdpr-data',
  USERS_PUSH_SUBSCRIPTIONS: 'POST users/current/push_subscriptions',

  USERS_POST_PHONES: `POST users/current/phones`,
  USERS_POST_PHONES_ACTIVATE: `POST users/current/phones/:id/activate`,

  REGISTRATION_SAVE_STEP: 'PUT steps/:stepId',
  REGISTRATION_GET_STEP_SCHEMA: 'registration-get-schema-for-step',
  REGISTRATION_GET_CURRENT_STEP: 'registration-get-current-step',

  PROFILING_GET_CURRENT_STEP: 'GET profiling/steps',
  PROFILING_GET_STEP_SCHEMA: 'GET profiling/steps/:id',
  PROFILING_PUT_STEP: 'PUT profiling/steps/:id',

  AUTHORIZATION_POST_MAGIC_SEND: 'POST authorization/magic/send',
  AUTHORIZATION_POST_MAGIC: 'POST authorization/magic',

  LOCALES_GET: 'locales-get',
  LOCALES_ALL_GET: 'locales-all-get',
  COUNTRY_STATES_LIST_GET: 'states-get',
  COUNTRIES_LIST_GET: 'GET geo/countries',
  ZIP_VALIDATION_GET: 'GET geo/countries/:country_code/zip_counties/:zip_code',
  GEO_VALIDATION_GET: 'GET geo/validate',
  FRIENDS_GET_SETTINGS: 'friends-get-settings',
  FRIENDS_GET_LIST: 'friends-get-list',
  FRIENDS_REFERRAL_POST: 'friends-referral-post',
  FRIENDS_INVITATION_POST: 'friends-invitation-post',
  FRIENDS_INVITATION_DELETE: 'DELETE friends/invitations/:id',
  FRIENDS_GET: 'friends-get',
  DEBUG: 'debug',
  TOKENS_REFRESH: 'POST authorization/tokens/refresh',
  TOKENS_DELETE: 'tokens-delete',
  TOKENS_POST: 'POST authorization/tokens',
  USERS_RESET_PASSWORD_POST: 'POST users/reset_password',
  USERS_RESET_PASSWORD_CONFIRM_PUT: 'users-reset-password-confirm-new-put',
  SETTINGS_GET: 'settings-get',
  SETTINGS_AVATAR_PUT: 'settings-put',
  SETTINGS_PATCH: 'PATCH settings',
  DYNAMIC_URL: 'dynamic-url',
  DYNAMIC_GET_URL_AUTH: 'get-dynamic-url-auth',
  DYNAMIC_DELETE_URL_AUTH: 'delete-dynamic-url-auth',
  DYNAMIC_POST_URL_AUTH: 'post-dynamic-url-auth',
  DYNAMIC_TOOLS_URL_AUTH: 'dynamic-tools-url-auth',
  SURVEY_GET_QUESTIONS: 'GET survey_sessions/:id',
  SURVEY_POST_ACQUIRE_SESSION: 'POST surveys/:id/session',
  SURVEYS_GET_AVAILABLE: 'GET surveys',

  SUPPORT_GET_TICKETS_LIST: 'support-get-tickets-list',
  SUPPORT_POST_TICKET: 'support-post-ticket',
  SUPPORT_GET_TICKET: 'support-get-ticket',
  SUPPORT_RESOLVE_TICKET: 'support-resolve-ticket',
  SUPPORT_POST_COMMENT: 'support-post-comment',

  USER_PHONES_SEND_VERIFICATION_CODE: 'POST users/current/phones/:id/send_verification_code',
  USER_PHONES_VERIFY: 'POST users/current/phones/:id/verify',

  EARNINGS_GET_CASH_OUT: 'GET earnings/cashout',
  EARNINGS_GET_TRANSACTIONS: 'earnings-get-transactions',
  EARNINGS_POST_CASH_OUT: 'earnings-post-cash-out',

  DEMOGRAPHICS_GET: 'demographics-get',
  DEMOGRAPHICS_PUT: 'demographics-put',

  UNSUBSCRIBE_POST: 'unsubscribe-post',
  RESUBSCRIBE_POST: 'resubscribe-post',

  DEBUG_API: 'debug-api',
} as const;

export const {
  BACKEND_HEALTH,
  USERS_REGISTRATION_POST,
  USERS_SIGNUP_EMAIL,
  USERS_SIGNUP_EMAIL_ACTIVATE,
  USERS_DELETE_CURRENT,
  USERS_RESTORE,
  USERS_POST_PASSWORD,
  USERS_ACTIVATIONS_POST,
  USERS_EMAILS_SEND_ACTIVATION,
  USERS_POST_ACCEPTANCE,
  USERS_POST_EXPORT_GDPR_DATA,
  USERS_PUSH_SUBSCRIPTIONS,
  USERS_POST_PHONES,
  USERS_POST_PHONES_ACTIVATE,

  PROFILING_GET_CURRENT_STEP,
  PROFILING_GET_STEP_SCHEMA,
  PROFILING_PUT_STEP,

  AUTHORIZATION_POST_MAGIC,
  AUTHORIZATION_POST_MAGIC_SEND,
  LOCALES_GET,
  LOCALES_ALL_GET,
  COUNTRY_STATES_LIST_GET,
  COUNTRIES_LIST_GET,
  ZIP_VALIDATION_GET,
  GEO_VALIDATION_GET,
  FRIENDS_GET_SETTINGS,
  FRIENDS_GET_LIST,
  FRIENDS_REFERRAL_POST,
  FRIENDS_INVITATION_POST,
  FRIENDS_INVITATION_DELETE,
  FRIENDS_GET,
  DEBUG,
  TOKENS_REFRESH,
  TOKENS_DELETE,
  TOKENS_POST,
  USERS_RESET_PASSWORD_POST,
  USERS_RESET_PASSWORD_CONFIRM_PUT,
  SETTINGS_GET,
  SETTINGS_AVATAR_PUT,
  SETTINGS_PATCH,
  DYNAMIC_URL,
  DYNAMIC_GET_URL_AUTH,
  DYNAMIC_DELETE_URL_AUTH,
  DYNAMIC_POST_URL_AUTH,
  DYNAMIC_TOOLS_URL_AUTH,
  SURVEY_GET_QUESTIONS,
  SURVEY_POST_ACQUIRE_SESSION,
  SUPPORT_GET_TICKETS_LIST,
  SUPPORT_POST_TICKET,
  SUPPORT_GET_TICKET,
  SUPPORT_RESOLVE_TICKET,
  SUPPORT_POST_COMMENT,
  SURVEYS_GET_AVAILABLE,
  USER_PHONES_SEND_VERIFICATION_CODE,
  USER_PHONES_VERIFY,
  EARNINGS_GET_CASH_OUT,
  EARNINGS_GET_TRANSACTIONS,
  EARNINGS_POST_CASH_OUT,
  DEMOGRAPHICS_GET,
  DEMOGRAPHICS_PUT,
  UNSUBSCRIBE_POST,
  RESUBSCRIBE_POST,
  DEBUG_API,
} = publishedEndpoints;
