import { ERROR_PAGE } from '@ui/routes/routeNames';
import { useParams } from 'react-router';
import { ErrorPage404 } from './ErrorPage404';
import { ErrorPageSquirrel } from './ErrorPageSquirrel';
import { NetworkErrorPage } from './NetworkErrorPage';

export function ErrorPage() {
  const { errorCode } = useParams();
  const code = errorCode ?? '404';

  switch (code) {
    case ERROR_PAGE.NETWORK: {
      return <NetworkErrorPage />;
    }
    case ERROR_PAGE.MAGIC_BAD:
    case ERROR_PAGE.MAGIC_NO: {
      return <ErrorPageSquirrel />;
    }
    case '404':
    default: {
      return <ErrorPage404 />;
    }
  }
}

export default ErrorPage;
