export function clsx(...args: (string | null | undefined | false)[]) {
  return args.filter(Boolean).join(' ');
}

export function concatClsx(class1: string, class2?: string | null | false) {
  if (class2) {
    return class1.concat(' ', class2);
  }
  return class1;
}
