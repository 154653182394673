import { useLocaleServices } from '@ui/contextProviders/Locale';
import { Button } from '@ui/lib/Button';
import { routeNames } from '@ui/routes/routeNames';
import { memo } from 'react';
import { useLocation, useNavigate } from 'react-router';

type BackButtonProps = {
  className?: string;
};

function BackButtonComp({ className }: BackButtonProps) {
  const { t } = useLocaleServices();
  const { state } = useLocation();
  const navigate = useNavigate();

  const handleBack = () => {
    state?.backToDashboard ? navigate(routeNames.dashboard) : navigate(-1);
  };

  return (
    <Button
      className={className}
      textButton
      icon="pi pv-arrow-back text-accent-a"
      onClick={handleBack}
      data-role="go-back"
      type="button"
    >
      <span className="ml-1 font-bitter text-body uppercase leading-none text-dark-i dark:text-light-b">
        {t('labels.back')}
      </span>
    </Button>
  );
}

export const BackButton = memo(BackButtonComp);
