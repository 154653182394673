import { GTags } from '@/constants/google_tags';
import { gTagSender } from '@services/analytics/gTagSender';
import { Checkbox } from 'primereact/checkbox';
import PropTypes from 'prop-types';
import { Controller, useFormContext } from 'react-hook-form';

ControlledCheckbox.propTypes = {
  name: PropTypes.string,
  value: PropTypes.bool,
  options: PropTypes.object,
};

export function ControlledCheckbox(props) {
  const { name, value, options } = props;
  const { submitOnChange, onChangeCallback } = options;
  const { control, forceSubmit, formId } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={value}
      render={({ field, fieldState }) => (
        <Checkbox
          id={field.name}
          name={name}
          form={formId}
          checked={Boolean(field.value)}
          onChange={event => {
            field.onChange(event.checked);
            if (onChangeCallback && 'function' === typeof onChangeCallback) {
              onChangeCallback(event.checked);
            }
            if (event.checked && 'read_policy' === name) {
              gTagSender(GTags.privacy);
            }
            submitOnChange && forceSubmit?.();
          }}
          className={fieldState.error ? 'p-invalid' : ''}
        />
      )}
    />
  );
}
