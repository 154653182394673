export class EndpointError extends Error {
  [key: string]: any;
  response?: ApiErrorResponseType;

  constructor(message: string, data: object | undefined | null) {
    super(message);
    this.name = 'EndpointError';
    return Object.assign(this, { _name: 'EndpointError' }, data || {});
  }

  toString() {
    return this.message;
  }

  [Symbol.toStringTag]() {
    return 'EndpointError';
  }
  static isEndpointError(input: EndpointError | unknown): input is EndpointError {
    return input instanceof EndpointError || 'EndpointError' === (input as EndpointError)?.name;
  }
}

export const CLIENT_ERRORS = {
  UNDEFINED_ENDPOINT: 'UNDEFINED_ENDPOINT',
  INVALID_MISSING_PARTS: 'INVALID_MISSING_PARTS',
  INVALID_MISSING_PARAMS: 'INVALID_MISSING_PARAMS',
  INVALID_MISSING_BODY: 'INVALID_MISSING_BODY',
  INVALID_MISSING_HANDLERS: 'INVALID_MISSING_HANDLERS',
  MISSING_FETCH_ENGINE: 'MISSING_FETCH_ENGINE',
  NETWORK_ERROR: 'NETWORK_ERROR',
  SERVER_UNEXPECTED_RESPONSE: 'SERVER_UNEXPECTED_RESPONSE',
  SERVER_UNEXPECTED_STATUS_CODE: 'SERVER_UNEXPECTED_STATUS_CODE',
} as const;
