import { useLocaleServices } from '@ui/contextProviders';
import PropTypes from 'prop-types';

SimpleFieldWrapper.propTypes = {
  children: PropTypes.any,
  label: PropTypes.string,
};
export function SimpleFieldWrapper({ children, label }) {
  const { t } = useLocaleServices();

  if (null == children) {
    return null;
  }

  return (
    <div className="flex w-full flex-col">
      <div className="label m-0 ml-4">{t(label)}</div>
      <div className="field m-0">{children}</div>
    </div>
  );
}
