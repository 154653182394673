import { SocialButtons } from '@ui/components';
import { EmailSignIn, PasswordSignIn } from '@ui/components/Login/elements';
import { useLocaleServices } from '@ui/contextProviders';
import { RouterLink } from '@ui/lib/Link/AppLink';
import { routeNames } from '@ui/routes/routeNames';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';

Login.propTypes = {
  section: PropTypes.string,
  rememberMe: PropTypes.bool,
};

export default function Login({ section, rememberMe }) {
  const { t } = useLocaleServices();
  const { state } = useLocation();
  const isEmailSignIn = 'login-email' === section;

  const [collapsed, setCollapsed] = useState(false);

  const handleFieldFocus = event => {
    if ('INPUT' === event.target.tagName && event.target.type !== 'checkbox' && !collapsed) {
      setCollapsed(true);
    }
  };

  const reauth = state?.reauth || false;

  const path = isEmailSignIn ? routeNames.loginPassword : routeNames.loginEmailIndex;

  return (
    <div data-testid={isEmailSignIn ? 'email' : 'password'} className="auth-form">
      <div className="title auth-form__title text-[2.75rem] md:text-[4.7rem]">
        {t(reauth ? 'sign_in.title_reauth' : 'sign_in.title')}
      </div>
      <div className="auth-form__subtitle">
        {/* {t(reauth ? 'sign_in.subtitle_reauth' : 'sign_in.subtitle')} */}
        {t(reauth ? 'sign_in.subtitle_reauth' : 'sign_up.temporary_message')}
      </div>
      <div className="p-fluid auth-form__content">
        <SocialButtons role="sign_in" behavior={collapsed ? 'compact' : 'dynamic'} rememberMe={rememberMe} />
        <div className="auth-layout__or-divider my-5">{t('login.or')}</div>
        {isEmailSignIn ? (
          <EmailSignIn onFieldFocus={handleFieldFocus} />
        ) : (
          <PasswordSignIn onFieldFocus={handleFieldFocus} />
        )}
      </div>
      <RouterLink data-role="sign-in-toggle" className="mt-6" to={path} state={state} variant="accent-b">
        {isEmailSignIn ? t('sign_in.with_password_toggle') : t('sign_in.with_email_toggle')}
      </RouterLink>
      <RouterLink data-role="sign-up" className="mt-14" variant="accent-b" to={routeNames.signUpIndex}>
        {t('sign_in.signup_instead')}
      </RouterLink>
    </div>
  );
}
