import { AppDispatch } from '@/store';
import { EndpointError } from '@model/api/endpointError';
import { AsyncThunkPayloadCreator, createAsyncThunk } from '@reduxjs/toolkit';
import { UnknownAsyncThunkRejectedWithValueAction } from '@reduxjs/toolkit/dist/matchers';
import { AsyncThunk, AsyncThunkOptions } from '@reduxjs/toolkit/src/createAsyncThunk';
import { STORAGE_KEY_LIST } from '@services/storage';
import { isFailureResponse } from '@ui/support/utils/guards';
import { safelyAwait } from '../promises';
import { pluggableConditionChecker } from './pluggableConditionChecker';

export const serializeErrorOption = {
  serializeError: err => ('string' === typeof err ? err : { ...err }),
};

type AsyncThunkParams = Parameters<typeof createAsyncThunk>[1];
type AsyncThunkConfig = Parameters<AsyncThunkParams>[1];

export const createAutoUnwrappingAsyncThunkWithTokenRefresh = <Returned, ThunkArg>(
  typePrefix: string,
  payloadCreator: AsyncThunkPayloadCreator<Returned, ThunkArg>,
  options?: AsyncThunkOptions<ThunkArg, AsyncThunkConfig>
): AsyncThunk<Returned, ThunkArg, AsyncThunkConfig> =>
  createAsyncThunk<Returned, ThunkArg, AsyncThunkConfig>(typePrefix, autoUnwrapResponse(payloadCreator, true), options);

export const createAsyncThunkWithTokenRefresh = <Returned, ThunkArg>(
  typePrefix: string,
  payloadCreator: AsyncThunkPayloadCreator<Returned, ThunkArg>,
  options?: AsyncThunkOptions<ThunkArg, AsyncThunkConfig>
): AsyncThunk<Returned, ThunkArg, AsyncThunkConfig> =>
  createAsyncThunk<Returned, ThunkArg, AsyncThunkConfig>(
    typePrefix,
    autoUnwrapResponse(payloadCreator, false),
    options
  );

export function autoUnwrapResponse<T, S>(
  fn: AsyncThunkPayloadCreator<T, S>,
  reject: boolean
): AsyncThunkPayloadCreator<T, S> {
  return async (arg, thunkAPI) => {
    const { rejectWithValue, dispatch } = thunkAPI;
    let retries = 0;
    const rToken = localStorage.getItem(STORAGE_KEY_LIST.AUTH_REF_TOKEN);

    for (;;) {
      const [err, result] = await safelyAwait(fn(arg, thunkAPI));
      const error = err || extractErrorFromRejectedWithValue(result);

      if (null == error && result != null) {
        return result as T;
      }

      if (await pluggableConditionChecker(error, retries, rToken, dispatch as AppDispatch)) {
        retries++;
        continue;
      }

      if (
        EndpointError.isEndpointError(error) &&
        error.response != null &&
        isFailureResponse(error.response) &&
        'AuthorizationInsufficientScopes' === error.response.errors[0].error
      ) {
        // Hope this do redirect to login page
        return rejectWithValue({ ...error, redirectToReAuth: true });
      }

      if (error && reject) {
        return rejectWithValue({ ...error });
      } else if (error) {
        throw error;
      }
    }
  };
}

export const unwrapResponseDataFromResolvedAction = (action, fallback = null) =>
  action.payload?.response?.data ?? fallback;

function isResultRejectedWithValue(
  result: UnknownAsyncThunkRejectedWithValueAction | unknown
): result is UnknownAsyncThunkRejectedWithValueAction {
  return (
    'meta' in (result as UnknownAsyncThunkRejectedWithValueAction) &&
    'payload' in (result as UnknownAsyncThunkRejectedWithValueAction) &&
    (result as UnknownAsyncThunkRejectedWithValueAction).payload instanceof Error
  );
}

function extractErrorFromRejectedWithValue<T extends Error>(
  result: UnknownAsyncThunkRejectedWithValueAction | unknown
): T | null {
  return isResultRejectedWithValue(result) ? ((result.payload || result.error) as T) : null;
}
