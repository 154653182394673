import { InfoBalloon } from '@ui/components/InfoBalloon';
import { BALLOON } from '@ui/components/InfoBalloon/resources';
import { useLocaleServices } from '@ui/contextProviders';
import { paths } from '@ui/navigation';
import { SupportButton } from '@ui/support';
import ReactMarkdown from 'react-markdown';
import { NavLink } from 'react-router-dom';

const components = {
  strong: ({ children }) => <s>{children}</s>,
  em: (_: unknown) => <br />,
  p: ({ children }) => <span>{children}</span>,
};

export function ErrorPage404() {
  const { t } = useLocaleServices();

  return (
    <div data-testid="error-page" className="mb-12">
      <div className="flex w-full flex-col items-center px-2">
        <div className="mx-auto max-w-4xl">
          <InfoBalloon className="w-full" name={BALLOON[404]} title={t('errors.not_found')} />
        </div>
        <div className="mx-auto mb-14 text-center font-bitter text-body-l font-bold text-dark-a dark:text-light-d">
          <ReactMarkdown components={components}>{t('errors.404_description')}</ReactMarkdown>
          &nbsp;
          <SupportButton link="/dashboard#support" className="lowercase" />.
        </div>
        <NavLink to={paths.toHome} className="p-button p-button-accent-b py-3 text-body">
          {t('errors.button_home')}
        </NavLink>
      </div>
    </div>
  );
}
