import { IconName } from '@ui/lib/IconSprite';

type Input = Window['tenant']['links'] | null | undefined;

export type FiiterSocialLink = {
  href: string;
  ariaLabel: string;
  icon: IconName;
};

export function getSocialLinks(links: Input): FiiterSocialLink[] | null {
  if (!links) return null;

  const result: FiiterSocialLink[] = [
    links.instagram && {
      href: links.instagram.url,
      icon: 'ig-icon',
      ariaLabel: links.instagram.label,
    },
    links.twitter && {
      href: links.twitter.url,
      icon: 'x-twitter',
      ariaLabel: links.twitter.label,
    },
    links.facebook && {
      href: links.facebook.url,
      icon: 'facebook_icon',
      ariaLabel: links.facebook.label,
    },
    links.youtube && {
      href: links.youtube.url,
      icon: 'youtube',
      ariaLabel: links.youtube.label,
    },
  ];

  return result.filter(Boolean);
}
