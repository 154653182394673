import { ApiClient } from '@/api/ApiClient';
import { createAsync } from '@/api/helpers/redux';
import { UserResponse } from '@/api/user/types';
import {
  apiAcceptPrivacyTerms,
  apiDeleteUserAccount,
  apiPostDynamicUrlWithAuth,
  apiPostExportGDPRData,
  apiResetPasswordForEmail,
  apiSendPhoneVerificationCode,
  apiUpdateEmailAndSendActivation,
  apiUserActivation,
  apiUserRestore,
  apiVerifyPhone,
} from '@model/api/apiCommunication';
import {
  createAsyncThunkWithTokenRefresh,
  createAutoUnwrappingAsyncThunkWithTokenRefresh,
  serializeErrorOption,
} from '@services/reduxToolkit';

const name = 'user';

export const userDeleteAccountAsyncThunk = createAsyncThunkWithTokenRefresh(
  `api/${name}/delete/current`,
  apiDeleteUserAccount,
  serializeErrorOption
);

export const userRestoreAccountAsyncThunk = createAsyncThunkWithTokenRefresh(
  `api/${name}/restore/current`,
  apiUserRestore,
  serializeErrorOption
);

export const userActivateAsyncThunk = createAutoUnwrappingAsyncThunkWithTokenRefresh(
  `api/${name}/activate`,
  apiUserActivation
);

/**
 * POST users/current/emails
 */
export const apiUpdateEmailAndSendActivationAsyncThunk = createAsyncThunkWithTokenRefresh(
  `api/${name}/updateEmail`,
  apiUpdateEmailAndSendActivation,
  serializeErrorOption
);

export const userResetPasswordAsyncThunk = createAsyncThunkWithTokenRefresh(
  `api/${name}/reset_password`,
  apiResetPasswordForEmail,
  serializeErrorOption
);

export const userExportGDPRDataAsyncThunk = createAsyncThunkWithTokenRefresh(
  `api/${name}/export/gdpr`,
  apiPostExportGDPRData,
  serializeErrorOption
);

export const postAcceptanceResponseAsyncThunk = createAsyncThunkWithTokenRefresh(
  `api/${name}/acceptance/response`,
  apiPostDynamicUrlWithAuth,
  serializeErrorOption
);
export const postAcceptanceActionsResponseAsyncThunk = createAsyncThunkWithTokenRefresh(
  `api/${name}/acceptance/action/response`,
  apiPostDynamicUrlWithAuth,
  serializeErrorOption
);

export const postAcceptPrivacyTermsAsyncThunk = createAsyncThunkWithTokenRefresh(
  `api/${name}/acceptance/terms`,
  apiAcceptPrivacyTerms,
  serializeErrorOption
);

export const sendPhoneVerificationCodeAsyncThunk = createAsyncThunkWithTokenRefresh(
  `api/${name}/phone/verification/request`,
  async (data: { id: string; channel: 'sms' | 'call' }) => apiSendPhoneVerificationCode(data).then(res => res.response),
  serializeErrorOption
);

export const sendVerificationCodeAsyncThunk = createAsyncThunkWithTokenRefresh(
  `api/${name}/phone/verification/send`,
  async (data: { id: string; code: string }) => apiVerifyPhone(data).then(res => res.response),
  serializeErrorOption
);

// ==================== NEW API ====================

export const userInfoAsyncThunk = createAsync<UserResponse, { noAutoRedirects?: boolean } | void>(
  'api/users/current',
  ApiClient.user.current
);
