function draw(video: HTMLVideoElement): HTMLCanvasElement {
  const canvas = document.createElement('canvas');
  video.width = canvas.width = video.videoWidth;
  video.height = canvas.height = video.videoHeight;

  canvas.getContext('2d')?.drawImage(video, 0, 0);

  if (video.srcObject != null) {
    const stream = video.srcObject as MediaStream;
    stream.getTracks().forEach(track => track.stop());
    video.srcObject = null;
  }

  return canvas;
}

export async function toCanvas(): Promise<HTMLCanvasElement | null> {
  if (navigator.mediaDevices) {
    const stream = await navigator.mediaDevices.getDisplayMedia({
      video: {},
      preferCurrentTab: true,
    } as DisplayMediaStreamOptions);
    const video = document.createElement('video');
    video.srcObject = stream;
    video.play();

    return new Promise(resolve => {
      video.addEventListener(
        'canplay',
        _ => {
          const canvas = draw(video);
          resolve(canvas);
        },
        { once: true }
      );
    });
  } else {
    alert('Your browser does not support screen capturing');
    return Promise.resolve(null);
  }
}

export async function toBlob(type?: string, quality?: any): Promise<Blob | null> {
  const canvas = await toCanvas();
  return canvas ? new Promise(resolve => canvas.toBlob(resolve, type, quality)) : null;
}
