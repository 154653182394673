const headers = {
  home: {
    lang: 'en-US',
    title: 'Get Paid For Your Opinion - PaidViewpoint',
    description:
      'Find out how to get paid for your opinion by taking surveys at PaidViewpoint. With our system, take quick surveys and get paid in cash!',
    canonicalHref: '/',
    path: '/',
  },
  about: {
    title: 'About',
    description: '',
    path: '/pages/about',
  },
  tos: {
    title: 'Terms Of Service',
    description: '',
    path: '/pages/tos',
  },
  privacy: {
    title: 'Privacy',
    description: '',
    path: '/pages/privacy',
  },
  faq: {
    title: '',
    description: '',
    path: '/pages/faq',
  },
  not_sell_info: {
    title: '',
    description: '',
    path: '/pages/not_sell_info',
  },
  rules: {
    title: '',
    description: '',
    path: '/pages/rules',
  },
  support: {
    title: '',
    description: '',
    path: '/pages/support',
  },
};

const headersMap = Object.fromEntries(
  Array.from(Object.entries(headers), ([key, { path, ...v }]) => [path, { ...v, key }])
);

export const getHeaderDataFor = path => headersMap[path] ?? headers[path] ?? headers['home'];
