export const UserStatus = {
  ALIVE: 'alive',
  REGISTRATION_PROCESS: 'registration_phase', // countries
  EMAIL_ACTIVATION: 'email_activation', // email activation
  EMAIL_SETUP: 'email_setup', // email setup
  UNSUPPORTED_COUNTRY: 'unsupported_country', // thanks, keep waiting
  FORBIDDEN_COUNTRY: 'forbidden_country', // china wall
  BANNED: 'banned', // banned
  MINOR_PROBLEM: 'minor_problem',
  TERMS_CONSENT_PHASE: 'terms_consent_phase',
} as const;

export type UserStatusType = (typeof UserStatus)[keyof typeof UserStatus];

export function isUserStatus(status: string | null | undefined): status is UserStatusType {
  if (null == status) {
    return false;
  }
  return Object.values(UserStatus).includes(status as UserStatusType);
}
