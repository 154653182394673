import { useAuth } from '@ui/contextProviders';
import { getAPIUrl, getPvContext } from '@services/context';

const isProduction = 'production' === getPvContext('backend_env', 'production');

export const usePrefetchActivationForDev = () => {
  const baseUrl = getAPIUrl();

  const { token } = useAuth();

  const fetchDataWrapperCreator = onDataCallback => async event => {
    if (event) {
      event.preventDefault();
    }

    const requestOptions = {
      headers: {
        accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await fetch(`${baseUrl}debug`, requestOptions);
    if (!response.ok) {
      return;
    }
    const json = await response.json();

    onDataCallback(json.data.user.data.debug);
  };

  return isProduction ? null : fetchDataWrapperCreator;
};
