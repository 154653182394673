import { useCallback, useEffect, useRef } from 'react';

export type DropCallback = (files: FileList) => void;

// Droppable elements bin
const refsBin: HTMLElement[] = [];
// Drop handlers bin
const dropHandlersBin: DropCallback[] = [];

function getEl() {
  return refsBin[refsBin.length - 1];
}

function getHandler() {
  return dropHandlersBin[dropHandlersBin.length - 1];
}

export function useWindowFileDrop<T extends HTMLElement>(onDrop: DropCallback, showClass?: string, hideClass?: string) {
  const dropZoneRef = useRef<T | null>(null);
  const handleDropRef = useRef<DropCallback>(onDrop);

  // Show last subscribed droppable element
  const toggleVisible = useCallback(
    (visible: boolean) => {
      // Get the last element from the bin
      const el = getEl();
      if (el) {
        if (visible) {
          showClass && el?.classList.add(showClass);
          hideClass && el?.classList.remove(hideClass);
        } else {
          showClass && el?.classList.remove(showClass);
          hideClass && el?.classList.add(hideClass);
        }
      }
    },
    [showClass, hideClass]
  );

  useEffect(() => {
    let lastTarget: any = null;
    const element = dropZoneRef.current;
    const currentHandler = handleDropRef.current;

    addToBin(currentHandler, element);

    const handleDrop = (event: DragEvent) => {
      event.preventDefault();
      const files = event.dataTransfer?.files;
      const onDrop = getHandler();
      if (onDrop && files && files.length > 0) {
        onDrop(files);
      }
      toggleVisible(false);
    };

    const dragenter = (evt: DragEvent) => {
      evt.preventDefault();
      // cache the target of the dragenter event
      // because when we show the dropzone it triggers a dragleave
      // so we can compare it later in dragleave
      lastTarget = evt?.target;
      toggleVisible(true);
    };

    const dragleave = (evt: DragEvent) => {
      evt.preventDefault();
      // this is the magic part.
      // we check if the target of the dragleave event
      // is the same as the target of the dragenter event
      // `e.target === document` is a workaround for Firefox 57
      if (evt.target === lastTarget || evt.target === document) {
        toggleVisible(false);
      }
    };

    const prevent = (event: DragEvent) => {
      event.preventDefault();
    };

    window.addEventListener('drop', handleDrop);
    window.addEventListener('dragover', prevent);
    window.addEventListener('dragenter', dragenter);
    window.addEventListener('dragleave', dragleave);
    return () => {
      window.removeEventListener('drop', handleDrop);
      window.removeEventListener('dragover', prevent);
      window.removeEventListener('dragenter', dragenter);
      window.removeEventListener('dragleave', dragleave);
      removeFromBin(currentHandler, element);
    };
  }, [toggleVisible]);

  return dropZoneRef;
}

function addToBin(handler: DropCallback, el?: HTMLElement | null) {
  dropHandlersBin.push(handler);
  if (el) {
    refsBin.push(el);
  }
}

function removeFromBin(handler: DropCallback, el?: HTMLElement | null) {
  const index = dropHandlersBin.indexOf(handler);
  if (index > -1) {
    dropHandlersBin.splice(index, 1);
  }
  if (el) {
    const index = refsBin.indexOf(el);
    if (index > -1) {
      refsBin.splice(index, 1);
    }
  }
}
