import { ApiClient } from '@/api/ApiClient';
import { createAsync } from '@/api/helpers/redux';
import { apiGetCashOutInformation, apiGetEarningsHistory, apiPostCashOut } from '@model/api/apiCommunication';
import { createAsyncThunkWithTokenRefresh, serializeErrorOption } from '@services/reduxToolkit';
import { isFailureResponse } from '@ui/support/utils/guards';
import { userInfoAsyncThunk } from '../user';

export const apiGetCashOutInformationAsyncThunk = createAsyncThunkWithTokenRefresh(
  'api/earnings/getCashOutInformation',
  apiGetCashOutInformation,
  serializeErrorOption
);

export const apiGetEarningsHistoryAsyncThunk = createAsyncThunkWithTokenRefresh(
  'api/earnings/getEarningsHistory',
  apiGetEarningsHistory,
  serializeErrorOption
);

export const apiCashOutAsyncThunk = createAsyncThunkWithTokenRefresh(
  'api/earnings/postCashOut',
  async (program: string, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    const res = await apiPostCashOut(program);
    if (isFailureResponse(res.response)) {
      return rejectWithValue(res.response.errors[0]);
    }
    return res.response.data;
  },
  serializeErrorOption
);

export const apiDeleteCashOutAsyncThunk = createAsync('api/earnings/deleteCashOut', async (_: void, thunkAPI) => {
  const { dispatch } = thunkAPI;
  await ApiClient.earnings.cashoutDelete();
  dispatch(apiGetCashOutInformationAsyncThunk());
  dispatch(userInfoAsyncThunk({ noAutoRedirects: true }));
});
