import { stringifyJson } from '@/utils/json';
import { apiRetrieveAvailableLocales, apiRetrieveLocaleStrings } from '@model/api/apiCommunication';
import { createSlice } from '@reduxjs/toolkit';
import { createAutoUnwrappingAsyncThunkWithTokenRefresh } from '@services/reduxToolkit';
import { accessSliceStatePropWithFallback } from '@services/slices';
import { persistTranslations } from '@ui/contextProviders/Locale/utils';

const name = 'locale';

const initialState = {
  currentLocale: null, // 'en',
  loading: null,
  resources: {},
  availableLocales: null,
  availableLocalesFailure: false,
  defaultLocale: null,
  preferredLocale: null,
};

export const loadAvailableLocalesAsyncThunk = createAutoUnwrappingAsyncThunkWithTokenRefresh(
  `api/${name}/list`,
  apiRetrieveAvailableLocales
);

export const loadLocaleStringsAsyncThunk = createAutoUnwrappingAsyncThunkWithTokenRefresh(
  `api/${name}/item`,
  apiRetrieveLocaleStrings
);

const localeSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      .addCase(loadAvailableLocalesAsyncThunk.pending, () => {})
      .addCase(loadAvailableLocalesAsyncThunk.fulfilled, (state, { payload }) => {
        state.availableLocales = Object.fromEntries(
          Array.from(payload.response.data, ({ code, ...rest }) => [code, rest])
        );
        state.defaultLocale = payload.response.data?.[0]?.code ?? 'en';
        state.availableLocalesFailure = false;
      })
      .addCase(loadAvailableLocalesAsyncThunk.rejected, state => {
        state.availableLocalesFailure = true;
      })
      .addCase(loadLocaleStringsAsyncThunk.fulfilled, (state, { payload }) => {
        if ('production' === process.env.NODE_ENV || 'true' !== process.env.REACT_APP_LOCAL_I18N) {
          state.currentResource = payload.response.data;
          persistTranslations(stringifyJson(payload.response));
        }
        state.currentLocale = state.loading;
      })
      .addCase(loadLocaleStringsAsyncThunk.pending, (state, { meta }) => {
        const { locale } = meta.arg;
        state.loading = locale;
      })
      .addCase(loadLocaleStringsAsyncThunk.rejected, (state, _action) => {
        state.loading = null;
      });
  },
});

const defaultExport = { [name]: localeSlice.reducer };

export default defaultExport;

export const accessLocaleState = accessSliceStatePropWithFallback(name);

export const accessLocaleDictionary =
  fallback =>
  ({ [name]: state }) =>
    state.dictionary || (fallback ?? null);
export const accessCurrentLocale = () => accessLocaleState('currentLocale');
export const accessDefaultLocale = () => accessLocaleState('defaultLocale');
export const accessPreferredLocale = () => accessLocaleState('preferredLocale');
export const accessLoadingLocale = () => accessLocaleState('loading');
export const accessAvailableLocales = () => accessLocaleState('availableLocales', {});
export const accessAvailableLocalesFailure = () => accessLocaleState('availableLocalesFailure');
export const accessCurrentResource = () => accessLocaleState('currentResource');

export const localizedStringForDictionary = source => (key, fallback) =>
  source ? (key in source ? source[key] : fallback ?? key) : fallback ?? key;
