import { UserStatus } from '@/api/user/user-status';
import { accessCloseAccountStage } from '@model/features/user';
import { selectUserStatus } from '@model/features/user/selectors';
import { format } from '@services/format';
import { AuthDialog } from '@ui/components/Auth/AuthDialog';
import { InfoBalloon } from '@ui/components/InfoBalloon';
import { useLocaleServices } from '@ui/contextProviders';
import { useAppSelector } from '@ui/hooks/redux';
import { Button } from '@ui/lib/Button';
import { useBalloonInterpolationData, useCloseHandler, useSignOutEffectIfNeeded } from '@ui/pages/BalloonPage/hooks';
import { routeNames } from '@ui/routes/routeNames';
import { FC, PropsWithChildren } from 'react';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import { Navigate } from 'react-router';
import { RegistrationCloseAccount } from '../../elements/CloseAccount/RegistrationCloseAccount';
import { BallonActionButton } from './BallonActionButton';
import { balloonPageData } from './balloonPage.data';
import { BalloonButtonActions, BalloonPageName } from './types';

export { BalloonPageName };

type BalloonPageProps = {
  name?: BalloonPageName;
};

export const BalloonPage: FC<PropsWithChildren<BalloonPageProps>> = ({ name: pageName, children }) => {
  const { t } = useLocaleServices();
  const pageData = useBalloonInterpolationData(pageName);
  const userStates = useAppSelector(selectUserStatus);

  useSignOutEffectIfNeeded(pageName);

  const closeHandler = useCloseHandler((pageName && balloonPageData[pageName]?.closeAction) ?? null);
  const closeAccountStage = useAppSelector(accessCloseAccountStage());

  if (UserStatus.ALIVE === userStates && pageName === BalloonPageName.UnsupportedCountry) {
    return <Navigate to={routeNames.dashboard} />;
  }

  if (!pageName) {
    return <>{children}</>;
  }

  const { balloonName, title, content, small, buttons } = balloonPageData[pageName];

  if (closeAccountStage !== 0) {
    return (
      <AuthDialog data-testid="close-account" className="w-full max-w-[900px] p-4 md:w-1/2" showCloseButton={false}>
        <RegistrationCloseAccount
          label="balloons.bs.buttons.close.label"
          variant="accent-a"
          testId="balloon-button-close-account"
          className="mt-0"
        />
      </AuthDialog>
    );
  }
  return (
    <div className="relative mx-auto mb-16 flex w-full max-w-4xl flex-col items-center px-3" data-testid="balloon-page">
      {/* TODO: change variant close button according to design */}
      {closeHandler && (
        <Button
          textButton
          variant="secondary"
          buttonType="rounded"
          type="button"
          aria-label="close account"
          onClick={closeHandler}
          icon="pi pi-times"
          className="absolute right-2 top-2 sm:right-10 sm:top-10"
          data-role="close-account"
          data-testid="balloon-cross-button-close-account"
        />
      )}
      <InfoBalloon className="w-full max-w-3xl" name={balloonName} title={t(title, pageData)} />
      {content && (
        <div className="mb-6 w-full text-center font-bitter text-body-l font-bold leading-8 dark:text-white">
          <ReactMarkdown>{t(content, pageData)}</ReactMarkdown>
        </div>
      )}
      {small && (
        <div className="mb-16 w-full text-center font-proxi text-body-s dark:text-white">
          {format(t(small, pageData), pageData)}
        </div>
      )}
      {buttons && (
        <div className="flex justify-center">
          {buttons.map(({ action, label, variant, testId }) =>
            action === BalloonButtonActions.CloseAccount ? (
              <RegistrationCloseAccount key={action} label={label} variant={variant} testId={testId} className="mt-0" />
            ) : (
              <BallonActionButton key={action} action={action} label={label} variant={variant} testId={testId} />
            )
          )}
        </div>
      )}
    </div>
  );
};
