import { accessIsAuthenticating, getIsKeepMeLoggedIn } from '@model/features/auth';
import { apiRequestMagicLinkForEmailAsyncThunk } from '@model/features/magic/asyncActions';
import { accessUserStatusChecks } from '@model/features/user';
import { apiUpdateEmailAndSendActivationAsyncThunk } from '@model/features/user/asyncActions';
import { getErrorMessage } from '@services/errors';
import { preprocessFormFields } from '@services/formHelpers';
import { safelyAwait } from '@services/promises';
import { STORAGE_KEY_LIST } from '@services/storage';
import { emailActivationLoginFieldsData, emailLoginFieldsData } from '@ui/components/Login/login.fields.data';
import { ProfilingFieldWrapper } from '@ui/components/UpdateProfileSteps/profilingFieldWrapper';
import { useLocaleServices } from '@ui/contextProviders';
import { useDidCodePromise } from '@ui/contextProviders/DidScript';
import { FieldsFormWrapper } from '@ui/elements/FormWrapper';
import { CONST_FORM_ERROR } from '@ui/elements/FormWrapper/submissionResultInterpreter';
import { FormButton } from '@ui/elements/buttons';
import { useAppDispatch, useAppSelector } from '@ui/hooks/redux';
import { routeNames } from '@ui/routes/routeNames';
import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { useNavigate } from 'react-router';

EmailSignIn.propTypes = {
  onFieldFocus: PropTypes.func,
};

function EmailSignIn({ onFieldFocus }) {
  const { t } = useLocaleServices();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const didPromise = useDidCodePromise();
  const { isUserInEmailActivation, isUserInEmailSetup } = useAppSelector(accessUserStatusChecks());
  const isAuthenticating = useAppSelector(accessIsAuthenticating());
  const isRememberMe = getIsKeepMeLoggedIn();
  const fields = preprocessFormFields(isUserInEmailActivation ? emailActivationLoginFieldsData : emailLoginFieldsData);

  const handleUpdateActivationEmail = useCallback(
    async payload => {
      const [error, result] = await safelyAwait(dispatch(apiUpdateEmailAndSendActivationAsyncThunk(payload)).unwrap());
      if (error || result?.code !== 201) {
        const errorLabel = getErrorMessage(error || result);
        return errorLabel ? { [CONST_FORM_ERROR]: errorLabel } : null;
      }
      navigate(routeNames.usersEmailActivate, {
        state: { email: payload['email'], code: result.response.data?.code_head },
      });
      return null;
    },
    [navigate, dispatch]
  );

  const handleSignInEmail = useCallback(
    async ({ email, remember_me }) => {
      const [error, result] = await safelyAwait(
        dispatch(apiRequestMagicLinkForEmailAsyncThunk({ email, did: await didPromise, remember_me })).unwrap()
      );
      const errorLabel = getErrorMessage(error || result);
      if (errorLabel) {
        return { [CONST_FORM_ERROR]: errorLabel };
      }
      navigate(routeNames.loginEmailCode, { state: { email, code: result.response.data.code_head } });
      return null;
    },
    [dispatch, didPromise, navigate]
  );

  const onSubmitHandler = useCallback(
    async (data, _evt, _formAPI) => {
      if (isAuthenticating) {
        return Promise.resolve(null);
      }
      const payload = {
        email: data['email'],
        remember_me: data['remember_me'],
      };
      localStorage.setItem(STORAGE_KEY_LIST.LOGIN_TYPE, '/login');
      return isUserInEmailActivation ? handleUpdateActivationEmail(payload) : handleSignInEmail(payload);
    },
    [handleSignInEmail, handleUpdateActivationEmail, isAuthenticating, isUserInEmailActivation]
  );

  return (
    <FieldsFormWrapper
      name="login-form"
      onSubmit={onSubmitHandler}
      fields={fields}
      formClassName="w-full pass_man-fix checkbox-small"
      fieldsWrapperComponent={ProfilingFieldWrapper}
      onFocus={onFieldFocus}
      hasPassword
      ignoreStoredValues
      defaultValues={{
        remember_me: isRememberMe,
      }}
      afterFormElement={
        <FormButton
          label={t(
            isUserInEmailActivation || isUserInEmailSetup ? 'activation.submit_button' : 'sign_in.with_email_submit'
          )}
          type="submit"
          variant="accent-a"
          className="!mt-10 w-full"
        />
      } //Sign in with email
    />
  );
}

export { EmailSignIn };
