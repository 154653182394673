import { yupResolver } from '@hookform/resolvers/yup';
import { accessFormsFormData, storeLastKnownFormData } from '@model/features/forms';
import { FIELD_TYPES } from '@services/fields';
import { getDefaultValuesForFields } from '@services/formHelpers';
import { createFormDataForJson } from '@services/forms';
import { formValidationComposer } from '@services/validation/validationComposer';
import { makeFormBuildingMapper } from '@ui/components/SmartFormComponents';
import { useLocaleServices } from '@ui/contextProviders';
import { CONST_FORM_ERROR, interpretSubmissionResults } from '@ui/elements/FormWrapper/submissionResultInterpreter';
import { classNames } from 'primereact/utils';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useId, useMemo, useRef, useState } from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

const isDevelopment = 'development' === process.env.NODE_ENV;

export function HiddenSubmitButton() {
  const { formId, hiddenSubmitBtnRef } = useFormContext();
  return <button className="hidden" type="submit" form={formId} ref={hiddenSubmitBtnRef} />;
}

FieldsFormWrapper.propTypes = {
  name: PropTypes.string.isRequired,
  onSubmit: PropTypes.func,
  onError: PropTypes.func,
  onInit: PropTypes.func,
  onChange: PropTypes.func,

  fields: PropTypes.array,
  formBuildingMapper: PropTypes.func,
  formBuildingMapperOptions: PropTypes.object,

  submitOnChange: PropTypes.bool,
  bareFields: PropTypes.bool,
  resetAtUnmount: PropTypes.bool,
  ignoreStoredValues: PropTypes.bool,

  formValidationSchema: PropTypes.object,
  knownValidationRuleObjects: PropTypes.array,
  interpretSubmissionResponse: PropTypes.bool,
  defaultValues: PropTypes.object,

  formClassName: PropTypes.string,
  fieldWrapClass: PropTypes.string,
  labelClass: PropTypes.string,
  fieldClass: PropTypes.string,
  errorClass: PropTypes.string,
  labelErrorClass: PropTypes.string,
  formWrapperComponent: PropTypes.elementType,
  formPaddingComponent: PropTypes.elementType,
  fieldsWrapperComponent: PropTypes.elementType,
  fieldsSeparatorComponent: PropTypes.elementType,

  beforeFormElement: PropTypes.element,
  afterFormElement: PropTypes.element,
  beforeFieldsElement: PropTypes.element,
  afterFieldsElement: PropTypes.element,
  betweenFieldsElement: PropTypes.element,

  formProviderProps: PropTypes.object,
  rest: PropTypes.object,
  hasPassword: PropTypes.bool,

  onFocus: PropTypes.func,
};

export function FieldsFormWrapper({
  name,
  onSubmit,
  onError,
  onInit,
  onChange,

  fields: propFields,
  /**
   * @deprecated
   */
  formBuildingMapper: _formBuildingMapper,
  formBuildingMapperOptions = { preferClean: true, absolute: true },
  submitOnChange,
  bareFields,
  resetAtUnmount,
  ignoreStoredValues,

  formValidationSchema: externalValidationSchema,
  knownValidationRuleObjects,
  interpretSubmissionResponse = true,
  defaultValues: propDefaultValues,

  formClassName,
  fieldWrapClass,
  labelClass,
  labelErrorClass,
  fieldClass,
  errorClass,
  formWrapperComponent,
  formPaddingComponent,
  fieldsWrapperComponent,
  fieldsSeparatorComponent,

  beforeFormElement,
  afterFormElement,
  beforeFieldsElement,
  afterFieldsElement,
  betweenFieldsElement,

  formProviderProps = {},

  hasPassword,
  onFocus,
  ...rest
}) {
  if (_formBuildingMapper && isDevelopment) {
    // eslint-disable-next-line no-console
    console.warn(`'FieldsFormWrapper.formBuildingMapper' property is deprecated`);
  }
  if ((!propFields || !propFields.length) && isDevelopment) {
    throw new Error(`'FieldsFormWrapper.fields' property is not set`);
  }

  const dispatch = useDispatch();
  const { t } = useLocaleServices();
  const storedValues = useSelector(accessFormsFormData(name));

  const defaultValues = useMemo(
    () =>
      !storedValues || ignoreStoredValues
        ? getDefaultValuesForFields(propFields, propDefaultValues)
        : Object.assign({}, getDefaultValuesForFields(propFields, propDefaultValues), { ...storedValues }),
    [ignoreStoredValues, storedValues, propFields, propDefaultValues]
  );

  const newFormId = useId();
  const formId = name ? name : newFormId;

  const FormWrapperComponent = formWrapperComponent ?? React.Fragment;
  const FormPaddingComponent = formPaddingComponent ?? React.Fragment;
  const FieldsWrapperComponent = fieldsWrapperComponent ?? React.Fragment;
  const FieldsSeparatorComponent = fieldsSeparatorComponent ?? React.Fragment;

  const preserveLastFormData = useCallback(
    (data, defaultValues) => {
      if (!name) {
        return;
      }
      const payload = data
        ? {
            formData: createFormDataForJson(data),
            defaultValues: createFormDataForJson(defaultValues),
            name,
          }
        : {
            formData: null,
            name,
          };
      dispatch(storeLastKnownFormData(payload));
    },
    [dispatch, name]
  );

  // if a field has the `fields` prop, we replace this field with the constituent ones
  /**
   * @type {import('@services/fields/types').FieldsArrayType}
   */
  const fields = useMemo(
    () =>
      [].concat(
        ...propFields.map(field => {
          if (field.fields && field.type === FIELD_TYPES.ADDRESS) {
            return field.fields
              .map(innerField =>
                Object({
                  ...innerField,
                  name: `${field.name}.${innerField.name}`,
                  parentName: field.name,
                })
              )
              .concat([{ ...field, fields: undefined, type: FIELD_TYPES.ERROR_GROUP, label: undefined }]);
          }
          return [field];
        })
      ),
    [propFields]
  );

  const formSchema = useMemo(
    () => externalValidationSchema ?? formValidationComposer(propFields, { dispatch, knownValidationRuleObjects }),
    [externalValidationSchema, propFields, dispatch, knownValidationRuleObjects]
  );

  const formOptions = useMemo(() => {
    return {
      defaultValues,
      mode: 'onBlur',
      reValidateMode: 'onChange',
      resolver:
        null === formSchema
          ? null
          : async (data, context, options) => {
              preserveLastFormData(data, defaultValues);
              return yupResolver(formSchema)(data, context, options);
              // // LEFT FOR DEBUGGING PURPOSES, DO NOT REMOVE
              // const [error, result] = await safelyAwait(
              //   formSchema.validate(data, Object.assign({ abortEarly: false }, { context }))
              // )
              // const validationResult = await yupResolver(formSchema)(data, context, options)
              // const { errors, values } = validationResult
              // //                eslint-disable-next-line no-console
              // console.log('FieldsFormWrapper.resolver', {
              //   name,
              //   fields,
              //   data,
              //   errors,
              //   values,
              //   error,
              //   result
              // })
              // return validationResult
            },
    };
  }, [defaultValues, formSchema, preserveLastFormData]);

  const methods = useForm(formOptions);

  const { handleSubmit, getValues, trigger, clearErrors, setError, getFieldState, formState, watch, reset } = methods;

  const { error: formError } = getFieldState(CONST_FORM_ERROR, formState);

  const wrappedOnSubmit = useCallback(
    (data, event) => {
      if (!onSubmit) {
        return null;
      }

      const result = onSubmit(data, event, methods);

      if (interpretSubmissionResponse && result && result.then) {
        // TODO refactor this piece
        result.then(
          interpretSubmissionResults(clearErrors, setError, data),
          interpretSubmissionResults(clearErrors, setError, data)
        );
      }

      return result;
    },
    [clearErrors, interpretSubmissionResponse, methods, onSubmit, setError]
  );

  const wrappedOnError = useCallback(
    async (...args) => {
      await trigger();
      if (!onError) {
        if ('development' === process.env.NODE_ENV) {
          // The following warn message is desired for DEV env
          // eslint-disable-next-line no-console
          console.warn(`The following validation errors were not handled in ${name || 'a nameless'} form`, args);
        }
        return null;
      }
      return onError(...args);
    },
    [name, onError, trigger]
  );

  const [fieldsMetadata, setFieldsMetadata] = useState(new Map(fields.map(({ name }) => [name, {}])));
  const getFieldsMetadata = useCallback(name => fieldsMetadata.get(name), [fieldsMetadata]);
  const setFieldMetadata = useCallback(
    (name, value) => {
      const currentValue = fieldsMetadata.get(name);
      const result = 'function' === typeof value ? value(currentValue) : value;
      if (Object.is(currentValue, result)) {
        return;
      }
      setFieldsMetadata(new Map(fieldsMetadata.set(name, result)));
    },
    [fieldsMetadata]
  );

  const formBuildingMapper = useMemo(
    () =>
      makeFormBuildingMapper({
        fields,
        masterOptions: formBuildingMapperOptions,
      }),
    [fields, formBuildingMapperOptions]
  );

  const fieldElements = fields
    .map(field =>
      submitOnChange
        ? {
            ...field,
            options: {
              ...(field.options || {}),
              submitOnChange,
            },
          }
        : field
    )
    .map(formBuildingMapper) // MOST IMPORTANT STEP!
    .filter(Boolean)
    .map((element, idx) =>
      fieldsWrapperComponent ? (
        <FieldsWrapperComponent
          key={element?.key ?? idx}
          {...(fields[idx] ?? {})}
          fieldWrapClass={fieldWrapClass}
          labelClass={labelClass}
          labelErrorClass={labelErrorClass}
          fieldClass={fieldClass}
          errorClass={errorClass}
          metadata={element?.key ? getFieldsMetadata(element.key) : undefined}
        >
          {element}
        </FieldsWrapperComponent>
      ) : (
        element
      )
    )
    .reduce(
      (a, b) =>
        [a, fieldsSeparatorComponent ? <FieldsSeparatorComponent /> : null, betweenFieldsElement ?? null, b].filter(
          Boolean
        ),
      []
    );

  const hiddenSubmitBtnRef = useRef(null);
  const forceSubmit = useCallback(() => {
    hiddenSubmitBtnRef.current?.click?.();
  }, []);

  const effectiveFormProviderProps = {
    formId,
    formSchema,
    hiddenSubmitBtnRef,
    forceSubmit,
    ...(name ? { name } : {}),
    ...formProviderProps,
    setFieldMetadata,
    getFieldsMetadata,
  };

  useEffect(() => {
    if (!onChange) {
      return () => null;
    }
    const { unsubscribe } = watch(onChange);
    return unsubscribe;
  }, [watch, onChange]);

  useEffect(() => {
    onInit?.(getValues());
  }, [onInit, getValues]);

  useEffect(() => (resetAtUnmount ? reset : () => null), [resetAtUnmount, reset]);

  if (bareFields) {
    return (
      <FormProvider {...methods} {...effectiveFormProviderProps}>
        {hasPassword ? (
          <form
            id={formId}
            className="form-w-inherit"
            {...(name ? { name } : {})}
            onSubmit={handleSubmit(wrappedOnSubmit, wrappedOnError)}
            onFocus={onFocus}
          >
            <HiddenSubmitButton />
            {fieldElements}
          </form>
        ) : (
          <>
            <form
              id={formId}
              className="form-singular"
              {...(name ? { name } : {})}
              onSubmit={handleSubmit(wrappedOnSubmit, wrappedOnError)}
              onFocus={onFocus}
            >
              <HiddenSubmitButton />
            </form>
            {fieldElements}
          </>
        )}
      </FormProvider>
    );
  }

  return (
    <FormProvider {...methods} {...effectiveFormProviderProps}>
      {beforeFormElement}
      <FormWrapperComponent>
        {hasPassword ? (
          <form
            id={formId}
            className="form-w-inherit"
            {...(name ? { name } : {})}
            onSubmit={handleSubmit(wrappedOnSubmit, wrappedOnError)}
            onFocus={onFocus}
          >
            <HiddenSubmitButton />
            <div
              className={[formClassName ? formClassName : 'form', formError ? 'p-invalid' : ''].join(' ')}
              data-form-id={formId}
              {...rest}
            >
              <FormPaddingComponent>
                {beforeFieldsElement}
                <div className="fields_wrap">{fieldElements}</div>
                {formError && (
                  <div className="fields_wrap">
                    <div className="ml-6 mt-2 text-body-xs text-error">{t(formError.message)}</div>
                  </div>
                )}
                {afterFieldsElement}
              </FormPaddingComponent>
            </div>
          </form>
        ) : (
          <>
            <form
              id={formId}
              className="form-singular"
              {...(name ? { name } : {})}
              onSubmit={handleSubmit(wrappedOnSubmit, wrappedOnError)}
              onFocus={onFocus}
            >
              <HiddenSubmitButton />
            </form>
            <div
              className={classNames('form', formClassName, {
                'p-invalid': formError,
              })}
              data-form-id={formId}
              {...rest}
            >
              <FormPaddingComponent>
                {beforeFieldsElement}
                <div className="fields_wrap">{fieldElements}</div>
                {formError && (
                  <div className="fields_wrap">
                    <div className="ml-6 mt-2 text-body-xs text-error">{t(formError.message)}</div>
                  </div>
                )}
                {afterFieldsElement}
              </FormPaddingComponent>
            </div>
          </>
        )}
      </FormWrapperComponent>
      {afterFormElement}
    </FormProvider>
  );
}
