import { InfoBalloon } from '@ui/components/InfoBalloon';
import { BALLOON } from '@ui/components/InfoBalloon/resources';
import { useLocaleServices } from '@ui/contextProviders';
import { RouterLink } from '@ui/lib/Link/AppLink';
import { routeNames } from '@ui/routes/routeNames';
import { useLocation } from 'react-router';

export function ErrorPageSquirrel() {
  const { t } = useLocaleServices();
  const location = useLocation();

  return (
    <div className="mb-10 flex w-full flex-col items-center justify-center">
      <div className="mx-auto w-full max-w-screen-md">
        <InfoBalloon name={BALLOON.SQUIRREL_WHOOPS} />
      </div>
      <div className="flex w-full flex-col items-center">
        <h3 className="mb-8 font-bitter text-body-l font-bold leading-[1.625rem] dark:text-white">
          {t(location.state?.message ?? 'magic.link_expired')}
        </h3>
        <RouterLink to={routeNames.root} className="capitalize" variant="primary">
          {t('magic.home_link')}
        </RouterLink>
      </div>
    </div>
  );
}
