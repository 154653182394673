import { useLocaleServices } from '@ui/contextProviders';
import { Button } from '@ui/lib/Button';
import { ButtonVariant } from '@ui/lib/commonTypes';
import { useCloseHandler } from '@ui/pages/BalloonPage/hooks';
import { FC } from 'react';

export type BallonActionButtonProps = {
  action: string;
  label: string;
  variant?: ButtonVariant;
  testId?: string;
};
export const BallonActionButton: FC<BallonActionButtonProps> = ({ action, label, variant, testId }) => {
  const { t } = useLocaleServices();

  const handler = useCloseHandler(action);

  return (
    <Button
      label={t(label)}
      onClick={handler}
      type="button"
      role="button"
      variant={variant}
      data-role="close-account"
      data-testid={testId}
    />
  );
};
