import { subscribeToOsThemeChange, unsubscribeToOsThemeChange } from '@/utils/osColorTheme';
import { selectAvailableSurveysCount } from '@model/features/survey/selectors';
import { wrapCreateBrowserRouter } from '@sentry/react';
import { restoreDataFromStorage } from '@services/storage';
import { lazy, useEffect } from 'react';
import {
  Navigate,
  Outlet,
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom';
import ClosedAccBalloonContent from './components/Balloons/ClosedAccountBalloon';
import { ErrorFallback } from './components/ErrorFallback';
import { BALLOON } from './components/InfoBalloon/resources';
import { useAppSelector } from './hooks/redux';
import { ErrorLayout, MainLayout, RootLayout } from './layout';
import { BalloonPage, BalloonPageName } from './pages/BalloonPage';
import CcpaPage from './pages/Ccpa';
import ErrorPage from './pages/ErrorPage';
import { ErrorPage404 } from './pages/ErrorPage/ErrorPage404';
import { ErrorPageSquirrel } from './pages/ErrorPage/ErrorPageSquirrel';
import { AuthProtected, AuthRedirect, ProtectedRoute, RootRedirect } from './routes/ProtectedRoute';
import { SuspenseRoute } from './routes/SuspenseRoute';

const MagicLink = lazy(() => import(/* webpackChunkName: "Home" */ './pages/MagicLink'));

const Unsubscribe = lazy(() => import(/* webpackChunkName: "Home" */ './pages/Unsubscribe'));

const AuthPage = lazy(() => import(/* webpackPreload: true */ './components/Auth/AuthPage'));
const Register = lazy(() => import(/* webpackPreload: true */ './components/Register'));
const Registration = lazy(() => import(/* webpackPreload: true */ './pages/Registration'));
const SetupEmail = lazy(() => import(/* webpackPreload: true */ './pages/SetupEmail'));
const TermsConsent = lazy(() => import(/* webpackPreload: true */ './pages/TermsConsent'));
const EmailActivationByCode = lazy(
  () => import(/* webpackPreload: true */ './components/Register/elements/EmailActivationByCode')
);
const Login = lazy(() => import(/* webpackPreload: true */ './components/Login'));
const LoginByCode = lazy(() => import(/* webpackPreload: true */ './components/Login/elements/LoginByCode'));
const ResetPassword = lazy(() => import(/* webpackPreload: true */ './pages/ResetPassword'));
const ForgotPassword = lazy(() => import(/* webpackPreload: true */ './pages/ForgotPassword'));

const Dashboard = lazy(() => import(/* webpackChunkName: "Home" */ './pages/Dashboard'));
const Earnings = lazy(() => import(/* webpackChunkName: "Earnings" */ './pages/Earnings'));
const Cashout = lazy(() => import(/* webpackChunkName: "Earnings" */ './pages/Earnings/Cashout'));
const EarningsHistory = lazy(() => import(/* webpackChunkName: "Earnings" */ './pages/Earnings/History'));
const Demographics = lazy(() => import(/* webpackChunkName: "Demographics" */ './pages/Demographics'));
const DemographicTraits = lazy(
  () => import(/* webpackChunkName: "Demographics" */ './pages/Demographics/DemographicTags')
);
const Friends = lazy(() => import(/* webpackChunkName: "Friends" */ './pages/Friends'));
const Settings = lazy(() => import(/* webpackChunkName: "Settings" */ './pages/Settings'));
const Registered = lazy(() => import(/* webpackChunkName: "Registered" */ './pages/Registered'));
const Survey = lazy(() => import(/* webpackChunkName: "Survey" */ './pages/Survey'));
const SurveyCodeHandler = lazy(() => import(/* webpackChunkName: "Survey" */ './pages/Survey/SurveyCodeHandler'));
const ConflictBalloon = lazy(() => import(/* webpackChunkName: "Survey" */ './pages/Survey/ConflictBalloon'));

export const AppRoutes = () => {
  const availableSurveysCount = useAppSelector(selectAvailableSurveysCount);

  useEffect(() => {
    const handler = event => {
      // Check if user has already selected a theme
      const savedTheme = restoreDataFromStorage('theme');
      if (null == savedTheme || 'auto' === savedTheme) {
        // If not, let's check the OS preference
        window.switchTheme(event.matches ? 'dark' : 'light', availableSurveysCount > 0);
      }
    };

    subscribeToOsThemeChange(handler);
    return () => {
      unsubscribeToOsThemeChange(handler);
    };
  }, [availableSurveysCount]);
  return <RouterProvider router={AppRootRouter()} />;
};

function AppRootRouter() {
  const sentryCreateBrowserRouter = wrapCreateBrowserRouter(createBrowserRouter);

  return sentryCreateBrowserRouter(
    createRoutesFromElements(
      <Route path="/" Component={RootLayout}>
        <Route path="error" Component={ErrorLayout}>
          <Route index element={<Navigate to="not-found" />} />
          <Route path=":errorCode" element={<ErrorPage />} />
        </Route>

        <Route index Component={RootRedirect} />

        <Route Component={MainLayout} ErrorBoundary={ErrorFallback}>
          <Route element={<AuthPage component={Outlet} />}>
            <Route path="user/email/activate" element={<SuspenseRoute component={EmailActivationByCode} />} />

            <Route element={<SuspenseRoute component={AuthRedirect} />}>
              <Route path="register" element={<SuspenseRoute component={Register} />} />
              <Route path="login/password" element={<SuspenseRoute component={Login} section="login" />} />
              <Route path="login" element={<SuspenseRoute component={Login} section="login-email" />} />
              <Route path="login/email/code" element={<SuspenseRoute component={LoginByCode} />} />
              <Route path="user">
                <Route index element={<Navigate to="login/password" />} />
                <Route path="password/forgot" element={<SuspenseRoute component={ForgotPassword} />} />
              </Route>
            </Route>
          </Route>

          <Route element={<SuspenseRoute component={AuthProtected} />}>
            <Route path="registration" Component={Registration} />

            <Route path="user">
              <Route index element={<Navigate to="not-found" />} />
              <Route path="password/reset" Component={ResetPassword} />
              <Route path="email/setup" Component={SetupEmail} />
              <Route path="terms" Component={TermsConsent} />
            </Route>

            <Route element={<ProtectedRoute component={Outlet} />}>
              <Route path="dashboard" Component={Dashboard} />
              <Route path="survey">
                <Route index element={<Survey />} />
                <Route path=":invitationCode" element={<SurveyCodeHandler />} />
                <Route
                  path="conflict"
                  element={
                    <BalloonPage>
                      <ConflictBalloon />
                    </BalloonPage>
                  }
                />
              </Route>

              <Route path="earnings" element={<SuspenseRoute component={Earnings} />}>
                <Route index element={<SuspenseRoute component={Cashout} />} />
                <Route path="history" element={<SuspenseRoute component={EarningsHistory} />} />
              </Route>

              <Route path="demographics">
                <Route index element={<SuspenseRoute component={Demographics} />} />
                <Route path="traits" element={<SuspenseRoute component={DemographicTraits} />} />
              </Route>

              <Route path="settings" element={<Settings />} />

              <Route path="friends">
                <Route index element={<Friends />} />
                <Route path="registered" element={<Registered />} />
              </Route>
            </Route>
            <Route path="country/no-survey-panel" element={<BalloonPage name={BalloonPageName.UnsupportedCountry} />} />
          </Route>

          <Route path="country">
            <Route index element={<Navigate to="not-found" />} />
            <Route path="out-of-reach" element={<BalloonPage name={BalloonPageName.ForbiddenCountry} />} />
          </Route>

          <Route
            path="account-closed"
            element={
              <BalloonPage>
                <SuspenseRoute component={ClosedAccBalloonContent} />
              </BalloonPage>
            }
          />

          <Route path="magic">
            <Route index element={<MagicLink />} />
            <Route path=":code" element={<MagicLink />} />
          </Route>

          <Route path="unsubscribe" element={<Unsubscribe />} />
          <Route path="your-privacy-choices" element={<CcpaPage />} />

          <Route path="miss-you" element={<BalloonPage name={BALLOON.WE_WILL_MISS_YOU} />} />
          <Route path="miss-you/*" element={<BalloonPage name={BALLOON.WE_WILL_MISS_YOU} />} />

          <Route path="error">
            <Route path="minor" element={<BalloonPage name={BalloonPageName.Minor} />} />
            <Route path="magic-bad" element={<ErrorPageSquirrel />} />
            <Route path="magic-no" element={<ErrorPageSquirrel />} />
          </Route>
        </Route>

        <Route Component={ErrorLayout}>
          <Route path="not-found" element={<ErrorPage404 />} />
          <Route path="*" element={<ErrorPage404 />} />
        </Route>
      </Route>
    )
  );
}
