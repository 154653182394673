import { useLocaleServices } from '@ui/contextProviders';
import { Button } from '@ui/lib/Button';
import { UI_TEST_ASSIST } from '@ui/testids';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';

HomeNext.propTypes = {
  label: PropTypes.string,
  props: PropTypes.object,
};
/**
 *
 * @param {string} label - string or translate key
 * @param {object} restProps - other properties
 * @returns {JSX.Element}
 */
export function HomeNext({ label, ...restProps }) {
  const { t } = useLocaleServices();
  const {
    formId,
    formState: { isSubmitting },
  } = useFormContext();

  return (
    <Button
      {...UI_TEST_ASSIST.BTN_LOGIN_SUBMIT}
      variant="accent-a"
      big
      label={t(label ?? 'labels.next')}
      type="submit"
      form={formId}
      disabled={isSubmitting}
      loading={isSubmitting}
      {...restProps}
    />
  );
}
