import { soundNotification } from '@/services/soundNotification';
import { magicLinksSupportOnAppStart } from '@model/actions/magicLinksSupportOnAppStart';
import { proceedToActualizeStoredToken } from '@model/actions/proceedToActualizeStoredToken';
import { reportNetworkErrorListener } from '@model/actions/reportNetworkErrorListener';
import { signOutThunk } from '@model/actions/signoutAction';
import { tokenActualizationSupportOnAppStart } from '@model/actions/tokenActualizationSupportOnAppStart';
import { welcomeAction } from '@model/actions/welcomeAction';
import { checkRepeatAsyncManager } from '@model/api/checkRepeatAsyncManager';
import { assignFetchRepeatConditioningManager } from '@model/api/endpointsContext';
import { pluggable401ConditionChecker } from '@model/api/refreshToken/pluggable401ConditionChecker';
import { repeatDue498ResponseConditionAsync, repeatDueToCfCaptcha } from '@model/api/repeatDue498Response';
import { accessAuthToken, changePasswordAsyncThunk, persistTokenPair, signout } from '@model/features/auth';
import {
  actualizeTokenThunk,
  apiSignUpUserActivateEmailAsyncThunk,
  loginAsyncThunk,
  registerAsyncThunk,
  saveUserCountryAsyncThunk,
} from '@model/features/auth/authAsyncThunks';
import { invokeDialog } from '@model/features/dialogs';
import { DIALOG_NAMES } from '@model/features/dialogs/dialogNames';
import { accessMagicActionName, accessMagicActionPayload, recordMagicAction } from '@model/features/magic';
import { authenticationExchangeMagicLinkAsyncThunk } from '@model/features/magic/asyncActions';
import { MAGIC_ACTION_NAMES } from '@model/features/magic/types';
import { accessIsNavigationBlockRequested } from '@model/features/navigation';
import { setSurveyPollingTimeout } from '@model/features/survey';
import { getSurveysAsync } from '@model/features/survey/action-creators';
import { accessUserId, accessUserStatusChecks, updateUserInfo, userInfoAsyncThunk } from '@model/features/user';
import { userActivateAsyncThunk } from '@model/features/user/asyncActions';
import { userStatusChecks } from '@model/features/user/status';
import { isAnyOf, isFulfilled, isRejected } from '@reduxjs/toolkit';
import { setUser } from '@sentry/react';
import { logged } from '@services/diagnostics';
import { dataCollector } from '@services/fcm/dataCollector';
import { safelyExecute } from '@services/functional';
import { safelyParseJSON } from '@services/json';
import { safelyAwait } from '@services/promises';
import { unwrapResponseDataFromResolvedAction } from '@services/reduxToolkit';
import { pluggableConditionCheckerRegister } from '@services/reduxToolkit/pluggableConditionChecker';
import {
  FIXED_STATE_KEYS,
  persistDataToStorage,
  popDataFromStorage,
  restoreDataFromStoragePersistIfAbsent,
  STORAGE_KEY_LIST,
} from '@services/storage';
import { HOUR_INTO_MSEC, isTimeWithin } from '@services/time';
import { isOnPage, isOnParentPage, paths } from '@ui/navigation';
import { ERROR_PAGE, routeNames, routeValues, toErrorPage, toSurveyPage } from '@ui/routes/routeNames';
import { onAppStartActionsManager } from './onAppStartActionsManager';

/**
 * First thing the application loads, it needs to restore the token info
 */
export const setupActionListeners = middleware => {
  onAppStartActionsManager.register(magicLinksSupportOnAppStart);
  onAppStartActionsManager.register(tokenActualizationSupportOnAppStart);

  pluggableConditionCheckerRegister(pluggable401ConditionChecker);

  assignFetchRepeatConditioningManager(checkRepeatAsyncManager.register(repeatDue498ResponseConditionAsync));
  assignFetchRepeatConditioningManager(checkRepeatAsyncManager.register(repeatDueToCfCaptcha));

  middleware.startListening({
    actionCreator: welcomeAction,
    effect: onAppStartActionsManager.actions,
  });

  middleware.startListening({
    matcher: isRejected,
    effect: async (originalAction, reduxAPI) => {
      safelyExecute(reportNetworkErrorListener)(originalAction, reduxAPI);
    },
  });

  middleware.startListening({
    matcher: isFulfilled,
    effect: async ({ payload }, { dispatch, getState }) => {
      if (payload != null) {
        const { code, response } = payload;
        if (422 === code && response.errors) {
          const [error] = response.errors;
          const { message } = error;
          if ('InvalidUserStatus' === message) {
            const token = accessAuthToken()(getState());
            await dispatch(actualizeTokenThunk(token));
          }
        }
      }
    },
  });

  middleware.startListening({
    actionCreator: changePasswordAsyncThunk.fulfilled,
    effect: async (action, { dispatch }) => {
      if (action.payload.code !== 201) {
        return;
      }
      const { meta } = action.payload.response;
      const [token, refreshToken] = meta.password.tokens;
      persistTokenPair({ token, refreshToken });
      await dispatch(actualizeTokenThunk(token));
    },
  });

  // LOGIN
  middleware.startListening({
    matcher: isAnyOf(
      loginAsyncThunk.fulfilled,
      registerAsyncThunk.fulfilled,
      authenticationExchangeMagicLinkAsyncThunk.fulfilled
    ),
    effect: async (action, { dispatch }) => {
      if (action.payload.code !== 201) {
        return;
      }
      const { email } = action.meta.arg;
      const data = unwrapResponseDataFromResolvedAction(action);
      const isSurveyInvitation = MAGIC_ACTION_NAMES.SURVEY_INVITATION === action.payload.response?.meta?.action;

      let token = data?.token ?? null;
      let refreshToken = data?.refresh_token ?? null;

      if (isSurveyInvitation) {
        // Remain token the same if any
        token = restoreDataFromStoragePersistIfAbsent(STORAGE_KEY_LIST.AUTH_TOKEN, token);
        refreshToken = restoreDataFromStoragePersistIfAbsent(STORAGE_KEY_LIST.AUTH_REF_TOKEN, refreshToken);
      } else {
        persistTokenPair({ token, refreshToken });
      }

      // I'd rather this if statement be as is, not ternary
      // eslint-disable-next-line unicorn/prefer-ternary
      if (authenticationExchangeMagicLinkAsyncThunk.fulfilled.match(action)) {
        await dispatch(performMagicActionThunk(token, email));
      } else {
        await dispatch(actualizeTokenThunk(token));
      }
    },
  });

  middleware.startListening({
    actionCreator: userActivateAsyncThunk.fulfilled,
    effect: async (_action, { dispatch }) => {
      dispatch(proceedToActualizeStoredToken());
    },
  });

  let prevUserId = null;
  middleware.startListening({
    matcher: isAnyOf(userInfoAsyncThunk.fulfilled, saveUserCountryAsyncThunk.fulfilled),
    effect: async (action, { dispatch, getState }) => {
      const data = action.payload.data;

      dispatch(updateUserInfo(data));
      persistDataToStorage(STORAGE_KEY_LIST.USER_STATUS, data.status);

      const { meta } = action;

      if (meta.arg?.noAutoRedirects !== true) {
        // TODO: Remove redirect handling from this effect and move it to caller to handle it directly and obvious way
        await dispatch(performStatusBasedActionsThunk(userStatusChecks(data.status)));
      }

      const userId = data?.id ?? accessUserId()(getState());
      if (userId != null && userId !== prevUserId) {
        // Send user id to sentry context
        setUser({ id: userId });
        logged(userId, 'User id sent to sentry');
        prevUserId = userId;
      }
    },
  });

  // Start listening auth token changes
  middleware.startListening({
    predicate: (_, currentState, prevState) =>
      accessAuthToken()(prevState) !== accessAuthToken()(currentState) && accessAuthToken()(currentState) != null,
    effect: async (_, { getState }) => {
      const token = accessAuthToken()(getState());
      dataCollector.putData({
        type: 'token',
        value: token,
      });
    },
  });

  // Listening for push notifications
  middleware.startListening({
    predicate: (_, currentState, prevState) =>
      currentState.user?.notifications != null &&
      currentState.user.notifications.push != prevState.user?.notifications?.push,
    effect: async (_, { getState }) => {
      dataCollector.putData({
        type: 'pushEnabled',
        value: getState().user.notifications.push,
      });
    },
  });

  let tm;
  middleware.startListening({
    actionCreator: getSurveysAsync.fulfilled,
    effect: async (action, { dispatch, getOriginalState, getState }) => {
      const { data, meta } = action.payload;

      const prevCount = getOriginalState().survey?.availableSurveys?.length || 0;
      const newCount = data?.length || 0;

      if (newCount > prevCount && getState().user?.notifications?.sound) {
        soundNotification();
      }

      if (newCount !== prevCount) {
        window.changeFavicon(window.getCurrentTheme(), newCount > 0);
      }

      // Check if we need to refresh surveys
      if (meta?.refresh > 0) {
        // Clear timeout if any
        if (tm) {
          clearTimeout(tm);
        }

        // if refresh > 0 than set timeout
        tm = setTimeout(() => {
          // and refresh
          dispatch(getSurveysAsync());
        }, meta.refresh * 1000);
        dispatch(setSurveyPollingTimeout(tm));
      }
    },
  });

  // Clear timeout on logout
  middleware.startListening({
    actionCreator: signout,
    effect: () => {
      if (tm) {
        clearTimeout(tm);
      }
    },
  });
};

export function performStatusBasedActionsThunk(statusFlags) {
  return async (dispatch, getState) => {
    const fallbackStatusFlags = accessUserStatusChecks()(getState());
    const presentPath = location.pathname;
    const {
      isUserAlive,
      isUserInRegistrationPhase,
      isUserInEmailSetup,
      isUserInEmailActivation,
      isUserInForbiddenCountry,
      isUserInUnsupportedCountry,
      isUserMinor,
      isUserInTermsConsentPhase,
    } = statusFlags || fallbackStatusFlags;

    const isNavigationBlockRequested = accessIsNavigationBlockRequested()(getState());

    if (isNavigationBlockRequested) {
      return;
    }

    const email = getState().forms?.['login-form']?.formData?.email;
    // TODO: Remove this spaghetti code
    if (isUserAlive) {
      dispatch(performRedirectIfNeeded);
    } else if (isUserInEmailSetup) {
      if (presentPath !== routeNames.usersEmailSetup) {
        window.navigate(routeNames.usersEmailSetup);
      }
    } else if (isUserInEmailActivation) {
      if (presentPath !== routeNames.usersEmailActivate) {
        window.navigate(routeNames.usersEmailActivate, {
          state: { email },
        });
      }
    } else if (isUserInRegistrationPhase) {
      if (presentPath !== routeNames.registrationIndex) {
        window.navigate(routeNames.registrationIndex);
      }
    } else if (isUserInUnsupportedCountry) {
      window.navigate(routeNames.countryUnsupported, { replace: true });
    } else if (isUserInForbiddenCountry) {
      window.navigate(routeNames.countryForbidden, { replace: true });
    } else if (isUserMinor) {
      window.navigate(routeValues.errorMinorProblem, { replace: true });
    } else if (isUserInTermsConsentPhase) {
      window.navigate(routeNames.usersTermsConsent, { replace: true });
    } else {
      window.navigate(toErrorPage(ERROR_PAGE.INVALID_USER_STATUS), { replace: true });
    }
  };
}

function performMagicActionThunk(token, email) {
  return async function _performMagicActionThunk(dispatch, getState) {
    if (!token) {
      dispatch(signOutThunk());
      return;
    }

    const magicActionName = accessMagicActionName()(getState());
    const magicActionPayload = accessMagicActionPayload()(getState());
    dispatch(recordMagicAction(null));

    switch (magicActionName) {
      case MAGIC_ACTION_NAMES.LOGIN: {
        await dispatch(userInfoAsyncThunk());
        window.navigate(routeNames.dashboard);
        break;
      }
      case MAGIC_ACTION_NAMES.ADMIN_TOOL_LOGIN: {
        await dispatch(userInfoAsyncThunk({ noAutoRedirects: true }));
        window.navigate(routeNames.root);
        break;
      }
      case MAGIC_ACTION_NAMES.RESET_PASSWORD: {
        const { code } = magicActionPayload;
        persistDataToStorage(STORAGE_KEY_LIST.PASSWORD_CHANGE_CODE, code, window.sessionStorage);
        window.navigate(routeNames.usersPasswordReset);
        await dispatch(userInfoAsyncThunk({ noAutoRedirects: true }));
        break;
      }
      case MAGIC_ACTION_NAMES.PASSWORD_CHANGE: {
        const { code } = magicActionPayload;
        dispatch(invokeDialog(DIALOG_NAMES.PWD_CHANGE_DIALOG, { code }));
        break;
      }
      case MAGIC_ACTION_NAMES.REGISTRATION_EMAIL_ACTIVATION: {
        const { activation_code: activationCode } = magicActionPayload;
        const [error, result] = await safelyAwait(
          dispatch(apiSignUpUserActivateEmailAsyncThunk({ code: activationCode, email })).unwrap()
        );
        if (error || 422 === result.code) {
          dispatch(signOutThunk());
          window.navigate(routeNames.root);
          break;
        }
        await dispatch(userInfoAsyncThunk());
        break;
      }
      // eslint-disable-next-line no-fallthrough
      case MAGIC_ACTION_NAMES.SETTINGS_EMAIL_CONFIRMATION:
      case MAGIC_ACTION_NAMES.SETTINGS_ALT_EMAIL_CONFIRMATION: {
        const { activation_code: activationCode } = magicActionPayload;
        void activationCode;
        break;
      }
      case MAGIC_ACTION_NAMES.SURVEY_INVITATION: {
        await dispatch(userInfoAsyncThunk({ noAutoRedirects: true }));
        // Navigate to survey if magic link is for survey invitation
        window.navigate(toSurveyPage(magicActionPayload.survey_id), { replace: true });
        break;
      }
      default: {
        void magicActionPayload;
      }
    }
  };
}

export function performRedirectIfNeeded(dispatch) {
  const socialRedirectTo = popDataFromStorage(STORAGE_KEY_LIST.PREVIOUS_LOCATION, null, window.sessionStorage);
  if (socialRedirectTo) {
    const { pathname, search, hash, key, state } = safelyParseJSON(socialRedirectTo, {});
    if ('undefined' === typeof pathname) {
      return;
    }
    // // eslint-disable-next-line no-console
    pathname &&
      window.navigate(pathname, {
        search,
        hash,
        key,
        state,
        replace: true,
      });
    state && dispatch(performRestoreState(state));
    return;
  }

  const redirectTo = popDataFromStorage(STORAGE_KEY_LIST.REDIRECT_TO, '/');
  const redirectToTs = popDataFromStorage(STORAGE_KEY_LIST.REDIRECT_TO_TIMESTAMP, 0, window.sessionStorage);

  if (redirectToTs && isTimeWithin(redirectToTs, 30 * HOUR_INTO_MSEC)) {
    // redirect to redirectTo
    window.navigate(redirectTo);
  }
  const pathname = location.pathname;
  if (isOnParentPage(paths.atPassword)(pathname) || isOnParentPage('/login')(pathname) || isOnPage('/')(pathname)) {
    window.navigate(routeNames.dashboard);
  }
}

export function performRestoreState(state) {
  return dispatch => {
    if (state === FIXED_STATE_KEYS.PROFILING_PAYPAL_MODAL) {
      dispatch(invokeDialog(DIALOG_NAMES.FILL_PROFILE, { payload: 'proceed' }));
    }
  };
}
