import { ErrorLogger } from '@/utils/ErrorLogger';
import { apiRequestSettingsGroups, apiSaveAvatarToSettings, apiSaveSettingsData } from '@model/api/apiCommunication';
import { createAsyncThunkWithTokenRefresh, serializeErrorOption } from '@services/reduxToolkit';
import { isFailureResponse, isSuccessResponse } from '@ui/support/utils/guards';
import { persistTokenPair, updateToken } from '../auth';
import { userInfoAsyncThunk } from '../user';

const name = 'settings';

export const getSettingsGroupsAsyncThunk = createAsyncThunkWithTokenRefresh(
  `api/${name}/settings/get`,
  async (_: void, thunkApi) => {
    try {
      const res = await apiRequestSettingsGroups();
      if (isFailureResponse(res.response)) {
        return thunkApi.rejectWithValue(res.response);
      }
      return res.response.data.groups;
    } catch (error) {
      ErrorLogger.setExtra('asyncAction', 'getSettingsGroupsAsyncThunk').send(error);
      return thunkApi.rejectWithValue(error);
    }
  },
  serializeErrorOption
);

export const saveImageAsAvatarAsyncThunk = createAsyncThunkWithTokenRefresh(
  `api/${name}/settings/save-avatar`,
  apiSaveAvatarToSettings,
  serializeErrorOption
);
export const saveSettingsDataAsyncThunk = createAsyncThunkWithTokenRefresh(
  `api/${name}/settings/save-data`,
  async (data: FormData | { [name: string]: unknown }, thunkApi) => {
    try {
      // Save settings
      const result = await apiSaveSettingsData(data);
      const { response } = result;
      // Check is error
      if (isFailureResponse(response)) {
        // Return error to the UI
        return thunkApi.rejectWithValue(response.errors[0] ?? {});
      }
      // Refresh settings and current user
      const [res] = await Promise.all([apiRequestSettingsGroups(), thunkApi.dispatch(userInfoAsyncThunk())]);
      if (isSuccessResponse(res.response)) {
        return res.response.data.groups;
      }
      return thunkApi.rejectWithValue(res.response.errors[0] ?? {});
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
  serializeErrorOption
);

export const saveSettingsDataWithActionsAsyncThunk = createAsyncThunkWithTokenRefresh(
  `api/${name}/settings/save-data-with-actions`,
  async (data: FormData | { [name: string]: unknown }, thunkApi) => {
    const { rejectWithValue, dispatch } = thunkApi;
    const payload = await apiSaveSettingsData(data);

    if (isFailureResponse(payload.response)) {
      // Patch response to catch error in the UI
      return rejectWithValue({
        ...(payload.response.errors[0] ?? {}),
        code: 400,
      });
    }
    // If we receive new tokens, we need to update them
    if (payload.response.meta?.password?.tokens != null) {
      const [token, refresh_token] = payload.response.meta.password.tokens;
      persistTokenPair({ token, refreshToken: refresh_token });
      dispatch(
        updateToken({
          token,
          refresh_token,
        })
      );
    }
    return payload.response.data.groups;
  },
  serializeErrorOption
);
