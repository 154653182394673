import { PVImage } from '@ui/elements/images';

export const iconCDNPaths = {
  apple: 'images/icons/apple.svg',
  arrow_back: 'images/icons/arrow_back.svg',
  award: 'images/icons/award.svg',
  cake_candles_duotone: 'images/icons/cake_candles_duotone.svg',
  camera: 'images/icons/camera.svg',
  celebration: 'images/icons/celebration.svg',
  circle_dollar_regular: 'images/icons/circle_dollar_regular.svg',
  coins: 'images/icons/coins.svg',
  crown_solid: 'images/icons/crown_solid.svg',
  crown_solid_violet: 'images/icons/crown_solid_violet.svg',
  dark_theme: 'images/icons/dark_theme.svg',
  envelope_dot_solid: 'images/icons/envelope_dot_solid.svg',
  exclamation_mark: 'images/icons/exclamation_mark.svg',
  exclamation_mark_accent_a: 'images/icons/exclamation_mark_accent_a.svg',
  facebook: 'images/icons/facebook.svg',
  fire_solid: 'images/icons/fire_solid.svg',
  gift_card_regular: 'images/icons/gift_card_regular.svg',
  google_play: 'images/icons/google_play.svg',
  group_facebook: 'images/icons/group_facebook.svg',
  group_linkedin: 'images/icons/group_linkedin.svg',
  group_twitter: 'images/icons/group_twitter.svg',
  letter_send: 'images/icons/letter_send.svg',
  link_simple_solid: 'images/icons/link_simple_solid.svg',
  linkedin: 'images/icons/linkedin.svg',
  mailbox: 'images/icons/mailbox.svg',
  mark: 'images/icons/mark.svg',
  handset: 'images/icons/handset.svg',
  picture: 'images/icons/picture.svg',
  plane: 'images/icons/plane.svg',
  qr_code: 'images/icons/qr_code.svg',
  sms: 'images/icons/sms.svg',
  snowflake_solid: 'images/icons/snowflake_solid.svg',
  timer_regular: 'images/icons/timer_regular.svg',
  twitter: 'images/icons/twitter.svg',
  user: 'images/icons/user.svg',
  user_premium: 'images/icons/user_premium.svg',
  user_solid: 'images/icons/user_solid.svg',
  users: 'images/icons/users.svg',
  yahoo: 'images/icons/yahoo.svg',
  instagram: 'images/icons/icon_inst_pink.svg',
  // Colorless
  colorlessFb: 'images/icons/icon_fb.svg',
  colorlessTw: 'images/icons/icon_tw.svg',
  colorlessInst: 'images/icons/icon_inst.svg',
};

export const IconApple = preApplyProps(PVImage, { url: iconCDNPaths.apple }, 'IconApple');
export const IconArrowBack = preApplyProps(PVImage, { url: iconCDNPaths.arrow_back }, 'IconArrowBack');
export const IconAward = preApplyProps(PVImage, { url: iconCDNPaths.award }, 'IconAward');
export const IconCakeCandlesDuotone = preApplyProps(
  PVImage,
  { url: iconCDNPaths.cake_candles_duotone },
  'IconCakeCandlesDuotone'
);
export const IconCamera = preApplyProps(PVImage, { url: iconCDNPaths.camera }, 'IconCamera');
export const IconCelebration = preApplyProps(PVImage, { url: iconCDNPaths.celebration }, 'IconCelebration');
export const IconCircleDollarRegular = preApplyProps(
  PVImage,
  { url: iconCDNPaths.circle_dollar_regular },
  'IconCircleDollarRegular'
);
export const IconCoins = preApplyProps(PVImage, { url: iconCDNPaths.coins }, 'IconCoins');
export const IconCrownSolid = preApplyProps(PVImage, { url: iconCDNPaths.crown_solid }, 'IconCrownSolid');
export const IconCrownSolidViolet = preApplyProps(
  PVImage,
  { url: iconCDNPaths.crown_solid_violet },
  'IconCrownSolidViolet'
);
export const IconDarkTheme = preApplyProps(PVImage, { url: iconCDNPaths.dark_theme }, 'IconDarkTheme');
export const IconEnvelopeDotSolid = preApplyProps(
  PVImage,
  { url: iconCDNPaths.envelope_dot_solid },
  'IconEnvelopeDotSolid'
);
export const IconExclamationMark = preApplyProps(
  PVImage,
  { url: iconCDNPaths.exclamation_mark },
  'IconExclamationMark'
);
export const IconExclamationMarkAccentA = preApplyProps(
  PVImage,
  { url: iconCDNPaths.exclamation_mark_accent_a },
  'IconExclamationMarkAccentA'
);
export const IconFacebook = preApplyProps(PVImage, { url: iconCDNPaths.facebook }, 'IconFacebook');
export const IconFireSolid = preApplyProps(PVImage, { url: iconCDNPaths.fire_solid }, 'IconFireSolid');
export const IconGiftCardRegular = preApplyProps(
  PVImage,
  { url: iconCDNPaths.gift_card_regular },
  'IconGiftCardRegular'
);
export const IconGooglePlay = preApplyProps(PVImage, { url: iconCDNPaths.google_play }, 'IconGooglePlay');
export const IconFacebookSmall = preApplyProps(PVImage, { url: iconCDNPaths.group_facebook }, 'IconFacebookSmall');
export const IconLinkedinSmall = preApplyProps(PVImage, { url: iconCDNPaths.group_linkedin }, 'IconLinkedinSmall');
export const IconTwitterSmall = preApplyProps(PVImage, { url: iconCDNPaths.group_twitter }, 'IconTwitterSmall');
export const IconLetterSend = preApplyProps(PVImage, { url: iconCDNPaths.letter_send }, 'IconLetterSend');
export const IconLinkSimpleSolid = preApplyProps(
  PVImage,
  { url: iconCDNPaths.link_simple_solid },
  'IconLinkSimpleSolid'
);
export const IconLinkedin = preApplyProps(PVImage, { url: iconCDNPaths.linkedin }, 'IconLinkedin');
export const IconMailbox = preApplyProps(PVImage, { url: iconCDNPaths.mailbox }, 'IconMailbox');
export const IconMark = preApplyProps(PVImage, { url: iconCDNPaths.mark }, 'IconMark');
export const IconHandset = preApplyProps(PVImage, { url: iconCDNPaths.handset }, 'IconHandset');
export const IconPicture = preApplyProps(PVImage, { url: iconCDNPaths.picture }, 'IconPicture');
export const IconPlane = preApplyProps(PVImage, { url: iconCDNPaths.plane }, 'IconPlane');
export const IconQRCode = preApplyProps(PVImage, { url: iconCDNPaths.qr_code }, 'IconQRCode');
export const IconSMS = preApplyProps(PVImage, { url: iconCDNPaths.sms }, 'IconSMS');
export const IconSnowflakeSolid = preApplyProps(PVImage, { url: iconCDNPaths.snowflake_solid }, 'IconSnowflakeSolid');
export const IconTimerRegular = preApplyProps(PVImage, { url: iconCDNPaths.timer_regular }, 'IconTimerRegular');
export const IconTwitter = preApplyProps(PVImage, { url: iconCDNPaths.twitter }, 'IconTwitter');
export const IconUser = preApplyProps(PVImage, { url: iconCDNPaths.user }, 'IconUser');
export const IconUserPremium = preApplyProps(PVImage, { url: iconCDNPaths.user_premium }, 'IconUserPremium');
export const IconUserSolid = preApplyProps(PVImage, { url: iconCDNPaths.user_solid }, 'IconUserSolid');
export const IconUsers = preApplyProps(PVImage, { url: iconCDNPaths.users }, 'IconUsers');
export const IconYahoo = preApplyProps(PVImage, { url: iconCDNPaths.yahoo }, 'IconYahoo');
export const IconInstagram = preApplyProps(PVImage, { url: iconCDNPaths.instagram }, 'IconInstagram');

// Colorless
export const IconFacebookColorless = preApplyProps(PVImage, { url: iconCDNPaths.colorlessFb }, 'IconFacebookColorless');
export const IconTwitterColorless = preApplyProps(PVImage, { url: iconCDNPaths.colorlessTw }, 'IconTwitterColorless');
export const IconInstagramColorless = preApplyProps(
  PVImage,
  { url: iconCDNPaths.colorlessInst },
  'IconInstagramColorless'
);

/**
 * @param {React.FC} Component
 * @param {object} hocProps
 * @param {string} name
 * @returns {(props: any) => JSX.Element}
 */
function preApplyProps(Component, hocProps, name = 'PreAppliedHOC') {
  return {
    [name](props) {
      return <Component {...hocProps} {...props} />;
    },
  }[name];
}
