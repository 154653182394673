import { useEffect, useState } from 'react';
import pvs from './popup.module.scss';
import PropTypes from 'prop-types';

PopUp.propTypes = {
  children: PropTypes.any,
  active: PropTypes.bool,
  setActive: PropTypes.func,
  error: PropTypes.bool,
};
/**
 * @deprecated
 *
 * @param {JSX.Element} children
 * @param {boolean} active
 * @param {function} setActive
 * @param {boolean} error
 * @returns {JSX.Element}
 */
export function PopUp({ children, active, setActive, error }) {
  const [style, setStyle] = useState(active ? [pvs.popup, pvs.active].join(' ') : pvs.popup);

  useEffect(() => {
    setStyle(active ? [pvs.popup, pvs.active, error ? pvs.error : pvs.success].join(' ') : pvs.popup);
  }, [active, error]);

  return (
    <div className={style} onClick={() => setActive(false)}>
      <div className={pvs.content} onClick={event => event.stopPropagation()}>
        <div className={pvs.cross} onClick={() => setActive(false)}>
          <i className="pi pi-times"></i>
        </div>
        {children}
      </div>
    </div>
  );
}
