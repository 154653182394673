import { UserStatus } from '@/api/user/user-status';
import { hasSupportUpdates, selectUserStatus } from '@model/features/user/selectors';
import { useAuth, useLocaleServices } from '@ui/contextProviders';
import { useAppSelector } from '@ui/hooks/redux';
import { AppLink } from '@ui/lib/Link/AppLink';
import { AnchorHTMLAttributes, FC, PropsWithChildren } from 'react';
import { useNavigate } from 'react-router-dom';

type SupportButtonProps = {
  className?: string;
  link?: string;
} & AnchorHTMLAttributes<HTMLAnchorElement>;

export const SupportButton: FC<PropsWithChildren<SupportButtonProps>> = ({ children, className, link, ...rest }) => {
  const { t } = useLocaleServices();
  const { isAuthenticated } = useAuth();
  const status = useAppSelector(selectUserStatus);
  const hasUpdates = useAppSelector(hasSupportUpdates);
  const navigate = useNavigate();

  if (!isAuthenticated || status === UserStatus.UNSUPPORTED_COUNTRY) {
    return (
      <AppLink
        aria-label="support"
        data-role="support-button"
        href="mailto:support@paidviewpoint.com"
        className={className}
        {...rest}
      >
        {children ?? t('links.support')}
      </AppLink>
    );
  }

  return (
    <AppLink
      tabIndex={0}
      aria-label="support"
      data-role="support-button"
      onClick={event => {
        event.preventDefault();
        navigate(link ?? window.location.pathname + '#support', { replace: true });
      }}
      className={className}
      {...rest}
    >
      {children ?? t('links.support')}
      {hasUpdates && <span className="ml-2 inline-block h-2 w-2 rounded-full bg-accent-a" />}
    </AppLink>
  );
};
