import { signout } from '@model/features/auth';
import { traitsApi } from '@model/features/demographics/traits';
import { setUser } from '@sentry/react';
import { dataCollector } from '@services/fcm/dataCollector';
import { STORAGE_KEY_LIST, removeDataFromAllStorages } from '@services/storage';

/**
 * TODO: add actions to reset/wipe entire store
 * @return {(function(*): void)|*}
 */
export function signOutThunk() {
  return dispatch => {
    dispatch(signout());
    dispatch(traitsApi.util.resetApiState());
    // Clearing user data
    removeDataFromAllStorages(STORAGE_KEY_LIST.AUTH_TOKEN);
    removeDataFromAllStorages(STORAGE_KEY_LIST.AUTH_REF_TOKEN);
    removeDataFromAllStorages(STORAGE_KEY_LIST.USER_STATUS);
    // Clearing sentry user
    setUser(null);
    window.changeFavicon(window.getCurrentTheme(), false);
    // Disable push notifications
    dataCollector.putData({
      type: 'pushEnabled',
      value: false,
    });
  };
}
