import { AsyncThunkPayloadCreator, createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError, AxiosResponse, isAxiosError } from 'axios';

interface ApiAxiosError extends AxiosError {
  response: AxiosResponse<ApiErrorResponseType>;
}

class ResponseError extends Error {
  constructor(
    public readonly error: ApiError,
    public readonly meta?: ApiResponseMeta
  ) {
    super(error.message_en ?? error.message);
    this.name = 'ResponseError';
  }
}

export function isError(error: unknown): error is ResponseError {
  return error instanceof ResponseError;
}

export function isApiAxiosError(error: unknown): error is ApiAxiosError {
  return (
    isAxiosError<ApiErrorResponseType>(error) &&
    (error.response?.data.errors != null || error.response?.data.meta != null)
  );
}

export function createAsync<Returned, ThunkArg>(
  typePrefix: string,
  payloadCreator: AsyncThunkPayloadCreator<Returned, ThunkArg>
) {
  return createAsyncThunk<
    Returned,
    ThunkArg,
    {
      rejectValue: ResponseError;
    }
  >(typePrefix, async (args, thunkAPI) => {
    try {
      return await payloadCreator(args, thunkAPI);
    } catch (error) {
      if (isApiAxiosError(error)) {
        // Automatically reject with MyCustomError
        const apiError = error.response.data?.errors?.[0] || {
          code: error.response.status,
          error: error.message,
          message: 'errors.general',
          exception: 'EmptyError',
        };
        return thunkAPI.rejectWithValue(new ResponseError(apiError, error.response.data?.meta));
      }
      // TODO log error
      // Fallback for unexpected errors
      return thunkAPI.rejectWithValue(error as any) as any;
    }
  });
}
