import { SurveyItem } from '@model/features/survey/types';
import { formatCurrency } from '@services/currency';
import { useLocaleServices } from '@ui/contextProviders';
import { Icon, Icons } from '@ui/lib/Icon';
import { RouterLink } from '@ui/lib/Link/AppLink';
import { ProgressBar } from '@ui/lib/ProgressBar/indes';
import { paths } from '@ui/navigation';
import { classNames } from 'primereact/utils';

export function SurveyTableRow({ survey }: { survey: SurveyItem }) {
  const { t } = useLocaleServices();

  const {
    id, //: "bb1b4c65bd8c6f2e",
    enabled: isEnabled, //: true,
    incentive_cents: incentiveCents, //: 343,
    incentive_percent: incentivePercent, //: 82,
    loi_seconds: loiSeconds, //: 150,
    loi_percent: loiPercent, //: 25,
    button: {
      label: buttonLabel,
      emphasis: buttonEmphasis, // 'primary'|'secondary'
    },
  } = survey;

  const loiSecondsToOneDecimal = loiSeconds ? (loiSeconds / 60).toFixed() : 0;

  return (
    <>
      <ProgressBar
        value={incentivePercent}
        aria-label="money"
        showValue={false}
        className="p-progressbar-accent-b w-full"
      />
      <div className="mr-10 flex items-center justify-end xl:mr-12">
        <span className="text-nowrap px-4 font-bitter text-body-l">
          up to <span className="font-bold">{formatCurrency(incentiveCents ?? 0, 'US')}</span>
        </span>
        <Icon icon={Icons.DollarCircle} className="align-middle text-[24px] leading-none text-accent-b" />
      </div>
      <ProgressBar value={loiPercent} aria-label="time" showValue={false} className="w-full" />
      <div className="mr-10 flex items-center justify-end xl:mr-12">
        <span className="text-nowrap px-4 font-bitter text-body-l">
          <span className="font-bold">~ {loiSecondsToOneDecimal}</span> min
        </span>
        <Icon icon={Icons.Timer} className="align-middle text-[24px] leading-none text-accent-a" />
      </div>
      <div>
        <RouterLink
          to={paths.toSurveyCode(id)}
          tabIndex={0}
          variant={'primary' === buttonEmphasis ? 'accent-a' : 'accent-b'}
          disabled={!isEnabled}
          button
          className={classNames('2xl:button-lg justify-self-center whitespace-nowrap')}
        >
          {t(buttonLabel)}
        </RouterLink>
      </div>
    </>
  );
}
