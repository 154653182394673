import { Controller, useFormContext } from 'react-hook-form';
import { MEDIA_BP, useDependsOnDataLoader, useMediaQuery, usePrevious } from '@ui/hooks';
import { useLocaleServices } from '@ui/contextProviders';
import { useEffect, useId } from 'react';
import { If, IfNot } from '@ui/elements';
import { EarningBonus } from './elements';
import { MultiSelect } from 'primereact/multiselect';
import { centsToDollar } from '@services/currency';
import PropTypes from 'prop-types';

MultiselectSmartFormComponent.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  values: PropTypes.array,
  className: PropTypes.string,
  options: PropTypes.object,
  submissionError: PropTypes.object,
  moreLabel: PropTypes.string,
  ariaLabel: PropTypes.string,
  showIcon: PropTypes.bool,
  dependentsMap: PropTypes.object,
};
/**
 *
 * @type {SmartFormSimpleComponent} MultiselectSmartFormComponent
 * @returns {JSX.Element}
 * @constructor
 * @deprecated
 */
export function MultiselectSmartFormComponent({
  label,
  name,
  values: propsValues,
  className: classNameField = '',
  options,
  submissionError,
  moreLabel = '',
  ariaLabel = '',
  showIcon: show_icon,
  dependentsMap,
}) {
  const { t } = useLocaleServices();
  const isLargeScreen = useMediaQuery(MEDIA_BP.isOrGreaterThan768);
  const fieldId = useId();
  const { incentive_cents, placeholder } = options;

  const { getFieldState, getValues, trigger, setError } = useFormContext();
  const { error: fieldError } = getFieldState(name);

  const parentFieldValue = dependentsMap[name] ? getValues(dependentsMap[name]) : null;
  const previousParentValue = usePrevious(parentFieldValue);

  const isValid = !fieldError;
  const { values } = useDependsOnDataLoader(options, propsValues, previousParentValue, name);
  const value = getValues(name);

  useEffect(() => submissionError && setError(name), [submissionError, setError, name]);

  const blurField = () => {
    void trigger(name);
  };

  return (
    <Controller
      name={name}
      render={({ field: { ref, onChange } }) => {
        const changeVal = event => {
          onChange(event.target.value);
          void trigger(name);
        };

        return (
          <div className={['field', classNameField, name].join(' ')}>
            <div className="field_label">
              <label className="label_left" htmlFor={fieldId}>{`${t(label)}${moreLabel}`}</label>
              <IfNot condition={isLargeScreen}>
                <EarningBonus
                  value={`+ ${centsToDollar(incentive_cents)}`}
                  condition={!fieldError && isValid}
                  hide={!incentive_cents || 0 === incentive_cents}
                />
                {show_icon && <div className={['ico', name].join(' ')} />}
              </IfNot>
            </div>
            <div className="input_wrap select">
              <MultiSelect
                className={fieldError ? 'input_select p-invalid' : 'input_select'}
                options={values}
                inputRef={ref}
                onChange={changeVal}
                onBlur={blurField}
                value={value}
                optionLabel="name"
                aria-label={ariaLabel}
                placeholder={t(placeholder)}
              />
            </div>
            <If condition={isLargeScreen}>
              {show_icon && <div className={['ico', name].join(' ')} />}
              <EarningBonus
                value={`+ ${centsToDollar(incentive_cents)}`}
                condition={!fieldError && isValid}
                hide={(fieldError && '' === fieldError.message) || !incentive_cents}
              />
            </If>
          </div>
        );
      }}
    />
  );
}
