import { GTags } from '@/constants/google_tags';
import { AppButtonProps, Button } from '@ui/lib/Button';
import { classNames } from 'primereact/utils';
import { useFormContext } from 'react-hook-form';

type Props = {
  disableOnInvalid?: boolean;
  tag?: GTags;
} & AppButtonProps;

export function FormButton(props: Props) {
  const data = useFormContext();
  const { isSubmitting, isSubmitted, isValid } = data.formState;
  const { className, tag, ...restProps } = props;

  return (
    <Button
      form={data.formId}
      className={classNames('justify-center', className)}
      big
      disabled={isSubmitting || (isSubmitted ? !isValid : false)}
      loading={isSubmitting}
      tag={tag}
      {...restProps}
    />
  );
}
