import {
  apiDeleteFriendsInvitations,
  apiGetFriends,
  apiGetFriendsInvitations,
  apiGetFriendsSettings,
  apiPostFriendsInvitations,
  apiPostFriendsReferralCode,
} from '@model/api';
import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunkWithTokenRefresh, serializeErrorOption } from '@services/reduxToolkit';
import { isSuccessResponse } from '@ui/support/utils/guards';

const name = 'friends';
const initialState = {
  /**
   * @type {{
   * data: {
   * friends: Array.<{avatar: {url: string}, name: string, status: string, pending_amount: number, awarded_amount: number, created_at: string}>,
   * totals: {friends: number, pending_amount: number, awarded_amount: number},
   * partner_level: string
   * },
   * meta: {current_page: number, next_page: number | null, prev_page: number | null, total_pages: number, total_count: number, referral_codes: Array.<string>}
   * }}
   */
  friendsGetData: {},
  /**
   * @type {{
   * data: {friends: Array.<{name: string, status: string, created_at: string}>, totals:{friends: number}},
   * meta: { current_page: number, next_page: number | null, prev_page: number | null, total_pages: number, total_count: number},
   * links: {create: {href: string, meta: {validate: {recaptcha: {public_key: string}}}}, referral_code: string}
   * }}
   */
  friendsGetInvitationsData: {},
  /**
   * @type {{enabled: boolean, referral_code: string, referral_link: string, referral_code_pattern: string, earning_type: string, show_vip_banner: boolean, refcodes: Array.<string>, invitations_available: boolean, since: string, interest_rate: number,
   * totals: {pending_invitations: number, friends: number, friends_active: number, pending_amount: number, awarded_amount: number}}}
   */
  friendsSettings: {},
  friendsDataTableLoading: false,
};

export const apiGetFriendsAsyncThunk = createAsyncThunkWithTokenRefresh(
  `api/${name}/getFriends`,
  apiGetFriends,
  serializeErrorOption
);

export const apiDeleteFriendsInvitationAsyncThunk = createAsyncThunkWithTokenRefresh(
  `api/${name}/deleteFriendsInvitation`,
  apiDeleteFriendsInvitations,
  serializeErrorOption
);

export const apiGetFriendsInvitationsAsyncThunk = createAsyncThunkWithTokenRefresh(
  `api/${name}/getFriendsInvitations`,
  apiGetFriendsInvitations,
  serializeErrorOption
);

export const apiGetFriendsSettingsAsyncThunk = createAsyncThunkWithTokenRefresh(
  `api/${name}/getFriendsSettings`,
  apiGetFriendsSettings,
  serializeErrorOption
);

export const apiPostFriendsInvitationAsyncThunk = createAsyncThunkWithTokenRefresh(
  `api/${name}/postFriendsInvitations`,
  apiPostFriendsInvitations,
  serializeErrorOption
);

export const apiPostFriendsReferralCodeAsyncThunk = createAsyncThunkWithTokenRefresh(
  `api/${name}/invitations/referral_code`,
  apiPostFriendsReferralCode,
  serializeErrorOption
);

const friendsSlice = createSlice({
  name,
  initialState,
  reducers: {
    clearSurveyId(state, _action) {
      state.surveySessionId = undefined;
    },
  },
  extraReducers(builder) {
    builder.addCase(apiGetFriendsInvitationsAsyncThunk.fulfilled, (state, action) => {
      state.friendsDataTableLoading = false;

      const { response } = action.payload;
      if (!('errors' in response)) {
        state.friendsGetInvitationsData = response;
      }
    });
    builder.addCase(apiGetFriendsInvitationsAsyncThunk.pending, state => {
      state.friendsDataTableLoading = true;
    });

    builder.addCase(apiGetFriendsAsyncThunk.fulfilled, (state, action) => {
      state.friendsDataTableLoading = false;

      const { response } = action.payload;
      if (!('errors' in response)) {
        state.friendsGetData = response;
      }
    });
    builder.addCase(apiGetFriendsAsyncThunk.pending, state => {
      state.friendsDataTableLoading = true;
    });

    builder.addCase(apiGetFriendsSettingsAsyncThunk.fulfilled, (state, action) => {
      const { response } = action.payload;

      if (!('errors' in response)) {
        state.friendsSettings = response.data;
      }
    });
    builder.addCase(apiPostFriendsReferralCodeAsyncThunk.fulfilled, (state, action) => {
      const { response } = action.payload;

      if (isSuccessResponse(response)) {
        const { referral_code } = response.data;
        state.friendsSettings = {
          ...state.friendsSettings,
          referral_code,
          referral_link:
            state.friendsSettings?.referral_link?.replace?.(state.friendsSettings.referral_code, referral_code) ?? '',
        };
      }
    });
  },
});

const defaultExport = { [name]: friendsSlice.reducer };

export default defaultExport;
