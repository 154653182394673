import { selectUsersEarnings, selectUserTrustScore } from '@model/features/user/selectors';
import { formatCurrency } from '@services/currency';
import { IconSprite } from '@ui/elements';
import { MEDIA_BP, useMediaQuery } from '@ui/hooks';
import { useAppSelector } from '@ui/hooks/redux';
import { paths } from '@ui/navigation';
import { FC } from 'react';
import { Link } from 'react-router-dom';

export const AmountInfoWidget: FC = () => {
  const isLargeScreen = useMediaQuery(MEDIA_BP.isOrGreaterThan768);

  const earnings = useAppSelector(selectUsersEarnings);
  const traitScore = useAppSelector(selectUserTrustScore);

  if (!isLargeScreen) {
    return null;
  }

  return (
    <div className="flex flex-wrap items-center justify-end">
      <Link
        to={paths.toEarnings}
        aria-label="earnings"
        className="mx-3 flex items-center whitespace-nowrap font-bitter font-semibold text-accent-b !no-underline 2xl:mx-4"
        data-role="earnings"
      >
        <span className="sr-only uppercase !tracking-1 2xl:not-sr-only">Earnings</span>
        <IconSprite iconId="dollar-circle" className="mr-2 size-5 fill-accent-b leading-none 2xl:hidden" />
        <span className="text-light-b 2xl:ml-1">{formatCurrency(earnings, 'US')}</span>
      </Link>
      <span className="mx-3 flex items-center whitespace-nowrap font-bitter font-semibold text-accent-a 2xl:mx-4">
        <span className="sr-only uppercase !tracking-1 2xl:not-sr-only">TraitScore</span>
        <IconSprite iconId="award" className="mr-2 size-5 fill-accent-a leading-none 2xl:hidden" />
        <span className="text-white 2xl:pl-1">{traitScore ? traitScore : null}</span>
      </span>
    </div>
  );
};
