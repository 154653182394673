import { InfoBalloon } from '@ui/components/InfoBalloon';
import { BALLOON } from '@ui/components/InfoBalloon/resources';
import { Button } from '@ui/lib/Button';
import { FC } from 'react';

export const ErrorFallback: FC = () => (
  <div className="flex w-full flex-col items-center">
    <div className="m-auto w-full max-w-screen-md">
      <InfoBalloon name={BALLOON.SQUIRREL_WHOOPS} />
    </div>
    <div className="mb-12 flex w-full flex-col items-center">
      <h3 className="mb-4 text-3xl">Something went wrong</h3>
      <Button label="RELOAD PAGE" onClick={handleReloadPage} />
    </div>
  </div>
);

async function handleReloadPage() {
  const href = window.location.origin;
  window.location.replace(href);
  setTimeout(() => window.location.reload(), 1000);
}
