import { UserStatusType } from '@/api/user/user-status';
import { signout } from '@model/features/auth';
import { postAcceptanceResponseAsyncThunk, userInfoAsyncThunk } from '@model/features/user/asyncActions';
import { AnyAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { hasDataInStorage, STORAGE_KEY_LIST } from '@services/storage';
import { getTimestamp } from '@services/time';
import { accessor } from '../../accessors';
import { apiCashOutAsyncThunk } from '../earnings/asyncActions';
import { name } from './name';
import { userStatusChecks } from './status';
import { ConfirmState, UserState } from './types';

export { userInfoAsyncThunk } from './asyncActions';

const initialState: UserState = {
  handledBanners: [],
  banners: [],
  referrals: {},
  timestamp: 0,
  vip: false,
  closeAccountStage: 0,
  dd2_script_url: undefined,
  isFetching: hasDataInStorage(STORAGE_KEY_LIST.AUTH_TOKEN),
};

export const slice = createSlice({
  name,
  initialState,
  reducers: {
    resetUserInfo() {
      return { ...initialState, timestamp: 0 };
    },
    updateCloseAccountStage(state, { payload }: PayloadAction<ConfirmState>) {
      state.closeAccountStage = payload;
    },
    updateUserStatus(state, { payload }: PayloadAction<{ status: UserStatusType; statusData?: any }>) {
      const { status, statusData } = payload;
      state.status = status;
      state.statusData = statusData;
    },
    updateUserInfo(state, { payload }: PayloadAction<Partial<UserState>>) {
      if (null == payload) {
        return state;
      }

      return {
        ...state,
        ...payload,
        vip: payload.referrals?.vip || false,
        // TODO: Test it
        dd2_script_url: state.dd2_script_url ?? payload.dd2_script_url,
        timestamp: payload.timestamp ?? getTimestamp(),
        isFetching: null == payload.status,
      };
    },
    updateUserProfile(state, { payload }: PayloadAction<UserState['profile']>) {
      if (null == payload) {
        return;
      }
      if (state.profile) {
        Object.assign(state.profile, payload);
      } else {
        state.profile = payload;
      }
    },
  },
  extraReducers: builder => {
    builder
      .addCase(userInfoAsyncThunk.pending, state => {
        state.timestamp = getTimestamp();
      })
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      .addCase(postAcceptanceResponseAsyncThunk.rejected, (_state, _action) => {})
      // TODO: Move deprecated AnyAction to UnknownAction type from redux-toolkit
      .addCase(postAcceptanceResponseAsyncThunk.fulfilled, (state, { meta }: AnyAction) => {
        const { body } = meta.arg;
        if (!body) {
          return;
        }
        const { token } = body;
        if (!new Set(state.handledBanners).has(token)) {
          state.handledBanners = [...state.handledBanners, token];
        }
      })
      .addCase(apiCashOutAsyncThunk.fulfilled, (state, action) => {
        state.earnings = action.payload.earnings;
      })
      .addCase(signout, () => {
        return { ...initialState, timestamp: 0 };
      });
  },
});

const defaultExport = { [name]: slice.reducer };

export default defaultExport;

export const { updateUserInfo, resetUserInfo, updateUserStatus, updateUserProfile, updateCloseAccountStage } =
  slice.actions;

export const accessUserInfo = accessor(name);

export const accessUserInfoTimestamp = () => accessUserInfo('timestamp', 0);
export const accessUserId = (fallback = 0) => accessUserInfo('id', fallback);
export const accessUserStatus = () => accessUserInfo('status', '');
/**
 * @deprecated use `selectUsersEarnings` instead
 */
export const accessUserEarnings = () => accessUserInfo(userState => userState?.earnings?.current, 0); // DEPRECATED
export const accessUserCashOutThreshold = () => accessUserInfo(userState => userState.cashout?.threshold, 0);
export const accessUserDashboardCards = () => accessUserInfo(userState => userState.dashboard?.cards, []);
export const accessUserDashboardProfileProgress = () =>
  accessUserInfo(userState => userState.dashboard?.fill_profile, null);
/**
 * isUserNotLoggedIn
 * isUserAlive
 * isUserInRegistrationPhase
 * isUserInEmailSetup
 * isUserInEmailActivation
 * isUserInUnsupportedCountry
 * isUserInForbiddenCountry
 * isUserMinor
 * isUserStatusUnidentified
 * isUserInTermsConsentPhase
 */
export const accessUserStatusChecks = () => accessUserInfo(state => userStatusChecks(state.status ?? ''));

export const accessUserCountry = () => accessUserInfo(userState => userState.profile?.country, null);

export const accessUserReferrals = () => accessUserInfo(userState => userState.referrals, {});
export const accessUserVip = () => accessUserInfo(userState => userState.vip, false);
export const accessCloseAccountStage = () => accessUserInfo(userState => userState.closeAccountStage, 0);
