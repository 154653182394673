export const makeControlledPromise = copy => new ControlledPromise(copy);
//  const api = {}
//  return Object.assign(new Promise((resolve, reject) => Object.assign(api, { resolve, reject })), api)

class ControlledPromise extends Promise {
  constructor(copy) {
    if (copy instanceof ControlledPromise) {
      return copy;
    }

    const api = {};
    super((resolve, reject) => Object.assign(api, { resolve, reject }));

    return Object.assign(this, api);
  }
  static get [Symbol.species]() {
    return Promise;
  }
  get [Symbol.toStringTag]() {
    return 'ControlledPromise';
  }
}
