export enum GTags {
  openSurvey = 'open_survey',
  closeSurvey = 'close_survey',
  returnFromSurvey = 'return_from_survey',
  signUp = 'sign_up',
  oauthSignUp = 'oauth_sign_up',
  signUpComplete = 'sign_up_completed',
  oauthSignUpComplete = 'oauth_sign_up_completed',
  privacy = 'privacy_cb',
  selectCountry = 'select_country',
  allowPushNotify = 'allow_push_notifications',
  allowLocation = 'allow_location',
  welcomeCompleted = 'welcome_completed',
  fillProfile = 'fill_profile',
  fillProfileCompleted = 'fill_profile_completed',
  surveyPageBack = 'post_survey_page_back',
  surveyPageHistory = 'post_survey_page_history',
  tagCompleted = 'tag_completed',
  surveyCompleted = 'survey_completed',
  googlePlay = 'google_play',
  appStore = 'app_store',
}
