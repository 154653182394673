import * as yup from 'yup';
import { yupStringOrNumber } from '@model/api/endpointSchema';

export const schemaProfilingGetStepParamsRequest = yup
  .object({
    stepId: yup.string().required(),
  })
  .required();
export const schemaProfilingGetStepResponse = yup
  .object({
    data: yup
      .object({
        id: yup.string().required(),
        name: yup.string().required(),
        labels: yup.object().nullable(),
        fields: yup.array().required(),
      })
      .required(),
    links: yup
      .object({
        prev_id: yup.string(), // "step0",
        prev: yup.string(), //  "/api/v1/profiling/steps/step0",
      })
      .required(),
    meta: yup
      .object({
        progress: yup.number(),
        current_step: yup.number(), //  1,
        total_steps: yup.number(), // 5,
        completed: yup.boolean(), // false
      })
      .required(),
  })
  .required();
export const schemaProfilingPutStepParamsRequest = yup
  .object({
    stepId: yupStringOrNumber.required(),
  })
  .required();
export const schemaProfilingPutStepBodyRequest = yup
  .object({
    step: yup.object().required(),
  })
  .required();

export const schemaProfilingStepPutRequestForm = yup
  .object({
    stepId: yupStringOrNumber.required(),
    data: yup.object(),
  })
  .required();

export const schemaProfilingPutStepResponse = yup
  .object({
    links: yup.object({
      location: yup.string(), // '/api/v1/profiling/steps/step1'
    }),
    meta: yup.object({
      completed: yup.boolean(),
    }),
  })
  .required();
