import { Logger } from '@/utils/Logger';
import {
  accessIsMobile,
  accessSurveySessionId,
  accessExitLink,
  accessSurveyWidgetCommand,
  getQuestionsAsyncThunk,
  getSessionIdAsyncThunk,
  selectPollingTimeout,
  setSurveyError,
  setSurveyResult,
} from '@model/features/survey';
import { getSurveysAsync } from '@model/features/survey/action-creators';
import { accessUserId, accessUserStatusChecks } from '@model/features/user';
import { unwrapResult } from '@reduxjs/toolkit';
import { getAPIUrl } from '@services/context';
import { installResources } from '@services/resources';
import { useAppSelector } from '@ui/hooks/redux';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resources } from './survey.resources';

const SurveyContext = React.createContext(null);

const apiHost = getAPIUrl('/');

SurveyProvider.propTypes = {
  children: PropTypes.any,
};

export function SurveyProvider({ children }) {
  const [isServiceRequested, setIsServiceRequested] = useState(false);
  const [scriptsAreReady, setScriptsAreReady] = useState(false);
  const [surveyIsReady, setSurveyIsReady] = useState(false);
  const [showExitDialog, setShowExitDialog] = useState(false);
  const [surveyRef, setSurveyRef] = useState(null);
  const exitLink = useSelector(accessExitLink());

  const dispatch = useDispatch();
  const surveySessionId = useSelector(accessSurveySessionId());
  const command = useSelector(accessSurveyWidgetCommand());

  const [scriptsAreFailed, setScriptsAreFailed] = useState(false);
  const userId = useSelector(accessUserId());
  const { isUserAlive } = useSelector(accessUserStatusChecks());

  const resourcesContext = useRef({});
  const isMobile = useSelector(accessIsMobile());

  const surveyPollingTm = useAppSelector(selectPollingTimeout);

  useEffect(() => {
    if (isServiceRequested) {
      resourcesContext.current = installResources(resources);
      Logger.info('SurveyProvider::resourcesContext', resourcesContext);
      resourcesContext.current.readinessPromise
        .then(() => {
          setScriptsAreReady(true);
        })
        .catch(() => {
          setScriptsAreFailed(true);
        });
    }
  }, [isServiceRequested]);

  const requestSurveyId = useCallback(
    async surveyInvitationCode => {
      await resourcesContext.current.readinessPromise;
      dispatch(getSessionIdAsyncThunk({ surveyInvitationCode })).then(unwrapResult);
    },
    [dispatch]
  );

  const requestSurvey = useCallback(
    async sessionId => {
      await resourcesContext.current.readinessPromise;
      dispatch(getQuestionsAsyncThunk({ sessionId })).then(unwrapResult);
    },
    [dispatch]
  );

  const requestAvailableSurveys = useCallback(
    async userId => {
      await resourcesContext.current.readinessPromise;
      dispatch(getSurveysAsync({ userId }));
    },
    [dispatch]
  );

  const installScripts = useCallback(() => {
    setIsServiceRequested(true);
  }, []);

  const receiveWidgetResponse = useCallback(
    data => {
      if (!data) {
        dispatch(
          setSurveyError({
            error: {
              context: {
                entry_status: 'inactive_queue',
              },
            },
          })
        );
        return;
      }
      const formattedData = data;
      if (!formattedData.text) formattedData.text = 'remove me into contextProviders::survey::index.js';
      /*if (data.action && 'show' === data.action) {
        data.blocks.forEach((block, _idx) => {
          if ('title' === block.type) formattedData.title = block.text
          if ('text' === block.type) formattedData.text += block.text + '<br/>'
        })
      }*/
      if (formattedData.errors && formattedData.errors[0]) dispatch(setSurveyError(formattedData.errors[0]));
      else dispatch(setSurveyResult(formattedData));
    },
    [dispatch]
  );

  useEffect(() => {
    if (isServiceRequested && surveySessionId) {
      requestSurvey(surveySessionId);

      if (surveyPollingTm) {
        clearTimeout(surveyPollingTm);
      }
    }
  }, [surveySessionId, requestSurvey, isServiceRequested, surveyPollingTm]);

  useEffect(() => {
    if (!userId || !isUserAlive) return;
    void requestAvailableSurveys(userId);
  }, [userId, isUserAlive, requestAvailableSurveys]);

  useEffect(() => {
    if (isServiceRequested && command) {
      if ('show' === command.action && !command.action_url) {
        receiveWidgetResponse(command);
      } else {
        window.initAYTMSurvey({
          commandTree: command,
          token: null,
          host: apiHost,
          mobile: isMobile,
          ref: surveyRef,
          callback: receiveWidgetResponse,
          exitCallback:
            exitLink && exitLink.link
              ? () => {
                  setShowExitDialog(true);
                }
              : null,
          initCallback: () => {
            setSurveyIsReady(true);
          },
        });
      }
    }
  }, [command, command?.action_url, isServiceRequested, receiveWidgetResponse, isMobile, surveyRef, exitLink]);

  const value = useMemo(() => {
    return {
      installScripts,
      scriptsAreReady,
      scriptsAreFailed,
      surveySessionId,
      surveyIsReady,
      setSurveyRef,
      requestSurveyId,
      requestAvailableSurveys,
      requestSurvey,
      showExitDialog,
      setShowExitDialog,
    };
  }, [
    installScripts,
    requestSurvey,
    requestSurveyId,
    scriptsAreReady,
    surveyIsReady,
    scriptsAreFailed,
    setSurveyRef,
    surveySessionId,
    requestAvailableSurveys,
    showExitDialog,
    setShowExitDialog,
  ]);

  return <SurveyContext.Provider value={value}>{children}</SurveyContext.Provider>;
}

export const useSurveyContext = () => {
  const context = useContext(SurveyContext);
  if (!context) {
    throw new Error(
      `Invalid usage. 'useSurveyContext()' needs to be called in elements descending from <SurveyProvider />`
    );
  }
  return context;
};
