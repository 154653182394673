import { sharingUrls, socialUrls } from '@/constants/socialUrls';
import { dismissDialog } from '@model/features/dialogs';
import { ISWidgetResultShow } from '@model/features/survey/types';
import { formatCurrency } from '@services/currency';
import { getPercentFrom } from '@services/math/percentage';
import { useLocaleServices } from '@ui/contextProviders/Locale';
import { CircularProgressBar, IconSprite } from '@ui/elements';
import { IconAward, IconFacebookColorless, IconInstagramColorless } from '@ui/elements/icons';
import { useAppDispatch } from '@ui/hooks/redux';
import { Button } from '@ui/lib/Button';
import { Icon, Icons } from '@ui/lib/Icon';
import { RouterLink } from '@ui/lib/Link/AppLink';
import { FC, useEffect, useState } from 'react';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';

import { GTags } from '@/constants/google_tags';
import styles from '../survey.module.scss';
import './dialog-content.scss';

type DialogContentProps = {
  dialogName: string;
  data: ISWidgetResultShow['data'];
  onWhyClick?: () => void;
};

export const DialogContent: FC<DialogContentProps> = ({ dialogName, data, onWhyClick }) => {
  const { t } = useLocaleServices();
  const dispatch = useAppDispatch();

  const { why, trait_score, earnings, message, error, notice, referral_link, earning, subtitle, hint } = data;
  const [values, setValues] = useState({
    earnings: earnings.from,
    traitScore: trait_score.from,
  });

  useEffect(() => {
    // Update state to animate progress
    const tm = window.setTimeout(() => {
      setValues({
        earnings: earnings.to,
        traitScore: trait_score.to,
      });
    }, 300);
    return () => window.clearTimeout(tm);
  }, [earnings, trait_score]);

  const dismissModalDialog = () => {
    dispatch(dismissDialog(dialogName));
  };

  return (
    <>
      <div className="flex w-full flex-col items-center overflow-y-auto pt-2 text-center">
        <div className="[&>p]:whitespace-pre-line">
          <ReactMarkdown className="whitespace-pre text-center font-bitter text-body-l text-accent-b md:text-title-l">
            {t(message.label, message.i18n)}
          </ReactMarkdown>
          {subtitle?.label && (
            <ReactMarkdown className="my-6 text-center text-body-l text-dark-h dark:text-accent-a">
              {t(subtitle.label, subtitle.i18n)}
            </ReactMarkdown>
          )}
          {hint?.label && (
            <ReactMarkdown className="text-center text-body-s text-dark-h dark:text-accent-a">
              {t(hint.label)}
            </ReactMarkdown>
          )}
          {notice != null && (
            <ReactMarkdown className="mt-8 text-center text-body-l text-accent-a">
              {t(notice.label, notice.i18n)}
            </ReactMarkdown>
          )}
          {error != null && (
            <ReactMarkdown className="mt-6 text-center text-body-l text-error">
              {t(error.label).replace('\\n', '&#10;')}
            </ReactMarkdown>
          )}
        </div>
        <div className="mt-8 flex flex-col sm:flex-row sm:justify-center md:mt-18">
          <div className="w-full max-w-[12.5rem] lg:w-[12.5rem]">
            <CircularProgressBar
              width="100%"
              className="survey-result pink"
              progress={getPercentFrom(values.earnings, earnings.max)}
              progressColor="rgb(var(--pv-accent-b))"
              bgColor="rgb(var(--pv-dark-e))"
              title={formatCurrency(earnings.to, 'US')}
              size={40}
              pointSize={16}
              viewBox="-10 -10 384 289"
              icon={<Icon className={'h-full w-full !text-3xl text-accent-b'} icon={Icons.DollarCircle} />}
            />
            <div className="mt-4 font-bitter text-body font-bold uppercase max-md:text-light-b md:mt-8">
              {t('surveys.earnings')}
            </div>
            {earning.component.notice && (
              <ReactMarkdown className="mt-1 text-body">
                {t(earning.component.notice.label, earning.component.notice.i18n)}
              </ReactMarkdown>
            )}
            {'link' === earning.component.action && (
              <RouterLink
                className="mt-2 inline-block capitalize"
                variant="accent-b"
                to={earning.component.link.url}
                onClick={dismissModalDialog}
                state={{ backToDashboard: true }}
                tag={GTags.surveyPageHistory}
              >
                {t(earning.component.name.label)}
              </RouterLink>
            )}
          </div>
          <div className="mx-20 mt-8 h-[7.5rem] border-l border-dashed border-dimmed-d max-md:hidden" />
          <div className="mt-12 w-full max-w-[12.5rem] sm:ml-6 sm:mt-0 md:ml-0 lg:w-[12.5rem]">
            <CircularProgressBar
              width="100%"
              progress={getPercentFrom(values.traitScore, trait_score.max)}
              className="survey-result blue"
              progressColor="rgb(var(--pv-accent-a))"
              bgColor="rgb(var(--pv-dark-e))"
              title={trait_score.to}
              size={40}
              pointSize={16}
              viewBox="-10 -10 384 289"
              icon={<IconAward className="h-full w-full" />}
            />
            <div className="mt-4 font-bitter text-body font-bold uppercase max-md:text-light-b md:mt-8">
              {t('surveys.traitscore')}
            </div>
            {why.component.notice && (
              <ReactMarkdown className="mt-1 text-body">
                {t(why.component.notice.label, why.component.notice.i18n)}
              </ReactMarkdown>
            )}
            {'link' === why.component.action ? (
              <RouterLink
                className="mt-2 inline-block capitalize"
                to={why.component.link.url}
                onClick={dismissModalDialog}
                tag={GTags.surveyPageBack}
              >
                {t(why.component.name.label)}
              </RouterLink>
            ) : (
              <Button textButton variant="primary" className="mt-2 capitalize" label="Learn why" onClick={onWhyClick} />
            )}
          </div>
        </div>
      </div>
      <div className={`mt-15 flex justify-center sm:mt-16 ${styles.icons_wrapper}`}>
        <a
          href={`${sharingUrls.facebook}?u=${encodeURIComponent(referral_link ?? location.origin)}`}
          target="_blank"
          rel="noreferrer"
          className="mr-12"
        >
          <IconFacebookColorless className="icon-gray h-8 w-8" />
        </a>
        <a href={socialUrls.insta} target="_blank" rel="noreferrer" className="mr-12">
          <IconInstagramColorless className="icon-gray h-8 w-8" />
        </a>
        <a
          target="_blank"
          href={sharingUrls.twitter}
          className="twitter-share-button"
          data-url={referral_link ?? location.origin}
          data-show-count="false"
          rel="noreferrer"
        >
          <IconSprite iconId={'x-twitter'} className="icon-gray h-8 w-8" />
        </a>
      </div>
      <div className="text-gray mt-8 text-center">{t('surveys.social')}</div>
    </>
  );
};
