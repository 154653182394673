import { numberOfDaysInMonth } from '@services/time/date';
import { useMemo } from 'react';
import { presetDateFields } from './presetFields.data';

export function useFieldsBasedOnMonthAndYear({ month, year }) {
  const daysCount = numberOfDaysInMonth(month, year);
  return useMemo(
    () => [
      presetDateFields[0], // months
      {
        ...presetDateFields[1],
        values: presetDateFields[1].values.slice(0, daysCount),
      }, // dates
      presetDateFields[2], // years
    ],
    [daysCount]
  );
}
