import { accessor } from '@model/accessors';
import { createSlice } from '@reduxjs/toolkit';
import { getTimestamp } from '@services/time';

const name = 'dialogs';
const initialState = {};

const slice = createSlice({
  name,
  initialState,
  reducers: {
    resetDialog(state, { payload: name }) {
      delete state[name];
    },
    updateDialogStateOnStart: {
      reducer(state, { payload: actionPayload, meta }) {
        const { name, payload } = actionPayload;
        const { timestamp } = meta;
        state[name] = {
          ...(state[name] ?? {}),
          name,
          show: true,
          shownAt: timestamp,
          updated: timestamp,
          onShowData: payload,
        };
      },
      prepare: (payload, meta) => Object({ payload, meta }),
    },
    updateDialogStateOnSave: {
      reducer(state, { payload: actionPayload, meta }) {
        const { name, payload } = actionPayload;
        const { timestamp } = meta;
        state[name] = {
          ...(state[name] ?? {}),
          name,
          updated: timestamp,
          onSaveData: payload,
        };
      },
      prepare: (payload, meta) => Object({ payload, meta }),
    },
    updateDialogStateOnFinish: {
      reducer(state, { payload: actionPayload, meta }) {
        const { name, payload } = actionPayload;
        const { timestamp } = meta;
        state[name] = {
          ...(state[name] ?? {}),
          name,
          show: false,
          hiddenAt: timestamp,
          updated: timestamp,
          onHideData: payload,
        };
      },
      prepare: (payload, meta) => Object({ payload, meta }),
    },
  },
});

const defaultExport = { [name]: slice.reducer };

export default defaultExport;

export const {
  resetDialog,
  updateDialogStateOnStart,
  updateDialogStateOnSave,
  updateDialogStateOnFinish,
  setDialog,
  openDialog,
  closeDialog,
} = slice.actions;

const accessDialogState = accessor(name);
export const accessDialogShown = dialogName => accessDialogState(dialogState => dialogState?.[dialogName]?.show, false);
export const accessDialogData = dialogName => accessDialogState(dialogName, {});

function dialogOperation(actionCreator, name, payload) {
  const effectivePayload = 'object' === typeof payload ? payload : { invalidPayload: payload };
  return dispatch => {
    dispatch(actionCreator({ name, payload: effectivePayload }, { timestamp: getTimestamp() }));
  };
}

export function invokeDialog(name, payload) {
  return dialogOperation(updateDialogStateOnStart, name, payload);
}

export function updateDialog(name, payload) {
  return dialogOperation(updateDialogStateOnSave, name, payload);
}

export function dismissDialog(name, payload) {
  return dialogOperation(updateDialogStateOnFinish, name, payload);
}
