import { FC, ImgHTMLAttributes } from 'react';

type PictureResourceProps = {
  path: string;
  name: string;
  includeX2?: boolean;
} & ImgHTMLAttributes<HTMLImageElement>;

export const PictureResource: FC<PictureResourceProps> = ({ name, path, includeX2, className, ...props }) => {
  const sourceSrcSet = includeX2 ? `${path}/${name}.webp, ${path}/${name}@2x.webp 2x` : `${path}/${name}.webp`;
  return (
    <picture className={className}>
      <source srcSet={sourceSrcSet} type="image/webp" />
      <img {...props} src={`${path}/${name}.png`} srcSet={includeX2 ? `${path}/${name}@2x.png 2x` : undefined} />
    </picture>
  );
};
