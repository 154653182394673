import { didManager } from '@modules/pvdid';
import { dataCollector } from '@services/fcm/dataCollector';
import { makeControlledPromise, safelyAwait } from '@services/promises';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useRef } from 'react';

const DidScriptContext = React.createContext(null);

DidScriptProvider.propTypes = {
  children: PropTypes.any,
  value: PropTypes.object,
};
export function DidScriptProvider({ children, value }) {
  const didManagerRef = useRef(null);
  const didRef = useRef(makeControlledPromise());

  useEffect(() => {
    if (didManagerRef.current) {
      return;
    }
    didManagerRef.current = (async () => {
      const [error, didCode] = await safelyAwait(didManager.setContext());
      if (error) {
        throw error;
      }
      didRef.current.resolve(error ? '' : didCode);
      dataCollector.putData({
        type: 'did',
        value: didCode,
      });
    })();
  }, []);

  return <DidScriptContext.Provider value={value || didRef.current}>{children}</DidScriptContext.Provider>;
}

export const useDidCodePromise = () => {
  const result = useContext(DidScriptContext);
  if ('undefined' === typeof result) {
    // eslint-disable-next-line unicorn/prefer-type-error
    throw new Error(
      `Invalid usage. 'useDidScriptContext()' needs to be called in elements descending from <DidScriptProvider />`
    );
  }
  return result;
};
