import { Keys, Values } from '@/types';

type Stringified<T extends string | number | bigint | boolean | null | undefined> = `${T}`;

type ReversedType<T extends object> = {
  [key in Stringified<
    Values<T> extends string | number | bigint | boolean | null | undefined ? Values<T> : never
  >]: Keys<T>[];
};

export function makeReverseObject<T extends object>(object: T): ReversedType<T> {
  if (!object || Object(object) !== object) {
    return {} as ReversedType<T>;
  }
  return Object.entries(object).reduce((memo, [v, k]) => {
    if (Array.isArray(k)) {
      return k.reduce((memo2, k2) => {
        memo2[k2] = memo[k2] ? [...memo[k2], v] : [v];
        return memo2;
      }, memo);
    } else {
      memo[k] = memo[k] ? [...memo[k], v] : [v];
    }
    return memo;
  }, {});
}
