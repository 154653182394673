import { FIELD_TYPES, onlyRequestedValidations } from '@services/fields';
import { FIELD_NAMES } from '@services/fields/fieldNames';
import { extraMessages } from '@ui/components/SmartFormComponents/elements';
import { useLocaleServices } from '@ui/contextProviders';
import { MEDIA_BP, useMediaQuery } from '@ui/hooks';
import { Icon, Icons } from '@ui/lib/Icon';
import { Tooltip } from 'primereact/tooltip';
import { classNames } from 'primereact/utils';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router';
import pvs from './profilingFieldWrapper.module.scss';

import { RouterLink } from '@ui/lib/Link/AppLink';
import { routeNames } from '@ui/routes/routeNames';
import './profiling-field-wrapper.scss';

ProfilingFieldWrapper.propTypes = {
  fieldId: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.object,
  metadata: PropTypes.object,
  children: PropTypes.any,
  type: PropTypes.string,
  fieldWrapClass: PropTypes.string,
  labelClass: PropTypes.string,
  fieldClass: PropTypes.string,
  errorClass: PropTypes.string,
  labelErrorClass: PropTypes.string,
};

function getIsSpecialTreatment(name) {
  return FIELD_NAMES.DEMOGRAPHICS_PARENTAL_FIELD_NAME === name;
}

export function ProfilingFieldWrapper({
  fieldId,
  name,
  label,
  children,
  options,
  type,
  metadata,
  fieldWrapClass,
  labelClass,
  fieldClass,
  errorClass,
  labelErrorClass,
}) {
  const { t } = useLocaleServices();
  const { moreError, checkbox_label: checkboxLabel, footerLabel, tooltipIcon } = options || {};
  const { formState, watch, getFieldState, trigger, setFocus, clearErrors } = useFormContext();
  const { isSubmitted, isSubmitSuccessful } = formState;
  const navigate = useNavigate();
  const isMobile = useMediaQuery(MEDIA_BP.isMobile);
  void watch(name);

  const isSpecialTreatment = getIsSpecialTreatment(name);
  const showError = !isSpecialTreatment;

  // TODO: Discuss with Vlad how to fix this shit
  const showLabel = type !== FIELD_TYPES.ADDRESS && type !== FIELD_TYPES.PROFILING_BIRTHDATE;
  const isCheckbox = type === FIELD_TYPES.CHECKBOX || type === FIELD_TYPES.CHECKBOX_CLEAN;

  const { error: fieldError, isTouched } = getFieldState(isSpecialTreatment ? FIELD_NAMES.PARENTAL_FIELD_NAME : name);

  const isHidden = metadata.hidden;
  const isBare = metadata.bare;

  const handleForgotPassword = async event => {
    event.preventDefault();
    const result = await trigger('email');
    if (!result) {
      setFocus('email');
      clearErrors('password');
    } else {
      navigate(routeNames.usersPasswordForgot);
    }
  };

  const showErrorMessage = () => {
    if (!fieldError || !showError) {
      return null;
    }

    if ('profiling_birthdate' === fieldError.ref?.name) {
      return isSubmitted ? (
        <div className={errorClass ?? pvs.error}>
          {fieldError.message && (
            <small className="p-error">{t(String(fieldError.message), onlyRequestedValidations(options))}</small>
          )}
        </div>
      ) : null;
    }
    return (
      <div className={errorClass ?? pvs.error}>
        {fieldError.message && (
          <small className="p-error">{t(String(fieldError.message), onlyRequestedValidations(options))}</small>
        )}
        {moreError && <small className="p-error">{extraMessages({ message: moreError, t, options })}</small>}
      </div>
    );
  };

  if (isBare) {
    return isHidden ? <div className="hidden">{children}</div> : children;
  }

  if (isCheckbox) {
    return (
      <div className={classNames('profiling-field', { hidden: isHidden })}>
        <div className={pvs.field}>
          {checkboxLabel ? (
            <span className="flex items-center pl-6 max-md:mr-8">
              <label htmlFor={fieldId} className="pv-checkbox-label" data-name={name}>
                {children} {extraMessages({ message: checkboxLabel, t, options })}
              </label>
              {tooltipIcon && (
                <>
                  <Tooltip
                    event="focus"
                    target=".settings-help"
                    position={isMobile ? 'bottom' : 'right'}
                    className="min-w-[150px] max-w-sm max-md:[&>.p-tooltip-arrow]:hidden"
                    content={t('login.keep_me_logged_in_tooltip')}
                  />
                  <Icon
                    icon={tooltipIcon.icon || Icons.Question}
                    tabIndex={0}
                    className="settings-help ml-2 cursor-pointer text-base leading-3 hover:text-blue-a"
                  />
                </>
              )}
            </span>
          ) : (
            children
          )}
        </div>
        <div className={pvs.label}>{label && <label htmlFor={fieldId}>{t(label)}</label>}</div>
        {fieldError && (isTouched || (isSubmitted && !isSubmitSuccessful)) && (
          <>
            <div className={pvs.error}>
              <span className="p-error">{t(String(fieldError?.message), onlyRequestedValidations(options))}</span>
            </div>
            {moreError && (
              <div className={pvs.error}>
                <span>{extraMessages({ message: moreError, t, options })}</span>
              </div>
            )}
          </>
        )}
      </div>
    );
  }

  return (
    <div
      className={classNames(fieldWrapClass ? fieldWrapClass : 'profiling-field', {
        '!hidden': isHidden,
      })}
    >
      {showLabel && (
        <label
          className={classNames(labelClass, 'mb-2', {
            [labelErrorClass]: fieldError && labelErrorClass,
          })}
          htmlFor={fieldId}
        >
          {t(label)}
        </label>
      )}

      <div className={fieldClass ?? pvs.field}>{children}</div>

      {showErrorMessage()}

      {footerLabel && (
        <div className="ml-6 mt-2">
          <RouterLink
            onClick={handleForgotPassword}
            className="!p-0 !text-body-s"
            variant="accent-c"
            aria-label="forgot password"
            data-role="forgot password"
          >
            {t(footerLabel)}
          </RouterLink>
        </div>
      )}
    </div>
  );
}
